import { Dispatch, FC, SetStateAction, useCallback, useEffect, useState } from 'react';

import s from './UserModal.module.css';
import { Button } from '@consta/uikit/Button';
import { Modal } from '@consta/uikit/Modal';
import { Text } from '@consta/uikit/Text';
import { TextField } from '@consta/uikit/TextField';

interface IModalProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

const UserModal: FC<IModalProps> = ({ isOpen, setIsOpen }) => {
  const [login, setLogin] = useState<string>('');

  const handleSetUser = useCallback(() => {
    localStorage.setItem('user', login);
    setIsOpen(false);
  }, [login, setIsOpen]);

  useEffect(() => {
    const user = localStorage.getItem('user');
    if (user) {
      setLogin(user);
    }
  }, []);

  useEffect(() => {
    const keyEnterListener = (event: KeyboardEvent) => {
      if (event.key === 'Enter' && isOpen) {
        event.preventDefault();
        handleSetUser();
      }
    };

    document.addEventListener('keydown', keyEnterListener);

    return () => {
      document.removeEventListener('keydown', keyEnterListener);
    };
  }, [login, handleSetUser, isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      className={s.wrapper}
      hasOverlay
      onClickOutside={() => setIsOpen(false)}
      onEsc={() => setIsOpen(false)}
    >
      <>
        <Text as="h1" size="l" view="secondary">
          Авторизация
        </Text>
        <TextField value={login} onChange={({ value }) => setLogin(value ? value : '')} withClearButton />
        <div className={s.action__btns}>
          <Button size="m" view="ghost" label="Отменить" width="default" onClick={() => setIsOpen(false)} />
          <Button size="m" view="primary" label="Сохранить" width="default" onClick={() => handleSetUser()} />
        </div>
      </>
    </Modal>
  );
};

export default UserModal;
