import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { MenuItemType } from '../../../types/types';
import classes from './SubMenuItem.module.css';
import { Text } from '@consta/uikit/Text';
import classNames from 'classnames';
import { Popover } from '@consta/uikit/Popover';
import { Card } from '@consta/uikit/Card';
import { IconArrowRight } from '@consta/uikit/IconArrowRight';

type PropsType = {
  item: MenuItemType;
};

const SubMenuItem: React.FC<PropsType> = ({ item }) => {
  const navigate = useNavigate();
  const itemHasChildren = item.children?.length;
  const itemContainer = useRef(null);
  const [isPopoverVisible, setIsPopoverVisible] = useState(false);
  const itemContainerClassName = itemHasChildren
    ? classNames(classes.container_withChildren, isPopoverVisible && classes.itemHighlighted)
    : classes.container;
  const itemTextClassName = item.children ? classes.title_withChildren : classes.title;

  const onClick = (targetItem: MenuItemType) => {
    if (targetItem.href) {
      navigate(targetItem.href);
    } else if (targetItem.extUrl) {
      window.open(targetItem.extUrl, '_blank', 'noreferrer');
    }
  };

  const handleMouseEnter = () => {
    itemHasChildren && setIsPopoverVisible(true);
  };

  const handleMouseLeave = () => {
    itemHasChildren && setIsPopoverVisible(false);
  };

  if (item.isDelimiter) {
    return (
      <div className={classes.delimiterContainer}>
        <Text className={classes.titleDelimiter}>{item.label}</Text>
        <div className={classes.delimiter} />
      </div>
    );
  }

  return (
    <div
      ref={itemContainer}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className={itemContainerClassName}
    >
      <Text
        className={classNames(itemTextClassName, { [classes.disabled]: item.disabled })}
        onClick={() => onClick(item)}
      >
        {item.label}
      </Text>
      {item.children && (
        <>
          <IconArrowRight className={classes.arrowIcon} />
          {isPopoverVisible && (
            <Popover anchorRef={itemContainer} style={{ zIndex: 2000 }} direction="rightStartUp" placeholder={''}>
              <Card border={true} className={classes.popover}>
                {item.children.map((child, index) => (
                  <div key={index} className={classes.container}>
                    <Text
                      className={classNames(classes.title, { [classes.disabled]: item.disabled })}
                      onClick={() => onClick(child)}
                    >
                      {child.label}
                    </Text>
                  </div>
                ))}
              </Card>
            </Popover>
          )}
        </>
      )}
    </div>
  );
};

export default SubMenuItem;
