import React from 'react';

import classes from './SectionDelimiter.module.css';

type PropsType = {
  groupsAmount: number;
};

const SectionDelimiter: React.FC<PropsType> = ({ groupsAmount }) => {
  const groups = [];
  for (let i = 0; i < groupsAmount; i++) {
    groups.push(i);
  }

  return (
    <div className={classes.container}>
      {groups.map((group) => {
        return (
          <div className={classes.cardContainer} key={group}>
            <div className={classes.centerVerticalLine} />
          </div>
        );
      })}
    </div>
  );
};

export default SectionDelimiter;
