import React from 'react';
import { useParams } from 'react-router-dom';

import { onLogoClickRoute } from '../../../appMenuAndRouting/appRoutes';
import BreadCrumbsRow from '../../../Components/BreadCrumbsRow/BreadCrumbsRow';
import LayoutOrdinaryPage from '../../../Components/LayoutOrdinaryPage/LayoutOrdinaryPage';
import { useBlogCase } from '../../../hooks/useBlogCase';
import AppFooter from '../../../shared-front/AppFooter/AppFooter';
import classes from './BlogContentCase.module.css';
import BlogContentCaseMain from './BlogContentCaseMain/BlogContentCaseMain';
import { Loader } from '@consta/uikit/Loader';

interface IBlogContentCaseProps {}

const BlogContentCase: React.FC<IBlogContentCaseProps> = () => {
  let { id } = useParams();
  const [blogCase, isLoading] = useBlogCase(id);

  return (
    <div className={classes.container}>
      <LayoutOrdinaryPage>
        {isLoading ? (
          <Loader style={{ marginTop: '80px' }} />
        ) : (
          <>
            <BreadCrumbsRow label={blogCase?.name} />
            <BlogContentCaseMain blogCase={blogCase} />
          </>
        )}
      </LayoutOrdinaryPage>
      {/*<AppFooter*/}
      {/*  portalName={'Дата-Платформе'}*/}
      {/*  questionEmail={'data-platform@gazprom-neft.ru'}*/}
      {/*  onLogoClickRoute={onLogoClickRoute}*/}
      {/*/>*/}
    </div>
  );
};

export default BlogContentCase;
