import React from 'react';

import BlogShareBlock from '../../../../../Components/BlogShareBlock/BlogShareBlock';
import BlogSuggestionsBlock from '../../../../../Components/BlogSuggestionsBlock/BlogSuggestionsBlock';
import classes from './RightColumn.module.css';

const RightColumn: React.FC = () => {
  return (
    <div className={classes.container}>
      <BlogShareBlock title={`Знаете, кому будет интересен этот\nматериал? Отправьте ссылку на почту в\nодин клик`} />
      <BlogSuggestionsBlock />
    </div>
  );
};

export default RightColumn;
