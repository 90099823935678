import React from 'react';
import { useSearchParams } from 'react-router-dom';

import EmptyDocumentsPlaceholder from '../../../../../../Components/EmptyDocumentsPlaceholder/EmptyDocumentsPlaceholder';
import { useAppSelector } from '../../../../../../hooks/hooks';
import {
  selectorDocumentFormats,
  selectorDocumentStatuses,
  selectorDocumentTypes,
  selectorMethodologyDocuments,
} from '../../../../../../store/methodologySlice';
import { MethodologySearchEnum } from '../../../../../../types/types';
import { getTableFilters } from '../../../../../../utils/functions';
import classes from './DocumentsTable.module.css';
import { SelectedFilters } from '@consta/uikit/__internal__/src/components/Table/filtering';
import { Table, TableFilters } from '@consta/uikit/Table';
import { TableColumn } from '@consta/uikit/Table';
import classNames from 'classnames';

const DocumentsTable: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const documents = useAppSelector(selectorMethodologyDocuments);
  const documentTypes = useAppSelector(selectorDocumentTypes);
  const documentFormats = useAppSelector(selectorDocumentFormats);
  const documentStatuses = useAppSelector(selectorDocumentStatuses);

  const documentsRows = documents.map((document) => ({
    id: document._uuid,
    title: document.title,
    type: document.type,
    format: document.format,
    status: document.status,
  }));

  const documentsColumns: TableColumn<(typeof documentsRows)[number]>[] = [
    {
      title: 'Заголовок',
      accessor: 'title',
      align: 'left',
      sortable: true,
    },
    {
      title: 'Тип',
      accessor: 'type',
      align: 'left',
      sortable: true,
    },
    {
      title: 'Формат',
      accessor: 'format',
      align: 'left',
      sortable: true,
    },
    {
      title: 'Статус',
      accessor: 'status',
      align: 'left',
      sortable: true,
    },
  ];

  const typeFilters = getTableFilters(documentTypes, 'type');
  const formatFilters = getTableFilters(documentFormats, 'format');
  const statusFilters = getTableFilters(documentStatuses, 'status');
  const filters: TableFilters<(typeof documentsRows)[number]> = [...typeFilters, ...formatFilters, ...statusFilters];

  const onFiltersUpdated = (filters: SelectedFilters) => {
    if (filters['format'].selected.length > 0) {
      const filterLabel = filters['format'].selected[0];
      if (!searchParams.getAll(MethodologySearchEnum.documentFormat).includes(filterLabel)) {
        searchParams.append(MethodologySearchEnum.documentFormat, filterLabel);
      }
      setSearchParams(searchParams);
    }

    if (filters['status'].selected.length > 0) {
      const filterLabel = filters['status'].selected[0];
      if (!searchParams.getAll(MethodologySearchEnum.documentStatus).includes(filterLabel)) {
        searchParams.append(MethodologySearchEnum.documentStatus, filterLabel);
      }
      setSearchParams(searchParams);
    }

    if (filters['type'].selected.length > 0) {
      const filterLabel = filters['type'].selected[0];
      if (!searchParams.getAll(MethodologySearchEnum.documentType).includes(filterLabel)) {
        searchParams.append(MethodologySearchEnum.documentType, filterLabel);
      }
      setSearchParams(searchParams);
    }
  };

  const onRowClick = ({ id }: { id: string }) => {
    searchParams.append(MethodologySearchEnum.documentUuid, id);
    setSearchParams(searchParams);
  };

  return (
    <div className={classNames(classes.container, { [classes.noBottomBorder]: documents.length === 0 })}>
      <Table
        columns={documentsColumns}
        rows={documentsRows}
        filters={filters}
        borderBetweenColumns
        borderBetweenRows
        stickyHeader
        isResizable
        className={classes.table}
        emptyRowsPlaceholder={<EmptyDocumentsPlaceholder />}
        isExpandedRowsByDefault={true}
        defaultExpandAll={true}
        onFiltersUpdated={onFiltersUpdated}
        onRowClick={onRowClick}
      />
    </div>
  );
};

export default DocumentsTable;
