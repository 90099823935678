import React from 'react';
import { useNavigate } from 'react-router-dom';

import { PageRoutesEnum } from '../../../appMenuAndRouting/appRoutes';
import bannerLearningCourses from '../../../img/learningPage/bannerLearningCourses.png';
import classes from './LearningAllVideosHeader.module.css';
import { Breadcrumbs } from '@consta/uikit/Breadcrumbs';
import { Text } from '@consta/uikit/Text';

const LearningAllVideosHeader: React.FC = () => {
  const breadcrumbs = [
    {
      label: 'Главная',
      route: PageRoutesEnum.MainPage,
    },
    {
      label: 'Обучение',
      route: PageRoutesEnum.Learning,
    },
    {
      label: 'Видео материалы',
    },
  ];

  const navigate = useNavigate();

  const onBreadcrumbsItemClick = (data: {
    item: { label: string; route?: PageRoutesEnum };
    e: React.MouseEvent<Element, MouseEvent>;
  }) => {
    if (data.item.route) {
      navigate(data.item.route);
    }
  };

  return (
    <div
      className={classes.container}
      style={{
        backgroundImage: `linear-gradient(0deg, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0)), url(${bannerLearningCourses})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }}
    >
      <div className={classes.contentContainer}>
        <Breadcrumbs items={breadcrumbs} className={classes.breadcrumbs} onItemClick={onBreadcrumbsItemClick} />
        <Text size="5xl" weight="bold" className={classes.title}>
          {`Видео материалы`}
        </Text>
        <Text size="m" className={classes.description}>
          {`Ознакомьтесь с кейсами, созданными вашими коллегами с помощью\nсовременных инструментов для самостоятельной аналитики`}
        </Text>
      </div>
    </div>
  );
};

export default LearningAllVideosHeader;
