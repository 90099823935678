import React, { ReactNode, useEffect, useMemo, useState } from 'react';

import { declOfNum } from '../utils/declOfNum';
import classes from './Pagination.module.css';
import { Button } from '@consta/uikit/Button';
import { IconBackward } from '@consta/uikit/IconBackward';
import { IconForward } from '@consta/uikit/IconForward';
import { Select } from '@consta/uikit/Select';
import { Text } from '@consta/uikit/Text';
import { TextField } from '@consta/uikit/TextField';
import classNames from 'classnames';

type Item = {
  label: string;
  id: number;
};

const onlyDigitRegex = /^\d+$/;

const getCurPageStr = (curPage: number | null | undefined): string => {
  if (curPage) {
    return curPage.toString();
  }
  return '0';
};

const getQueryString = (curPageStr: string, pageSize: Item | null) => {
  return `page[size]=${pageSize?.label}&page[number]=${curPageStr}`;
};

interface IPaginationProps {
  onChangePagination: (queryString: string) => void;
  currentPage?: number | null;
  lastPage?: number | null;
  total?: number | null;
  currentPageSize?: number | null;
  className?: string;
  pageSizes?: number[];
  pageSizeDefault?: number;
  maxPage?: number | null;
  customButton?: ReactNode;
}

const getPageSizeDefaultInd = (pageSizes: number[], pageSizeDefault: number) => {
  const ind = pageSizes.findIndex((pageSize) => pageSize === pageSizeDefault);
  if (ind >= 0) {
    return ind;
  }
  return 0;
};

const Pagination: React.FC<IPaginationProps> = ({
  onChangePagination,
  currentPage = 1,
  currentPageSize,
  lastPage,
  total = 0,
  className,
  pageSizes = [10, 25, 50, 100],
  pageSizeDefault = currentPageSize ? currentPageSize : pageSizes[1],
  customButton,
}) => {
  const items = useMemo(() => {
    let localPageSizes = [...pageSizes];
    if (currentPageSize && !pageSizes.includes(currentPageSize)) {
      localPageSizes.push(currentPageSize);
    }
    if (!pageSizes.includes(pageSizeDefault)) {
      localPageSizes.push(pageSizeDefault);
    }
    localPageSizes.sort((a, b) => a - b);
    let uniq: number[] = [];
    localPageSizes.forEach(function (item) {
      if (uniq.indexOf(item) < 0) {
        uniq.push(item);
      }
    });
    return localPageSizes.map((perPageItem, ind) => ({ label: perPageItem.toString(), id: ind }));
  }, [pageSizes, pageSizeDefault, currentPageSize]);

  const [pageSize, setPageSize] = useState<Item | null>(items[getPageSizeDefaultInd(pageSizes, pageSizeDefault)]);
  const [curPageStr, setCurPageStr] = useState<string>(getCurPageStr(currentPage));

  useEffect(() => {
    setCurPageStr(getCurPageStr(currentPage));
  }, [currentPage]);

  useEffect(() => {
    if (currentPageSize && currentPageSize.toString() !== pageSize?.label) {
      const curItem = items.find((item) => item.label === currentPageSize.toString());
      curItem && setPageSize(curItem);
    }
  }, [currentPageSize, pageSize?.label, items]);

  const onChangeCurPageStr = ({ value }: { value: string | null }) => {
    if (value) {
      if (onlyDigitRegex.test(value) && lastPage && Number(value) <= lastPage) {
        setCurPageStr(value);
      }
    } else {
      setCurPageStr('');
    }
  };

  const onKeyDownCurPageStr = (e: React.KeyboardEvent) => {
    if (e.code === 'Enter') {
      onChangePagination(getQueryString(curPageStr, pageSize));
      (document.activeElement as HTMLElement).blur();
    }
  };

  const onBlurCurPageStr = () => {
    onChangePagination(getQueryString(curPageStr, pageSize));
  };

  const onBackwardClick = () => {
    if (Number(curPageStr) > 1) {
      const newCurPageStr = getCurPageStr(Number(curPageStr) - 1);
      setCurPageStr(newCurPageStr);
      onChangePagination(getQueryString(newCurPageStr, pageSize));
    }
  };

  const onForwardClick = () => {
    if (lastPage && Number(curPageStr) < lastPage) {
      const newCurPageStr = getCurPageStr(Number(curPageStr) + 1);
      setCurPageStr(newCurPageStr);
      onChangePagination(getQueryString(newCurPageStr, pageSize));
    }
  };

  const onChangePageSize = ({ value }: { value: Item | null }) => {
    if (value?.label) {
      setPageSize(value);
      onChangePagination(getQueryString('1', value));
    }
  };

  const isForwardDisabled = () => {
    if (!total || total <= 1) {
      return true;
    }
    if (!lastPage || lastPage <= 1) {
      return true;
    }
    return Number(curPageStr) >= lastPage;
  };

  return (
    <div className={classNames(classes.container, { [classNames(className)]: className })}>
      <div className={classes.leftPart}>
        <Button
          size="s"
          view="clear"
          iconLeft={IconBackward}
          label={`Назад`}
          className={classes.buttonCatalogList}
          onClick={onBackwardClick}
          disabled={Number(curPageStr) <= 1}
        />
        <div>
          <TextField
            size={'s'}
            onChange={onChangeCurPageStr}
            value={curPageStr}
            type="text"
            className={classes.textField}
            onKeyDown={onKeyDownCurPageStr}
            onBlur={onBlurCurPageStr}
          />
        </div>
        <Text className={classes.amountTitle}>{`из ${lastPage ? lastPage : ''}`}</Text>
        <Button
          size="s"
          view="clear"
          iconRight={IconForward}
          label={`Вперед`}
          className={classes.buttonCatalogList}
          onClick={onForwardClick}
          disabled={isForwardDisabled()}
        />
      </div>
      <div className={classes.rightPart}>
        {!!total && customButton && customButton}
        {
          <Text
            className={`${classes.allAmountTitle} ${
              !!total && customButton ? classes.amountTitleWithCustomButton : ''
            }`}
          >
            {`${total} ${declOfNum(total, ['элемент', 'элемента', 'элементов'])}`}
          </Text>
        }
        <Text className={classes.showFor}>{`Отображать по:`}</Text>
        <div>
          <Select items={items} value={pageSize} size={'s'} onChange={onChangePageSize} className={classes.select} />
        </div>
      </div>
    </div>
  );
};

export default Pagination;
