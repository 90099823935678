export const getTableHeaderSize = (size?: string | null | undefined, isSortable?: unknown, isFilterable?: unknown) => {
  if (!size || Number(size) < 50) {
    return undefined;
  }
  let headerSize = 50;
  if (Number(size) > headerSize) {
    headerSize = Number(size);
  }
  if (isSortable) {
    headerSize += 24;
  }
  if (isFilterable) {
    headerSize += 24;
  }
  return `${headerSize}px`;
};
