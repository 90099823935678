import React, { ReactElement } from 'react';

import { MetaServerType } from '../api/serverResponse';
import { PageRoutesEnum } from '../appMenuAndRouting/appRoutes';
import { IconComponent } from '@consta/uikit/Icon';
import { ColumnDef } from '@tanstack/react-table';

export type RouteType = {
  path: string;
  element: React.ReactNode;
};

export enum UserStatusEnum {
  available = 'available',
  out = 'out',
  remote = 'remote',
}

export type UserType = {
  _uuid?: string;
  userName: string;
  userInfo?: string;
  userStatus?: UserStatusEnum;
  userAvatarUrl?: string;
  sectionTitle?: string;
  userAvatarFile?: string;
  userPosition?: string;
  userEmail?: string;
  userUuid?: string;
  isConfluence?: string;
  icon?: string;
  block?: {
    _uuid: string;
    name: string;
  };
  team?: {
    _uuid: string;
    name: string;
  };
};

export type PersonInfoType = {
  name: string;
  position?: string;
  department?: string;
  email?: string;
  avatarURL?: string;
  avatarLocalFileName?: string;
  mailSubject?: string;
};

export type ProductPerformanceType = {
  specification: string;
  description: string;
};

export type ProductInfoType = {
  name: string;
  description: string;
  slug: string;
  icon?: string;
  owner: string;
  performance: ProductPerformanceType[];
  detailsURL: string;
};

export type InfoLinkType = {
  title: string;
  description: string;
  url: string;
};

export type TeamGroupType = {
  groupTitle: string;
  groupMembers: PersonInfoType[];
};

export type ProductInstrumentType = {
  title: string;
  url: string;
};

export type ProductComponentType = {
  title: string;
  description: string;
  backGroundFileName: string;
  instrumentsAsIs?: ProductInstrumentType[];
  instrumentsToBe?: ProductInstrumentType[];
};

export type ProductType = {
  name: string;
  description: string;
  slug: string;
  owner: string;
  detailsURL: string;
  performance: ProductPerformanceType[];
  components: ProductComponentType[];
  team?: TeamGroupType[];
  infoLinks?: InfoLinkType[];
};

export type PersonInDomainOrProgramType = {
  domainOrProgramChiefAvatarFile: string;
  domainOrProgramChiefName: string;
  domainOrProgramChiefEmail?: string;
  icon: string;
  isHead?: boolean;
  _uuid?: string;
};

export type DomainOrProgramType = {
  domainOrProgramTitle: string;
  domainOrProgramSectionTitle?: string;
  domainOrProgramConfluenceUri?: string;
  persons: PersonInDomainOrProgramType[];
  is_head: string;
  _uuid: string;
  groupUuid?: string;
};

export type DomainOrProgramGroupType = {
  domainOrProgramGroupTile: string;
  _uuid: string;
  domainOrProgramGroupColor: string;
  domainOrProgramGroupSections: DomainOrProgramType[][];
  domainOrProgramSectionTitle: string;
  groupUuid: string;
  domainUuid: string;
};

export type PersonType = {
  personName: string;
  personPosition: string;
  personAvatarFile: string;
};

export type UpdDoType = {
  title: string;
  persons: PersonType[];
};

export type UpdDoGroupType = {
  groupTile: string;
  groupColor: string;
  organisations: UpdDoType[];
};

export enum TypeViewEnum {
  cards = 'Карточки',
  table = 'Таблица',
  about = 'О процессе',
}

export type MethodologyProcessType = {
  uuid: string;
  code: string;
  name: string;
  children: MethodologyProcessType[];
};

export type ComboboxItemType = {
  label: string;
  id: number;
};

export enum MethodologySearchEnum {
  documentUuid = 'documentUuid',
  processUuid = 'processUuid',
  documentStatus = 'documentStatus',
  documentType = 'documentType',
  documentTag = 'documentTag',
  documentFormat = 'documentFormat',
  documentTitle = 'documentTitle',
}

export type PersonRolesType = {
  role?: string | null;
  name?: string | null;
  email?: string | null;
  position?: string | null;
};

export enum LearningSearchEnum {
  courseUuid = 'courseUuid',
  courseBlock = 'courseBlock',
  courseCategory = 'courseCategory',
  courseTitle = 'courseTitle',
  courseTag = 'courseTag',
}

export type LearningCourseType = {
  courseUuid: string;
  courseTitle: string;
  courseDescription?: string;
  courseUrl?: string;
  courseTags: string[];
};

export type MenuItemType = {
  label: string;
  href?: PageRoutesEnum;
  extUrl?: string;
  children?: MenuItemType[];
  icon?: ReactElement;
  disabled?: boolean;
};

export type ColumnFilterType = {
  filterName: string;
  valueUuid: string;
  value: string;
};

export type ColumnSortType = {
  sortName: string;
  value: 'asc' | 'desc';
};

export type CrumbsType = {
  label: string;
  href?: PageRoutesEnum;
};

export type MetricItem = {
  name: string;
  type?: string;
  uuid: string;
  value: string;
  _alter_ts?: string;
  _alter_user?: string;
  _create_ts?: string;
  _create_uer?: string;
};

export type MetricType = {
  metrics: MetricItem[];
  name: string;
  owner: string;
  slug: string;
  _uuid: string;
  _alter_ts: string;
  _alter_user: string;
  _create_user: string;
  _create_ts: string;
};

export type BlockType = {
  name: string;
  slug?: string;
  _uuid: string;
};
export type DataServicesType = {
  name: string;
  slug?: string;
  _uuid: string;
};
export type SystemType = {
  name: string;
  slug?: string;
  _uuid: string;
  icon?: string;
};
export type ReportsItemType = {
  blocks?: BlockType[];
  can_edit: boolean;
  data_services: DataServicesType[];
  hasAccess?: boolean;
  link?: string;
  manual?: string;
  name: string;
  system?: SystemType;
  ad_groups: string[];
  uuid?: string | undefined;
  _alter_ts: string;
  _alter_user: string;
  _create_ts: string;
  _create_user: string;
};
export type ReportsDictionaryItemType = {
  name: string;
  slug?: string;
  _uuid: string;
  description?: string;
  isChecked?: boolean;
};
export type ReportsDictionaryType = {
  blocks?: ReportsDictionaryItemType[];
  systems?: ReportsDictionaryItemType[];
  dataServices?: ReportsDictionaryItemType[];
};

export type ManualMenuItem = {
  label: string;
  icon: IconComponent;
  status?: string;
  value: string;
};

export type ViewSelectorItemType = {
  name: string;
  disabled?: boolean;
  slug?: string;
};

export type BlogItemStatusType = {
  _uuid: string;
  name: string;
  slug: string;
};

export type BlogItemTagType = {
  uuid: string | number;
  name: string;
  partition?: string | null;
};

export type BlogItemType = {
  _uuid: string;
  status: BlogItemStatusType | null;
  type?: string | null;
  cover?: string | null | any;
  name?: string | null;
  publication_date?: string | null;
  tags?: BlogItemTagType[] | null;
};

export type BlogItemsType = {
  data: BlogItemType[];
  meta: MetaServerType;
};

export type ColumnGenericType = ColumnDef<{ [key: string]: unknown }> & {
  title: string;
  isShow: boolean;
  accessorKey: string;
};

export type StoreSnackBarItemType = {
  key: number | string;
  message: string;
  status: string;
  autoClose?: number;
};

export type BlockContactsPersonType = {
  _uuid: string;
  first_name: string;
  last_name: string;
  middle_name?: string;
  email: string;
  is_news_subscriber?: boolean;
  display_role?: string;
  role?: string;
  position?: string;
  icon?: string;
};

export type BlockContactsContactType = {
  _uuid: string;
  position: string;
  person: BlockContactsPersonType | undefined;
};
export type BlockContactsType = {
  _uuid: string;
  name: string;
  slug: string;
  contacts: BlockContactsContactType[] | undefined;
};
