import React, { useCallback } from 'react';

import confluenceIco from '../../../../img/confluenceIco.svg';
import { UserType } from '../../../../types/types';
import TeamCardContainer from '../TeamCardContainer/TeamCardContainer';
import classes from './TeamCardsVertical.module.css';
import { Button } from '@consta/uikit/Button';
import { useAppDispatch, useAppSelector } from '../../../../hooks/hooks';
import { selectorIsAdminTeam } from '../../../../store/appSlice';
import { setCurrentConfluence, setCurrentTeamEmployee } from '../../../../store/commandSlice';

type PropsType = {
  usersLength: number;
  users: UserType[];
  verticalColor: string;
  openSidebarClick: () => void;
  openConfluenceSidebar: () => void;
  domain: { id: string; label: string };
  division: { id: string; label: string };
};

const emptyUser: UserType = {
  userName: '',
  userPosition: '',
  userAvatarFile: '',
};

const TeamCardsVertical: React.FC<PropsType> = ({
  usersLength,
  users,
  verticalColor,
  openSidebarClick,
  openConfluenceSidebar,
  domain,
  division,
}) => {
  const isAdminTeam = useAppSelector(selectorIsAdminTeam);
  const dispatch = useAppDispatch();
  const setEmployee = useCallback(() => {
    console.log(domain);
    dispatch(
      setCurrentTeamEmployee({
        block: { id: domain?.id, label: domain?.label },
        division: { id: division?.id, label: division?.label },
      })
    );
    dispatch(
      setCurrentConfluence({
        block: { id: domain?.id, label: domain?.label },
        division: { id: division?.id, label: division?.label },
      })
    );
  }, [domain, division]);
  let hasConflence: boolean = false;
  if (users.length && users.find((el) => el.isConfluence)) {
    hasConflence = true;
  }

  if (users.length === 0) {
    return (
      <div className={classes.container}>
        {isAdminTeam && (
          <div className={classes.teamAdd}>
            <Button
              size={'xs'}
              label={'Добавить'}
              view={'ghost'}
              form={'default'}
              onClick={() => {
                setEmployee();
                openSidebarClick();
              }}
            />
            <img
              src={confluenceIco}
              alt={''}
              onClick={() => {
                setEmployee();
                openConfluenceSidebar();
              }}
            />
          </div>
        )}
        <div className={classes.allHeightCentralLine} />
      </div>
    );
  }

  const emptyUserCards: UserType[] = [];
  if (usersLength > users.length) {
    const emptyUserAmount = usersLength - users.length;
    for (let i = 0; i < emptyUserAmount; i++) {
      emptyUserCards.push(emptyUser);
    }
  }

  return (
    <div className={classes.container}>
      {users.map((user, ind) => (
        <TeamCardContainer
          key={ind}
          user={user}
          verticalColor={verticalColor}
          openSidebarClick={openSidebarClick}
          openConfluenceSidebar={openConfluenceSidebar}
        />
      ))}
      {isAdminTeam && (
        <div className={classes.teamAdd}>
          <Button
            size={'xs'}
            label={'Добавить'}
            view={'ghost'}
            form={'default'}
            onClick={() => {
              setEmployee();
              openSidebarClick();
            }}
          />
          {!hasConflence && (
            <img
              src={confluenceIco}
              alt={''}
              onClick={() => {
                setEmployee();
                openConfluenceSidebar();
              }}
            />
          )}
        </div>
      )}
      {emptyUserCards.map((_, ind) => (
        <div key={ind} className={classes.emptyCardContainer}>
          <div className={classes.centerVerticalLine} />
        </div>
      ))}
    </div>
  );
};

export default TeamCardsVertical;
