import React, { useCallback, useState } from 'react';
import { Combobox } from '@consta/uikit/ComboboxCanary';
import classes from './UserSelect.module.css';
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks';
import { getCommandUsersThunk, selectorCommandUsers } from '../../../store/commandSlice';

interface UsersType {
  id: string;
  label: string;
}

type PropType = {
  initValue?: UsersType;
  onChangeInit: (value: any) => void;
  label: string;
};

const UserSelect: React.FC<PropType> = ({ initValue, onChangeInit, label }) => {
  const dispatch = useAppDispatch();
  const users = useAppSelector(selectorCommandUsers);
  const [searchValue, setSearchValue] = useState<string>('');
  const [value, setValue] = useState<UsersType | null>(initValue || null);

  const setSearch = useCallback((searchString: string) => {
    setSearchValue(searchString);
    dispatch(getCommandUsersThunk(searchString)).then(() => {});
  }, []);

  const onChange = (value: any) => {
    setValue(value);
    onChangeInit(value);
  };

  return (
    <Combobox
      style={{ zIndex: 99999, width: '499px !important' }}
      label={label}
      items={users}
      value={value?.id ? value : null}
      onChange={(value: any) => {
        onChange(value);
      }}
      getItemLabel={(el: UsersType) => el.label}
      getItemKey={(el: UsersType) => el.id}
      onSearchValueChange={setSearch}
      searchValue={searchValue}
      className={classes.fullWidth}
    />
  );
};

export default UserSelect;
