export const formatDateHHMMDDMMYYYY = (date: Date) => {
  const year = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(date);
  const month = new Intl.DateTimeFormat('ru', { month: 'long' }).format(date);
  const day = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(date);
  const hour = new Intl.DateTimeFormat('ru', { hour: '2-digit' }).format(date);
  const minute = new Intl.DateTimeFormat('ru', { minute: '2-digit' }).format(date);
  const adaptedMonth =
    month === 'январь'
      ? 'января'
      : month === 'февраль'
      ? 'февраля'
      : month === 'март'
      ? 'марта'
      : month === 'апрель'
      ? 'апреля'
      : month === 'май'
      ? 'мая'
      : month === 'июнь'
      ? 'июня'
      : month === 'июль'
      ? 'июля'
      : month === 'август'
      ? 'августа'
      : month === 'сентябрь'
      ? 'сентября'
      : month === 'октябрь'
      ? 'октября'
      : month === 'ноябрь'
      ? 'ноября'
      : month === 'декабрь'
      ? 'декабря'
      : 'error';
  return `${hour}:${minute.length > 1 ? minute : `0${minute}`},\n${day} ${adaptedMonth} ${year}`;
};
