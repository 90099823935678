import React from 'react';

import templateContent from '../../templateContent/dataPlatformProducts.json';
import DataPlatformAbout from './DataPlatformAbout/DataPlatformAbout';
import DataPlatformHero from './DataPlatformHero/DataPlatformHero';
import DataPlatformLifeCircle from './DataPlatformLifeCircle/DataPlatformLifeCircle';
import classes from './DataPlatformPage.module.css';

const DataPlatformPage = () => {
  const products = templateContent.products;

  return (
    <div className={classes.container}>
      <DataPlatformHero />
      <DataPlatformAbout />
      <DataPlatformLifeCircle products={products} />
    </div>
  );
};

export default DataPlatformPage;
