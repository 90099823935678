import React from 'react';

import NewEmployeeModalContent from '../../../Components/NewEmployeeModalContent/NewEmployeeModalContent';
import classes from './PageHeader.module.css';
import { Button } from '@consta/uikit/Button';
import { IconDownload } from '@consta/uikit/IconDownload';
import { IconInfo } from '@consta/uikit/IconInfo';
import { IconOpenInNew } from '@consta/uikit/IconOpenInNew';
import { Modal } from '@consta/uikit/Modal';
import { Text } from '@consta/uikit/Text';

const PageHeader: React.FC = () => {
  const [isNewEmployeeModalOpen, setIsNewEmployeeModalOpen] = React.useState(false);

  const newEmployeeClick = () => {
    setIsNewEmployeeModalOpen(true);
  };

  const openBackLogClick = () => {};

  const goToConfluenceClick = () => {
    const url = `https://kb.gazprom-neft.local/pages/viewpage.action?pageId=133698661`;
    window.open(url, '_blank', 'noreferrer');
  };

  return (
    <div className={classes.container}>
      <Text className={classes.title}>{`Методология управления данными`}</Text>
      <div className={classes.linksGroup}>
        <Button
          label={`Новому сотруднику`}
          view={'secondary'}
          size={'s'}
          iconRight={IconInfo}
          onClick={newEmployeeClick}
        />
        <Button
          label={`Открыть файл бэклога`}
          view={'secondary'}
          size={'s'}
          iconRight={IconDownload}
          onClick={openBackLogClick}
        />
        <Button
          label={`Открыть базу знаний`}
          view={'secondary'}
          size={'s'}
          iconRight={IconOpenInNew}
          onClick={goToConfluenceClick}
        />
      </div>
      <Modal
        isOpen={isNewEmployeeModalOpen}
        onClickOutside={() => setIsNewEmployeeModalOpen(false)}
        onEsc={() => setIsNewEmployeeModalOpen(false)}
      >
        <NewEmployeeModalContent closeModal={() => setIsNewEmployeeModalOpen(false)} />
      </Modal>
    </div>
  );
};

export default PageHeader;
