import React, { useState } from 'react';

import { BlogPostSuggestionParamsType } from '../../../../api/blogServerResponse';
import { serverApi } from '../../../../api/serverApi';
import { useAppDispatch } from '../../../../hooks/hooks';
import { setAppError } from '../../../../store/appSlice';
import { setIsOpenBlogModalSuggestion, setIsSuccessSuggestion } from '../../../../store/blogSlice';
import classes from './SuggestionForm.module.css';
import { Button } from '@consta/uikit/Button';
import { ChoiceGroup } from '@consta/uikit/ChoiceGroup';
import { Loader } from '@consta/uikit/Loader';
import { Text } from '@consta/uikit/Text';
import { TextField, TextFieldPropValue } from '@consta/uikit/TextField';

const SuggestionForm: React.FC = () => {
  const dispatch = useAppDispatch();
  const typeItems: string[] = ['Предложить новость', 'Предложить анонс'];
  const [valueType, setValueType] = useState<string | null>(typeItems[0]);

  const roleItems: string[] = ['Хочу слушать', 'Хочу выступить'];
  const [valueRole, setValueRole] = useState<string | null>(roleItems[0]);

  const [status, setStatus] = useState<'alert' | undefined>(undefined);

  const [text, setText] = useState<string | null>('');

  const [isLoading, setIsLoading] = useState(false);

  const closePopUp = () => {
    dispatch(setIsOpenBlogModalSuggestion(false));
  };

  const onChangeText = ({ value }: { value: TextFieldPropValue }) => {
    if (text && status) {
      setStatus(undefined);
    }
    setText(value);
  };

  const sendPopUp = async () => {
    if (!text) {
      setStatus('alert');
    }
    if (text) {
      let userUid: string | undefined;
      try {
        setIsLoading(true);
        const users = await serverApi.getBlogUsers();
        userUid = users.data?.[0]._uuid;
        if (userUid) {
          const data: BlogPostSuggestionParamsType = {
            value: text,
            type: valueType === typeItems[0] ? 'news' : 'announcement',
            is_speaker: valueRole === roleItems[1],
            uuid_person: userUid,
          };
          await serverApi.postBlogSuggestion(data);
          dispatch(setIsSuccessSuggestion(true));
        }
      } catch (e: any) {
        dispatch(setAppError(`Ошибка отправки в предложения для Блога, ${e.toString()}`));
      } finally {
        setIsLoading(false);
      }
    }
  };

  if (isLoading) {
    return (
      <div className={classes.containerLoader}>
        <Loader style={{ marginTop: '100px' }} />
      </div>
    );
  }

  return (
    <div className={classes.container}>
      <Text className={classes.title}>{'Предложение новости или анонса'}</Text>
      <div className={classes.delimiter} />
      <ChoiceGroup
        value={valueType}
        onChange={({ value }) => setValueType(value)}
        items={typeItems}
        getItemLabel={(item) => item}
        multiple={false}
        name="ChoiceGroupType"
        className={classes.choiceGroupType}
      />
      {valueType === typeItems[1] && (
        <>
          <Text className={classes.role}>{'Ваша роль на мероприятии'}</Text>
          <ChoiceGroup
            value={valueRole}
            onChange={({ value }) => setValueRole(value)}
            items={roleItems}
            getItemLabel={(item) => item}
            multiple={false}
            name="ChoiceGroupRole"
            className={classes.choiceGroupRole}
          />
        </>
      )}
      <Text className={classes.role}>
        {valueType === typeItems[1] ? 'Содержание выступления' : 'Содержание новости'}
      </Text>
      <Text className={classes.suggestionTitle}>
        {'Ваше предложение '}
        <span className={classes.sign}>{'*'}</span>
      </Text>
      <div className={classes.textFieldContainer}>
        <TextField
          status={status}
          placeholder="Введите ваше предложение"
          caption={status ? 'Обязательно для заполнения' : ''}
          width="full"
          type="textarea"
          rows={4}
          size={'m'}
          value={text}
          onChange={onChangeText}
        />
      </div>
      <div className={classes.infoBlock}>
        <Text className={classes.infoText}>
          {
            'Рассмотрение вашей заявки займёт от 1 до 5 дней. После рассмотрения заявки мы напишем вам на почту, чтобы уточнить подробности.'
          }
        </Text>
      </div>
      <div className={classes.delimiter} />
      <div className={classes.buttonsRow}>
        <Button label="Отмена" view="ghost" onClick={closePopUp} />
        <Button label="Отправить на модерацию" onClick={sendPopUp} />
      </div>
    </div>
  );
};

export default SuggestionForm;
