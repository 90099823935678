import { MenuItemType } from '../../types/types';

export const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    left: 0,
  });
};

export const getParentMenuItems = (parentMenuRoute: string, menuItems: MenuItemType[]): MenuItemType[] => {
  let parentMenuItems: MenuItemType[] = [];
  for (let i = 0; i < menuItems.length; i++) {
    const subItems = menuItems[i].children;
    if (parentMenuRoute === menuItems[i].href && subItems && subItems.length > 0) {
      parentMenuItems = subItems;
      break;
    }
    if (subItems) {
      const childItems = getParentMenuItems(parentMenuRoute, subItems);
      if (childItems.length > 0) {
        parentMenuItems = childItems;
        break;
      }
    }
  }
  return parentMenuItems;
};

export const getMenuItem = (route: string, menuItems: MenuItemType[]): MenuItemType | undefined => {
  let menuItem: MenuItemType | undefined = undefined;
  for (let i = 0; i < menuItems.length; i++) {
    const subItems = menuItems[i].children;
    if (route === menuItems[i].href) {
      menuItem = menuItems[i];
      break;
    }
    if (subItems) {
      const childMenuItem = getMenuItem(route, subItems);
      if (childMenuItem) {
        menuItem = childMenuItem;
        break;
      }
    }
  }
  return menuItem;
};

export const setDocumentTitle = (route: string, menuItems: MenuItemType[]) => {
  const matchedMenuitem = getMenuItem(route, menuItems);
  if (matchedMenuitem && document.title !== matchedMenuitem.label) {
    document.title = `DataOffice ${matchedMenuitem.label}`;
  }
};

export const hasActiveChildren = (currentPath: string, menuItem: MenuItemType | undefined) => {
  let result = false;
  if (menuItem && menuItem.children) {
    for (let i = 0; i < menuItem.children.length; i++) {
      if (menuItem.children[i].href === currentPath) {
        result = true;
        break;
      }
      const subChildren = menuItem.children[i];
      if (subChildren.children) {
        const subResult = hasActiveChildren(currentPath, subChildren);
        if (subResult) {
          result = true;
          break;
        }
      }
    }
  }
  return result;
};
