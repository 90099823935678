import React, { useEffect, useRef, useState } from 'react';

import CoolTextButton from '../CoolTextButton/CoolTextButton';
import { useClickOutsideComponent } from '../hooks/useClickOutsideComponent';
import FilterItemsList from './FilterItemsList/FilterItemsList';
import classes from './TableColumnFilter.module.css';
import { Button } from '@consta/uikit/Button';
import { IconFunnel } from '@consta/uikit/IconFunnel';
import { IconSearch } from '@consta/uikit/IconSearch';
import { TextField } from '@consta/uikit/TextField';

export type ColumnFilterItemType = {
  uuid: string;
  value: string;
};

interface ITableColumnFilterProps {
  accessorKey: string;
  onChangeFilter: (accessorKey: string, filtersItems: ColumnFilterItemType[]) => void;
  getFilterItems: (accessorKey: string) => Promise<ColumnFilterItemType[]>;
  selectedFilters?: ColumnFilterItemType[];
}

const TableColumnFilter: React.FC<ITableColumnFilterProps> = ({
  accessorKey,
  onChangeFilter,
  getFilterItems,
  selectedFilters,
}) => {
  const refButton = useRef<HTMLDivElement>(null);
  const refDropBox = useRef<HTMLDivElement>(null);
  const [showDropBox, setShowDropBox] = useState(false);
  const [filters, setFilters] = useState<ColumnFilterItemType[]>(selectedFilters ? selectedFilters : []);
  const [filterItems, setFilterItems] = useState<ColumnFilterItemType[]>([]);
  const [showPreloader, setShowPreloader] = useState(false);
  const [searchFilterValue, setSearchFilterValue] = useState<string | null>(null);
  const [isFiltersChanged, setIsFiltersChanged] = useState(false);

  const closeDropBox = () => {
    clearFilters();
    setShowDropBox(false);
  };

  useClickOutsideComponent(refDropBox, closeDropBox, refButton);

  const handleChange = ({ value }: { value: string | null }) => setSearchFilterValue(value);

  useEffect(() => {
    if (showDropBox) {
      setShowPreloader(true);
      getFilterItems(accessorKey)
        .then((filterItems) => {
          setShowPreloader(false);
          setFilterItems(filterItems);
        })
        .catch(window.alert);
    }
  }, [showDropBox, accessorKey, getFilterItems]);

  const onClickShowDropBox = () => {
    setShowDropBox((prv) => !prv);
  };

  const clearFilters = () => {
    setSearchFilterValue('');
    setFilters([]);
  };

  const selectAllFilters = () => {
    if (searchFilterValue) {
      const matchedValues = filterItems.filter((filterItem) =>
        filterItem.value.toLowerCase().includes(searchFilterValue.toLowerCase())
      );
      setFilters(matchedValues);
    } else {
      setFilters(filterItems);
    }
  };

  const onChangeFilterItem = (filterItem: ColumnFilterItemType) => {
    if (filters.map((filter) => filter.uuid).includes(filterItem.uuid)) {
      setFilters(filters.filter((curFilter) => curFilter.uuid !== filterItem.uuid));
    } else {
      setFilters(filters.concat(filterItem));
    }
  };

  const applyFilters = () => {
    onChangeFilter(accessorKey, filters);
    closeDropBox();
  };

  const cancelFilters = () => {
    setFilters([]);
    closeDropBox();
  };

  useEffect(() => {
    const selectedFiltersUuid = selectedFilters?.map((filter) => filter.uuid);
    const filtersUuid = filters?.map((filter) => filter.uuid);
    if (selectedFiltersUuid!.sort().toString() === filtersUuid!.sort().toString()) {
      isFiltersChanged && setIsFiltersChanged(false);
    } else {
      !isFiltersChanged && setIsFiltersChanged(true);
    }
  }, [filters, isFiltersChanged, selectedFilters]);

  const IconFilter = () => {
    const hasSelectedFilters = selectedFilters && selectedFilters.length && selectedFilters.length > 0;
    return <IconFunnel view={hasSelectedFilters ? 'link' : 'secondary'} size={'s'} />;
  };

  return (
    <div className={classes.container}>
      <div ref={refButton}>
        <Button size="xs" view="clear" iconLeft={IconFilter} onlyIcon onClick={onClickShowDropBox} />
      </div>
      {showDropBox && (
        <div ref={refDropBox} className={classes.dropBox}>
          <div className={classes.textField}>
            <TextField
              value={searchFilterValue}
              onChange={handleChange}
              style={{ flexGrow: '1' }}
              leftSide={IconSearch}
              placeholder="Найти в списке"
              size={'s'}
            />
          </div>
          <div className={classes.buttonRow}>
            <CoolTextButton title={'Выбрать все'} onClick={selectAllFilters} />
            <CoolTextButton title={'Сбросить'} disabled={filters.length === 0} onClick={clearFilters} />
          </div>
          <FilterItemsList
            filterItems={filterItems}
            filters={filters}
            onChangeFilterItem={onChangeFilterItem}
            isLoading={showPreloader}
            searchFilterValue={searchFilterValue}
          />
          <div className={classes.footerRow}>
            <Button label="Отмена" view="ghost" style={{ width: '175px' }} onClick={cancelFilters} />
            <Button label="Применить" style={{ width: '175px' }} disabled={!isFiltersChanged} onClick={applyFilters} />
          </div>
        </div>
      )}
    </div>
  );
};

export default TableColumnFilter;
