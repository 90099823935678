import React from 'react';

import classes from './DataPlatformHero.module.css';

const DataPlatformHero: React.FC = () => {
  return (
    <div className={classes.container}>
      <div className={classes.contentContainer}>
        <div className={classes.infoContainer}>
          <div className={classes.title}>Дата-платформа</div>
          <div className={classes.subtitle}>
            {'Набор взаимосвязанных импортонезависимых инструментов для работы с данными сотрудниками Компании'}
          </div>
          <div className={classes.number}>{'6 инструментов'}</div>
          <div className={classes.numberDesc}>{'доступны для задач\n разной сложности'}</div>
        </div>
      </div>
    </div>
  );
};

export default DataPlatformHero;
