import React from 'react';

import TableWrapper from '../../../../../Components/TableWrapper/TableWrapper';
import { useAppSelector } from '../../../../../hooks/hooks';
import { Table } from '../../../../../shared-front/Table/Table';
import { selectorViewSelectorBlogItems } from '../../../../../store/adminBlogSlice';
import { formatDateHHMMDDMMYYYY } from '../../../../../utils/formatDateHHMMDDMMYYYY';
import classes from './AdminSubscriptionTable.module.css';
import AdminBlogSubscriptionTableActions from './AdminBlogTableActions/AdminBlogSubscriptionTableActions';

import { ADMIN_BLOG_TABLE_COLUMNS, AdminBlogSubsriptionDataType } from './adminSubscriptionTableColumns';

interface IAdminBlogTableProps {}

const AdminSubscriptionTable: React.FC<IAdminBlogTableProps> = () => {
  const blogItems = useAppSelector(selectorViewSelectorBlogItems)?.data;

  const tableData: AdminBlogSubsriptionDataType[] =
    blogItems?.map((item: any) => {
      return {
        fio: <div>{item?.fio}</div>,
        email: <div>{item?.email}</div>,
        actions: <AdminBlogSubscriptionTableActions item={item} />,
      };
    }) || [];

  return (
    <div className={classes.container}>
      <TableWrapper height={'100%'}>
        <Table data={tableData} columns={ADMIN_BLOG_TABLE_COLUMNS} isColumnsResizable={true} />
      </TableWrapper>
    </div>
  );
};

export default AdminSubscriptionTable;
