import React from 'react';

import classes from './SubscribeModal.module.css';
import { Button } from '@consta/uikit/Button';
import { IconMail } from '@consta/uikit/IconMail';
import { Modal } from '@consta/uikit/Modal';
import { Text } from '@consta/uikit/Text';

type PropsType = {
  isOpen: boolean;
  closeSubscribeModal: () => void;
};

const SubscribeModal: React.FC<PropsType> = ({ isOpen, closeSubscribeModal }) => {
  const onCancel = (event: KeyboardEvent | React.MouseEvent | MouseEvent) => {
    event?.stopPropagation();
    closeSubscribeModal();
  };

  return (
    <Modal isOpen={isOpen} onClickOutside={onCancel} onEsc={onCancel}>
      <div className={classes.container}>
        <Text className={classes.title}>{`Подписка на анонсы`}</Text>
        <Text className={classes.info}>
          {`Подпишитесь на наши анонсы по обучению и получайте первыми доступ к самым актуальным курсам, тренингам и мастер-классам.`}
        </Text>
        <div className={classes.delimiter} />
        <div className={classes.buttonRow}>
          <Button size="m" view="ghost" label="Отменить" onClick={onCancel} />
          <Button size="m" label="Подписаться" iconLeft={IconMail} />
        </div>
      </div>
    </Modal>
  );
};

export default SubscribeModal;
