import React from 'react';

import { BlogItemTagType } from '../../../../../../types/types';
import classes from './AdminBlogTableTags.module.css';
import { Tag } from '@consta/uikit/Tag';
interface IAdminBlogTableTagsProps {
  tags?: BlogItemTagType[] | null;
}

const AdminBlogTableTags: React.FC<IAdminBlogTableTagsProps> = ({ tags }) => {
  if (!tags) {
    return null;
  }
  return (
    <div className={classes.container}>
      {tags.map((tag, ind) => (
        <Tag key={ind} size="s" label={tag.name ?? '-'} mode={'info'} />
      ))}
    </div>
  );
};

export default AdminBlogTableTags;
