import { BlogEventEnum, BlogEventType } from '../../api/blogServerResponse';

export const getTypeTitleForBlogEvent = (blogEvent: BlogEventType) => {
  switch (blogEvent.type) {
    case BlogEventEnum.news:
      return 'Новости';
    case BlogEventEnum.announcement:
      return 'Анонсы';
    case BlogEventEnum.case:
      return 'Кейсы';
    default:
      return '';
  }
};
