import React, { useEffect } from 'react';

import { useAppDispatch, useAppSelector } from '../../../hooks/hooks';
import { declOfNum } from '../../../shared-front/utils/declOfNum';
import {
  blogContentTypes,
  getAllBlogEventsThunk,
  selectorActiveBlogContentType,
  selectorBlogEventsMeta,
  selectorBlogIsLoading,
  selectorBlogSearchString,
  selectorBlogSelectedTags,
  setActiveBlogContentType,
} from '../../../store/blogSlice';
import { getContentTypeQuery } from '../../../utils/blogUtils/getContentTypeQuery';
import { getFilterTagQuery } from '../../../utils/blogUtils/getFilterTagQuery';
import { combineQuery } from '../../../utils/combineQuery';
import classes from './ViewControl.module.css';
import { ChoiceGroup } from '@consta/uikit/ChoiceGroup';
import { Text } from '@consta/uikit/Text';

export type BlogContentType = {
  name: string;
};

const ViewControl: React.FC = () => {
  const dispatch = useAppDispatch();
  const activeBlogContentType = useAppSelector(selectorActiveBlogContentType);
  const blogEventsMeta = useAppSelector(selectorBlogEventsMeta);
  const blogSelectedTags = useAppSelector(selectorBlogSelectedTags);
  const blogIsLoading = useAppSelector(selectorBlogIsLoading);
  const searchString = useAppSelector(selectorBlogSearchString);

  const onChange = ({ value }: { value: BlogContentType }) => {
    dispatch(setActiveBlogContentType(value));
  };

  const total = blogEventsMeta?.total;

  useEffect(() => {
    const contentTypeQuery = getContentTypeQuery(activeBlogContentType);
    const filterTagQuery = getFilterTagQuery(blogSelectedTags);
    const filterSearch = `filter[search]=${searchString}`;
    const paginationQuery = `page[size]=5&page[number]=1`;
    const query = combineQuery(contentTypeQuery, filterTagQuery, paginationQuery, filterSearch);
    dispatch(getAllBlogEventsThunk(query));
  }, [dispatch, activeBlogContentType, blogSelectedTags, searchString]);

  return (
    <div className={classes.container}>
      <ChoiceGroup
        value={activeBlogContentType}
        onChange={onChange}
        items={blogContentTypes}
        getItemLabel={(item) => item.name}
        multiple={false}
        name="ChoiceGroupExample"
      />
      {!blogIsLoading && total && (
        <Text className={classes.total}>{`${total} ${declOfNum(total, ['материал', 'материала', 'материалов'])}`}</Text>
      )}
    </div>
  );
};

export default ViewControl;
