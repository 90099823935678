import React from 'react';

import useDebouncedFunction from '../hooks/useDebounceFunction';
import { IconClose } from '@consta/uikit/IconClose';
import { IconSearch } from '@consta/uikit/IconSearch';
import { TextField } from '@consta/uikit/TextField';

interface ITextSearchAreaProps {
  searchValue: string;
  setSearchValue: (value: string) => void;
  onSearch?: ({ searchValue }: { searchValue?: string }) => void;
  id?: string | number;
  customStyleWidth?: string;
  placeholder?: string;
  withoutDebounce?: boolean;
}

const TextSearchArea: React.FC<ITextSearchAreaProps> = ({
  searchValue,
  setSearchValue,
  onSearch,
  id,
  customStyleWidth,
  placeholder,
  withoutDebounce,
}) => {
  const sendRequestDebounce = useDebouncedFunction(
    (val) => {
      onSearch && onSearch(val);
    },
    500,
    true
  );

  const onChangeHandler = ({ value }: { value: string | null }) => {
    if (value) {
      setSearchValue(value);
      withoutDebounce ? onSearch && onSearch({ searchValue: value }) : sendRequestDebounce({ searchValue: value });
    } else {
      setSearchValue('');
      withoutDebounce ? onSearch && onSearch({ searchValue: '' }) : sendRequestDebounce({ searchValue: '' });
    }
  };

  const handleKeypress = (e: React.KeyboardEvent) => {
    if (e.code === 'Enter') {
      onSearch && onSearch({ searchValue });
      (document.activeElement as HTMLElement).blur();
    }
  };

  const ResetSearchBtn = () => {
    const onClick = () => {
      setSearchValue('');
      onSearch && onSearch({ searchValue: '' });
    };

    return <IconClose style={{ width: '12px', cursor: 'pointer', color: '#00395C' }} onClick={onClick} />;
  };

  return (
    <TextField
      id={id}
      type="text"
      value={searchValue}
      onChange={onChangeHandler}
      placeholder={placeholder ? placeholder : 'Поиск'}
      style={{ width: customStyleWidth ? customStyleWidth : '320px' }}
      leftSide={IconSearch}
      rightSide={searchValue ? ResetSearchBtn : undefined}
      onKeyDown={handleKeypress}
      size={'s'}
    />
  );
};

export default TextSearchArea;
