import React, { useCallback, useEffect } from 'react';

import { useAppDispatch, useAppSelector } from '../../../hooks/hooks';
import {
  getCommandDomainThunk,
  getCommandProgramsThunk,
  getCommandUnitsThunk,
  selectorCommandDomains,
  selectorCommandPrograms,
  selectorCommandUnits,
} from '../../../store/commandSlice';
// import domains from '../../../templateContent/command/domains.json';
//import programs from '../../../templateContent/command/programs.json';
// import units from '../../../templateContent/command/units.json';
import EmbeddingSection from './EmbeddingSection/EmbeddingSection';
import classes from './EmbeddingSectionComponent.module.css';

type PropsType = {
  type: 'programs' | 'domains' | 'units';
};

const EmbeddingSectionComponent: React.FC<PropsType> = ({ type }) => {
  const dispatch = useAppDispatch();
  const domains = useAppSelector(selectorCommandDomains);
  const units = useAppSelector(selectorCommandUnits);
  const programs = useAppSelector(selectorCommandPrograms);
  const getInfo = useCallback(() => {
    dispatch(getCommandDomainThunk());
    dispatch(getCommandUnitsThunk());
    dispatch(getCommandProgramsThunk());
  }, [dispatch]);

  useEffect(() => {
    getInfo();
  }, [getInfo]);

  const groups = type === 'programs' ? programs : type === 'domains' ? domains : units;

  return (
    <div className={classes.container}>
      <div className={classes.contentContainer}>
        <EmbeddingSection groups={groups} type={type} />
      </div>
    </div>
  );
};

export default EmbeddingSectionComponent;
