import { serverApi } from '../api/serverApi';
import {
  AnnouncementServerType,
  AnnouncementsServerResponseType,
  CourseServerType,
  CoursesServerResponseType,
  VideoServerType,
  VideosServerResponseType,
} from '../api/serverResponse';
import { getRejectedValue } from './rejectedValueHelper';
import { RootState } from './store';
import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';

type LearningSliceType = {
  announcements: AnnouncementServerType[];
  courses: CourseServerType[];
  videos: VideoServerType[];
  isLoading: boolean;
  isError?: string;
};

const initialState: LearningSliceType = {
  announcements: [],
  courses: [],
  videos: [],
  isLoading: false,
  isError: undefined,
};

export const getAnnouncementsThunk = createAsyncThunk<
  AnnouncementsServerResponseType,
  undefined,
  { rejectValue: string }
>('learning/getAnnouncementsThunk', async (_, { rejectWithValue }) => {
  try {
    return await serverApi.getAnnouncements();
  } catch (e) {
    return rejectWithValue(getRejectedValue('Ошибка получения Анонсов для обучения', e));
  }
});

export const getCoursesThunk = createAsyncThunk<CoursesServerResponseType, undefined, { rejectValue: string }>(
  'learning/getCoursesThunk',
  async (_, { rejectWithValue }) => {
    try {
      return await serverApi.getCourses();
    } catch (e) {
      return rejectWithValue(getRejectedValue('Ошибка получения Курсов для обучения', e));
    }
  }
);

export const getVideosThunk = createAsyncThunk<VideosServerResponseType, undefined, { rejectValue: string }>(
  'learning/getVideosThunk',
  async (_, { rejectWithValue }) => {
    try {
      return await serverApi.getVideos();
    } catch (e) {
      return rejectWithValue(getRejectedValue('Ошибка получения Видео для обучения', e));
    }
  }
);

export const learningSlice = createSlice({
  name: 'learningSlice',
  initialState,
  reducers: {
    setIsError: (state, action) => {
      state.isError = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAnnouncementsThunk.fulfilled, (state, action) => {
        state.announcements = action.payload.data;
      })
      .addCase(getCoursesThunk.fulfilled, (state, action) => {
        state.courses = action.payload.data;
      })
      .addCase(getVideosThunk.fulfilled, (state, action) => {
        state.videos = action.payload.data;
      })
      .addMatcher(isAnyOf(getAnnouncementsThunk.pending, getCoursesThunk.pending, getVideosThunk.pending), (state) => {
        state.isLoading = true;
      })
      .addMatcher(
        isAnyOf(getAnnouncementsThunk.rejected, getCoursesThunk.rejected, getVideosThunk.rejected),
        (state, action) => {
          state.isLoading = false;
          state.isError = action.payload ? action.payload : 'Неизвестная ошибка - learningSlice';
        }
      );
  },
});

export const { setIsError } = learningSlice.actions;

// export const selectorLearningIsLoading = (state: RootState) => state.learning.isLoading;
export const selectorLearningError = (state: RootState) => state.learning.isError;
export const selectorLearningAnnouncements = (state: RootState) => state.learning.announcements;
export const selectorLearningCourses = (state: RootState) => state.learning.courses;
export const selectorLearningVideos = (state: RootState) => state.learning.videos;

export default learningSlice.reducer;
