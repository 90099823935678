import React, { useCallback, useEffect } from 'react';

import Page403 from '../../../Components/403/403';
import AdminHero from '../../../Components/Admin/AdminHero/AdminHero';
import AdminMetricContent from '../../../Components/Admin/AdminMetricContent/AdminMetricContent';
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks';
import metricImg from '../../../img/admin/admin-metrics.svg';
import { getAdminMetricsThunk, selectorIsLoadingMetrics, selectorMetrics } from '../../../store/adminSlice';
import { selectorIsAdminMetric, selectorUserInfo } from '../../../store/appSlice';
import { Loader } from '@consta/uikit/Loader';

const AdminMetricsPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const metrics = useAppSelector(selectorMetrics);

  const isAccess = useAppSelector(selectorIsAdminMetric);
  const isLoadingMetric = useAppSelector(selectorIsLoadingMetrics);
  const getMetrics = useCallback(() => {
    dispatch(getAdminMetricsThunk());
  }, [dispatch]);

  useEffect(() => {
    getMetrics();
  }, [getMetrics]);

  return (
    <>
      {!isAccess ? (
        <Page403 />
      ) : !isLoadingMetric && metrics ? (
        <>
          <AdminHero
            pageName={'Администрирование раздела\n Дата-платформа'}
            pageDesc={'Укажите метрики вашего продукта'}
            backgroundImage={metricImg}
            parentName={'Дата-Платформа'}
            parentLink={'/main'}
          />
          <AdminMetricContent metrics={metrics} />
        </>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default AdminMetricsPage;
