import React from 'react';

import { BlogCommentType } from '../../../../../../../api/blogServerResponse';
import { getTimeDateForBlogEvent } from '../../../../../../../utils/blogUtils/getTimeDateForBlogEvent';
import classes from './CommentItem.module.css';
import { Button } from '@consta/uikit/Button';
import { IconMeatball } from '@consta/uikit/IconMeatball';
import { Text } from '@consta/uikit/Text';

interface ICommentItemProps {
  comment: BlogCommentType;
}

const CommentItem: React.FC<ICommentItemProps> = ({ comment }) => {
  return (
    <div className={classes.container}>
      <div className={classes.userNameContainer}>
        <Text className={classes.userName}>{comment.person.first_name}</Text>
        {comment.person.last_name && (
          <Text className={classes.userName}>&nbsp;{`${comment.person.last_name.slice(0, 1)}.`}</Text>
        )}
        {comment.person.middle_name && (
          <Text className={classes.userName}>&nbsp;{`${comment.person.middle_name.slice(0, 1)}.`}</Text>
        )}
      </div>
      <Text className={classes.userPosition}>{`Менеджер`}</Text>
      <Text className={classes.dateTime}>{getTimeDateForBlogEvent(comment._create_ts)}</Text>
      <Text className={classes.text}>{comment.value}</Text>
      <div className={classes.delimiter} />
      <div className={classes.btnContainer}>
        <Button label="more" view="clear" iconLeft={IconMeatball} onlyIcon size={'s'} />
      </div>
    </div>
  );
};

export default CommentItem;
