import React, { useState } from 'react';

import { BlogItemType } from '../../../../../../types/types';
import classes from './AdminBlogTableActions.module.css';
import { Button } from '@consta/uikit/Button';
import { IconEdit } from '@consta/uikit/IconEdit';
import { IconStorage } from '@consta/uikit/IconStorage';
import { IconTrash } from '@consta/uikit/IconTrash';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useAppDispatch } from '../../../../../../hooks/hooks';
import {
  archiveBlogNewsThunk,
  deleteBlogNewsThunk,
  getAdminBlogItemsThunk,
} from '../../../../../../store/adminBlogSlice';
import ReportsModalDelete from '../../../../../../Components/Reports/ReportsModalDelete/ReportsModalDelete';
import BlogModalDelete from '../../../BlogModalDelete/BlogModalDelete';
import { useGetBlogItems } from '../../../../../../hooks/useGetBlogItems';

interface IAdminBlogTableActionsProps {
  item: BlogItemType;
}

const AdminBlogTableActions: React.FC<IAdminBlogTableActionsProps> = ({ item }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const getBlogItems = useGetBlogItems();
  const onEdit = () => {
    navigate(`/admin/blog/news/${item._uuid}`);
  };

  const onArchive = () => {
    dispatch(archiveBlogNewsThunk(item._uuid)).then((data) => {
      getBlogItems({});
    });
  };

  const onDelete = (uuid: string) => {
    dispatch(deleteBlogNewsThunk(item._uuid)).then((data) => {
      // navigate('/admin/blog');
      setIsOpenDeleteModal(false);
      dispatch(getAdminBlogItemsThunk()).then();
    });
  };

  return (
    <div className={classes.container}>
      <Button size="xs" view="ghost" iconLeft={IconEdit} onlyIcon onClick={onEdit} />
      <Button size="xs" view="ghost" iconLeft={IconStorage} onlyIcon onClick={onArchive} />
      <Button size="xs" view="ghost" iconLeft={IconTrash} onlyIcon onClick={() => setIsOpenDeleteModal(true)} />
      <BlogModalDelete
        isModalOpen={isOpenDeleteModal}
        setIsModalOpen={setIsOpenDeleteModal}
        modalDeleteTitle={'Вы действительно хотите удалить  новость?'}
        onConfirmDelete={() => onDelete(item._uuid)}
      />
    </div>
  );
};

export default AdminBlogTableActions;
