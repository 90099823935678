import instanceAxios from './instanceAxios';

export const contactsApi = {
  async getContactsByContentBlockSlug(slug: string) {
    const response = await instanceAxios.get(`/sections?filter[slug]=${slug}`);
    return response.data.data[0];
  },
  async contactsBatchUpdate(id: string, params: any) {
    console.log(params);
    const response = await instanceAxios.post(`/sections/${id}/contacts/batch`, params);
    return response.data.data;
  },
};
