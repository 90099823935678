import React, { useRef, useState } from 'react';

import { useClickOutsideComponent } from '../hooks/useClickOutsideComponent';
import classes from './TableColumnSort.module.css';
import { Button } from '@consta/uikit/Button';
import { IconSortDown } from '@consta/uikit/IconSortDown';
import { IconSortUp } from '@consta/uikit/IconSortUp';
import { Text } from '@consta/uikit/Text';

export enum SortDirectionEnum {
  asc = 'asc',
  desc = 'desc',
}

interface ITableColumnSortProps {
  accessorKey: string;
  onChangeSortDirection: (accessorKey: string, sortDirection: SortDirectionEnum) => void;
}

const TableColumnSort: React.FC<ITableColumnSortProps> = ({ accessorKey, onChangeSortDirection }) => {
  const refButton = useRef<HTMLDivElement>(null);
  const refDropBox = useRef<HTMLDivElement>(null);
  const [showDropBox, setShowDropBox] = useState(false);

  const closeDropBox = () => {
    setShowDropBox(false);
  };

  const onClick = () => {
    setShowDropBox((prv) => !prv);
  };

  const onClickSort = (sortDirection: SortDirectionEnum) => {
    closeDropBox();
    onChangeSortDirection(accessorKey, sortDirection);
  };

  useClickOutsideComponent(refDropBox, closeDropBox, refButton);

  return (
    <div className={classes.container}>
      <div ref={refButton}>
        <Button size="xs" view="clear" iconLeft={IconSortUp} onlyIcon onClick={onClick} />
      </div>
      {showDropBox && (
        <div ref={refDropBox} className={classes.dropBox}>
          <div className={classes.row} onClick={() => onClickSort(SortDirectionEnum.desc)}>
            <IconSortDown size={'xs'} className={classes.ico} />
            <Text className={classes.title}>{`Сортировать по убыванию`}</Text>
          </div>
          <div className={classes.delimiter} />
          <div className={classes.row} onClick={() => onClickSort(SortDirectionEnum.asc)}>
            <IconSortUp size={'xs'} className={classes.ico} />
            <Text className={classes.title}>{`Сортировать по возрастанию`}</Text>
          </div>
        </div>
      )}
    </div>
  );
};

export default TableColumnSort;
