import React from 'react';
import { Navigate } from 'react-router-dom';

import AdminBlogNewsForm from '../Pages/Admin/AdminBlogNewsForm/AdminBlogNewsForm';
import AdminBlogPage from '../Pages/Admin/AdminBlogPage/AdminBlogPage';
import AdminMetricsPage from '../Pages/Admin/AdminMetricsPage/AdminMetricsPage';
import AnnouncementDetailsPage from '../Pages/AnnouncementDetailsPage/AnnouncementDetailsPage';
import BlogContentAnnounce from '../Pages/BlogPage/BlogContentAnnounce/BlogContentAnnounce';
import BlogContentCase from '../Pages/BlogPage/BlogContentCase/BlogContentCase';
import BlogContentNews from '../Pages/BlogPage/BlogContentNews/BlogContentNews';
import BlogPage from '../Pages/BlogPage/BlogPage';
import CatalogsSearchPage from '../Pages/CatalogsSearchPage/CatalogsSearchPage';
import CourseDetailsPage from '../Pages/CourseDetailsPage/CourseDetailsPage';
import DataAnalyticsPage from '../Pages/DataAnalyticsPage/DataAnalyticsPage';
import DataCatalogPage from '../Pages/DataCatalogPage/DataCatalogPage';
import DataInfoAndStandardsPage from '../Pages/DataInfoAndStandardsPage/DataInfoAndStandardsPage';
import DataLaboratoryPage from '../Pages/DataLaboratoryPage/DataLaboratoryPage';
import DataPlatformPage from '../Pages/DataPlatformPage/DataPlatformPage';
import DataQualityPage from '../Pages/DataQualityPage/DataQualityPage';
import DataStoragePage from '../Pages/DataStoragePage/DataStoragePage';
import LearningAllCoursesPage from '../Pages/LearningAllCoursesPage/LearningAllCoursesPage';
import LearningAllVideosPage from '../Pages/LearningAllVideosPage/LearningAllVideosPage';
import LearningPage from '../Pages/LearningPage/LearningPage';
import MainPage from '../Pages/MainPage/MainPage';
import MethodologyPage from '../Pages/MethodologyPage/MethodologyPage';
import PlaceHolderPage from '../Pages/PlaceHolderPage/PlaceHolderPage';
import ReportsPage from '../Pages/ReportsPage/ReportsPage';
import TeamPage from '../Pages/TeamPage/TeamPage';

export enum ExternalRoutesEnum {
  Laboratory_Main = 'https://datalab-d.gazprom-neft.local/lab/',
  Laboratory_KnowledgeBase = 'https://datalab-d.gazprom-neft.local/lab/knowledge',
  Confluence_UPD_BRD = 'https://kb.gazprom-neft.local/pages/viewpage.action?pageId=16948120',
  Confluence_UPD_BLPS = 'https://kb.gazprom-neft.local/pages/viewpage.action?pageId=178098834',
  Confluence_UPD_DRP = 'https://confluence.gazprom-neft.local/display/ASCPublic/Analytics+Solution+Center+Public+Home',
}
export enum PageRoutesEnum {
  MainPage = '/main',
  Methodology = '/methodology',
  Instruments = '/data-platform',
  Instruments_DataPlatformPage = '/data-platform',
  Instruments_DataStorage = '/data-platform/dwh',
  Instruments_DataQuality = '/data-platform/dq',
  Instruments_DataInfoAndStandards = '/data-platform/nsi',
  Instruments_DataCatalogPage = '/data-platform/catalog',
  Instruments_DataAnalyticsPage = '/data-platform/bi',
  Instruments_DataLaboratory = '/data-platform/laboratory',
  Team = '/command',
  Reports = '/reports',
  ProjectsAndServices = '/projects-and-services',
  Blog = '/blog',
  Blog_Content = '/blog/:id',
  Blog_News = '/blog/news/:id',
  Blog_Announcements = '/blog/announcement/:id',
  Blog_Cases = '/blog/best_practice/:id',
  Search = '/search',
  Search_TechMeta = '/search/tech-meta',
  Search_BusinessMeta = 'http://spb99pkl-alxc01.gazprom-neft.local/search?query=&comeFrom=suggest',
  Search_Catalogs = '/search/catalogs',
  Learning = '/learning',
  Learning_Announcements = '/learning/announcements',
  Learning_Announcements_Uuid = '/learning/announcements/:uuid',
  Learning_Courses = '/learning/courses',
  Learning_Courses_Uuid = '/learning/courses/:uuid',
  Learning_Videos = '/learning/videos',
  Laboratory = '/laboratory',
  Laboratory_DataShop = '/laboratory/data-shop',
  Laboratory_Bi = '/laboratory/bi',
  Laboratory_Instruments = '/laboratory/instruments',
  Laboratory_Learning = '/laboratory/learning',
  Laboratory_Consultation = '/laboratory/consultation',
  Quality = '/quality',
  Quality_Monitoring = '/quality/monitoring',
  Quality_Registers = '/quality/registers',
  Quality_Directories = '/quality/directories',
  Meta = '/meta',
  Meta_SearchMeta = '/meta/search-meta',
  Meta_TuneConnections = '/meta/tune-connections',
  Admin_Metrics = '/admin/metrics',
  Admin_Blog = '/admin/blog',
  Admin_Blog_Edit = '/admin/blog/news/:id',
  Admin_Blog_Form = '/admin/blog/news/add',
  Admin = '/admin',
}

export const onLogoClickRoute = PageRoutesEnum.MainPage;
export const appRedirectRoute = PageRoutesEnum.MainPage;

export const appRoutes = [
  { path: PageRoutesEnum.MainPage, element: <MainPage /> },
  { path: PageRoutesEnum.Instruments_DataPlatformPage, element: <DataPlatformPage /> },
  { path: PageRoutesEnum.Instruments_DataStorage, element: <DataStoragePage /> },
  { path: PageRoutesEnum.Instruments_DataQuality, element: <DataQualityPage /> },
  { path: PageRoutesEnum.Instruments_DataInfoAndStandards, element: <DataInfoAndStandardsPage /> },
  { path: PageRoutesEnum.Instruments_DataCatalogPage, element: <DataCatalogPage /> },
  { path: PageRoutesEnum.Instruments_DataAnalyticsPage, element: <DataAnalyticsPage /> },
  { path: PageRoutesEnum.Instruments_DataLaboratory, element: <DataLaboratoryPage /> },
  { path: PageRoutesEnum.Team, element: <TeamPage /> },
  { path: PageRoutesEnum.Reports, element: <ReportsPage /> },
  {
    path: PageRoutesEnum.ProjectsAndServices,
    element: <PlaceHolderPage title={'Data Office - Проекты и сервис'} />,
  },
  { path: PageRoutesEnum.Blog, element: <BlogPage /> },
  { path: PageRoutesEnum.Blog_News, element: <BlogContentNews /> },
  { path: PageRoutesEnum.Blog_Announcements, element: <BlogContentAnnounce /> },
  { path: PageRoutesEnum.Blog_Cases, element: <BlogContentCase /> },
  { path: PageRoutesEnum.Search_Catalogs, element: <CatalogsSearchPage /> },
  {
    path: PageRoutesEnum.Search_TechMeta,
    element: <PlaceHolderPage title={'Data Office - Поиск - по техническим метаданным'} />,
  },
  {
    path: PageRoutesEnum.Search_BusinessMeta,
    element: <PlaceHolderPage title={'Data Office - Поиск - по бизнес-метаданным'} />,
  },
  { path: PageRoutesEnum.Methodology, element: <MethodologyPage /> },
  { path: PageRoutesEnum.Learning, element: <LearningPage /> },
  { path: PageRoutesEnum.Learning_Announcements_Uuid, element: <AnnouncementDetailsPage /> },
  { path: PageRoutesEnum.Learning_Courses_Uuid, element: <CourseDetailsPage /> },
  { path: PageRoutesEnum.Learning_Courses, element: <LearningAllCoursesPage /> },
  { path: PageRoutesEnum.Learning_Videos, element: <LearningAllVideosPage /> },
  { path: PageRoutesEnum.Laboratory_DataShop, element: <PlaceHolderPage title={'Лаборатория - Магазин данных'} /> },
  { path: PageRoutesEnum.Laboratory_Bi, element: <PlaceHolderPage title={'Лаборатория - Бизнес-аналитика(BI)'} /> },
  { path: PageRoutesEnum.Laboratory_Instruments, element: <PlaceHolderPage title={'Лаборатория - Инструменты'} /> },
  { path: PageRoutesEnum.Laboratory_Learning, element: <PlaceHolderPage title={'Лаборатория - Обучение'} /> },
  { path: PageRoutesEnum.Laboratory_Consultation, element: <PlaceHolderPage title={'Лаборатория - Консультации'} /> },
  { path: PageRoutesEnum.Quality_Monitoring, element: <PlaceHolderPage title={'Качество Данных - Мониторинг'} /> },
  { path: PageRoutesEnum.Quality_Registers, element: <PlaceHolderPage title={'Качество Данных - Реестры'} /> },
  { path: PageRoutesEnum.Quality_Directories, element: <PlaceHolderPage title={'Качество Данных - Справочники'} /> },
  { path: PageRoutesEnum.Meta_SearchMeta, element: <PlaceHolderPage title={'Метаданные - Поиск метаданных'} /> },
  { path: PageRoutesEnum.Admin, element: <AdminMetricsPage /> },
  { path: PageRoutesEnum.Admin_Metrics, element: <AdminMetricsPage /> },
  { path: PageRoutesEnum.Admin_Blog, element: <AdminBlogPage /> },
  { path: PageRoutesEnum.Admin_Blog_Form, element: <AdminBlogNewsForm /> },
  { path: PageRoutesEnum.Admin_Blog_Edit, element: <AdminBlogNewsForm /> },
  {
    path: PageRoutesEnum.Meta_TuneConnections,
    element: <PlaceHolderPage title={'Метаданные - Настройка подключений'} />,
  },
  { path: '*', element: <Navigate to={PageRoutesEnum.MainPage} replace /> },
];

export const withoutFooterRoutes = [
  PageRoutesEnum.Methodology,
  PageRoutesEnum.Search_Catalogs,
  PageRoutesEnum.Admin_Blog,
];
