import React from 'react';

import classes from './CourseProgram.module.css';
import ProgramItem from './ProgramItem/ProgramItem';
import { Text } from '@consta/uikit/Text';

const CourseProgram: React.FC = () => {
  const courseProgram = [
    'Аналитика данных БЛПС',
    'Принципы работы аналитических приложений',
    'Обзор решений ',
    'Загрузка данных',
    'Модель данных',
  ];

  return (
    <div className={classes.container}>
      <Text className={classes.title}>{`Программа курса`}</Text>
      {courseProgram.map((programItem, ind) => (
        <ProgramItem key={ind} text={programItem} ind={ind} isLastItem={ind + 1 === courseProgram.length} />
      ))}
    </div>
  );
};

export default CourseProgram;
