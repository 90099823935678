import React from 'react';

import { TableColumnType } from '../../Table/Table';
import classes from './ColumnsTuneList.module.css';
import ColumnsTuneListItem from './ColumnsTuneListItem/ColumnsTuneListItem';
import { Text } from '@consta/uikit/Text';

interface IColumnsTuneListProps {
  columns: (object & TableColumnType)[];
  onChangeShowColumn: (val: string) => void;
  searchTitleValue: string | null;
}

const ColumnsTuneList: React.FC<IColumnsTuneListProps> = ({ columns, onChangeShowColumn, searchTitleValue }) => {
  return (
    <div className={classes.container}>
      <div className={classes.row}>
        <Text className={classes.title}>{`Открыты в таблице`}</Text>
      </div>
      <div className={classes.delimiter} />
      <div className={classes.selectedItemsList}>
        {columns.map((column, ind) =>
          searchTitleValue ? (
            column.title.toLowerCase().includes(searchTitleValue.toLowerCase()) ? (
              <ColumnsTuneListItem key={ind} column={column} onChangeShowColumn={onChangeShowColumn} />
            ) : null
          ) : (
            <ColumnsTuneListItem key={ind} column={column} onChangeShowColumn={onChangeShowColumn} />
          )
        )}
      </div>
    </div>
  );
};

export default ColumnsTuneList;
