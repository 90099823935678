import React, { ChangeEvent } from 'react';

import { useAppDispatch, useAppSelector } from '../../../../../hooks/hooks';
import {
  selectorMethodologySelectedProcess,
  selectorViewType,
  setViewType,
} from '../../../../../store/methodologySlice';
import { TypeViewEnum } from '../../../../../types/types';
import classes from './ViewControl.module.css';
import { ChoiceGroup } from '@consta/uikit/ChoiceGroup';
import { IconComponent } from '@consta/uikit/Icon';
import { IconCards } from '@consta/uikit/IconCards';
import { IconInfo } from '@consta/uikit/IconInfo';
import { IconTable } from '@consta/uikit/IconTable';

type Item = {
  name: string;
  icon?: IconComponent;
};

const items: Item[] = [
  { name: TypeViewEnum.cards, icon: IconCards },
  { name: TypeViewEnum.table, icon: IconTable },
  /*{ name: TypeViewEnum.about, icon: IconInfo },*/
];

const ViewControl: React.FC = () => {
  const dispatch = useAppDispatch();
  const viewType = useAppSelector(selectorViewType);
  const selectedProcess = useAppSelector(selectorMethodologySelectedProcess);
  const value = items.find((item) => item.name === viewType);

  const onChangeViewType = (props: { e: ChangeEvent<HTMLInputElement>; value: Item }) => {
    if (props.value.name === TypeViewEnum.about && selectedProcess) {
      dispatch(setViewType(props.value.name));
    }
    if (props.value.name !== TypeViewEnum.about) {
      dispatch(setViewType(props.value.name));
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.choiceGroupContainer}>
        <ChoiceGroup
          value={value}
          onChange={onChangeViewType}
          items={items}
          getItemLabel={(item) => item.name}
          multiple={false}
          name="TypeView"
          size="s"
          view={'ghost'}
        />
      </div>
    </div>
  );
};

export default ViewControl;
