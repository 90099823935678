import React from 'react';

import classes from './BlogContentImage.module.css';

interface IContentImageProps {
  imageUrl?: string;
}

const BlogContentImage: React.FC<IContentImageProps> = ({ imageUrl }) => {
  if (!imageUrl) {
    return null;
  }
  return <img src={imageUrl} className={classes.img} alt="content thumbnail" />;
};

export default BlogContentImage;
