import React from 'react';

import { useAppSelector } from '../../../hooks/hooks';
import { selectorLearningCourses } from '../../../store/learningSlice';
import CourseCard from './CourseCard/CourseCard';
import classes from './LearningAllCoursesList.module.css';
import { Button } from '@consta/uikit/Button';

const LearningAllCoursesList: React.FC = () => {
  const courses = useAppSelector(selectorLearningCourses);

  return (
    <div className={classes.container}>
      {courses.map((course, ind) => (
        <CourseCard key={ind} course={course} />
      ))}
      <div className={classes.buttonContainer}>
        <Button label="Показать еще" view="clear" className={classes.button} />
      </div>
    </div>
  );
};

export default LearningAllCoursesList;
