import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { MenuItemType } from '../../../../types/types';
import { hasActiveChildren } from '../../../utils/utils';
import classes from './SidebarMenuItem.module.css';
import SidebarSubMenuItem from './SidebarSubMenuItem/SidebarSubMenuItem';
import { IconArrowRight } from '@consta/uikit/IconArrowRight';
import { Text } from '@consta/uikit/Text';
import classNames from 'classnames';

type PropsType = {
  item: MenuItemType;
  closeSidebar: () => void;
  isSidebarOpen: boolean;
  setActiveItem: (item: any) => void;
  activeMenuitem: string | null;
};

const SidebarMenuItem: React.FC<PropsType> = ({ item, closeSidebar, isSidebarOpen, setActiveItem, activeMenuitem }) => {
  const location = useLocation();
  const isActiveMenuItem = hasActiveChildren(location.pathname, item);

  useEffect(() => {
    if (!activeMenuitem && isActiveMenuItem) {
      setActiveItem(item.label);
    }
  }, [activeMenuitem, isActiveMenuItem, setActiveItem, item.label]);

  return (
    <div className={classes.container}>
      <div
        className={classNames(classes.row, { [classes.active]: activeMenuitem === item.label })}
        onClick={() => setActiveItem(item.label)}
      >
        {item.icon && <div className={classes.icoContainer}>{item.icon}</div>}
        <Text
          className={classNames(classes.title, { [classes.activeTitle]: isActiveMenuItem })}
          truncate
          aria-disabled={item.disabled}
        >
          {item.label}
        </Text>
        {item.children && <IconArrowRight view="secondary" size="s" className={classes.iconRight} />}
      </div>
      {isSidebarOpen && (
        <div className={classes.submenu}>
          <div className={classes.submenuTitle}>{item.label}</div>
          {item.children &&
            item.children.map((item, ind) => (
              <SidebarSubMenuItem key={ind} item={item} level={1} closeSidebar={closeSidebar} />
            ))}
        </div>
      )}
    </div>
  );
};

export default SidebarMenuItem;
