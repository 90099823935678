import { BlogContentType } from '../../Pages/BlogPage/ViewControl/ViewControl';
import { blogContentTypes } from '../../store/blogSlice';

export const getContentTypeQuery = (value: BlogContentType) => {
  if (value === blogContentTypes[1]) {
    return 'filter[type]=news';
  }
  if (value === blogContentTypes[2]) {
    return 'filter[type]=announcement';
  }
  if (value === blogContentTypes[3]) {
    return 'filter[type]=best_practice';
  }
  return '';
};
