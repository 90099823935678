import React, { useRef, useState } from 'react';

import classes from './TooltipForShortString.module.css';
import { Tooltip } from '@consta/uikit/Tooltip';

interface ITooltipOnHoverProps {
  tooltipText?: string;
  tooltipTextMinLimit?: number;
  children: React.ReactNode;
}

const TooltipForShortString: React.FC<ITooltipOnHoverProps> = ({ tooltipText, tooltipTextMinLimit, children }) => {
  const ref = useRef<HTMLDivElement>(null);
  const refMyTimer = useRef<NodeJS.Timeout | undefined>(undefined);

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const setTimer = () => {
    refMyTimer.current = setTimeout(() => {
      setIsOpen(true);
    }, 1000);
  };

  const onMouseEnter = () => {
    if (!isOpen) {
      if (tooltipTextMinLimit) {
        if (tooltipText?.length! > tooltipTextMinLimit) {
          setTimer();
        }
      } else {
        setTimer();
      }
    }
  };

  const onMouseExit = () => {
    clearTimeout(refMyTimer.current);
    if (refMyTimer.current) {
      setIsOpen(false);
    }
  };

  return (
    <>
      <div className={classes.container} ref={ref} onMouseEnter={onMouseEnter} onMouseLeave={onMouseExit}>
        {children}
      </div>
      {isOpen && (
        <Tooltip size="s" anchorRef={ref} direction={'rightCenter'} placeholder={''}>
          {tooltipText}
        </Tooltip>
      )}
    </>
  );
};

export default TooltipForShortString;
