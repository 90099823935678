export const getDateForAnnouncementCalendar = (dateTime: Date) => {
  const yearOptions: Intl.DateTimeFormatOptions = {
    year: 'numeric',
  };
  const yearFormatter = new Intl.DateTimeFormat('ru', yearOptions);
  const year = yearFormatter.format(dateTime);

  const monthOptions: Intl.DateTimeFormatOptions = {
    month: 'long',
  };
  const monthFormatter = new Intl.DateTimeFormat('ru', monthOptions);
  const month = monthFormatter.format(dateTime);

  return `${month.charAt(0).toUpperCase() + month.slice(1)} ${year}`;
};
