import React, { useRef, useState } from 'react';

import LeftSideBar, { LeftSideBarHandleType } from '../../../../../shared-front/LeftSideBar/LeftSideBar';
import TableColumnsTune from '../../../../../shared-front/TableColumnsTune/TableColumnsTune';
import { TableColumnsType } from '../CatalogDataColumn';
import classes from './SearchRow.module.css';
import { Button } from '@consta/uikit/Button';
import { IconDownload } from '@consta/uikit/IconDownload';
import { IconFunnel } from '@consta/uikit/IconFunnel';
import { IconSearch } from '@consta/uikit/IconSearch';
import { IconSettings } from '@consta/uikit/IconSettings';
import { TextField } from '@consta/uikit/TextField';

interface ISearchRowProps {
  columns: TableColumnsType[];
  onChangeShowColumn: (val: string) => void;
}

const SearchRow: React.FC<ISearchRowProps> = ({ columns, onChangeShowColumn }) => {
  const [value, setValue] = useState<string | null>(null);
  const handleChange = ({ value }: { value: string | null }) => setValue(value);

  const refButton = useRef<HTMLButtonElement>(null);
  const refSidebar = useRef<LeftSideBarHandleType>(null);

  const openSidebarClick = () => {
    refSidebar.current?.openSidebar();
  };

  const closeSidebarClick = () => {
    refSidebar.current?.closeSidebar();
  };

  return (
    <div className={classes.container}>
      <div className={classes.textField}>
        <TextField
          value={value}
          onChange={handleChange}
          style={{ flexGrow: '1' }}
          leftSide={IconSearch}
          placeholder="Введите текст"
          size={'s'}
        />
      </div>
      <div className={classes.verticalDelimiter} />
      <Button size="s" view="ghost" iconLeft={IconDownload} className={classes.buttonCatalogList} label={`XLS`} />
      <div className={classes.verticalDelimiter} />
      <Button size="s" view="ghost" iconLeft={IconFunnel} className={classes.buttonCatalogList} onlyIcon />
      <div className={classes.verticalDelimiter} />
      <div>
        <Button
          ref={refButton}
          size="s"
          view="ghost"
          iconLeft={IconSettings}
          className={classes.buttonCatalogList}
          onlyIcon
          onClick={openSidebarClick}
        />
      </div>
      <LeftSideBar ref={refSidebar} refButton={refButton}>
        <TableColumnsTune
          columns={columns}
          onChangeShowColumn={onChangeShowColumn}
          closeSidebarClick={closeSidebarClick}
        />
      </LeftSideBar>
    </div>
  );
};

export default SearchRow;
