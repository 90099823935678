import React from 'react';

import LayoutBanneredPage from '../../Components/LayoutBanneredPage/LayoutBanneredPage';
import LearningAllCoursesControl from './LearningAllCoursesControl/LearningAllCoursesControl';
import LearningAllCoursesHeader from './LearningAllCoursesHeader.tsx/LearningAllCoursesHeader';
import LearningAllCoursesList from './LearningAllCoursesList/LearningAllCoursesList';

const LearningAllCoursesPage: React.FC = () => {
  return (
    <LayoutBanneredPage pageHeader={<LearningAllCoursesHeader />}>
      <LearningAllCoursesControl />
      <LearningAllCoursesList />
    </LayoutBanneredPage>
  );
};

export default LearningAllCoursesPage;
