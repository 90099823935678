import React, { useCallback, useEffect, useState } from 'react';

import { adminApi } from '../../api/adminApi';
import { MetricItem, ProductInfoType } from '../../types/types';
import PerformanceItem from './PerformanceItem/PerformanceItem';
import classes from './ProductPerformance.module.css';

type PropsType = {
  productInfo: ProductInfoType;
};

const ProductPerformance: React.FC<PropsType> = ({ productInfo }) => {
  const [performance, setPerformance] = useState<MetricItem[]>();

  const fetchData = useCallback(async () => {
    const res = await adminApi.getPublicMetric(productInfo.slug);
    setPerformance(res.data[0].metrics);
  }, [productInfo]);

  useEffect(() => {
    fetchData().catch(console.error);
  }, [fetchData]);
  return (
    <div className={classes.container}>
      {performance?.[0] && <PerformanceItem productPerformance={performance[0]} />}
      {performance?.[1] && <PerformanceItem productPerformance={performance?.[1]} />}
    </div>
  );
};

export default ProductPerformance;
