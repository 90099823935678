import React, { useEffect } from 'react';

import { useAppDispatch, useAppSelector } from '../../../../../hooks/hooks';
import {
  selectorCatalogsSearchActiveCatalogUuid,
  selectorCatalogsSearchCatalogs,
  setActiveCatalogUuid,
} from '../../../../../store/catalogsSearchSlice';
import CatalogItem from './CatalogItem/CatalogItem';
import classes from './CatalogsList.module.css';

const CatalogsList: React.FC = () => {
  const dispatch = useAppDispatch();
  const catalogsSearchCatalogs = useAppSelector(selectorCatalogsSearchCatalogs);
  const catalogsSearchActiveCatalogUuid = useAppSelector(selectorCatalogsSearchActiveCatalogUuid);

  useEffect(() => {
    if (!catalogsSearchActiveCatalogUuid && catalogsSearchCatalogs.length > 0) {
      dispatch(setActiveCatalogUuid(catalogsSearchCatalogs[0].uuid));
    }
  }, [dispatch, catalogsSearchCatalogs, catalogsSearchActiveCatalogUuid]);

  return (
    <div className={classes.container}>
      {catalogsSearchCatalogs.map((catalog, ind) => (
        <CatalogItem
          key={ind}
          catalog={catalog}
          isActive={catalog.uuid === catalogsSearchActiveCatalogUuid}
          isLast={catalogsSearchCatalogs.length === ind + 1}
        />
      ))}
    </div>
  );
};

export default CatalogsList;
