import React from 'react';

import classes from './BlogContentText.module.css';
import { Text } from '@consta/uikit/Text';

import parse from 'html-react-parser';
interface IContentTextProps {
  text?: string;
}

const BlogContentText: React.FC<IContentTextProps> = ({ text }) => {
  if (!text) {
    return null;
  }
  return (
    <div className={classes.container}>
      <Text className={classes.text}>{parse(text)}</Text>
    </div>
  );
};

export default BlogContentText;
