import React from 'react';

import TooltipForShortString from '../../../../Components/TooltipForShortString/TooltipForShortString';
import { useAppSelector } from '../../../../hooks/hooks';
import { selectorSearchFIOTeamSlice } from '../../../../store/teamSlice';
import { UserType } from '../../../../types/types';
import { getAvatarUrl } from '../../../../utils/functions';
import { openURLinNewTab } from '../../../../utils/openURLinNewTab';
import classes from './TeamCard.module.css';
import { Avatar } from '@consta/uikit/Avatar';
import { IconForward } from '@consta/uikit/IconForward';
import classNames from 'classnames';

type PropsType = {
  user: UserType;
  verticalColor: string;
};

const TeamCard: React.FC<PropsType> = ({ user, verticalColor }) => {
  const avatarURL = user.icon ? 'data:image/png;base64,' + user.icon : '';
  const isConfluence = user.userName?.toLowerCase().includes('confluence');
  const searchFIO = useAppSelector(selectorSearchFIOTeamSlice);
  const includesSearchFIO = searchFIO && user.userName.toLowerCase().includes(searchFIO.toLowerCase());

  if (user.userName === '') {
    return <div className={classNames(classes.container, classes.emptyContainer)} />;
  }

  const onClick = () => {
    const userEmail = user.userEmail;
    if (userEmail) {
      if (isConfluence) {
        openURLinNewTab(userEmail);
      } else {
        window.open(`mailto:${userEmail}?subject=Вопрос о Дата-Платформе`, '_blank', 'noreferrer');
      }
    }
  };

  return (
    <div className={classNames(classes.container, { [classes.active]: includesSearchFIO })}>
      <div className={classes.topRow}>
        <Avatar className={classes.avatar} url={avatarURL} name={user.userName} />
        <div className={classes.colorCircle} style={{ backgroundColor: verticalColor }} />
      </div>
      <div className={classes.textContainer}>
        <div className={classes.userName}>{user.userName}</div>
        <TooltipForShortString tooltipText={user.userPosition} tooltipTextMinLimit={50}>
          <div className={classes.userPosition}>{user.userPosition}</div>
        </TooltipForShortString>
        {user.userEmail && isConfluence ? (
          <div className={classes.userEmail} onClick={onClick}>
            {'Перейти'}
            <IconForward size={'xs'} />
          </div>
        ) : (
          <div className={classes.userEmail} onClick={onClick}>
            {user.userEmail}
          </div>
        )}
      </div>
    </div>
  );
};

export default TeamCard;
