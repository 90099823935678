import React from 'react';

import PersonalInfoCard from '../../../Components/PersonalInfoCard/PersonalInfoCard';
import learningTeamJson from '../../../templateContent/learningPage/learningTeam.json';
import { PersonInfoType } from '../../../types/types';
import classes from './LearningTeam.module.css';
import { Text } from '@consta/uikit/Text';

const learningTeam: PersonInfoType[] = learningTeamJson.learningTeam;

const LearningTeam: React.FC = () => {
  return (
    <div className={classes.container}>
      <Text className={classes.subTitle}>{`Команда раздела`}</Text>
      <div className={classes.teamList}>
        {learningTeam?.map((personInfo, ind) => (
          <PersonalInfoCard key={ind} info={personInfo} shortName positionTwoRow collapsed={true} />
        ))}
      </div>
    </div>
  );
};

export default LearningTeam;
