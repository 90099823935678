import React from 'react';

import { useBlogAnnouncementsByDate } from '../../../../../hooks/useBlogAnnouncementsByDate';
import { getDateForAnnouncementCalendar } from '../../../../../utils/blogUtils/getDateForAnnouncementCalendar';
import classes from './AnnounceCalendar.module.css';
import AnnounceCalendarItem from './AnnounceCalendarItem/AnnounceCalendarItem';
import { Button } from '@consta/uikit/Button';
import { DateTime } from '@consta/uikit/DateTime';
import { IconBackward } from '@consta/uikit/IconBackward';
import { IconForward } from '@consta/uikit/IconForward';
import { Loader } from '@consta/uikit/Loader';
import { Text } from '@consta/uikit/Text';

interface IAnnounceCalendarProps {}

const AnnounceCalendar: React.FC<IAnnounceCalendarProps> = () => {
  const [currentVisibleDate, announcements, isLoading, increment, decrement] = useBlogAnnouncementsByDate();

  const events = announcements.map((announcement) => new Date(announcement.start_date!));

  return (
    <div className={classes.container}>
      <Text className={classes.title}>{`Календарь анонсов`}</Text>
      {isLoading ? (
        <Loader />
      ) : (
        <div className={classes.calendarWrapper}>
          <DateTime type="date" currentVisibleDate={currentVisibleDate} events={events} />
          <Button
            className={classes.buttonIncrease}
            onClick={increment}
            size={'s'}
            onlyIcon={true}
            iconRight={IconForward}
            view={'clear'}
          />
          <div
            className={classes.centerPlaceHolder}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {getDateForAnnouncementCalendar(currentVisibleDate)}
          </div>
          <Button
            className={classes.buttonDecrease}
            onClick={decrement}
            size={'s'}
            onlyIcon={true}
            iconRight={IconBackward}
            view={'clear'}
          />
        </div>
      )}
      {!isLoading && (
        <div className={classes.list}>
          {announcements.map((announcement, ind) => (
            <div key={ind} className={classes.itemWrapper}>
              <AnnounceCalendarItem announcement={announcement} />
              {ind !== announcements.length - 1 && <div className={classes.delimiter} />}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default AnnounceCalendar;
