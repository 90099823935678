import React from 'react';

import { useAppDispatch, useAppSelector } from '../../../hooks/hooks';
import {
  selectorIsOpenBlogModalSuggestion,
  selectorIsSuccessSuggestion,
  setIsOpenBlogModalSuggestion,
} from '../../../store/blogSlice';
import SuccessSentFrom from './SuccessSentFrom/SuccessSentFrom';
import SuggestionForm from './SuggestionForm/SuggestionForm';
import { Modal } from '@consta/uikit/Modal';

const BlogModalSuggestion: React.FC = () => {
  const dispatch = useAppDispatch();
  const isOpenBlogModalSuggestion = useAppSelector(selectorIsOpenBlogModalSuggestion);
  const isSuccessSuggestion = useAppSelector(selectorIsSuccessSuggestion);

  const closeModal = () => {
    dispatch(setIsOpenBlogModalSuggestion(false));
  };

  return (
    <Modal isOpen={isOpenBlogModalSuggestion} hasOverlay onClickOutside={closeModal} onEsc={closeModal}>
      {isSuccessSuggestion ? <SuccessSentFrom /> : <SuggestionForm />}
    </Modal>
  );
};

export default BlogModalSuggestion;
