import React from 'react';
import { Children } from 'react';

import classes from './LayoutWithoutFooterPage.module.css';
import classNames from 'classnames';

type PropsType = {
  fullWidthContent?: boolean;
  noScrollContent?: boolean;
  children: React.ReactElement | React.ReactElement[];
};

const LayoutWithoutFooterPage: React.FC<PropsType> = ({ fullWidthContent, noScrollContent, children }) => {
  return (
    <div className={classNames(classes.container, { [classes.containerNoScroll]: noScrollContent })}>
      <div className={classNames(classes.contentContainer, { [classes.fullScreenContent]: fullWidthContent })}>
        {Children.map(children, (child) => child)}
      </div>
    </div>
  );
};

export default LayoutWithoutFooterPage;
