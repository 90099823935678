import React from 'react';

import Chichikina_AN from '../../../img/avatars/Chichikina_AN.jpg';
import Delakova_EA from '../../../img/avatars/Delakova_EA.jpg';
import connectionIco from '../../../img/blogPage/connectionIco.svg';
import classes from './Contacts.module.css';
import { Text } from '@consta/uikit/Text';

const Contacts: React.FC = () => {
  const onWriteToEmailUPD = () => {
    window.open('mailto:upd@gazprom-neft.ru?subject=Вопрос по разделу Блог', '_blank', 'noreferrer');
  };

  const onWriteToEmailDelakova = () => {
    window.open('mailto:Delakova.EA@gazprom-neft.ru?subject=Вопрос по разделу Блог', '_blank', 'noreferrer');
  };

  const onWriteToEmailChichikina = () => {
    window.open('mailto:Chichikina.AN@gazprom-neft.ru?subject=Вопрос по разделу Блог', '_blank', 'noreferrer');
  };

  return (
    <div className={classes.container}>
      <Text className={classes.title}>{`Контакты`}</Text>
      <div className={classes.row}>
        <div className={classes.leftColumn}>
          <Text className={classes.team}>{`Команда коммуникаций`}</Text>
          <Text className={classes.post}>{`По вопросам пишите нам на почту`}</Text>
          <Text className={classes.email} onClick={onWriteToEmailUPD}>{`UPD@gazprom-neft.ru`}</Text>
          <div className={classes.rowResources}>
            <img src={connectionIco} alt="connectionIco" />
            <Text className={classes.resources}>{`Ресурсы`}</Text>
          </div>
          <Text className={classes.mobile}>{`Мобильная лента`}</Text>
        </div>
        <div className={classes.card}>
          <img src={Chichikina_AN} alt="Chichikina_AN" className={classes.imgAvatar} />
          <Text className={classes.name}>{`Чичикина А.Н.`}</Text>
          <Text className={classes.position}>{`Администратор кафедры`}</Text>
          <Text
            className={classes.cardEmail}
            onClick={onWriteToEmailChichikina}
          >{`Chichikina.AN@gazprom-neft.ru`}</Text>
        </div>
        <div className={classes.card}>
          <img src={Delakova_EA} alt="Delakova_EA" className={classes.imgAvatar} />
          <Text className={classes.name}>{`Делакова Е.А.`}</Text>
          <Text className={classes.position}>{`Руководитель направления по развитию\nкомпетенций`}</Text>
          <Text className={classes.cardEmail} onClick={onWriteToEmailDelakova}>{`Delakova.EA@gazprom-neft.ru`}</Text>
        </div>
      </div>
    </div>
  );
};

export default Contacts;
