import React from 'react';

import { useAppDispatch, useAppSelector } from '../../../../../hooks/hooks';
import { setCurrentLeader } from '../../../../../store/commandSlice';
import { selectorSearchFIOTeamSlice } from '../../../../../store/teamSlice';
import { getAvatarUrl } from '../../../../../utils/functions';
import classes from './TeamHeadCard.module.css';
import { Avatar } from '@consta/uikit/Avatar';
import { Text } from '@consta/uikit/Text';
import classNames from 'classnames';
import { selectorIsAdminTeam } from '../../../../../store/appSlice';

type PropsType = {
  verticalColor: string;
  verticalTitle: string;
  userName: string;
  userPosition: string;
  userAvatarFile: string;
  blockUuid: string;
  userUuid?: string;
  userEmail?: string;
  openSidebarClick: () => void;
};

const TeamHeadCard: React.FC<PropsType> = ({
  verticalColor,
  verticalTitle,
  blockUuid,
  userName,
  userEmail,
  userPosition,
  userUuid,
  userAvatarFile,
  openSidebarClick,
}) => {
  const dispatch = useAppDispatch();
  // const avatarURL = getAvatarUrl(userAvatarFile);
  const avatarURL = userAvatarFile ? 'data:image/png;base64,' + userAvatarFile : '';
  const searchFIO = useAppSelector(selectorSearchFIOTeamSlice);
  const includesSearchFIO = searchFIO && userName.toLowerCase().includes(searchFIO.toLowerCase());

  const onClick = () => {
    if (userEmail) {
      window.open(`mailto:${userEmail}?subject=Вопрос о Дата-Платформе`, '_blank', 'noreferrer');
    }
  };
  const isTeamAdmin = useAppSelector(selectorIsAdminTeam);
  const onDbCLick = () => {
    if (!isTeamAdmin) {
      return;
    }
    dispatch(
      setCurrentLeader({
        blockTitle: verticalTitle,
        blockUuid: blockUuid,
        user: { label: userName, id: userUuid },
        position: userPosition,
      })
    );
    openSidebarClick();
  };

  return (
    <>
      <div className={classNames(classes.container, { [classes.active]: includesSearchFIO })} onDoubleClick={onDbCLick}>
        <div className={classes.topRow}>
          <Avatar className={classes.avatar} url={avatarURL} name={userName} />
          <div className={classes.tagNameVertical} style={{ backgroundColor: verticalColor }}>
            <Text className={classes.verticalTitle}>{verticalTitle}</Text>
          </div>
        </div>
        <div className={classes.textContainer}>
          <Text className={classes.userName} truncate>
            {userName || 'TBD'}
          </Text>
          <Text className={classes.userPosition}>{userPosition || 'TBD'}</Text>
          <Text className={classes.userEmail} onClick={onClick}>
            {userEmail}
          </Text>
        </div>
      </div>
    </>
  );
};

export default TeamHeadCard;
