import { serverApi } from '../api/serverApi';
import {
  getDocumentsServerType,
  getProcessServerType,
  MethodologyDocumentServerType,
  MethodologyProcessServerType,
  PaginationServerMetaType,
  UniversalServerType,
} from '../api/serverResponse';
import { ComboboxItemType, MethodologyProcessType, TypeViewEnum } from '../types/types';
import { RootState } from './store';
import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';

type MethodologySliceType = {
  viewType: TypeViewEnum;
  documentTags: string[];
  documentTypes: string[];
  documentFormats: string[];
  documentStatuses: string[];
  processes: MethodologyProcessServerType[];
  documents: MethodologyDocumentServerType[];
  documentsMeta?: PaginationServerMetaType;
  processesSearchValue: string;
  selectedProcess?: MethodologyProcessType;
  selectedDocumentStatuses: ComboboxItemType[];
  selectedDocumentTypes: ComboboxItemType[];
  selectedDocumentTags: ComboboxItemType[];
  search: string;
  isLoading: boolean;
  isError?: string;
  isOpenLeftColumn: boolean;
};

const initialState: MethodologySliceType = {
  viewType: TypeViewEnum.cards,
  documentTags: [],
  documentTypes: [],
  documentFormats: [],
  documentStatuses: [],
  processes: [],
  documents: [],
  documentsMeta: undefined,
  processesSearchValue: '',
  selectedProcess: undefined,
  selectedDocumentStatuses: [],
  selectedDocumentTypes: [],
  selectedDocumentTags: [],
  search: '',
  isLoading: false,
  isError: undefined,
  isOpenLeftColumn: true,
};

export const getDocumentTagsThunk = createAsyncThunk<UniversalServerType, undefined, { rejectValue: string }>(
  'methodology/getDocumentTagsThunk',
  async (_, { rejectWithValue }) => {
    try {
      return await serverApi.getDocumentTags();
    } catch (e) {
      return rejectWithValue('Ошибка получения Тэгов для документов');
    }
  }
);

export const getDocumentTypesThunk = createAsyncThunk<UniversalServerType, undefined, { rejectValue: string }>(
  'methodology/getDocumentTypesThunk',
  async (_, { rejectWithValue }) => {
    try {
      return await serverApi.getDocumentTypes();
    } catch (e) {
      return rejectWithValue('Ошибка получения Типов для документов');
    }
  }
);

export const getDocumentFormatsThunk = createAsyncThunk<UniversalServerType, undefined, { rejectValue: string }>(
  'methodology/getDocumentFormatsThunk',
  async (_, { rejectWithValue }) => {
    try {
      return await serverApi.getDocumentFormats();
    } catch (e) {
      return rejectWithValue('Ошибка получения Форматов для документов');
    }
  }
);

export const getDocumentStatusesThunk = createAsyncThunk<UniversalServerType, undefined, { rejectValue: string }>(
  'methodology/getDocumentStatusesThunk',
  async (_, { rejectWithValue }) => {
    try {
      return await serverApi.getDocumentStatuses();
    } catch (e) {
      return rejectWithValue('Ошибка получения Статусов для документов');
    }
  }
);

export const getProcessesThunk = createAsyncThunk<getProcessServerType, undefined, { rejectValue: string }>(
  'methodology/getProcessesThunk',
  async (_, { rejectWithValue }) => {
    try {
      return await serverApi.getProcesses();
    } catch (e) {
      return rejectWithValue('Ошибка получения Процессов');
    }
  }
);

export const getDocumentsThunk = createAsyncThunk<
  getDocumentsServerType,
  { queryString?: string },
  { rejectValue: string }
>('methodology/getDocumentsThunk', async ({ queryString }, { rejectWithValue }) => {
  try {
    return await serverApi.getDocuments(queryString);
  } catch (e) {
    return rejectWithValue('Ошибка получения Документов по процессу');
  }
});

export const methodologySlice = createSlice({
  name: 'methodologySlice',
  initialState,
  reducers: {
    setViewType: (state, action) => {
      state.viewType = action.payload;
    },
    setIsOpenLeftColumn: (state, actions) => {
      state.isOpenLeftColumn = actions.payload;
    },
    setSelectedProcess: (state, action) => {
      state.selectedProcess = action.payload;
    },
    setProcessesSearchValue: (state, action) => {
      state.processesSearchValue = action.payload;
    },
    setSelectedDocumentStatuses: (state, action) => {
      state.selectedDocumentStatuses = action.payload;
    },
    setSearch: (state, action) => {
      state.search = action.payload;
    },
    setSelectedDocumentTypes: (state, action) => {
      state.selectedDocumentTypes = action.payload;
    },
    setSelectedDocumentTags: (state, action) => {
      state.selectedDocumentTags = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDocumentTagsThunk.fulfilled, (state, action) => {
        state.documentTags = action.payload.data;
      })
      .addCase(getDocumentTypesThunk.fulfilled, (state, action) => {
        state.documentTypes = action.payload.data;
      })
      .addCase(getDocumentFormatsThunk.fulfilled, (state, action) => {
        state.documentFormats = action.payload.data;
      })
      .addCase(getDocumentStatusesThunk.fulfilled, (state, action) => {
        state.documentStatuses = action.payload.data;
      })
      .addCase(getProcessesThunk.fulfilled, (state, action) => {
        state.processes = action.payload.data;
      })
      .addCase(getDocumentsThunk.fulfilled, (state, action) => {
        state.documents = action.payload.data;
        state.documentsMeta = (({ data, ...o }) => o)(action.payload);
        state.isLoading = false;
      })
      .addMatcher(
        isAnyOf(
          getDocumentTagsThunk.pending,
          getDocumentTypesThunk.pending,
          getDocumentFormatsThunk.pending,
          getDocumentStatusesThunk.pending,
          getProcessesThunk.pending,
          getDocumentsThunk.pending
        ),
        (state) => {
          state.isLoading = true;
        }
      )
      .addMatcher(
        isAnyOf(
          getDocumentTagsThunk.rejected,
          getDocumentTypesThunk.rejected,
          getDocumentFormatsThunk.rejected,
          getDocumentStatusesThunk.rejected,
          getProcessesThunk.rejected,
          getDocumentsThunk.rejected
        ),
        (state, action) => {
          state.isLoading = false;
          state.isError = action.payload ? action.payload : 'Неизвестная ошибка - methodologySlice';
        }
      );
  },
});

export const {
  setViewType,
  setIsOpenLeftColumn,
  setProcessesSearchValue,
  setSelectedProcess,
  setSelectedDocumentStatuses,
  setSelectedDocumentTypes,
  setSelectedDocumentTags,
  setSearch,
} = methodologySlice.actions;

export const selectorMethodologyIsLoading = (state: RootState) => state.methodology.isLoading;
export const selectorMethodologyError = (state: RootState) => state.methodology.isError;
export const selectorViewType = (state: RootState) => state.methodology.viewType;
export const selectorDocumentTags = (state: RootState) => state.methodology.documentTags;
export const selectorDocumentTypes = (state: RootState) => state.methodology.documentTypes;
export const selectorDocumentFormats = (state: RootState) => state.methodology.documentFormats;
export const selectorDocumentStatuses = (state: RootState) => state.methodology.documentStatuses;
export const selectorMethodologyProcesses = (state: RootState) => state.methodology.processes;
export const selectorMethodologyDocuments = (state: RootState) => state.methodology.documents;
export const selectorMethodologyDocumentsMeta = (state: RootState) => state.methodology.documentsMeta;
export const selectorIsOpenLeftColumn = (state: RootState) => state.methodology.isOpenLeftColumn;
export const selectorMethodologyProcessesSearchValue = (state: RootState) => state.methodology.processesSearchValue;
export const selectorMethodologySelectedProcess = (state: RootState) => state.methodology.selectedProcess;
export const selectorSelectedDocumentStatuses = (state: RootState) => state.methodology.selectedDocumentStatuses;
export const selectorSelectedDocumentTypes = (state: RootState) => state.methodology.selectedDocumentTypes;
export const selectorMethodologySearch = (state: RootState) => state.methodology.search;
export const selectorSelectedDocumentTags = (state: RootState) => state.methodology.selectedDocumentTags;

export default methodologySlice.reducer;
