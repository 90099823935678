import React, { useState } from 'react';

import CatalogDataColumn from './CatalogDataColumn/CatalogDataColumn';
import CatalogsListColumn from './CatalogsListColumn/CatalogsListColumn';
import classes from './MainPart.module.css';

const MainPart: React.FC = () => {
  const [isShowCatalogList, setIsShowCatalogList] = useState(true);

  const showCatalogList = () => {
    setIsShowCatalogList(true);
  };

  const closeCatalogList = () => {
    setIsShowCatalogList(false);
  };

  return (
    <div className={classes.container}>
      <CatalogsListColumn isShowCatalogList={isShowCatalogList} closeCatalogList={closeCatalogList} />
      <CatalogDataColumn isShowCatalogList={isShowCatalogList} showCatalogList={showCatalogList} />
    </div>
  );
};

export default MainPart;
