import React, { Component, ErrorInfo, ReactNode } from 'react';

interface Props {
  message?: string;
  resetError?: () => void;
  children: ReactNode;
}

interface State {
  hasError: boolean;
  error: Error | undefined;
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
    error: undefined,
  };

  public static getDerivedStateFromError(caughtError: Error): State {
    return { hasError: true, error: caughtError };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('Uncaught error:', error, errorInfo);
  }

  public render() {
    if (this.state.hasError) {
      return (
        <div style={{ padding: '60px 32px 0 32px' }}>
          {this.props.message ? (
            <h3 style={{ whiteSpace: 'pre-line', color: '#eb3333' }}>{this.props.message}</h3>
          ) : (
            <h3 style={{ color: '#eb3333' }}>{'Дата-платформа, что-то пошло не так...'}</h3>
          )}
          {this.state.error?.message && <h3 style={{ color: '#0071b2' }}>{this.state.error?.message}</h3>}
          {this.props.resetError && <button onClick={this.props.resetError}>{'Сброс ошибки'}</button>}
        </div>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
