import React from 'react';
import { useParams } from 'react-router-dom';

import { onLogoClickRoute } from '../../../appMenuAndRouting/appRoutes';
import BreadCrumbsRow from '../../../Components/BreadCrumbsRow/BreadCrumbsRow';
import LayoutOrdinaryPage from '../../../Components/LayoutOrdinaryPage/LayoutOrdinaryPage';
import { useBlogAnnounce } from '../../../hooks/useBlogAnnounce';
import AppFooter from '../../../shared-front/AppFooter/AppFooter';
import classes from '../BlogContentNews/BlogContentNews.module.css';
import BlogContentAnnounceMain from './BlogContentAnnounceMain/BlogContentAnnounceMain';
import { Loader } from '@consta/uikit/Loader';

const BlogContentAnnounce = () => {
  const { id } = useParams();
  const [announce, isLoading] = useBlogAnnounce(id);

  return (
    <div className={classes.container}>
      <LayoutOrdinaryPage>
        {isLoading ? (
          <Loader style={{ marginTop: '80px' }} />
        ) : (
          <>
            <BreadCrumbsRow label={announce?.name} />
            <BlogContentAnnounceMain announce={announce} />
          </>
        )}
      </LayoutOrdinaryPage>
      {/*<AppFooter*/}
      {/*  portalName={'Дата-Платформе'}*/}
      {/*  questionEmail={'data-platform@gazprom-neft.ru'}*/}
      {/*  onLogoClickRoute={onLogoClickRoute}*/}
      {/*/>*/}
    </div>
  );
};

export default BlogContentAnnounce;
