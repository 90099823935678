import React, { useState } from 'react';

import { MenuItemType } from '../../../types/types';
import classes from './SidebarMenu.module.css';
import SidebarMenuItem from './SidebarMenuItem/SidebarMenuItem';

type PropsType = {
  sideBarMenuItems: MenuItemType[];
  closeSidebar: () => void;
  isSidebarOpen: boolean;
};

const SidebarMenu: React.FC<PropsType> = ({ sideBarMenuItems, closeSidebar, isSidebarOpen }) => {
  const [activeMenuitem, setActiveMenuItem] = useState<string | null>(null);

  const setActiveItem = (item: string | null) => {
    setActiveMenuItem(item);
  };

  return (
    <div className={classes.container}>
      {sideBarMenuItems.map((item, ind) => (
        <SidebarMenuItem
          key={ind}
          item={item}
          closeSidebar={closeSidebar}
          setActiveItem={setActiveItem}
          activeMenuitem={activeMenuitem}
          isSidebarOpen={isSidebarOpen}
        />
      ))}
    </div>
  );
};

export default SidebarMenu;
