import { serverApi } from '../api/serverApi';
import { getRejectedValue } from './rejectedValueHelper';
import { RootState } from './store';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

type AppSliceType = {
  metrics: any | undefined;
  isLoadingMetrics: boolean;
  isErrorMetrics: string | undefined;
};

const initialState: AppSliceType = {
  metrics: undefined,
  isLoadingMetrics: false,
  isErrorMetrics: undefined,
};

export const getAdminMetricsThunk = createAsyncThunk<any, undefined, { rejectValue: string }>(
  'app/getAdminMetricsThunk',
  async (_, { rejectWithValue }) => {
    try {
      return await serverApi.getAdminMetric();
    } catch (e) {
      return rejectWithValue(getRejectedValue('Ошибка получения метрик', e));
    }
  }
);

export const adminSlice = createSlice({
  name: 'appSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAdminMetricsThunk.fulfilled, (state, action) => {
        state.isLoadingMetrics = false;
        state.metrics = action.payload.data;
      })
      .addCase(getAdminMetricsThunk.pending, (state) => {
        state.metrics = undefined;
        state.isErrorMetrics = undefined;
        state.isLoadingMetrics = true;
      })
      .addCase(getAdminMetricsThunk.rejected, (state, action) => {
        state.metrics = undefined;
        state.isLoadingMetrics = false;
        state.isErrorMetrics = action.payload ? action.payload : 'Неизвестная ошибка - appSlice';
      });
  },
});

export const selectorMetrics = (state: RootState) => state.admin.metrics;
export const selectorIsLoadingMetrics = (state: RootState) => state.admin.isLoadingMetrics;
export const selectorIsErrorMetrics = (state: RootState) => state.admin.isErrorMetrics;

export default adminSlice.reducer;
