import React from 'react';

import { useAppDispatch, useAppSelector } from '../../../../../hooks/hooks';
import {
  getAllBlogAddEventsThunk,
  selectorActiveBlogContentType,
  selectorBlogEvents,
  selectorBlogEventsMeta,
  selectorBlogIsLoading,
  selectorBlogSelectedTags,
} from '../../../../../store/blogSlice';
import { getContentTypeQuery } from '../../../../../utils/blogUtils/getContentTypeQuery';
import { getFilterTagQuery } from '../../../../../utils/blogUtils/getFilterTagQuery';
import { combineQuery } from '../../../../../utils/combineQuery';
import ContentItem from './ContentItem/ContentItem';
import classes from './ContentList.module.css';
import { Button } from '@consta/uikit/Button';
import { Loader } from '@consta/uikit/Loader';
import { Text } from '@consta/uikit/Text';

const ContentList: React.FC = () => {
  const dispatch = useAppDispatch();
  const blogEvents = useAppSelector(selectorBlogEvents);
  const blogIsLoading = useAppSelector(selectorBlogIsLoading);
  const blogEventsMeta = useAppSelector(selectorBlogEventsMeta);
  const activeBlogContentType = useAppSelector(selectorActiveBlogContentType);
  const blogSelectedTags = useAppSelector(selectorBlogSelectedTags);

  if (blogIsLoading) {
    return <Loader style={{ top: '20%' }} />;
  }

  const onClickShowMore = () => {
    if (blogEventsMeta && blogEventsMeta.current_page) {
      const contentTypeQuery = getContentTypeQuery(activeBlogContentType);
      const filterTagQuery = getFilterTagQuery(blogSelectedTags);
      const paginationQuery = `page[size]=5&page[number]=${blogEventsMeta.current_page + 1}`;
      const query = combineQuery(contentTypeQuery, filterTagQuery, paginationQuery);
      dispatch(getAllBlogAddEventsThunk(query));
    }
  };

  return (
    <div className={classes.container}>
      {blogEvents.length > 0 ? (
        <>
          {blogEvents.map((blogEvent, ind) => (
            <ContentItem key={ind} blogEvent={blogEvent} />
          ))}
          {blogEventsMeta &&
            blogEventsMeta.current_page &&
            blogEventsMeta.last_page &&
            blogEventsMeta.current_page < blogEventsMeta.last_page && (
              <Button label={`Показать ещё`} view={'ghost'} onClick={onClickShowMore} />
            )}
        </>
      ) : (
        <Text className={classes.notFound}>{'Ничего не найдено'}</Text>
      )}
    </div>
  );
};

export default ContentList;
