import { ReportsDictionaryItemType, ReportsDictionaryType } from '../types/types';

type FilterType = {
  blocks: ReportsDictionaryItemType[];
  systems: ReportsDictionaryItemType[];
  dataServices: ReportsDictionaryItemType[];
  hasAccess: ReportsDictionaryItemType[];
  searchString: string;
};

export const formatReportsFilterToString = (filter: FilterType) => {
  let str = '?';
  let tmp: string[] = [];
  if (filter.blocks) {
    filter.blocks.forEach((el) => {
      tmp.push('filter[blocks][]=' + el._uuid);
    });
  }
  if (filter.dataServices) {
    filter.dataServices.forEach((el) => {
      tmp.push('filter[dataServices][]=' + el._uuid);
    });
  }
  if (filter.systems) {
    filter.systems.forEach((el) => {
      tmp.push('filter[uuid_system][]=' + el._uuid);
    });
  }
  if (filter.hasAccess) {
    filter.hasAccess.forEach((el) => {
      tmp.push('filter[hasAccess]=' + el._uuid);
    });
  }
  if (filter.searchString) {
    str = str + 'filter[name]=' + filter.searchString;
  }

  str += tmp.join('&');
  return str;
};

export const getFilterFromUrl = (searchParams: URLSearchParams, dictionaries: ReportsDictionaryType) => {
  const filter: FilterType = {
    blocks: [],
    systems: [],
    dataServices: [],
    hasAccess: [],
    searchString: '',
  };

  searchParams.forEach((val, key) => {
    if (key === 'filter[name]') {
      filter.searchString = val;
    } else if (key === 'filter[hasAccess]') {
      filter.hasAccess = [
        {
          name: val === '1' ? 'Есть доступ' : 'Нет доступа',
          _uuid: val,
        },
      ];
    } else if (key === 'filter[blocks][]') {
      if (dictionaries.blocks) {
        const tmp = dictionaries.blocks.find((el) => el._uuid === val);
        if (tmp) {
          filter.blocks.push(tmp);
        }
      }
    } else if (key === 'filter[uuid_system][]') {
      if (dictionaries.systems) {
        const tmp = dictionaries.systems.find((el) => el._uuid === val);
        if (tmp) {
          filter.systems.push(tmp);
        }
      }
    } else if (key === 'filter[dataServices][]') {
      if (dictionaries.dataServices) {
        const tmp = dictionaries.dataServices.find((el) => el._uuid === val);
        if (tmp) {
          filter.dataServices.push(tmp);
        }
      }
    }
  });

  return filter;
};
