import React, { useEffect, useState } from 'react';

import { MethodologyProcessServerType } from '../../../../../../api/serverResponse';
import { useAppSelector } from '../../../../../../hooks/hooks';
import { selectorMethodologyProcesses } from '../../../../../../store/methodologySlice';
import { MethodologyProcessType } from '../../../../../../types/types';
import ProcessItem from './ProcessItem/ProcessItem';
import classes from './ProcessList.module.css';

const transformProcess = (serverProcess: MethodologyProcessServerType): MethodologyProcessType => {
  return {
    uuid: serverProcess._uuid,
    code: serverProcess.code || 'нет кода',
    name: serverProcess.name || 'нет названия',
    children: [],
  };
};

const findProcessParent = (processesTree: MethodologyProcessType[], serverProcess: MethodologyProcessServerType) => {
  processesTree.forEach((curProcess) => {
    if (curProcess.uuid === serverProcess.uuid_process_parent) {
      const methodologyProcess = transformProcess(serverProcess);
      curProcess.children.push(methodologyProcess);
      return;
    } else {
      findProcessParent(curProcess.children, serverProcess);
    }
  });
};

const makeProcessTree = (serverProcesses: MethodologyProcessServerType[]): MethodologyProcessType[] => {
  const processesTree: MethodologyProcessType[] = [];
  serverProcesses?.forEach((curProcess) => {
    if (curProcess.uuid_process_parent === null) {
      const methodologyProcess = transformProcess(curProcess);
      processesTree.push(methodologyProcess);
    } else {
      findProcessParent(processesTree, curProcess);
    }
  });
  return processesTree;
};

interface IProcessListProps {
  searchValue: string;
}

const ProcessList: React.FC<IProcessListProps> = ({ searchValue }) => {
  const processes = useAppSelector(selectorMethodologyProcesses);
  const [processTree, setProcessTree] = useState<MethodologyProcessType[]>([]);

  useEffect(() => {
    if (processes.length > 0) {
      const processesTree = makeProcessTree(processes);
      setProcessTree(processesTree);
    }
  }, [processes]);

  useEffect(() => {
    if (searchValue) {
      const filteredProcesses = processes.filter((process) =>
        process.name?.toLowerCase().includes(searchValue.toLowerCase())
      );
      setProcessTree(filteredProcesses.map((fp) => transformProcess(fp)));
    } else {
      const processesTree = makeProcessTree(processes);
      setProcessTree(processesTree);
    }
  }, [searchValue, processes]);

  return (
    <div className={classes.container}>
      {processTree.map((process, ind) => (
        <ProcessItem key={ind} process={process} />
      ))}
    </div>
  );
};

export default ProcessList;
