import React from 'react';

import Reports from '../../Components/Reports/Reports';
import classes from '../DataPlatformPage/DataPlatformPage.module.css';
import ReportsAbout from './ReportsAbout/ReportsAbout';
import ReportsHero from './ReportsHero/ReportsHero';

const ReportsPage: React.FC = () => {
  return (
    <div className={classes.container}>
      <ReportsHero />
      <ReportsAbout />
      <Reports />
    </div>
  );
};

export default ReportsPage;
