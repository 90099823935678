import React from 'react';

import { ProductPerformanceType } from '../../../types/types';
import classes from './PerformanceItem.module.css';
import classNames from 'classnames';

type PropsType = {
  info: any;
  detailsURL?: string;
  showRedirectButton?: boolean;
};

const PerformanceItem: React.FC<PropsType> = ({ info, detailsURL, showRedirectButton }) => {
  return (
    <div className={classes.container}>
      <div className={classes.textInfoContainer}>
        <div className={classes.indTitle}>{info.value}</div>
        <div className={classNames(classes.descriptionTitle, { [classes.shortDescriptionTitle]: detailsURL })}>
          {info.name}
        </div>
      </div>
      {/*{showRedirectButton && detailsURL && (*/}
      {/*  <Button label="Подробнее" view="secondary" iconRight={IconForward} onClick={onClick} />*/}
      {/*)}*/}
    </div>
  );
};

export default PerformanceItem;
