import { SORTING_ITEMS } from '../../Pages/Admin/AdminBlogPage/AdminBlogPageHeader/AdminBlogPageActionRow/SortingSelector/SORTING_ITEMS';

export const getQuerySortValue = (activeSortingItemId?: number | undefined) => {
  console.log('activeSortingItemId', activeSortingItemId);
  if (activeSortingItemId) {
    const activeSortingItem = SORTING_ITEMS.find((item) => item.id === activeSortingItemId);
    return activeSortingItem ? activeSortingItem.sortParams : '';
  }
  return ``;
};
