import { serverApi } from '../api/serverApi';
import { BlogItemsType, BlogItemTagType, ViewSelectorItemType } from '../types/types';
import { RootState } from './store';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

export const ADMIN_BLOG_PAGE_VIEW_SELECTOR_ITEMS: ViewSelectorItemType[] = [
  {
    name: 'Опубликованные материалы',
    slug: 'news',
  },
  {
    name: 'Предложения читателей',
    //disabled: true,
  },
  {
    name: 'Управление рассылкой',
    slug: 'subscription',
    //disabled: true,
  },
  {
    name: 'Дайджесты',
    //disabled: true,
  },
];

type AdminBlogPageSliceType = {
  viewSelectorActiveItem: ViewSelectorItemType | null;
  searchValue: string | undefined;
  inArchive: boolean;
  activeSortingId: number;
  blogItems: BlogItemsType | undefined;
  isLoadingBlogItems: boolean;
  errorAdminBlogPage: string | undefined;
  newsTypes: ViewSelectorItemType[];
  newsTags: BlogItemTagType[];
  blogRoles: [];
};

const initialState: AdminBlogPageSliceType = {
  viewSelectorActiveItem: ADMIN_BLOG_PAGE_VIEW_SELECTOR_ITEMS[0],
  searchValue: undefined,
  activeSortingId: 1,
  blogItems: undefined,
  inArchive: false,
  isLoadingBlogItems: false,
  newsTypes: [],
  newsTags: [],
  errorAdminBlogPage: undefined,
  blogRoles: [],
};

export const getAdminBlogItemsThunk = createAsyncThunk<BlogItemsType, string | undefined, { rejectValue: string }>(
  'adminBlogPageSlice/getAdminBlogItemsThunk',
  async (params, { rejectWithValue }) => {
    try {
      return await serverApi.getBlogItems(params);
    } catch (e) {
      return rejectWithValue('Ошибка получения элементов блога');
    }
  }
);

export const getAdminBlogSubscriptionThunk = createAsyncThunk<
  BlogItemsType,
  string | undefined,
  { rejectValue: string }
>('adminBlogPageSlice/getAdminBlogSubscriptionThunk', async (params, { rejectWithValue }) => {
  try {
    return await serverApi.getBlogSubscriptions(params);
  } catch (e) {
    return rejectWithValue('Ошибка получения элементов блога');
  }
});

export const getAdminBlogRolesThunk = createAsyncThunk<any, undefined, { rejectValue: string }>(
  'adminBlogPageSlice/getAdminBlogRolesThunk',
  async (_, { rejectWithValue }) => {
    try {
      return await serverApi.getBlogSpeakerRoles();
    } catch (e) {
      return rejectWithValue('Ошибка получения ролей блога');
    }
  }
);

export const getBlogNewsTypesThunk = createAsyncThunk<
  ViewSelectorItemType[],
  string | undefined,
  { rejectValue: string }
>('adminBlogPageSlice/getBlogNewsTypesThunk', async (params, { rejectWithValue }) => {
  try {
    return await serverApi.getBlogNewsTypes();
  } catch (e) {
    return rejectWithValue('Ошибка получения типов новостей');
  }
});

export const getBlogNewsTagsThunk = createAsyncThunk<BlogItemTagType[], string | undefined, { rejectValue: string }>(
  'adminBlogPageSlice/getBlogNewsTagsThunk',
  async (params, { rejectWithValue }) => {
    try {
      return await serverApi.getBlogNewsTags();
    } catch (e) {
      return rejectWithValue('Ошибка получения тэгов');
    }
  }
);

export const postBlogNewsThunk = createAsyncThunk<any, string | undefined, { rejectValue: string }>(
  'adminBlogPageSlice/postBlogNewsThunk',
  async (params, { rejectWithValue }) => {
    try {
      return await serverApi.postNews(params);
    } catch (e) {
      return rejectWithValue('Ошибка добавления новости');
    }
  }
);

export const updateBlogNewsThunk = createAsyncThunk<any, any | undefined, { rejectValue: string }>(
  'adminBlogPageSlice/updateBlogNewsThunk',
  async (params: any, { rejectWithValue }) => {
    try {
      return await serverApi.updateNews(params.data, params.uuid);
    } catch (e) {
      return rejectWithValue('Ошибка добавления новости');
    }
  }
);

export const publishBlogNewsThunk = createAsyncThunk<any, any | undefined, { rejectValue: string }>(
  'adminBlogPageSlice/publishBlogNewsThunk',
  async (uuid: string, { rejectWithValue }) => {
    try {
      return await serverApi.publishNews(uuid);
    } catch (e) {
      return rejectWithValue('Ошибка публикации новости');
    }
  }
);

export const archiveBlogNewsThunk = createAsyncThunk<any, any | undefined, { rejectValue: string }>(
  'adminBlogPageSlice/archiveBlogNewsThunk',
  async (uuid: any, { rejectWithValue }) => {
    try {
      return await serverApi.archiveNews(uuid);
    } catch (e) {
      return rejectWithValue('Ошибка архивирования новости');
    }
  }
);

export const deleteBlogNewsThunk = createAsyncThunk<any, any | undefined, { rejectValue: string }>(
  'adminBlogPageSlice/deleteBlogNewsThunk',
  async (uuid: string, { rejectWithValue }) => {
    try {
      return await serverApi.deleteNews(uuid);
    } catch (e) {
      return rejectWithValue('Ошибка удаления новости');
    }
  }
);
export const deleteBlogSubscriptionThunk = createAsyncThunk<any, any | undefined, { rejectValue: string }>(
  'adminBlogPageSlice/deleteBlogSubscriptionThunk',
  async (uuid: string, { rejectWithValue }) => {
    try {
      return await serverApi.deleteSubscription(uuid);
    } catch (e) {
      return rejectWithValue('Ошибка удаления подписки');
    }
  }
);

export const addBlogSubscriptionThunk = createAsyncThunk<any, any | undefined, { rejectValue: string }>(
  'adminBlogPageSlice/addBlogSubscriptionThunk',
  async (uuid: string, { rejectWithValue }) => {
    try {
      return await serverApi.addSubscription(uuid);
    } catch (e) {
      return rejectWithValue('Ошибка добавления подписчика');
    }
  }
);

export const adminBlogSlice = createSlice({
  name: 'adminBlogPageSlice',
  initialState,
  reducers: {
    setViewSelectorActiveItem: (state, action) => {
      state.viewSelectorActiveItem = action.payload;
    },
    setAdminBlogSearchValue: (state, action) => {
      state.searchValue = action.payload;
    },
    setAdminBlogInArchive: (state, action) => {
      state.inArchive = action.payload;
    },
    setAdminBlogActiveSortingId: (state, action) => {
      state.activeSortingId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAdminBlogItemsThunk.fulfilled, (state, action) => {
        state.isLoadingBlogItems = false;
        state.blogItems = action.payload;
      })
      .addCase(getAdminBlogSubscriptionThunk.fulfilled, (state, action) => {
        state.isLoadingBlogItems = false;
        state.blogItems = action.payload;
      })
      .addCase(getAdminBlogRolesThunk.fulfilled, (state, action) => {
        state.blogRoles = action.payload;
      })
      .addCase(getBlogNewsTypesThunk.fulfilled, (state, action) => {
        state.isLoadingBlogItems = false;
        state.newsTypes = action.payload;
      })
      .addCase(getBlogNewsTagsThunk.fulfilled, (state, action) => {
        state.isLoadingBlogItems = false;
        state.newsTags = action.payload;
      })
      .addCase(getAdminBlogItemsThunk.pending, (state) => {
        state.blogItems = undefined;
        state.isLoadingBlogItems = true;
        state.errorAdminBlogPage = undefined;
      })
      .addCase(getAdminBlogSubscriptionThunk.pending, (state) => {
        state.blogItems = undefined;
        state.isLoadingBlogItems = true;
        state.errorAdminBlogPage = undefined;
      })
      .addCase(getAdminBlogItemsThunk.rejected, (state, action) => {
        state.isLoadingBlogItems = false;
        state.errorAdminBlogPage = action.payload
          ? action.payload.toString()
          : 'Неизвестная ошибка - adminBlogPageSlice';
      })
      .addCase(getBlogNewsTypesThunk.rejected, (state, action) => {
        state.isLoadingBlogItems = false;
        state.errorAdminBlogPage = action.payload
          ? action.payload.toString()
          : 'Неизвестная ошибка - adminBlogPageSlice';
      });
  },
});

export const {
  setViewSelectorActiveItem,
  setAdminBlogSearchValue,
  setAdminBlogInArchive,
  setAdminBlogActiveSortingId,
} = adminBlogSlice.actions;

export const selectorViewSelectorActiveItem = (state: RootState) => state.adminBlog.viewSelectorActiveItem;
export const selectorViewSelectorSearchValue = (state: RootState) => state.adminBlog.searchValue;
export const selectorViewSelectorInArchive = (state: RootState) => state.adminBlog.inArchive;
export const selectorViewSelectorActiveSortingId = (state: RootState) => state.adminBlog.activeSortingId;
export const selectorViewSelectorBlogItems = (state: RootState) => state.adminBlog.blogItems;
export const selectorViewSelectorNewsTypes = (state: RootState) => state.adminBlog.newsTypes;
export const selectorViewSelectorNewsTags = (state: RootState) => state.adminBlog.newsTags;
export const selectorBlogRoles = (state: RootState) => state.adminBlog.blogRoles;
export const selectorViewSelectorIsLoadingBlogItems = (state: RootState) => state.adminBlog.isLoadingBlogItems;

export const selectorErrorAdminBlogPage = (state: RootState) => state.adminBlog.errorAdminBlogPage;

export default adminBlogSlice.reducer;
