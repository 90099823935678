import React from 'react';

import learnMorePicture from '../../../img/mainPage/learnMorePicture.png';
import classes from './LearnMoreBlock.module.css';
import ModalPlungeInData from './ModalPlungeInData/ModalPloungInData';
import { Button } from '@consta/uikit/Button';
import { Modal } from '@consta/uikit/Modal';
import { Text } from '@consta/uikit/Text';

const LearnMoreBlock = () => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className={classes.container}>
      <img src={learnMorePicture} className={classes.img} alt="learnMorePicture" />
      <div className={classes.rightColum}>
        <Text className={classes.title}>{`Узнайте больше о работе с данными`}</Text>
        <Text
          className={classes.description}
        >{`Пройдите небольшое погружение в управление данными для формирования базовых знаний и принципов работы с данными в компании`}</Text>
        <div className={classes.btn}>
          <Button label={'Подробнее'} form="round" size={'m'} onClick={() => setIsModalOpen(true)} />
        </div>
      </div>
      <Modal isOpen={isModalOpen} hasOverlay onClickOutside={closeModal} onEsc={closeModal}>
        <ModalPlungeInData closeModal={closeModal} />
      </Modal>
    </div>
  );
};

export default LearnMoreBlock;
