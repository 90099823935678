import { BlogItemsType, BlogItemType, ViewSelectorItemType } from '../types/types';
import instanceAxios from './instanceAxios';

export const adminBlogApi = {
  async getBlogItems(params?: string) {
    const response = await instanceAxios.get<BlogItemsType>(`/admin/blog${params ? params : ''}`);
    return response.data;
  },
  async getBlogSubscriptions(params?: string) {
    const response = await instanceAxios.get<BlogItemsType>(`/admin/newsletter${params ? params : ''}`);
    return response.data;
  },
  async getAdminBlogItem(id: string) {
    const response = await instanceAxios.get<any>(`/admin/blog/${id}`);
    return response.data;
  },
  async publishBlogItem(id: string) {
    const response = await instanceAxios.get<BlogItemType>(`/admin/blog/${id}/publish`);
    return response.data;
  },
  async getBlogNewsTypes() {
    const response = await instanceAxios.get<ViewSelectorItemType[]>(`/admin/blog/types`);
    return response.data;
  },
  async getBlogNewsTags() {
    const response = await instanceAxios.get(`/admin/blog/tags`);
    return response.data.data;
  },
  async postNews(newsBody: any) {
    const response = await instanceAxios.post('/admin/blog', newsBody, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  },
  async updateNews(newsBody: any, uuid: string) {
    const response = await instanceAxios.post(`/admin/blog/${uuid}`, newsBody, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  },
  async publishNews(uuid: string) {
    const response = await instanceAxios.get(`/admin/blog/${uuid}/publish`);
    return response.data;
  },
  async archiveNews(uuid: string) {
    const response = await instanceAxios.get(`/admin/blog/${uuid}/to-archive`);
    return response.data;
  },
  async deleteNews(uuid: string) {
    const response = await instanceAxios.delete(`/admin/blog/${uuid}`);
    return response.data;
  },
  async deleteSubscription(uuid: string) {
    const response = await instanceAxios.delete(`/admin/newsletter/${uuid}`);
    return response.data;
  },
  async addSubscription(uuid: string) {
    const response = await instanceAxios.post(`/admin/newsletter`, { _uuid: uuid });
    return response.data;
  },
  async getBlogSpeakerRoles() {
    const response = await instanceAxios.get(`/admin/blog/roles`);
    return response.data.data.map((el: any) => {
      return {
        label: el.name,
        id: el._uuid,
      };
    });
  },
};
