import React from 'react';

import { PersonInfoType } from '../../types/types';
import { getShortName } from '../../utils/functions';
import classes from './PersonalInfoCard.module.css';
import { Avatar } from '@consta/uikit/Avatar';
import { Text } from '@consta/uikit/Text';
import classNames from 'classnames';

type PropsType = {
  info: PersonInfoType;
  teamCard?: boolean;
  shortName?: boolean;
  positionTwoRow?: boolean;
  collapsed: boolean;
};

const PersonalInfoCard: React.FC<PropsType> = ({ info, teamCard, shortName, positionTwoRow, collapsed }) => {
  const avatarURL = info.avatarURL ? 'data:image/png;base64,' + info.avatarURL : '';

  return collapsed ? (
    <div className={classNames(classes.container, { [classes.teamCard]: teamCard })}>
      <div className={classes.mainPartContainer}>
        <Avatar className={classes.avatar} url={avatarURL} size={'l'} name={info.name} />
        <div className={classNames(classes.infoContainer, { [classes.infoContainerTeamCard]: teamCard })}>
          <div className={classes.nameInfo}>{shortName ? getShortName(info.name) : info.name}</div>
          <Text className={classNames(classes.positionInfo, { [classes.positionTwoRow]: positionTwoRow })} size="s">
            {info.position}
          </Text>
          {!teamCard && info.department && (
            <div className={classNames(classes.departmentInfo, { [classes.departmentInfoTeamCard]: teamCard })}>
              {info.department}
            </div>
          )}
          {info.email && (
            <a
              href={`mailto:${info.email}${info.mailSubject || ''}`}
              target={'_blank'}
              className={classes.linkToEmail}
              rel="noreferrer"
            >
              {info.email}
            </a>
          )}
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default PersonalInfoCard;
