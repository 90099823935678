import React from 'react';

import classes from './EmptyDocumentsPlaceholder.module.css';
import { Text } from '@consta/uikit/Text';

const EmptyDocumentsPlaceholder: React.FC = () => {
  return (
    <div className={classes.emptyDocumentsContainer}>
      <div className={classes.emptyDocumentsTitle}>
        <Text size="s" view="secondary" className={classes.title}>
          Документов не найдено, вы можете ознакомиться с
        </Text>
        <Text size="s" view="link" className={classes.continueTitle}>
          {` бэклогом документов`}
        </Text>
      </div>
    </div>
  );
};

export default EmptyDocumentsPlaceholder;
