import React from 'react';

import iconDataOffice from '../shared-front/AppHeader/assets/ico_data_office.svg';
import { ExternalRoutesEnum, PageRoutesEnum } from './appRoutes';

export const appSideBarMenuItems = {
  label: 'Data Office ',
  href: '/',
  icon: <img src={iconDataOffice} alt="data-office" />,
  children: [
    { label: 'Главная', href: PageRoutesEnum.MainPage },
    {
      label: 'Поиск по данным',
      href: PageRoutesEnum.Search,
      children: [
        { label: 'Поиск по каталогам', href: PageRoutesEnum.Search_Catalogs, disabled: true },
        {
          label: 'Поиск по бизнес-метаданным',
          extUrl: PageRoutesEnum.Search_BusinessMeta,
        },
        {
          label: 'Поиск по техническим метаданным',
          href: PageRoutesEnum.Search_TechMeta,
          disabled: true,
        },
      ],
    },
    { label: 'Блог', href: PageRoutesEnum.Blog },
    { label: 'Методология', href: PageRoutesEnum.Methodology, disabled: true },
    { label: 'Обучение', href: PageRoutesEnum.Learning, disabled: true },
    {
      label: 'Дата-платформа',
      href: PageRoutesEnum.Instruments,
      children: [
        { label: 'Дата-платформа', href: PageRoutesEnum.Instruments_DataPlatformPage },
        { label: 'Хранилище и доступ к данным', href: PageRoutesEnum.Instruments_DataStorage },
        { label: 'Качество данных', href: PageRoutesEnum.Instruments_DataQuality },
        { label: 'НСИ', href: PageRoutesEnum.Instruments_DataInfoAndStandards },
        { label: 'Каталог данных', href: PageRoutesEnum.Instruments_DataCatalogPage },
        { label: 'Бизнес-аналитика', href: PageRoutesEnum.Instruments_DataAnalyticsPage },
        { label: 'Лаборатория данных', href: PageRoutesEnum.Instruments_DataLaboratory },
      ],
    },
    {
      label: 'Проекты и сервисы',
      href: PageRoutesEnum.ProjectsAndServices,
      children: [
        { label: 'Confluence УПД БРД', extUrl: ExternalRoutesEnum.Confluence_UPD_BRD },
        { label: 'Confluence УПД БЛПС', extUrl: ExternalRoutesEnum.Confluence_UPD_BLPS },
        { label: 'Confluence УПД ДРП', extUrl: ExternalRoutesEnum.Confluence_UPD_DRP },
      ],
    },
    { label: 'Отчеты', href: PageRoutesEnum.Reports },
    { label: 'Команда', href: PageRoutesEnum.Team },
  ],
};
