import React, { useCallback, useEffect, useState } from 'react';

import { serverApi } from '../../../../api/serverApi';
import { useAppDispatch, useAppSelector } from '../../../../hooks/hooks';
import { createTableColumn } from '../../../../shared-front/Table/utils/createTableColumn';
import { getTableHeaderSize } from '../../../../shared-front/Table/utils/getHeaderSize';
import { ColumnFilterItemType } from '../../../../shared-front/TableColumnFilter/TableColumnFilter';
import { SortDirectionEnum } from '../../../../shared-front/TableColumnSort/TableColumnSort';
import {
  getCatalogDataByUuidThunk,
  selectorCatalogsSearchActiveCatalogUuid,
  selectorCatalogsSearchCatalogData,
  selectorCatalogsSearchFilters,
  selectorCatalogsSearchSortings,
  updateColumnsFilters,
  updateColumnsSorting,
} from '../../../../store/catalogsSearchSlice';
import { getColumnFiltersQuery } from '../../../../utils/getColumnFiltersQuery';
import { getColumnSortingsQuery } from '../../../../utils/getColumnSortingsQuery';
import { getPaginationQuery } from '../../../../utils/getPaginationQuery';
import classes from './CatalogDataColumn.module.css';
import CatalogDataTable from './CatalogDataTable/CatalogDataTable';
import SearchRow from './SearchRow/SearchRow';
import TagsRow from './TagsRow/TagsRow';
import { Button } from '@consta/uikit/Button';
import { IconHamburger } from '@consta/uikit/IconHamburger';
import { Text } from '@consta/uikit/Text';
import { ColumnDef } from '@tanstack/react-table';
import classNames from 'classnames';

type PropsType = {
  isShowCatalogList: boolean;
  showCatalogList: () => void;
};

export type TableColumnsType = ColumnDef<{ [key: string]: unknown }> & {
  title: string;
  isShow: boolean;
  accessorKey: string;
};

const CatalogDataColumn: React.FC<PropsType> = ({ isShowCatalogList, showCatalogList }) => {
  const dispatch = useAppDispatch();
  const catalogData = useAppSelector(selectorCatalogsSearchCatalogData);
  const selectedFilters = useAppSelector(selectorCatalogsSearchFilters);
  const selectedSortings = useAppSelector(selectorCatalogsSearchSortings);
  const catalogUuid = useAppSelector(selectorCatalogsSearchActiveCatalogUuid);

  const [columns, setColumns] = useState<TableColumnsType[]>([]);

  const onChangeSort = useCallback(
    (accessorKey: string, sortDirection: SortDirectionEnum) => {
      dispatch(updateColumnsSorting({ accessorKey, sortDirection }));
    },
    [dispatch]
  );

  const onChangeFilter = useCallback(
    (accessorKey: string, filtersItems: ColumnFilterItemType[]) => {
      dispatch(updateColumnsFilters({ accessorKey, filtersItems }));
    },
    [dispatch]
  );

  const onChangeShowColumn = (accessorKey: string) => {
    setColumns(
      columns.map((column) => (column.accessorKey === accessorKey ? { ...column, isShow: !column.isShow } : column))
    );
  };

  useEffect(() => {
    if (catalogUuid) {
      const currenPage = catalogData?.current_page?.toString();
      const pageSize = catalogData?.per_page?.toString();
      const paginationQuery = getPaginationQuery(currenPage ? currenPage : '1', pageSize ? pageSize : '25');
      const columnsFiltersQuery = getColumnFiltersQuery(selectedFilters);
      const columnsSortingsQuery = getColumnSortingsQuery(selectedSortings);
      const query = paginationQuery + columnsFiltersQuery + columnsSortingsQuery;
      dispatch(getCatalogDataByUuidThunk({ catalogUuid, query }));
    }
  }, [selectedFilters, selectedSortings, catalogData?.current_page, catalogData?.per_page, catalogUuid, dispatch]);

  const getFilterItems = useCallback(
    async (accessorKey: string): Promise<ColumnFilterItemType[]> => {
      if (catalogUuid) {
        const serverFilterItems = await serverApi.getFilterItems(catalogUuid, accessorKey);
        const filterItems = serverFilterItems.data.map(
          (serverFilterItem) => ({ uuid: serverFilterItem._uuid, value: serverFilterItem.name } as ColumnFilterItemType)
        );
        return new Promise((res) => res(filterItems));
      } else {
        return new Promise((res) => res([]));
      }
    },
    [catalogUuid]
  );

  useEffect(() => {
    if (catalogData?.meta.columns.length && catalogData.meta.columns.length > 0) {
      const tabColumns: TableColumnsType[] = catalogData.meta.columns.map((column) => {
        return createTableColumn({
          accessorKey: column.en_name || column.uuid,
          title: column.ru_name || '',
          minContentWidth: getTableHeaderSize(column.min_size, onChangeSort, onChangeFilter),
          maxContentWidth: getTableHeaderSize(column.max_size, onChangeSort, onChangeFilter),
          onChangeSort: column.is_sortable ? onChangeSort : undefined,
          onChangeFilter: column.is_filterable ? onChangeFilter : undefined,
          getFilterItems: column.is_filterable ? getFilterItems : undefined,
          selectedFilters: selectedFilters
            .filter((filter) => filter.filterName === column.en_name)
            .map((filter) => ({ uuid: filter.valueUuid, value: filter.value })),
        });
      });
      setColumns(tabColumns);
    }
  }, [catalogData, getFilterItems, onChangeFilter, selectedFilters, onChangeSort]);

  return (
    <div className={classNames(classes.container, { [classes.containerWithoutLeftPadding]: !isShowCatalogList })}>
      <div className={classes.row}>
        {!isShowCatalogList && (
          <Button
            size="xs"
            view="ghost"
            iconLeft={IconHamburger}
            onlyIcon
            onClick={showCatalogList}
            className={classes.buttonCatalogList}
          />
        )}
        <Text className={classes.title}>{`Каталог интегрированных в хранилища источников данных`}</Text>
      </div>
      <SearchRow columns={columns} onChangeShowColumn={onChangeShowColumn} />
      <TagsRow />
      <CatalogDataTable columns={columns} isShowCatalogList={isShowCatalogList} />
    </div>
  );
};

export default CatalogDataColumn;
