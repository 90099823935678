import React from 'react';

import classes from './NewEmployeeModalContent.module.css';
import StepSection from './StepSection/StepSection';
import { Button } from '@consta/uikit/Button';
import { Text } from '@consta/uikit/Text';

type PropsType = {
  closeModal: () => void;
};

const NewEmployeeModalContent: React.FC<PropsType> = ({ closeModal }) => {
  const onButtonStepOneClick = () => {};

  const onButtonStepTwoClick = () => {
    const url = `https://edu.gazprom-neft.local/knowledge_portal/nop/6916346654623687599`;
    window.open(url, '_blank', 'noreferrer');
  };

  const onButtonStepThreeClick = () => {};

  return (
    <div className={classes.container}>
      <Text className={classes.title} size="xl" weight={'bold'}>
        {`Новому сотруднику`}
      </Text>
      <Text className={classes.description} size="s" view={'secondary'}>
        {`Добро пожаловать на страницу методологии УПД.\nПройдите три шага, для эффективной работы\nс разделом и погружения в управление данными.`}
      </Text>
      <div className={classes.delimiter} />
      <div className={classes.sectionsGroup}>
        <StepSection
          stepTitle={'Шаг 1: Знакомство с политикой'}
          stepDescription={'Основополагающий документ\nУправления данными'}
          buttonLabel={'Смотреть документ'}
          onButtonClick={onButtonStepOneClick}
        />
        <StepSection
          stepTitle={'Шаг 2: Обучение'}
          stepDescription={'Пройдите курс «Введение\nв Управление данными»'}
          buttonLabel={'Пройти обучение'}
          onButtonClick={onButtonStepTwoClick}
        />
        <StepSection
          stepTitle={'Шаг 3: Процессы'}
          stepDescription={'Детальное ознакомление \nс процессами управления данными'}
          buttonLabel={'Изучить процессы'}
          onButtonClick={onButtonStepThreeClick}
        />
      </div>
      <div className={classes.delimiter} />
      <div className={classes.buttonContainer}>
        <Button label={'Начать работу'} view="ghost" onClick={closeModal} />
      </div>
    </div>
  );
};

export default NewEmployeeModalContent;
