import React from 'react';

import { VideoServerType } from '../../api/serverResponse';
import IconPlay from '../../img/learningPage/IconPlay.svg';
import classes from './VideoCard.module.css';
import { Text } from '@consta/uikit/Text';

type PropsType = {
  video: VideoServerType;
};

const VideoCard: React.FC<PropsType> = ({ video }) => {
  const onClick = () => {
    const url = require('../../img/video_file.mp4');
    window.open(url, '_blank', 'noreferrer');
  };

  return (
    <div className={classes.container}>
      <div className={classes.ico} onClick={onClick}>
        <img src={IconPlay} alt="play" />
      </div>
      <video width="auto" height="auto">
        <source src={require('../../img/video_file.mp4')} type="video/ogg" />
      </video>
      <Text className={classes.description}>{video.description}</Text>
    </div>
  );
};

export default VideoCard;
