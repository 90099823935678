import React from 'react';

import { BlogCaseType } from '../../../../api/blogServerResponse';
import classes from './BlogContentCaseMain.module.css';
import LeftColumn from './LeftColumn/LeftColumn';
import RightColumn from './RightColumn/RightColumn';

interface IBlogContentCaseMainProps {
  blogCase: BlogCaseType | undefined;
}

const BlogContentCaseMain: React.FC<IBlogContentCaseMainProps> = ({ blogCase }) => {
  return (
    <div className={classes.container}>
      <LeftColumn blogCase={blogCase} />
      <RightColumn />
    </div>
  );
};

export default BlogContentCaseMain;
