import React from 'react';

import LayoutWithoutFooterPage from '../../Components/LayoutWithoutFooterPage/LayoutWithoutFooterPage';
import ContactsBlock from './ContactsBlock/ContactsBlock';
import DataProviders from './DataProviders/DataProviders';
import DevelopingBlock from './DevelopingBlock/DevelopingBlock';
import LearnMoreBlock from './LearnMoreBlock/LearnMoreBlock';
import classes from './MainPage.module.css';
import MaintainingBlock from './MaintainingBlock/MaintainingBlock';
import TellAboutBanner from './TellAboutBanner/TellAboutBanner';
import VisitBlogBanner from './VisitBlogBanner/VisitBlogBanner';
import WelcomeBanner from './WelcomeBanner/WelcomeBanner';

const MainPage: React.FC = () => {
  return (
    <div className={classes.wrapper}>
      <LayoutWithoutFooterPage>
        <WelcomeBanner />
        <DataProviders />
        <VisitBlogBanner />
        <DevelopingBlock />
        <TellAboutBanner />
        <MaintainingBlock />
        <LearnMoreBlock />
        <ContactsBlock />
      </LayoutWithoutFooterPage>
    </div>
  );
};

export default MainPage;
