import React, { useState } from 'react';

import { TeamGroupType } from '../../../types/types';
import PersonalInfoCard from '../../PersonalInfoCard/PersonalInfoCard';
import classes from './ProductTeamList.module.css';
import { Button } from '@consta/uikit/Button';

type PropsType = {
  team?: TeamGroupType[];
};

const ProductTeamList: React.FC<PropsType> = ({ team }) => {
  const [collapsed, setCollapse] = useState(false);
  return (
    <div className={classes.container}>
      {team &&
        team.map((group, ind) => {
          return (
            <div key={ind} className={classes.teamGroupContainer}>
              <div className={classes.groupTitle}>{group.groupTitle}</div>
              <div className={classes.groupMembersContainer}>
                {group.groupMembers.map((member, ind) => (
                  <PersonalInfoCard
                    key={ind}
                    info={member}
                    teamCard
                    collapsed={(!collapsed && ind < 4) || collapsed || group.groupMembers?.length < 5}
                  />
                ))}

                {group.groupMembers && group.groupMembers.length > 4 && (
                  <Button
                    size="m"
                    view="ghost"
                    label={collapsed ? 'Скрыть' : 'Показать еще'}
                    form="default"
                    className={classes.button}
                    onClick={() => setCollapse(!collapsed)}
                  />
                )}
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default ProductTeamList;
