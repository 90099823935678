import React, { useRef } from 'react';
import Slider from 'react-slick';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { useAppSelector } from '../../../../hooks/hooks';
import { selectorLearningAnnouncements } from '../../../../store/learningSlice';
import AnnouncementCard from './AnnouncementCard/AnnouncementCard';
import classes from './AnnouncementsCarousel.module.css';
import { Button } from '@consta/uikit/Button';
import { IconArrowLeft } from '@consta/uikit/IconArrowLeft';
import { IconArrowRight } from '@consta/uikit/IconArrowRight';
import { Loader } from '@consta/uikit/Loader';

const AnnouncementsCarousel: React.FC = () => {
  const sliderRef = useRef<Slider>(null);
  const announcements = useAppSelector(selectorLearningAnnouncements);

  const next = () => {
    sliderRef.current?.slickNext();
  };
  const previous = () => {
    sliderRef.current?.slickPrev();
  };
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    autoplaySpeed: 3000,
    arrows: false,
  };

  return (
    <div className={classes.container}>
      <div className={classes.buttonWrapper}>
        <Button
          label="previous"
          onClick={previous}
          view="secondary"
          iconRight={IconArrowLeft}
          className={classes.prevBtn}
          onlyIcon
        />
      </div>
      <div className={classes.wrapper}>
        {announcements.length > 0 ? (
          <Slider ref={sliderRef} {...settings}>
            {announcements.map((announcement, ind) => (
              <AnnouncementCard key={ind} announcement={announcement} />
            ))}
          </Slider>
        ) : (
          <Loader />
        )}
      </div>
      <div className={classes.buttonWrapper}>
        <Button
          label="next"
          onClick={next}
          view="secondary"
          iconRight={IconArrowRight}
          className={classes.nextBtn}
          onlyIcon
        />
      </div>
    </div>
  );
};

export default AnnouncementsCarousel;
