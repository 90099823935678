import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { serverApi } from '../../api/serverApi';
import { CourseServerType } from '../../api/serverResponse';
import { PageRoutesEnum } from '../../appMenuAndRouting/appRoutes';
import LayoutOrdinaryPage from '../../Components/LayoutOrdinaryPage/LayoutOrdinaryPage';
import { useAppDispatch } from '../../hooks/hooks';
import { setIsError } from '../../store/learningSlice';
import classes from './CourseDetailsPage.module.css';
import CourseProgram from './CourseProgram/CourseProgram';
import { Avatar } from '@consta/uikit/Avatar';
import { Breadcrumbs } from '@consta/uikit/Breadcrumbs';
import { Button } from '@consta/uikit/Button';
import { IconAdd } from '@consta/uikit/IconAdd';
import { IconReply } from '@consta/uikit/IconReply';
import { Loader } from '@consta/uikit/Loader';
import { Text } from '@consta/uikit/Text';

const CourseDetailsPage: React.FC = () => {
  const breadcrumbs = [
    {
      label: 'Обучение',
      route: PageRoutesEnum.Learning,
    },
    {
      label: 'Теория',
      route: PageRoutesEnum.Learning,
    },
    {
      label: 'Курс',
    },
  ];

  const { uuid } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [course, setCourse] = useState<CourseServerType>();

  useEffect(() => {
    if (uuid) {
      serverApi
        .getCourseByUuid(uuid)
        .then((response) => {
          setCourse(response.data);
        })
        .catch((e) => {
          dispatch(setIsError(`Ошибка получения данных по курсу\n${(e as Error).message}`));
          navigate(PageRoutesEnum.Learning);
        });
    }
  }, [uuid, dispatch, navigate]);

  const onBreadcrumbsItemClick = (data: {
    item: { label: string; route?: PageRoutesEnum };
    e: React.MouseEvent<Element, MouseEvent>;
  }) => {
    if (data.item.route) {
      navigate(data.item.route);
    }
  };

  const email = 'Biyanov.SV@gazprom-neft.ru';

  if (!course) {
    return (
      <div className={classes.loaderContainer}>
        <Loader />
      </div>
    );
  }

  const onClickStudyCourse = () => {
    if (course.link) {
      window.open(course.link, '_blank', 'noreferrer');
    }
  };

  return (
    <LayoutOrdinaryPage>
      <Breadcrumbs items={breadcrumbs} className={classes.breadcrumbs} onItemClick={onBreadcrumbsItemClick} />
      <div className={classes.columnContainer}>
        <div className={classes.leftColumn}>
          <Text className={classes.title}>{course?.name}</Text>
          <Text className={classes.tags} view={'secondary'}>
            {course?.tags?.map((tag) => `${tag.name}; `)}
          </Text>
          <Text className={classes.subTitle}>{`Описание курса`}</Text>
          <Text className={classes.description}>{course?.description}</Text>
          <Text className={classes.subTitle}>{`Целевая аудитория`}</Text>
          <div className={classes.row}>
            {course.target_groups?.length
              ? course.target_groups.map((target_group) => (
                  <Text key={target_group._uuid} className={classes.rowItem} view={'secondary'}>
                    {`${target_group.name}; `}
                  </Text>
                ))
              : '-'}
          </div>
          <Text className={classes.subTitle}>{`Компетенции`}</Text>
          <div className={classes.row}>
            <Text className={classes.rowItem} view={'secondary'}>
              {`Тестировщик;`}
            </Text>
            <Text className={classes.rowItem} view={'secondary'}>
              {`Аналитик данных`}
            </Text>
          </div>
          <Text className={classes.subTitle}>{`Эксперты курса`}</Text>
          <div className={classes.teacherCard}>
            <Avatar className={classes.teacherAvatar} url={''} size={'l'} name={`Константинов К.К`} />
            <div className={classes.teacherCardRightColumn}>
              <Text className={classes.teacherName}>{`Константинов К.К.`}</Text>
              <Text className={classes.teacherPosition} view={'secondary'}>
                {`Руководитель практики`}
              </Text>
              <a href={`mailto:${email}`} target={'_blank'} className={classes.teacherEmail} rel="noreferrer">
                {email}
              </a>
            </div>
          </div>
          <Text className={classes.subTitle}>{`Продолжительность`}</Text>
          <div className={classes.row}>
            <Text className={classes.rowItem} view={'secondary'}>
              {course.duration ? course.duration : '-'}
            </Text>
          </div>
          <Text className={classes.subTitle}>{`Тип`}</Text>
          <div className={classes.row}>
            <Text className={classes.rowItem} view={'secondary'}>
              {course?.type}
            </Text>
          </div>
          <CourseProgram />
        </div>
        <div className={'classes.textContainer'}>
          <Text className={classes.rightColumnSubTitle} view={'secondary'}>
            {`Действия с курсом:`}
          </Text>
          <div className={classes.buttonRow}>
            <div>
              <Button label="Пройти курс" size={'s'} iconLeft={IconAdd} onClick={onClickStudyCourse} />
            </div>
            <div>
              <Button view="ghost" label="Записаться" size={'s'} iconLeft={IconReply} />
            </div>
          </div>
          <Text className={classes.rightColumnSubTitle} view={'secondary'}>
            {`Контакты:`}
          </Text>
          <div className={classes.contactEmailContainer}>
            <a href={`mailto:${email}`} target={'_blank'} className={classes.contactEmail} rel="noreferrer">
              {`Kon.KK@mail.com`}
            </a>
          </div>
          <div className={classes.contactEmailContainer}>
            <a href={`mailto:${email}`} target={'_blank'} className={classes.contactEmail} rel="noreferrer">
              {`Konst.KK@yandex.ru`}
            </a>
          </div>
        </div>
      </div>
    </LayoutOrdinaryPage>
  );
};

export default CourseDetailsPage;
