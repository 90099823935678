import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { serverApi } from '../../api/serverApi';
import { AnnouncementServerType } from '../../api/serverResponse';
import { PageRoutesEnum } from '../../appMenuAndRouting/appRoutes';
import LayoutOrdinaryPage from '../../Components/LayoutOrdinaryPage/LayoutOrdinaryPage';
import TeamMemberCard from '../../Components/TeamMemberCard/TeamMemberCard';
import { useAppDispatch } from '../../hooks/hooks';
import { setIsError } from '../../store/learningSlice';
import { formatDateToDDMMHHMM } from '../../utils/formatDateTimeUtils';
import classes from './AnnouncementDetailsPage.module.css';
import { Badge } from '@consta/uikit/Badge';
import { Breadcrumbs } from '@consta/uikit/Breadcrumbs';
import { Button } from '@consta/uikit/Button';
import { IconCopy } from '@consta/uikit/IconCopy';
import { IconMail } from '@consta/uikit/IconMail';
import { Loader } from '@consta/uikit/Loader';
import { Text } from '@consta/uikit/Text';

const AnnouncementDetailsPage: React.FC = () => {
  const breadcrumbs = [
    {
      label: 'Обучение',
      route: PageRoutesEnum.Learning,
    },
    {
      label: 'Анонс',
    },
  ];

  const { uuid } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [announcement, setAnnouncement] = useState<AnnouncementServerType>();

  const teachers = announcement?.instructors?.filter((instructor) => instructor.role !== 'responsible');
  const responsible = announcement?.instructors?.filter((instructor) => instructor.role === 'responsible');

  useEffect(() => {
    if (uuid) {
      serverApi
        .getAnnouncementByUuid(uuid)
        .then((response) => {
          setAnnouncement(response.data);
        })
        .catch((e) => {
          dispatch(setIsError(`Ошибка получения данных по мероприятию\n${(e as Error).message}`));
          navigate(PageRoutesEnum.Learning);
        });
    }
  }, [uuid, dispatch, navigate]);

  const onBreadcrumbsItemClick = (data: {
    item: { label: string; route?: PageRoutesEnum };
    e: React.MouseEvent<Element, MouseEvent>;
  }) => {
    if (data.item.route) {
      navigate(data.item.route);
    }
  };

  if (!announcement) {
    return (
      <div className={classes.loaderContainer}>
        <Loader />
      </div>
    );
  }

  return (
    <LayoutOrdinaryPage>
      <Breadcrumbs items={breadcrumbs} className={classes.breadcrumbs} onItemClick={onBreadcrumbsItemClick} />
      <div className={classes.columnContainer}>
        <div className={classes.leftColumn}>
          <div className={classes.badgesContainer}>
            {announcement.format && <Badge status="normal" view="stroked" size="s" label={announcement.format} />}
            {announcement.place_name && (
              <Badge status="success" view="stroked" size="s" label={announcement.place_name} />
            )}
          </div>
          <Text className={classes.title}>{announcement.name}</Text>
          <Text className={classes.tags} view={'secondary'}>
            {announcement.tags?.map((tag) => `${tag.name}; `)}
          </Text>
          <Text className={classes.subTitle}>{`Описание`}</Text>
          <Text className={classes.description}>{announcement.description}</Text>
          <Text className={classes.subTitle}>{`Коротко об анонсе`}</Text>
          <div className={classes.detailsGroup}>
            <div className={classes.row}>
              <Text className={classes.leftPartRow}>{`Дата мероприятия`}</Text>
              <Text className={classes.rightPartRow}>
                {announcement.start_date ? formatDateToDDMMHHMM(announcement.start_date) : 'Дата еще не назначена'}
                {announcement.finish_date ? (
                  <>
                    &nbsp;-&nbsp;
                    {formatDateToDDMMHHMM(announcement.finish_date)}
                  </>
                ) : (
                  ''
                )}
              </Text>
            </div>
            <div className={classes.row}>
              <Text className={classes.leftPartRow}>{`Расположение`}</Text>
              <Text className={classes.rightPartRow}>{announcement.place_name}</Text>
            </div>
            <div className={classes.row}>
              <Text className={classes.leftPartRow}>{`Провайдер`}</Text>
              <Text className={classes.rightPartRow}>{announcement.provider}</Text>
            </div>
          </div>

          <Text className={classes.subTitle}>{`Преподаватели`}</Text>
          <div className={classes.teachersList}>
            {teachers?.map((instructor, ind) => (
              <TeamMemberCard key={ind} person={instructor} />
            ))}
          </div>
        </div>
        <div className={classes.rightColumn}>
          <Text className={classes.rightColumnSubTitle} view={'secondary'}>
            {`Действия с курсом:`}
          </Text>
          <div className={classes.buttonRow}>
            <div>
              <Button label="Подать заявку" size={'m'} iconLeft={IconMail} />
            </div>
            <div>
              <Button view="ghost" label="Копировать ссылку" size={'m'} iconLeft={IconCopy} />
            </div>
          </div>
          <Text className={classes.rightColumnSubTitle} view={'secondary'}>
            {`Контакты:`}
          </Text>
          <div className={classes.responsibleList}>
            {responsible?.map((instructor, ind) => (
              <TeamMemberCard key={ind} person={instructor} />
            ))}
          </div>
        </div>
      </div>
    </LayoutOrdinaryPage>
  );
};

export default AnnouncementDetailsPage;
