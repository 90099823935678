import React, { useRef } from 'react';
import Slider from 'react-slick';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import VideoCard from '../../../../Components/VideoCard/VideoCard';
import { useAppSelector } from '../../../../hooks/hooks';
import { selectorLearningVideos } from '../../../../store/learningSlice';
import classes from './VideosCarousel.module.css';
import { Button } from '@consta/uikit/Button';
import { IconArrowLeft } from '@consta/uikit/IconArrowLeft';
import { IconArrowRight } from '@consta/uikit/IconArrowRight';
import { Loader } from '@consta/uikit/Loader';

const VideosCarousel: React.FC = () => {
  const sliderRef = useRef<Slider>(null);
  const videos = useAppSelector(selectorLearningVideos);

  const next = () => {
    sliderRef.current?.slickNext();
  };
  const previous = () => {
    sliderRef.current?.slickPrev();
  };
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    autoplaySpeed: 3000,
    arrows: false,
  };

  return (
    <div className={classes.container}>
      <div>
        <Button
          label="previous"
          onClick={previous}
          view="secondary"
          iconRight={IconArrowLeft}
          className={classes.prevBtn}
          onlyIcon
        />
      </div>
      <div className={classes.wrapper}>
        {videos.length > 0 ? (
          <Slider ref={sliderRef} {...settings}>
            {videos.map((video, ind) => (
              <VideoCard key={ind} video={video} />
            ))}
          </Slider>
        ) : (
          <Loader />
        )}
      </div>
      <div>
        <Button
          label="next"
          onClick={next}
          view="secondary"
          iconRight={IconArrowRight}
          className={classes.nextBtn}
          onlyIcon
        />
      </div>
    </div>
  );
};

export default VideosCarousel;
