import React from 'react';

import BlogShareBlock from '../../../../../Components/BlogShareBlock/BlogShareBlock';
import classes from './RightColumn.module.css';

const RightColumn: React.FC = () => {
  return (
    <div className={classes.container}>
      <BlogShareBlock title={`Знаете, кому будет интересен этот кейс? \nОтправьте ссылку на почту в один клик`} />
    </div>
  );
};

export default RightColumn;
