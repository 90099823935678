import React from 'react';

import { BlogAnnouncementType } from '../../../../api/blogServerResponse';
import classes from './BlogContentAnnounceMain.module.css';
import LeftColumn from './LeftColumn/LeftColumn';
import RightColumn from './RightColumn/RightColumn';

interface IBlogContentAnnounceMainProps {
  announce: BlogAnnouncementType | undefined;
}

const BlogContentAnnounceMain: React.FC<IBlogContentAnnounceMainProps> = ({ announce }) => {
  return (
    <div className={classes.container}>
      <LeftColumn announce={announce} />
      <RightColumn announce={announce} />
    </div>
  );
};

export default BlogContentAnnounceMain;
