import React from 'react';

import VideoCard from '../../../Components/VideoCard/VideoCard';
import { useAppSelector } from '../../../hooks/hooks';
import { selectorLearningVideos } from '../../../store/learningSlice';
import classes from './LearningAllVideosList.module.css';
import { Button } from '@consta/uikit/Button';

const LearningAllVideosList: React.FC = () => {
  const videos = useAppSelector(selectorLearningVideos);

  return (
    <div className={classes.container}>
      {videos.map((video, ind) => (
        <VideoCard key={ind} video={video} />
      ))}
      <div className={classes.buttonContainer}>
        <Button label="Показать еще" view="clear" className={classes.button} />
      </div>
    </div>
  );
};

export default LearningAllVideosList;
