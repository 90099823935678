import React, { useCallback, useEffect, useMemo } from 'react';
import { BrowserRouter } from 'react-router-dom';

import { appApi } from '../../api/appApi';
import { appHeaderMenuItems } from '../../appMenuAndRouting/appHeaderMenuItems';
import { appRedirectRoute, appRoutes, onLogoClickRoute, withoutFooterRoutes } from '../../appMenuAndRouting/appRoutes';
import { appSideBarMenuItems } from '../../appMenuAndRouting/appSideBarMenuItems';
import { useAppDispatch, useAppSelector } from '../../hooks/hooks';
import AppFooter from '../../shared-front/AppFooter/AppFooter';
import AppHeader from '../../shared-front/AppHeader/AppHeader';
import { PortalsEnum } from '../../shared-front/AppHeader/sharedSideBarMenuItems';
import AppRouter from '../../shared-front/AppRouter/AppRouter';
import ErrorBoundary from '../../shared-front/ErrorBoundary/ErrorBoundary';
import {
  deleteSnackBarItemReports,
  getUserInfoThunk,
  selectorIsAdminBlog,
  selectorIsAdminMetric,
  selectorIsLoadingUserInfo,
  selectorSnackBarItemsReports,
  selectorUserInfo,
} from '../../store/appSlice';
import { ManualMenuItem } from '../../types/types';
import { getMenuWithPermission } from '../../utils/menuPermission/getMenuWithPermission';
import AppModalError from '../AppModalError/AppModalError';
import classes from './App.module.css';
import { IconConfluence } from '@consta/icons/IconConfluence';
import { Loader } from '@consta/uikit/Loader';
import { presetGpnDefault, Theme } from '@consta/uikit/Theme';
import { Location } from 'history';
import { SnackBar } from '@consta/uikit/SnackBar';
import { deleteSnackBarItemTeam, selectorSnackBarItemsTeam } from '../../store/commandSlice';

const App: React.FC = () => {
  const dispatch = useAppDispatch();
  const userInfo = useAppSelector(selectorUserInfo);
  const isLoadingUserInfo = useAppSelector(selectorIsLoadingUserInfo);
  const isAdminBlog = useAppSelector(selectorIsAdminBlog);
  const isAdminMetric = useAppSelector(selectorIsAdminMetric);
  const snackBarItemsStoreReports = useAppSelector(selectorSnackBarItemsReports);
  const snackBarItemsStoreTeam = useAppSelector(selectorSnackBarItemsTeam);

  const snackBarItems = [
    ...snackBarItemsStoreReports?.map((snackBarItem) => ({
      ...snackBarItem,
      onClose: (item: { key: string }) => {
        dispatch(deleteSnackBarItemReports({ key: item.key }));
      },
    })),
    ...snackBarItemsStoreTeam?.map((snackBarItem) => ({
      ...snackBarItem,
      onClose: (item: { key: string }) => {
        dispatch(deleteSnackBarItemTeam({ key: item.key }));
      },
    })),
  ];

  const getUserInfo = useCallback(() => {
    dispatch(getUserInfoThunk());
  }, [dispatch]);

  useEffect(() => {
    getUserInfo();
  }, [getUserInfo]);

  const menuWithPermission = useMemo(() => {
    if (userInfo) {
      return getMenuWithPermission(appHeaderMenuItems, isAdminBlog, isAdminMetric);
    }
    return [];
  }, [userInfo]);

  const onChangeLocation = useCallback(async (location: Location) => {
    await appApi.log('view', location.pathname + location.search);
  }, []);

  const confluenceIcon = () => {
    return <IconConfluence />;
  };

  const manualMenuItems: ManualMenuItem[] = [
    {
      label: 'Инструкция по работе с порталом УпД',
      value: 'https://kb.gazprom-neft.local/display/IPKD',
      icon: confluenceIcon,
      // status: 'link',
    },
  ];

  return (
    <ErrorBoundary>
      <Theme preset={presetGpnDefault}>
        <BrowserRouter>
          <div className={classes.container}>
            {!isLoadingUserInfo && userInfo ? (
              <>
                <AppHeader
                  portal={PortalsEnum.DataOffice}
                  appHeaderMenuItems={menuWithPermission}
                  appSideBarMenuItems={appSideBarMenuItems}
                  onLogoClickRoute={onLogoClickRoute}
                  authMode={
                    !!process.env.REACT_APP_IS_EXTERNAL_DOMAIN && process.env.REACT_APP_IS_EXTERNAL_DOMAIN !== 'false'
                  }
                  manuals={manualMenuItems}
                  //manualsTooltip={'Инструкция по использованию портала'}
                />
                <div className={classes.pageWrapper}>
                  <AppRouter
                    appRoutes={appRoutes}
                    appRedirectRoute={appRedirectRoute}
                    onChangeLocation={onChangeLocation}
                  />
                </div>
                <AppFooter
                  // footerLogo={<img src={footerLogoDataOffice} alt="footerLogoDataOffice" />}
                  // portalName={'Дата-Платформе'}
                  // questionEmail={'data-platform@gazprom-neft.ru'}
                  onLogoClickRoute={onLogoClickRoute}
                  withoutFooterRoutes={withoutFooterRoutes}
                  version={'1.0.2706'}
                />
                <div className={classes.snackBarContainer} style={{ zIndex: 1000 }}>
                  <SnackBar items={snackBarItems ? snackBarItems : []} style={{ whiteSpace: 'pre-line' }} />
                </div>

                <AppModalError />
              </>
            ) : (
              <div className={classes.loader}>
                <Loader />
              </div>
            )}
          </div>
        </BrowserRouter>
      </Theme>
    </ErrorBoundary>
  );
};

export default App;
