import React from 'react';
import { useNavigate } from 'react-router-dom';

import { MetricType, ProductInfoType } from '../../types/types';
import { scrollToTop } from '../../utils/functions';
import PerformanceItem from './PerformanceItem/PerformanceItem';
import classes from './ProductInfoCard.module.css';
import { Button } from '@consta/uikit/Button';

type PropsType = {
  ind: number;
  info: ProductInfoType;
  showRedirectButton?: boolean;
  metric: MetricType;
};

const ProductInfoCard: React.FC<PropsType> = ({ ind, info, showRedirectButton, metric }) => {
  const productIcon = require(`../../img/dataPlatformPage/icons/${info.icon}`);
  const navigate = useNavigate();
  const onClick = (url: string) => {
    if (url) {
      scrollToTop();
      navigate(url);
    }
  };
  return (
    <div className={classes.container}>
      <div className={classes.firstRow}>
        <img src={productIcon} alt={''} className={classes.icon} />
        <div className={classes.column}>
          <div className={classes.nameTitle}>{metric.name}</div>
          {/*<Text className={classes.ownerTitle} size="m" truncate>*/}
          {/*  {info.owner}*/}
          {/*</Text>*/}
        </div>
      </div>
      <div className={classes.performanceRow}>
        <PerformanceItem info={metric.metrics[0]} detailsURL={info.detailsURL} />
        {metric.metrics?.[1] && <PerformanceItem info={metric.metrics?.[1]} />}
      </div>
      <div className={classes.footer}>
        <Button label="Подробнее" size="s" view="primary" form="round" onClick={() => onClick(info.detailsURL)} />
        <div className={classes.ownerTitle}> {metric.owner}</div>
      </div>
    </div>
  );
};

export default ProductInfoCard;
