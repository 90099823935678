const getMonthNameInGenitiveCase = (date: Date) =>
  [
    'января',
    'февраля',
    'марта',
    'апреля',
    'мая',
    'июня',
    'июля',
    'августа',
    'сентября',
    'октября',
    'ноября',
    'декабря',
  ][date.getMonth()];

export const formatDateToDDMMYYYY = (strDate: string | null | undefined) => {
  if (!strDate) {
    return '';
  }
  const date = new Date(strDate);
  return date.toLocaleDateString('en-GB').replaceAll('/', '.');
};

export const formatDateToDDMMHHMM = (strDate: string | null | undefined) => {
  if (!strDate) {
    return '';
  }
  const date = new Date(strDate);
  const day = new Intl.DateTimeFormat('en', { year: '2-digit' }).format(date);
  const month = getMonthNameInGenitiveCase(date);
  const hours = new Intl.DateTimeFormat('en', { hour: '2-digit', hour12: false }).format(date);
  let minutes = new Intl.DateTimeFormat('en', { minute: '2-digit' }).format(date);
  minutes = minutes.length > 1 ? minutes : '0' + minutes;
  return `${day} ${month} ${hours}:${minutes}`;
};
