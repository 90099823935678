import React, { useEffect } from 'react';

import ModalError from '../../../Components/ModalError/ModalError';
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks';
import {
  getAdminBlogItemsThunk,
  getAdminBlogSubscriptionThunk,
  selectorErrorAdminBlogPage,
  selectorViewSelectorActiveItem,
  selectorViewSelectorBlogItems,
} from '../../../store/adminBlogSlice';
import classes from './AdminBlogPage.module.css';
import AdminBlogPageContent from './AdminBlogPageContent/AdminBlogPageContent';
import AdminBlogPageHeader from './AdminBlogPageHeader/AdminBlogPageHeader';
import AdminBlogPagePaginationRow from './AdminBlogPagePaginationRow/AdminBlogPagePaginationRow';

interface IAdminBlogPageProps {}

const AdminBlogPage: React.FC<IAdminBlogPageProps> = () => {
  const dispatch = useAppDispatch();
  const errorAdminBlogPage = useAppSelector(selectorErrorAdminBlogPage);
  const view = useAppSelector(selectorViewSelectorActiveItem);

  useEffect(() => {
    if (view?.name === 'Опубликованные материалы') {
      dispatch(getAdminBlogItemsThunk(`?sort=-publication_date&page[size]=25&page[number]=1&filter[in_archive]=false`));
    } else if (view?.name === 'Управление рассылкой') {
      dispatch(getAdminBlogSubscriptionThunk(`?sort=-fio&page[size]=25&page[number]=1`));
    } else {
      dispatch(getAdminBlogSubscriptionThunk(`?sort=-fio&page[size]=25&page[number]=1`));
    }
  }, [dispatch, view]);

  return (
    <div className={classes.container}>
      <AdminBlogPageHeader />
      <AdminBlogPageContent />
      <AdminBlogPagePaginationRow />
      <ModalError errorMessage={errorAdminBlogPage} />
    </div>
  );
};

export default AdminBlogPage;
