import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { PageRoutesEnum } from '../../../appMenuAndRouting/appRoutes';
import teamIco from '../../../img/mainPage/teamIco.svg';
import classes from './ContactsBlock.module.css';
import { Button } from '@consta/uikit/Button';
import { IconForward } from '@consta/uikit/IconForward';
import { Text } from '@consta/uikit/Text';
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks';
import { selectorIsAdminContacts } from '../../../store/appSlice';
import { IconEdit } from '@consta/uikit/IconEdit';
import { Modal } from '@consta/uikit/Modal';
import ContactsForm from '../../../Components/ContactsForm/ContactsForm';
import { getContactsByContentBlockSlug, selectorContactsByContentBlockSlug } from '../../../store/contactsSlice';
import { Avatar } from '@consta/uikit/Avatar';

const ContactsBlock: React.FC = () => {
  const navigate = useNavigate();
  const isAdminContacts = useAppSelector(selectorIsAdminContacts);
  const [isModalShow, setIsModalShow] = useState<boolean>(false);
  const onClick = () => {
    navigate(PageRoutesEnum.Team);
  };

  /*const onWriteToEmail = () => {
    window.open('mailto:Maslov.AAle@gazprom-neft.ru?subject=Вопрос по Data Office', '_blank', 'noreferrer');
  };*/
  const dispatch = useAppDispatch();
  const users = useAppSelector(selectorContactsByContentBlockSlug);
  const getUsers = useCallback(() => {
    dispatch(getContactsByContentBlockSlug('main_page_contacts'));
  }, [dispatch]);

  useEffect(() => {
    getUsers();
  }, [getUsers]);

  return (
    <div className={classes.container}>
      <Text className={classes.title}>
        {`Контакты`}
        {isAdminContacts && (
          <IconEdit size={'m'} view={'primary'} className={classes.iconEdit} onClick={() => setIsModalShow(true)} />
        )}
      </Text>
      <Modal
        isOpen={isModalShow}
        className={classes.contactsModal}
        hasOverlay
        onClickOutside={() => setIsModalShow(false)}
        onEsc={() => setIsModalShow(false)}
      >
        <ContactsForm
          contacts={users}
          close={() => setIsModalShow(false)}
          afterDispatch={() => dispatch(getContactsByContentBlockSlug('main_page_contacts'))}
        />
      </Modal>
      <div className={classes.cardsContainer}>
        {users &&
          users.contacts &&
          users.contacts.length &&
          users.contacts.map((user: any, ind) => (
            <div className={classes.personCard} key={ind}>
              <Avatar
                className={classes.avatar}
                url={user.person?.icon ? 'data:image/png;base64,' + user.person?.icon : ''}
                name={user.person?.last_name + ' ' + user.person?.first_name + ' ' + user.person?.middle_name}
              />
              <Text className={classes.name}>
                {user.person?.last_name + ' ' + user.person?.first_name + ' ' + (user.person?.middle_name || '')}
              </Text>
              <Text className={classes.position}>{user.position}</Text>
              <Text
                className={classes.email}
                onClick={() => window.open(`mailto:${user.person?.email}`, '_blank', 'noreferrer')}
              >
                {user.person?.email}
              </Text>
            </div>
          ))}
        <div className={classes.teamCard}>
          <img src={teamIco} alt="teamIco" className={classes.img} />
          <Text className={classes.name}>{`Команда Data Office `}</Text>
          <Text
            className={classes.position}
          >{`Подскажем, к кому вы можете обратиться\nв любой момент по вопросам работы с данными`}</Text>
          <div>
            <Button
              label={'Перейти'}
              view={'clear'}
              iconRight={IconForward}
              form="round"
              size={'m'}
              className={classes.btn}
              onClick={onClick}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactsBlock;
