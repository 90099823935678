import { ColumnFilterType } from '../types/types';

export const getColumnFiltersQuery = (selectedFilters: ColumnFilterType[]) => {
  let query = '';
  if (selectedFilters.length > 0) {
    const uniqueFilterName = selectedFilters
      .map((filter) => filter.filterName)
      .filter((value, index, array) => array.indexOf(value) === index)
      .sort();
    uniqueFilterName.forEach((filterName) => {
      const filterNameValueUuids = selectedFilters
        .filter((filterItem) => filterItem.filterName === filterName)
        .map((filterItem) => filterItem.valueUuid)
        .sort();
      query = `${query}&filter[${filterName}]=${filterNameValueUuids.join('|')}`;
    });
  }
  return query;
};
