import React from 'react';

import LeftColumn from './LeftColumn/LeftColumn';
import classes from './MainPart.module.css';
import RightColumn from './RightColumn/RightColumn';

const MainPart: React.FC = () => {
  return (
    <div className={classes.container}>
      <LeftColumn />
      <RightColumn />
    </div>
  );
};

export default MainPart;
