import React from 'react';

import { ProductComponentType } from '../../types/types';
import classes from './ProductComponentCard.module.css';
import ProductInstrumentsList from './ProductInstrumentsList/ProductInstrumentsList';

type PropsType = {
  component: ProductComponentType;
};

const ProductComponentCard: React.FC<PropsType> = ({ component }) => {
  return (
    <div className={classes.tr}>
      <div className={classes.trTitle}>
        <div className={classes.title}>{component.title}</div>
        <div className={classes.description}>{component.description}</div>
      </div>
      <div className={classes.trCurrent}>
        {component.instrumentsAsIs && component.instrumentsAsIs.length > 0 && (
          <div className={classes.instrumentsContainer}>
            <ProductInstrumentsList instruments={component.instrumentsAsIs} />
          </div>
        )}
      </div>
      <div className={classes.trFuture}>
        {component.instrumentsToBe && component.instrumentsToBe.length > 0 && (
          <>
            <div className={classes.instrumentsContainer}>
              <ProductInstrumentsList instruments={component.instrumentsToBe} />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ProductComponentCard;
