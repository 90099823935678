import React, { ReactNode } from 'react';

import classes from './TableWrapper.module.css';

interface ITableWrapperProps {
  children: ReactNode;
  height?: string;
}

const TableWrapper: React.FC<ITableWrapperProps> = ({ children, height }) => {
  return (
    <div className={classes.container}>
      <div className={classes.tableContainer} style={{ height: height ? height : undefined }}>
        <div className={classes.tableWrapper}>{children}</div>
      </div>
    </div>
  );
};

export default TableWrapper;
