import React from 'react';
import { useNavigate } from 'react-router-dom';

import { PageRoutesEnum } from '../../../appMenuAndRouting/appRoutes';
import coursesPicture from '../../../img/mainPage/coursesPicture.png';
import methodologyPicture from '../../../img/mainPage/methodologyPicture.png';
import classes from './DevelopingBlock.module.css';
import { Button } from '@consta/uikit/Button';
import { IconForward } from '@consta/uikit/IconForward';
import { Text } from '@consta/uikit/Text';

const DevelopingBlock: React.FC = () => {
  const navigate = useNavigate();

  const onClickMethodology = () => {
    navigate(PageRoutesEnum.Methodology);
  };

  const onClickLearning = () => {
    navigate(PageRoutesEnum.Learning);
  };

  return (
    <div className={classes.container}>
      <Text className={classes.title}>{`Развиваем культуру работы с данными`}</Text>
      <Text
        className={classes.description}
      >{`Унифицируем принципы работы с данными,\nавтоматизируем их в Дата-платформе, проводим\nобучения и осуществляем консультации по вопросам`}</Text>
      <div className={classes.cardsContainer}>
        <div className={classes.card}>
          <div className={classes.cardTop}>
            <Text className={classes.cardTitle}>{`Методология`}</Text>
            <Text className={classes.cardDescription}>{`Познакомим вас с теоретической основой работы с данными`}</Text>
            <div>
              {/*<Button*/}
              {/*  label={'Перейти'}*/}
              {/*  view={'clear'}*/}
              {/*  iconRight={IconForward}*/}
              {/*  size={'m'}*/}
              {/*  className={classes.cardBtn}*/}
              {/*  onClick={onClickMethodology}*/}
              {/*/>*/}
            </div>
          </div>
          <div className={classes.cardBottom}>
            <img src={methodologyPicture} alt="methodologyPicture" />
            <div className={classes.bottomRightColumn}>
              <Text className={classes.amountTitle}>{`8`}</Text>
              <Text className={classes.amountDescription}>{`Процессов описаны`}</Text>
            </div>
          </div>
        </div>
        <div className={classes.card}>
          <div className={classes.cardTop}>
            <Text className={classes.cardTitle}>{`Обучение`}</Text>
            <Text
              className={classes.cardDescription}
            >{`Даём возможность освоить новые навыки и применить\nих на практике`}</Text>
            <div>
              {/*<Button*/}
              {/*  label={'Перейти'}*/}
              {/*  view={'clear'}*/}
              {/*  iconRight={IconForward}*/}
              {/*  size={'m'}*/}
              {/*  className={classes.cardBtn}*/}
              {/*  onClick={onClickLearning}*/}
              {/*/>*/}
            </div>
          </div>
          <div className={classes.cardBottom}>
            <img src={coursesPicture} alt="coursesPicture" className={classes.img} />
            <div className={classes.bottomRightColumn}>
              <Text className={classes.amountTitle}>{`45`}</Text>
              <Text className={classes.amountDescription}>{`Доступных курсов`}</Text>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DevelopingBlock;
