import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { PageRoutesEnum } from '../../../appMenuAndRouting/appRoutes';
import CoursesCarousel from './CoursesCarousel/CoursesCarousel';
import classes from './LearningCourses.module.css';
import { Button } from '@consta/uikit/Button';
import { ChoiceGroup } from '@consta/uikit/ChoiceGroup';
import { Tag } from '@consta/uikit/Tag';
import { Text } from '@consta/uikit/Text';

const items: string[] = ['Теоретические знания', 'Лучшие практики', 'Практика'];

const LearningCourses = () => {
  const navigate = useNavigate();
  const [value, setValue] = useState<string | null>(items[0]);

  const onViewAllCourses = () => {
    navigate(`${PageRoutesEnum.Learning_Courses}`);
  };

  return (
    <div className={classes.container}>
      <div className={classes.choiceGroupWrapper}>
        <ChoiceGroup
          value={value}
          onChange={({ value }) => setValue(value)}
          items={items}
          getItemLabel={(item) => item}
          multiple={false}
          name="ChoiceGroupCommand"
        />
      </div>
      <div className={classes.delimiterWrapper}>
        <div className={classes.delimiter} />
      </div>
      <Text className={classes.title}>{`Информации об обучении`}</Text>
      <div className={classes.filtersRow}>
        <Tag mode="button" onClick={() => {}} label="coursesSections" className={classes.allTag} size={'s'} />
        <div className={classes.tagGroup}>
          <Tag mode="check" onChange={() => {}} label="Програмирование" size={'s'} checked={false} />
          <Tag mode="check" onChange={() => {}} label="Аналитика" size={'s'} checked={false} />
          <Tag mode="check" onChange={() => {}} label="DevOps" size={'s'} checked={false} />
          <Tag mode="check" onChange={() => {}} label="Product" size={'s'} checked={false} />
          <Tag mode="check" onChange={() => {}} label="Front" size={'s'} checked={false} />
          <Tag mode="check" onChange={() => {}} label="Копирайтинг" size={'s'} checked={false} />
          <Tag mode="check" onChange={() => {}} label="UX редактура" size={'s'} checked={false} />
        </div>
      </div>
      <CoursesCarousel />
      <div className={classes.btnSeeAll}>
        <Button view="secondary" label="Смотреть все курсы" size={'s'} onClick={onViewAllCourses} />
      </div>
    </div>
  );
};

export default LearningCourses;
