import React from 'react';

import iconOpenInNew from '../../../img/iconOpenInNew.svg';
import { ProductInstrumentType } from '../../../types/types';
import { openURLinNewTab } from '../../../utils/openURLinNewTab';
import classes from './ProductInstrumentsList.module.css';

type PropsType = {
  instruments: ProductInstrumentType[];
};

const ProductInstrumentsList: React.FC<PropsType> = ({ instruments }) => {
  const onClick = (url: string) => {
    openURLinNewTab(url);
  };

  return (
    <div className={classes.container}>
      {instruments.map((instrument, ind) => {
        return instrument.url ? (
          <div
            key={ind}
            className={classes.url}
            onClick={() => {
              onClick(instrument.url);
            }}
          >
            <div className={classes.title}>
              {instrument.title}
              <img src={iconOpenInNew} className={classes.ico} alt="open in new tab" />
            </div>
          </div>
        ) : (
          <div key={ind} className={classes.title}>
            {instrument.title}
          </div>
        );
      })}
    </div>
  );
};

export default ProductInstrumentsList;
