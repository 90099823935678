import React from 'react';

import classes from './CoolTextButton.module.css';

interface ICoolTextButtonProps {
  title: string;
  onClick?: () => void;
  disabled?: boolean;
}

const CoolTextButton: React.FC<ICoolTextButtonProps> = ({ title, onClick, disabled }) => {
  return (
    <button
      className={classes.container}
      onClick={() => {
        onClick && onClick();
      }}
      disabled={disabled}
    >
      {title}
    </button>
  );
};

export default CoolTextButton;
