import React from 'react';

import LayoutOrdinaryPage from '../../Components/LayoutOrdinaryPage/LayoutOrdinaryPage';
import humanImage from '../../img/placeHolderPage/humanImage.svg';
import classes from './PlaceHolderPage.module.css';
import { Text } from '@consta/uikit/Text';

type PropsType = {
  title: string;
};

const PlaceHolderPage: React.FC<PropsType> = ({ title }) => {
  return (
    <LayoutOrdinaryPage>
      <div className={classes.container}>
        <div className={classes.row}>
          <Text
            size={'2xl'}
            weight={'bold'}
            view="link"
            style={{ marginTop: '64px' }}
          >{`Скоро здесь будет страница`}</Text>
        </div>
        <div className={classes.row}>
          <Text size={'2xl'} weight={'bold'} view="link" style={{ marginBottom: '64px' }}>{`" ${title} "`}</Text>
        </div>
        <div className={classes.row}>
          <img src={humanImage} className={classes.humanImage} alt="human" />
        </div>
        <div className={classes.row}>
          <Text
            size={'m'}
            weight={'bold'}
            view="secondary"
            style={{ marginTop: '64px', marginLeft: '48px' }}
          >{`© Дата-платформа 2023`}</Text>
        </div>
      </div>
    </LayoutOrdinaryPage>
  );
};

export default PlaceHolderPage;
