import React, { useEffect } from 'react';

import { useAppDispatch, useAppSelector } from '../../../../../hooks/hooks';
import Pagination from '../../../../../shared-front/Pagination/Pagination';
import { Table } from '../../../../../shared-front/Table/Table';
import { getShowColumns } from '../../../../../shared-front/Table/utils/getShowColumns';
import {
  getCatalogDataByUuidThunk,
  selectorCatalogsSearchActiveCatalogUuid,
  selectorCatalogsSearchCatalogData,
  selectorCatalogsSearchFilters,
  selectorCatalogsSearchIsLoading,
  selectorCatalogsSearchSortings,
} from '../../../../../store/catalogsSearchSlice';
import { combineQuery } from '../../../../../utils/combineQuery';
import { getColumnFiltersQuery } from '../../../../../utils/getColumnFiltersQuery';
import { getColumnSortingsQuery } from '../../../../../utils/getColumnSortingsQuery';
import { TableColumnsType } from '../CatalogDataColumn';
import classes from './CatalogDataTable.module.css';
import { Loader } from '@consta/uikit/Loader';
import classNames from 'classnames';

type PropsType = {
  columns: TableColumnsType[];
  isShowCatalogList: boolean;
};

const CatalogDataTable: React.FC<PropsType> = ({ columns, isShowCatalogList }) => {
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(selectorCatalogsSearchIsLoading);
  const catalogData = useAppSelector(selectorCatalogsSearchCatalogData);
  const catalogUuid = useAppSelector(selectorCatalogsSearchActiveCatalogUuid);
  const selectedFilters = useAppSelector(selectorCatalogsSearchFilters);
  const selectedSortings = useAppSelector(selectorCatalogsSearchSortings);

  const onChangePagination = (paginationQuery: string) => {
    if (catalogUuid) {
      const columnsFiltersQuery = getColumnFiltersQuery(selectedFilters);
      const columnsSortingsQuery = getColumnSortingsQuery(selectedSortings);
      const query = combineQuery(paginationQuery + columnsFiltersQuery + columnsSortingsQuery);
      dispatch(getCatalogDataByUuidThunk({ catalogUuid, query }));
    }
  };

  useEffect(() => {
    if (catalogUuid) {
      dispatch(getCatalogDataByUuidThunk({ catalogUuid, query: '?page[number]=1&page[size]=25' }));
    }
  }, [catalogUuid, dispatch]);

  if (isLoading) {
    return (
      <div
        className={classNames(classes.container, classes.loaderContainer, {
          [classes.wildContainer]: !isShowCatalogList,
        })}
      >
        <Loader />
      </div>
    );
  }

  return (
    <div className={classNames(classes.container, { [classes.wildContainer]: !isShowCatalogList })}>
      <div className={classes.tableContainer}>
        <Table data={catalogData?.data ? catalogData?.data : []} columns={getShowColumns(columns)} />
        {catalogData?.data.length && !(catalogData.data.length > 0) && (
          <div className={classes.noData}>{`Нет данных по выбранным фильтрам`}</div>
        )}
      </div>
      <Pagination
        currentPageSize={catalogData?.per_page}
        currentPage={catalogData?.current_page}
        lastPage={catalogData?.last_page}
        total={catalogData?.total}
        className={classes.pagination}
        onChangePagination={onChangePagination}
      />
    </div>
  );
};

export default CatalogDataTable;
