import React, { useState } from 'react';

import { TableColumnType } from '../Table/Table';
import ColumnsTuneList from './ColumnsTuneList/ColumnsTuneList';
import classes from './TableColumnsTune.module.css';
import { Button } from '@consta/uikit/Button';
import { IconClose } from '@consta/uikit/IconClose';
import { IconSearch } from '@consta/uikit/IconSearch';
import { Text } from '@consta/uikit/Text';
import { TextField } from '@consta/uikit/TextField';

interface ITableColumnsTuneProps {
  columns: (object & TableColumnType)[];
  onChangeShowColumn: (val: string) => void;
  closeSidebarClick: () => void;
}

const TableColumnsTune: React.FC<ITableColumnsTuneProps> = ({ columns, onChangeShowColumn, closeSidebarClick }) => {
  const [searchTitleValue, setSearchTitleValue] = useState<string | null>(null);
  const handleChange = ({ value }: { value: string | null }) => setSearchTitleValue(value);

  return (
    <div className={classes.container}>
      <div className={classes.row}>
        <Text className={classes.title}>{`Настройка столбцов`}</Text>
        <Button size="xs" view="ghost" iconLeft={IconClose} onlyIcon onClick={closeSidebarClick} />
      </div>
      <div className={classes.delimiter} />
      <div className={classes.textField}>
        <TextField
          value={searchTitleValue}
          onChange={handleChange}
          style={{ flexGrow: '1' }}
          leftSide={IconSearch}
          placeholder="Введите название свойства..."
          size={'s'}
        />
      </div>
      <ColumnsTuneList columns={columns} onChangeShowColumn={onChangeShowColumn} searchTitleValue={searchTitleValue} />
    </div>
  );
};

export default TableColumnsTune;
