import React from 'react';

import TooltipForShortString from '../../../../../../Components/TooltipForShortString/TooltipForShortString';
import { useAppDispatch, useAppSelector } from '../../../../../../hooks/hooks';
import leaderBadge from '../../../../../../img/teamPage/leaderBadge.svg';
import { selectorCurrentGroups, setCurrentUnit } from '../../../../../../store/commandSlice';
import { selectorSearchFIOTeamSlice } from '../../../../../../store/teamSlice';
import { DomainOrProgramType } from '../../../../../../types/types';
import { getAvatarUrl } from '../../../../../../utils/functions';
import { openURLinNewTab } from '../../../../../../utils/openURLinNewTab';
import classes from './EmbeddingCard.module.css';
import { Avatar } from '@consta/uikit/Avatar';
import { IconForward } from '@consta/uikit/IconForward';
import classNames from 'classnames';
import { selectorIsAdminTeam } from '../../../../../../store/appSlice';

type PropsType = {
  domainOrProgram: DomainOrProgramType;
  domainName: string;
  domainUuid: string;
  type: string;
  openSideBar: () => void;
  groupDomainUUid: string;
};

const EmbeddingCard: React.FC<PropsType> = ({
  domainOrProgram,
  domainUuid,
  domainName,
  type,
  openSideBar,
  groupDomainUUid,
}) => {
  // console.log(domainUuid, groupDomainUUid);
  const dispatch = useAppDispatch();
  const titleWidth = domainOrProgram.persons.length * 249;
  const searchFIO = useAppSelector(selectorSearchFIOTeamSlice);
  const groupsByDomain: any[] = useAppSelector(selectorCurrentGroups);
  const personNames = domainOrProgram.persons.map((person) => person.domainOrProgramChiefName);
  const includesSearchFIO = personNames.filter(
    (name) => searchFIO && name.toLowerCase().includes(searchFIO.toLowerCase())
  );

  const isIncludesDomainOrProgramTitle =
    searchFIO && domainOrProgram.domainOrProgramTitle.toLowerCase().includes(searchFIO.toLowerCase());

  const onClickEmail = (email: string) => {
    window.open(`mailto:${email}?subject=Вопрос о Дата-Платформе`, '_blank', 'noreferrer');
  };

  const onClickConfluence = (uri: string) => {
    openURLinNewTab(uri);
  };
  const isAdminTeam = useAppSelector(selectorIsAdminTeam);
  const onDbClick = () => {
    if (!isAdminTeam) {
      return;
    }

    openSideBar();
    if (type === 'units' || type === 'programs') {
      dispatch(
        setCurrentUnit({
          domain_uuid: domainUuid,
          domain_name: domainName,
          users: domainOrProgram.persons.map((el, key) => {
            return {
              label: el.domainOrProgramChiefName,
              id: el._uuid,
              ord: key,
            };
          }),
          confluence: domainOrProgram.domainOrProgramConfluenceUri,
          isHeadValue: {
            name: domainOrProgram.is_head ? 'Да' : 'Нет',
          },
          unitName: domainOrProgram.domainOrProgramTitle,
          unitUuid: domainOrProgram._uuid,
        })
      );
    } else {
      let groups = [];

      const tmp = groupsByDomain.find((el: any) => el.uuid_block === groupDomainUUid).team_groups;

      if (tmp) {
        groups = tmp.map((el: any) => {
          return {
            id: el._uuid,
            label: el.name,
          };
        });
      }
      dispatch(
        setCurrentUnit({
          domain_uuid: groupDomainUUid,
          domain_name: domainName,
          users: domainOrProgram.persons.map((el, key) => {
            return {
              label: el.domainOrProgramChiefName,
              id: el._uuid,
              ord: key,
            };
          }),
          confluence: domainOrProgram.domainOrProgramConfluenceUri,
          isHeadValue: {
            name: domainOrProgram.is_head ? 'Да' : 'Нет',
          },
          unitName: domainOrProgram.domainOrProgramTitle,
          unitUuid: domainOrProgram._uuid,
          groups: groups,
          group: { label: domainOrProgram?.domainOrProgramSectionTitle, id: domainOrProgram?.groupUuid },
        })
      );
    }
  };

  return (
    <div
      onDoubleClick={onDbClick}
      className={classNames(classes.container, {
        [classes.active]: includesSearchFIO.length > 0 || isIncludesDomainOrProgramTitle,
      })}
    >
      {domainOrProgram?.is_head && <img src={leaderBadge} className={classes.leaderBadge} alt="leader badge" />}
      <TooltipForShortString tooltipText={domainOrProgram.domainOrProgramTitle} tooltipTextMinLimit={40}>
        <div className={classes.domainTitle} style={{ width: `${titleWidth}px` }}>
          {domainOrProgram.domainOrProgramTitle}
        </div>
      </TooltipForShortString>
      <div className={classes.personsList}>
        {domainOrProgram.persons.map((person, ind) => {
          const avatarURL = person.icon ? 'data:image/png;base64,' + person.icon : '';
          return (
            <div key={ind} className={classes.row}>
              <Avatar className={classes.avatar} url={avatarURL} name={person.domainOrProgramChiefName} />
              <div className={classes.rightColumn}>
                <div className={classes.domainChiefName}>{person.domainOrProgramChiefName}</div>
                {person.domainOrProgramChiefEmail && (
                  <div
                    className={classes.domainChiefEmail}
                    onClick={() => onClickEmail(person.domainOrProgramChiefName)}
                  >
                    {person.domainOrProgramChiefEmail}
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
      {domainOrProgram.domainOrProgramConfluenceUri && (
        <div
          className={classes.confluenceUri}
          onClick={() => onClickConfluence(domainOrProgram.domainOrProgramConfluenceUri!)}
        >
          {'Confluence'} <IconForward size={'xs'} />
        </div>
      )}
    </div>
  );
};

export default EmbeddingCard;
