import React from 'react';

import { useAppDispatch, useAppSelector } from '../../hooks/hooks';
import { resetAppError, selectorAppError } from '../../store/appSlice';
import classes from './AppModalError.module.css';
import { Button } from '@consta/uikit/Button';
import { IconClose } from '@consta/uikit/IconClose';
import { Modal } from '@consta/uikit/Modal';
import { Text } from '@consta/uikit/Text';

const AppModalError: React.FC = () => {
  const dispatch = useAppDispatch();
  const appError = useAppSelector(selectorAppError);

  const closeModal = () => {
    dispatch(resetAppError());
  };

  return (
    <Modal isOpen={!!appError} hasOverlay onClickOutside={closeModal} onEsc={closeModal}>
      <div className={classes.container}>
        <div className={classes.topRow}>
          <Button
            className={classes.btnCornerClose}
            view="clear"
            iconLeft={IconClose}
            onlyIcon
            size={`s`}
            onClick={closeModal}
          />
        </div>
        <Text className={classes.title}>{`Ошибка`}</Text>
        <Text className={classes.description}>{appError}</Text>
        <div className={classes.delimiter} />
        <Button size="m" label="Понятно" onClick={closeModal} />
      </div>
    </Modal>
  );
};

export default AppModalError;
