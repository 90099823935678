import React from 'react';

import './constaFix.css';

import { useAppDispatch, useAppSelector } from '../../../../../../hooks/hooks';
import { useGetBlogItems } from '../../../../../../hooks/useGetBlogItems';
import {
  selectorViewSelectorActiveSortingId,
  selectorViewSelectorBlogItems,
  setAdminBlogActiveSortingId,
} from '../../../../../../store/adminBlogSlice';
import { SORTING_ITEMS } from './SORTING_ITEMS';
import classes from './SortingSelector.module.css';
import { cn } from '@bem-react/classname';
import { Combobox } from '@consta/uikit/ComboboxCanary';

export type SortingItemType = {
  id: number;
  label: string;
  ico: React.ReactNode;
  icoItem: React.ReactNode;
  sortParams: string;
};

const cnComboboxExampleRenderItem = cn('ComboboxExampleRenderItem');

const SortingSelector: React.FC = () => {
  const dispatch = useAppDispatch();
  const getBlogItems = useGetBlogItems();
  const activeSortingId = useAppSelector(selectorViewSelectorActiveSortingId);
  const activeSorting = SORTING_ITEMS.find((item) => item.id === activeSortingId);
  const pagination = useAppSelector(selectorViewSelectorBlogItems)?.meta;

  const changeHandler = (activeSortingItem: SortingItemType | null) => {
    if (activeSortingItem) {
      dispatch(setAdminBlogActiveSortingId(activeSortingItem.id));
      getBlogItems({
        sortingItem: activeSortingItem,
        queryPagination: `page[size]=${pagination?.per_page ? pagination?.per_page : 25}&page[number]=1`,
      });
    }
  };

  return (
    <div className={classes.container}>
      <Combobox
        placeholder="Выберите вариант"
        items={SORTING_ITEMS}
        value={activeSorting}
        onChange={(item) => changeHandler(item)}
        size={'s'}
        renderItem={({ item, active, hovered, onClick, onMouseEnter, ref }) => (
          <div
            className={cnComboboxExampleRenderItem('Item', { active, hovered })}
            aria-selected={active}
            aria-hidden="true"
            onMouseEnter={onMouseEnter}
            onClick={onClick}
            ref={ref}
            style={{ display: 'flex', paddingLeft: '8px', fontSize: '14px', margin: '6px 0' }}
          >
            {item.icoItem} {item.label}
          </div>
        )}
        renderValue={({ item }) => (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {item.ico} {item.label}
          </div>
        )}
        className={classes.combobox}
      />
    </div>
  );
};

export default SortingSelector;
