import React, { MutableRefObject } from 'react';

import classes from './SearchBox.module.css';
import { IconSearch } from '@consta/uikit/IconSearch';

type PropsType = {
  returnFocusRef: MutableRefObject<HTMLInputElement | null>;
  searchValue: string;
  onChangeSearchValue: (value: string) => void;
};

const SearchBox: React.FC<PropsType> = ({ returnFocusRef, searchValue, onChangeSearchValue }) => {
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChangeSearchValue(e.target.value);
  };

  return (
    <div className={classes.container}>
      <IconSearch className={classes.ico} />
      <input
        ref={returnFocusRef}
        type="text"
        className={classes.input}
        placeholder={'Поиск'}
        value={searchValue}
        onChange={onChange}
      />
    </div>
  );
};

export default SearchBox;
