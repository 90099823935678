import React from 'react';
import { useNavigate } from 'react-router-dom';

import { CourseServerType } from '../../../../api/serverResponse';
import { PageRoutesEnum } from '../../../../appMenuAndRouting/appRoutes';
import classes from './CourseCard.module.css';
import { Button } from '@consta/uikit/Button';
import { Text } from '@consta/uikit/Text';

type PropsType = {
  course: CourseServerType;
};

const CourseCard: React.FC<PropsType> = ({ course }) => {
  const navigate = useNavigate();

  const onViewDetails = () => {
    navigate(`${PageRoutesEnum.Learning_Courses}/${course._uuid}`);
  };

  return (
    <div className={classes.container}>
      <Text className={classes.title}>{course.name}</Text>
      <Text className={classes.description} view={'secondary'}>
        {course.description}
      </Text>
      <div className={classes.tagList}>
        {course.tags?.map((tag, ind) => (
          <Text key={ind} className={classes.tag} view={'secondary'}>
            {tag.name}
          </Text>
        ))}
      </div>
      <div>
        <Button view="secondary" label="Открыть" size={'s'} onClick={onViewDetails} />
      </div>
    </div>
  );
};

export default CourseCard;
