import React from 'react';
import { useNavigate } from 'react-router-dom';

import { BlogEventType } from '../../../../../../api/blogServerResponse';
import { PageRoutesEnum } from '../../../../../../appMenuAndRouting/appRoutes';
import { getTimeDateForBlogEvent } from '../../../../../../utils/blogUtils/getTimeDateForBlogEvent';
import { getTypeTitleForBlogEvent } from '../../../../../../utils/blogUtils/getTypeTitleForBlogEvent';
import classes from './ContentItem.module.css';
import { Button } from '@consta/uikit/Button';
import { Tag } from '@consta/uikit/Tag';
import { Text } from '@consta/uikit/Text';
import parse from 'html-react-parser';
interface IContentItemProps {
  blogEvent: BlogEventType;
}

const ContentItem: React.FC<IContentItemProps> = ({ blogEvent }) => {
  const navigate = useNavigate();

  const onClick = () => {
    navigate(`${PageRoutesEnum.Blog}/${blogEvent.type}/${blogEvent._uuid}`);
  };

  return (
    <div className={classes.container}>
      {blogEvent?.picture?.path && (
        <div className={classes.imgContainer}>
          <img src={blogEvent?.picture?.path} alt="thumbnail" />
        </div>
      )}
      <div className={classes.rowInfo}>
        <Text className={classes.type}>{getTypeTitleForBlogEvent(blogEvent)}</Text>
        <div className={classes.tabsRow}>
          {blogEvent.tags?.map((tag, ind) => (
            <Tag key={ind} mode={'button'} label={tag.name} onClick={() => {}} size={'s'} style={{ flexShrink: 0 }} />
          ))}
        </div>
      </div>
      <Text className={classes.title}>{blogEvent.name}</Text>
      <Text className={classes.description}>{parse(blogEvent.description)}</Text>
      <div className={classes.rowBottom}>
        <Button label={`Читать дальше`} view="clear" className={classes.btnReadMore} size={'s'} onClick={onClick} />
        <Text className={classes.time}>{getTimeDateForBlogEvent(blogEvent.publication_date)}</Text>
      </div>
      <div className={classes.delimiter} />
    </div>
  );
};

export default ContentItem;
