import React from 'react';

import { TagServerType } from '../../api/serverResponse';
import { getTimeDateForBlogEvent } from '../../utils/blogUtils/getTimeDateForBlogEvent';
import classes from './BlogContentInfoRow.module.css';
import { Tag } from '@consta/uikit/Tag';
import { Text } from '@consta/uikit/Text';

interface IContentInfoRowProps {
  format?: string;
  title?: string;
  date?: string;
  type?: string;
  tags?: TagServerType[];
}

const BlogContentInfoRow: React.FC<IContentInfoRowProps> = ({ format, title, date, type, tags }) => {
  return (
    <div className={classes.container}>
      <div className={classes.topRow}>
        <Text className={classes.type}>{type}</Text>
        <div className={classes.tagsContainer}>
          {tags?.map((tag, ind) => (
            <Tag key={ind} mode={'button'} label={tag.name} onClick={() => {}} size={'s'} style={{ flexShrink: 0 }} />
          ))}
        </div>
      </div>
      {format ? (
        <Text className={classes.title}>{`${format} «${title}»`}</Text>
      ) : (
        <Text className={classes.title}>{title}</Text>
      )}
      <Text className={classes.date}>{getTimeDateForBlogEvent(date)}</Text>
    </div>
  );
};

export default BlogContentInfoRow;
