import React, { useState } from 'react';

import CatalogsList from './CatalogsList/CatalogsList';
import classes from './CatalogsListColumn.module.css';
import { Button } from '@consta/uikit/Button';
import { IconArrowLeft } from '@consta/uikit/IconArrowLeft';
import { IconSearch } from '@consta/uikit/IconSearch';
import { Text } from '@consta/uikit/Text';
import { TextField } from '@consta/uikit/TextField';
import classNames from 'classnames';

type PropsType = {
  isShowCatalogList: boolean;
  closeCatalogList: () => void;
};

const CatalogsListColumn: React.FC<PropsType> = ({ isShowCatalogList, closeCatalogList }) => {
  const [value, setValue] = useState<string | null>(null);
  const handleChange = ({ value }: { value: string | null }) => setValue(value);

  return (
    <div className={classNames(classes.container, { [classes.hideContainer]: !isShowCatalogList })}>
      {isShowCatalogList && (
        <div className={classes.content}>
          <div className={classes.columnHeader}>
            <Text className={classes.title}>{`Каталоги`}</Text>
            <Button size="xs" view="ghost" iconLeft={IconArrowLeft} onlyIcon onClick={closeCatalogList} />
          </div>
          <div className={classes.textField}>
            <TextField
              value={value}
              onChange={handleChange}
              style={{ flexGrow: '1' }}
              leftSide={IconSearch}
              placeholder="Введите название каталога"
              size={'s'}
            />
          </div>
          <CatalogsList />
        </div>
      )}
    </div>
  );
};

export default CatalogsListColumn;
