export const getDateTimeForBlogEvent = (dateTime?: string) => {
  if (!dateTime) {
    return '';
  }

  const eventDateTime = new Date(dateTime);

  const timeOptions: Intl.DateTimeFormatOptions = {
    hour: '2-digit',
    minute: '2-digit',
  };
  const timeFormatter = new Intl.DateTimeFormat('ru', timeOptions);
  const time = timeFormatter.format(eventDateTime);

  const dateOptions: Intl.DateTimeFormatOptions = {
    day: '2-digit',
    month: 'long',
    year: 'numeric',
  };
  const dateFormatter = new Intl.DateTimeFormat('ru', dateOptions);
  const date = dateFormatter.format(eventDateTime);

  return `${date.slice(0, -3)}, ${time}`;
};
