import React from 'react';

import { BlogNewsDetailType } from '../../../../../api/blogServerResponse';
import BlogContentImage from '../../../../../Components/BlogContentImage/BlogContentImage';
import BlogContentInfoRow from '../../../../../Components/BlogContentInfoRow/BlogContentInfoRow';
import BlogContentText from '../../../../../Components/BlogContentText/BlogContentText';
import ContentComments from './ContentComments/ContentComments';
import classes from './LeftColumn.module.css';

interface ILeftColumnProps {
  news?: BlogNewsDetailType;
  updateNews: () => void;
}

const LeftColumn: React.FC<ILeftColumnProps> = ({ news, updateNews }) => {
  return (
    <div className={classes.container}>
      <BlogContentInfoRow title={news?.name} date={news?.publication_date!} type={'Новость'} tags={news?.tags} />
      <BlogContentImage imageUrl={news?.picture.path} />
      <BlogContentText text={news?.description} />
      <ContentComments comments={news?.comments} updateNews={updateNews} newsId={news?._uuid} />
    </div>
  );
};

export default LeftColumn;
