import instanceAxios from './instanceAxios';
import {
  CatalogDataServerType,
  CatalogsListServerResponseType,
  ColumnFilterItemsServerResponseType,
} from './serverResponse';

interface IGetCatalogByUuidParams {
  catalogUuid: string;
  query?: string;
}

export const catalogsSearchApi = {
  async getCatalogs() {
    const response = await instanceAxios.get<CatalogsListServerResponseType>('/pbs/dct-catalogs');
    return response.data;
  },

  async getCatalogByUuid(params: IGetCatalogByUuidParams) {
    const response = await instanceAxios.get<CatalogDataServerType>(
      `pbs/dct-catalogs/${params.catalogUuid}${params.query ? params.query : ''}`
    );
    return response.data;
  },

  async getFilterItems(catalogUuid: string, accessorKey: string) {
    const response = await instanceAxios.get<ColumnFilterItemsServerResponseType>(
      `pbs/dct-catalogs/${catalogUuid}/filters/uuid_${accessorKey}`
    );
    return response.data;
  },
};
