import React from 'react';

import classes from './Delimiter.module.css';

interface DelimiterInterface {
  customClass?: string;
}

const Delimiter: React.FC<DelimiterInterface> = ({ customClass }) => {
  return <div className={`${classes.delimiter} ${customClass ? customClass : ''}`} />;
};

export default Delimiter;
