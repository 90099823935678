import React from 'react';

import LeftColum from './LeftColum/LeftColum';
import classes from './MainPart.module.css';
import RightColum from './RightColum/RightColum';

const MainPart: React.FC = () => {
  return (
    <div className={classes.container}>
      <LeftColum />
      <RightColum />
    </div>
  );
};

export default MainPart;
