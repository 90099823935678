import React from 'react';

import shareBlockIco from '../../img/blogPage/shareBlockIco.svg';
import shareIco from '../../img/blogPage/shareIco.svg';
import classes from './BlogShareBlock.module.css';
import { Button } from '@consta/uikit/Button';
import { IconCopy } from '@consta/uikit/IconCopy';
import { Text } from '@consta/uikit/Text';

interface IShareBlock {
  title: string;
}

const BlogShareBlock: React.FC<IShareBlock> = ({ title }) => {
  const onClickCopy = () => {
    navigator.clipboard.writeText(window.location.href).then(() => {});
  };

  const onClickSendEmail = () => {
    const body = `Новость о Дата-Платформе \n \n ${window.location.href}`;
    window.open(`mailto:?subject=Новость о Дата-Платформе&body=${encodeURIComponent(body)}`, '_blank', 'noreferrer');
  };

  return (
    <div className={classes.container}>
      <img src={shareBlockIco} alt="shareIco" className={classes.ico} />
      <Text className={classes.title}>{title}</Text>
      <div className={classes.btnRow}>
        <Button
          label={`Поделиться`}
          className={classes.btnShare}
          iconLeft={() => (
            <div style={{ marginRight: '14px', display: 'flex', alignItems: 'center' }}>
              <img src={shareIco} alt="shareIco" />
            </div>
          )}
          onClick={onClickSendEmail}
        />
        <Button label="Копировать" view="ghost" iconLeft={IconCopy} onlyIcon onClick={onClickCopy} />
      </div>
    </div>
  );
};

export default BlogShareBlock;
