import React from 'react';

import { ReportsDictionaryType, ReportsItemType } from '../../../types/types';
import ReportsItem from './ReportsItem/ReportsItem';
import classes from './ReportsList.module.css';

type PropsType = {
  queryString: string;
  reports: ReportsItemType[] | null;
  dictionaries: ReportsDictionaryType;
  refetch: () => void;
};

const ReportsList: React.FC<PropsType> = ({ reports, dictionaries, refetch }) => {
  return (
    <div className={classes.list}>
      {reports &&
        reports.map((el, index) => <ReportsItem key={index} el={el} dictionaries={dictionaries} refetch={refetch} />)}
    </div>
  );
};

export default ReportsList;
