import React from 'react';

import { useAppSelector } from '../../../../../hooks/hooks';
import { selectorIsOpenLeftColumn } from '../../../../../store/methodologySlice';
import classes from './OpenCloseContainer.module.css';
import classNames from 'classnames';

type PropsType = {
  children: JSX.Element;
};

const OpenCloseContainer: React.FC<PropsType> = ({ children }) => {
  const isOpenLeftColumn = useAppSelector(selectorIsOpenLeftColumn);

  return (
    <div className={classNames(classes.container, { [classes.closeContainer]: !isOpenLeftColumn })}>
      <div className={classNames(classes.childrenContainer, { [classes.hideChildren]: !isOpenLeftColumn })}>
        {children}
      </div>
    </div>
  );
};

export default OpenCloseContainer;
