import React, { useEffect, useRef, useState } from 'react';

import classes from './ProgramItem.module.css';
import { Text } from '@consta/uikit/Text';

type PropsType = {
  text: string;
  ind: number;
  isLastItem: boolean;
};

const ProgramItem: React.FC<PropsType> = ({ text, ind, isLastItem }) => {
  const [containerHeight, setContainerHeight] = useState('16px');
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (containerRef.current?.offsetHeight) {
      setContainerHeight(`${containerRef.current.offsetHeight + 24}px`);
    }
  }, []);

  return (
    <div ref={containerRef} className={classes.container}>
      <div className={classes.indContainer}>
        <Text className={classes.ind}>{ind}</Text>
      </div>
      <Text className={classes.title}>{text}</Text>
      {!isLastItem && <div className={classes.connector} style={{ height: containerHeight }} />}
    </div>
  );
};

export default ProgramItem;
