import React, { useEffect } from 'react';

import LayoutWithoutFooterPage from '../../Components/LayoutWithoutFooterPage/LayoutWithoutFooterPage';
import { useAppDispatch } from '../../hooks/hooks';
import { getBlogTagsDictionary } from '../../store/blogSlice';
import classes from './BlogPage.module.css';
import BlogPageBanner from './BlogPageBanner/BlogPageBanner';
import Contacts from './Contacts/Contacts';
import MainPart from './MainPart/MainPart';
import ViewControl from './ViewControl/ViewControl';

const BlogPage: React.FC = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getBlogTagsDictionary());
  }, [dispatch]);

  return (
    <div className={classes.container}>
      <BlogPageBanner />
      <LayoutWithoutFooterPage>
        <ViewControl />
        <MainPart />
        <Contacts />
      </LayoutWithoutFooterPage>
    </div>
  );
};

export default BlogPage;
