import React, { useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../../../../../hooks/hooks';
import { useGetMethodologyDocuments } from '../../../../../../hooks/useGetMethodologyDocuments';
import {
  selectorSelectedDocumentStatuses,
  selectorSelectedDocumentTags,
  selectorSelectedDocumentTypes,
  setSelectedDocumentStatuses,
  setSelectedDocumentTags,
  setSelectedDocumentTypes,
} from '../../../../../../store/methodologySlice';
import { MethodologySearchEnum } from '../../../../../../types/types';
import classes from './ControlConditionsRow.module.css';
import { Button } from '@consta/uikit/Button';
import { IconClose } from '@consta/uikit/IconClose';
import { Tag } from '@consta/uikit/Tag';

type ConditionType = {
  type: MethodologySearchEnum;
  label: string;
};

const ControlConditionsRow: React.FC = () => {
  const dispatch = useAppDispatch();
  const getMethodologyDocuments = useGetMethodologyDocuments();
  const selectedDocumentStatuses = useAppSelector(selectorSelectedDocumentStatuses);
  const selectedDocumentTypes = useAppSelector(selectorSelectedDocumentTypes);
  const selectedDocumentTags = useAppSelector(selectorSelectedDocumentTags);
  const [searchConditions, setSearchConditions] = useState<ConditionType[]>([]);

  useEffect(() => {
    const searchParamsDocumentStatuses = selectedDocumentStatuses.map((status) => ({
      type: MethodologySearchEnum.documentStatus,
      label: status.label,
    }));

    const searchParamsDocumentTypes = selectedDocumentTypes.map((status) => ({
      type: MethodologySearchEnum.documentType,
      label: status.label,
    }));

    const searchParamsDocumentTags = selectedDocumentTags.map((status) => ({
      type: MethodologySearchEnum.documentTag,
      label: status.label,
    }));
    setSearchConditions([...searchParamsDocumentStatuses, ...searchParamsDocumentTypes, ...searchParamsDocumentTags]);
  }, [selectedDocumentStatuses, selectedDocumentTypes, selectedDocumentTags]);

  const onClickClearAllConditions = () => {
    dispatch(setSelectedDocumentStatuses([]));
    dispatch(setSelectedDocumentTypes([]));
    dispatch(setSelectedDocumentTags([]));
    getMethodologyDocuments({});
  };

  const onClickCancelCondition = (condition: ConditionType) => {
    if (condition.type === MethodologySearchEnum.documentStatus) {
      const newSelectedDocumentStatuses = selectedDocumentStatuses.filter((status) => status.label !== condition.label);
      dispatch(setSelectedDocumentStatuses(newSelectedDocumentStatuses));
      getMethodologyDocuments({ selectedDocumentStatuses: newSelectedDocumentStatuses });
    }
    if (condition.type === MethodologySearchEnum.documentType) {
      const newSelectedDocumentTypes = selectedDocumentTypes.filter((type) => type.label !== condition.label);
      dispatch(setSelectedDocumentTypes(newSelectedDocumentTypes));
      getMethodologyDocuments({ selectedDocumentTypes: newSelectedDocumentTypes });
    }
    if (condition.type === MethodologySearchEnum.documentTag) {
      const newSelectedDocumentTags = selectedDocumentTags.filter((tag) => tag.label !== condition.label);
      dispatch(setSelectedDocumentTags(newSelectedDocumentTags));
      getMethodologyDocuments({ selectedDocumentTags: newSelectedDocumentTags });
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.conditionsList}>
        {searchConditions.map((condition, ind) => (
          <Tag
            key={ind}
            size={'s'}
            mode="cancel"
            onCancel={() => {
              onClickCancelCondition(condition);
            }}
            label={condition.label}
            className={classes.tag}
          />
        ))}
      </div>
      <div className={classes.btnCloseContainer}>
        <Button className={classes.btnClose} view="clear" onlyIcon onClick={onClickClearAllConditions} />
        <IconClose className={classes.ico} />
      </div>
    </div>
  );
};

export default ControlConditionsRow;
