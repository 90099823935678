import React, { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import useDebouncedFunction from '../../../shared-front/hooks/useDebounceFunction';
import { LearningSearchEnum } from '../../../types/types';
import ControlConditionsRow from './ControlConditionsRow/ControlConditionsRow';
import ControlSelectorsRow from './ControlSelectorsRow/ControlSelectorsRow';

const LearningAllCoursesControl: React.FC = () => {
  // const courses = useAppSelector(selectorLearningCourses);

  const clearFocusRef = useRef<HTMLInputElement | null>(null);
  const returnFocusRef = useRef<HTMLInputElement | null>(null);
  const isReturnFocusRef = useRef<boolean>(false);

  const [searchValue, setSearchValue] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();

  const onChangeSearchValue = (value: string) => {
    setSearchValue(value);
  };

  const setFilterSearchParams = () => {
    const filterTitle = searchParams.get(LearningSearchEnum.courseTitle);
    if (searchValue === '') {
      if (filterTitle) {
        isReturnFocusRef.current = true;
        searchParams.delete(LearningSearchEnum.courseTitle);
        setSearchParams(searchParams);
      }
    } else {
      if (filterTitle !== searchValue) {
        isReturnFocusRef.current = true;
        searchParams.delete(LearningSearchEnum.courseTitle);
        searchParams.append(LearningSearchEnum.courseTitle, searchValue);
        setSearchParams(searchParams);
      }
    }
  };

  const setFilterTitleSearchParams = useDebouncedFunction(setFilterSearchParams, 500, true);

  useEffect(() => {
    setFilterTitleSearchParams({});
  }, [searchValue, setFilterTitleSearchParams]);

  // useEffect(() => {
  //   if (isReturnFocusRef.current) {
  //     returnFocusRef.current?.focus();
  //     isReturnFocusRef.current = false;
  //   }
  // }, [courses]);

  return (
    <>
      <input ref={clearFocusRef} style={{ width: 0, height: 0, border: 'none', outline: 'none' }} />
      <ControlSelectorsRow
        clearFocusRef={clearFocusRef}
        returnFocusRef={returnFocusRef}
        searchValue={searchValue}
        onChangeSearchValue={onChangeSearchValue}
      />
      <ControlConditionsRow onChangeSearchValue={onChangeSearchValue} />
    </>
  );
};

export default LearningAllCoursesControl;
