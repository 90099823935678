import React from 'react';

import teamBannerImg from '../../../img/teamPage/teamBannerImg.png';
import classes from './TeamBanner.module.css';
import { Text } from '@consta/uikit/Text';

const TeamBanner: React.FC = () => {
  return (
    <div className={classes.container}>
      <div className={classes.bannerContent}>
        <div className={classes.leftColumn}>
          <Text className={classes.title}>{`Команда Data Office`}</Text>
          <Text
            className={classes.subTitle}
          >{`Познакомьтесь с командой УпД, которая поможет в решении вопросов в разрезе проектной и сервисной поддержки, доменов данных, бизнес-единиц и программ ЦТ`}</Text>
        </div>
        <img src={teamBannerImg} className={classes.img} alt="banner" />
      </div>
    </div>
  );
};

export default TeamBanner;
