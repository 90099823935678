import React from 'react';

import classes from './StepSection.module.css';
import { Button } from '@consta/uikit/Button';
import { Text } from '@consta/uikit/Text';

type PropsType = {
  stepTitle: string;
  stepDescription: string;
  buttonLabel: string;
  onButtonClick: () => void;
};

const StepSection: React.FC<PropsType> = ({ stepTitle, stepDescription, buttonLabel, onButtonClick }) => {
  return (
    <div className={classes.container}>
      <Text className={classes.title} size="m" weight={'bold'}>
        {stepTitle}
      </Text>
      <Text className={classes.description} size="m">
        {stepDescription}
      </Text>
      <div className={classes.buttonContainer}>
        <Button label={buttonLabel} view="secondary" onClick={onButtonClick} />
      </div>
    </div>
  );
};

export default StepSection;
