import instanceAxios from './instanceAxios';

export const appApi = {
  async getUserInfo() {
    const response = await instanceAxios.get('/user/info');
    return response.data;
  },
  async toggleNewsSubscribe() {
    const response = await instanceAxios.get('/user/toggle-news-subscribe');
    return response.data;
  },
  async log(eventType: string, httpPath: string) {
    const response = await instanceAxios.post('/event-log', {
      event_type: eventType,
      http_path: httpPath,
    });
    return response.data;
  },
};
