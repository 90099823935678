import React, { useCallback, useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../../../hooks/hooks';
import {
  getCommandTeamThunk,
  getCommandUsersThunk,
  postCommandLeaderThunk,
  pushSnackBarItemTeam,
  selectorCommandUsers,
  selectorCurrentLeader,
} from '../../../../store/commandSlice';
import classes from './TeamLeaderFormComponent.module.css';
import { Button } from '@consta/uikit/Button';
import { Combobox } from '@consta/uikit/ComboboxCanary';
import { TextField } from '@consta/uikit/TextField';

type Item = {
  label: string;
  id: number;
};
type PropsType = {
  closeSideBar: () => void;
};

const TeamLeaderFormComponent: React.FC<PropsType> = ({ closeSideBar }) => {
  const dispatch = useAppDispatch();
  const leader = useAppSelector(selectorCurrentLeader);
  const users: Item[] = useAppSelector(selectorCommandUsers);
  const [userValue, setUserValue] = useState<Item | null>(leader?.user);
  const [positionValue, setPositionValue] = useState<string | null>(leader?.position);
  const handlePositionChange = ({ value }: { value: string | null }) => setPositionValue(value);
  const [searchValue, setSearchValue] = useState<string>('');

  const setSearch = useCallback((searchString: string) => {
    setSearchValue(searchString);
    console.log(searchString);
    dispatch(getCommandUsersThunk(searchString)).then(() => {});
  }, []);
  const postLeader = useCallback(() => {
    dispatch(
      postCommandLeaderThunk({ uuid_block: leader.blockUuid, uuid_person: userValue?.id, position: positionValue })
    ).finally(() => {
      dispatch(
        pushSnackBarItemTeam({
          key: Math.random(),
          message: `Руководитель обновлен`,
          status: 'success',
          autoClose: 5,
        })
      );
      closeSideBar();
      dispatch(getCommandTeamThunk());
    });
  }, [userValue, positionValue, leader, closeSideBar, dispatch]);

  return (
    <div className={classes.sidebarFilter}>
      <div className={classes.sidebarTitle}>
        <div>Информация о руководителе</div>
      </div>
      <div className={classes.sidebarItem}>
        <div className={classes.itemTitle}>Блок</div>
        <div className={classes.itemText}>{leader?.blockTitle}</div>
      </div>
      <div className={classes.sidebarItem}>
        {users && (
          <Combobox
            style={{ zIndex: 999 }}
            label="Cотрудник"
            items={users}
            value={userValue?.id ? userValue : null}
            getItemLabel={(el: Item) => el.label}
            getItemKey={(el: Item) => el.id}
            onSearchValueChange={setSearch}
            searchValue={searchValue}
            onChange={(valueItem) => setUserValue(valueItem)}
            placeholder="Выберите сотрудника из списка"
          />
        )}
      </div>
      <div className={classes.sidebarItem}>
        <TextField
          onChange={handlePositionChange}
          label="Должность"
          value={positionValue}
          type="textarea"
          cols={200}
          rows={3}
          placeholder="Введите должность сотрудника"
        />
      </div>
      <div className={classes.bottomButtons}>
        <div></div>
        <div>
          <Button size="m" view="ghost" form={'default'} label={'Отмена'} onClick={closeSideBar} />
          <Button
            size="m"
            view="primary"
            form={'default'}
            label={'Сохранить'}
            onClick={postLeader}
            disabled={!(userValue && positionValue)}
          />
        </div>
      </div>
    </div>
  );
};

export default TeamLeaderFormComponent;
