import React, { useCallback, useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../../../hooks/hooks';
import {
  deleteTeamEmployeeThunk,
  getCommandTeamThunk,
  getCommandUsersThunk,
  postTeamEmployeeThunk,
  pushSnackBarItemTeam,
  selectorBlocks,
  selectorCommandUsers,
  selectorCurrentTeamEmployee,
  selectorDivisions,
} from '../../../../store/commandSlice';
import classes from './TeamEmplyeeFormComponent.module.css';
import { Button } from '@consta/uikit/Button';
import { Combobox } from '@consta/uikit/ComboboxCanary';
import { IconTrash } from '@consta/uikit/IconTrash';
import { TextField } from '@consta/uikit/TextField';

type Item = {
  label: string;
  id: number;
};
type PropsType = {
  closeSideBar: () => void;
};

const TeamEmplyeeFormComponent: React.FC<PropsType> = ({ closeSideBar }) => {
  const dispatch = useAppDispatch();
  const employee = useAppSelector(selectorCurrentTeamEmployee);
  const users: Item[] = useAppSelector(selectorCommandUsers);
  const divisions: Item[] = useAppSelector(selectorDivisions);
  const blocks: Item[] = useAppSelector(selectorBlocks);
  const [userValue, setUserValue] = useState<Item | null>(employee?.user);
  const [blockValue, setBlockValue] = useState<Item | null>(employee?.block);
  const [divisionValue, setDivisionValue] = useState<Item | null>(employee?.division);
  const [positionValue, setPositionValue] = useState<string | null>(employee?.position);
  const handlePositionChange = ({ value }: { value: string | null }) => setPositionValue(value);
  const [searchValue, setSearchValue] = useState<string>('');
  const setSearch = useCallback((searchString: string) => {
    setSearchValue(searchString);
    console.log(searchString);
    dispatch(getCommandUsersThunk(searchString)).then(() => {});
  }, []);

  const postEmployee = useCallback(() => {
    dispatch(
      postTeamEmployeeThunk({
        uuid_block: blockValue?.id,
        uuid_person: userValue?.id,
        position: positionValue,
        uuid_team_division: divisionValue?.id,
      })
    ).finally(() => {
      dispatch(
        pushSnackBarItemTeam({
          key: Math.random(),
          message: `Сотрудник обновлен`,
          status: 'success',
          autoClose: 5,
        })
      );
      closeSideBar();
      dispatch(getCommandTeamThunk()).then();
    });
  }, [userValue, positionValue, blockValue, divisionValue, closeSideBar, dispatch]);

  const deleteEmployee = useCallback(() => {
    dispatch(deleteTeamEmployeeThunk(employee?.field_uuid)).finally(() => {
      dispatch(
        pushSnackBarItemTeam({
          key: Math.random(),
          message: `Сотрудник удален`,
          status: 'success',
          autoClose: 5,
        })
      );
      closeSideBar();
      dispatch(getCommandTeamThunk()).then();
    });
  }, [employee, closeSideBar, dispatch]);

  return (
    <div className={classes.sidebarFilter}>
      <div className={classes.sidebarTitle}>
        <div>Информация о сотруднике</div>
      </div>
      <div className={classes.sidebarItem}>
        <div className={classes.itemTitle}>Блок</div>
        <div className={classes.itemText}>{blockValue?.label}</div>
      </div>
      <div className={classes.sidebarItem}>
        <div className={classes.itemTitle}>Подразделение</div>
        <div className={classes.itemText}>{divisionValue?.label}</div>
      </div>

      {/*<div className={classes.sidebarItem}>
        {blocks && blocks.length > 0 && (
          <Combobox
            style={{ zIndex: 999 }}
            label="Блок"
            items={blocks}
            value={blockValue}
            onChange={(valueItem) => setBlockValue(valueItem.value)}
            aria-placeholder="Выберите блок"
          />
        )}
      </div>*/}
      {/*      <div className={classes.sidebarItem}>
        {divisions && divisions.length > 0 && (
          <Combobox
            style={{ zIndex: 999 }}
            label="Подразделение"
            items={divisions}
            value={divisionValue}
            onChange={(valueItem) => setDivisionValue(valueItem.value)}
            aria-placeholder="Выберите подразделение"
          />
        )}
      </div>*/}
      <div className={classes.sidebarItem}>
        {users && (
          <Combobox
            style={{ zIndex: 999 }}
            label="Cотрудник"
            items={users}
            value={userValue?.id ? userValue : null}
            getItemLabel={(el: Item) => el.label}
            getItemKey={(el: Item) => el.id}
            onSearchValueChange={setSearch}
            searchValue={searchValue}
            onChange={(valueItem) => setUserValue(valueItem)}
            placeholder="Выберите сотрудника из списка"
          />
        )}
      </div>
      <div className={classes.sidebarItem}>
        <TextField
          onChange={handlePositionChange}
          label="Должность"
          value={positionValue}
          type="textarea"
          cols={200}
          rows={3}
          placeholder="Введите должность сотрудника"
        />
      </div>
      <div className={classes.bottomButtons}>
        <div>
          {employee?.field_uuid && (
            <Button size={'m'} view={'ghost'} onlyIcon iconRight={IconTrash} onClick={deleteEmployee} />
          )}
        </div>
        <div>
          <Button size="m" view="ghost" form={'default'} label={'Отмена'} onClick={closeSideBar} />
          <Button
            size="m"
            view="primary"
            form={'default'}
            label={'Сохранить'}
            onClick={postEmployee}
            disabled={!(userValue && positionValue && blockValue && divisionValue)}
          />
        </div>
      </div>
    </div>
  );
};

export default TeamEmplyeeFormComponent;
