import React, { useState } from 'react';

import bannerMainPage from '../../../img/learningPage/bannerLearningPage.png';
import AnnouncementsCarousel from './AnnouncementsCarousel/AnnouncementsCarousel';
import classes from './LearningPageHeader.module.css';
import SubscribeModal from './SubscribeModal/SubscribeModal';
import { Button } from '@consta/uikit/Button';
import { IconMail } from '@consta/uikit/IconMail';
import { Text } from '@consta/uikit/Text';

const LearningPageHeader: React.FC = () => {
  const [isOpenSubscribeModal, setIsOpenSubscribeModal] = useState(false);

  const onClickSubscribe = () => {
    setIsOpenSubscribeModal(true);
  };

  const closeSubscribeModal = () => {
    setIsOpenSubscribeModal(false);
  };

  return (
    <div
      className={classes.container}
      style={{
        backgroundImage: `linear-gradient(0deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)), url(${bannerMainPage})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }}
    >
      <div className={classes.contentContainer}>
        <Text size="5xl" weight="bold" className={classes.title}>
          {`Обучение`}
        </Text>
        <Text size="m" className={classes.description}>
          {`Мы поможем подобрать обучение, освоить новые\nнавыки и применить их на практике`}
        </Text>
        <div className={classes.buttonRow}>
          <div>
            <Button view="secondary" label="Подписаться на анонсы" iconLeft={IconMail} onClick={onClickSubscribe} />
          </div>
        </div>
        <AnnouncementsCarousel />
        <SubscribeModal isOpen={isOpenSubscribeModal} closeSubscribeModal={closeSubscribeModal} />
      </div>
    </div>
  );
};

export default LearningPageHeader;
