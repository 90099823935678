import React, { useState } from 'react';

import { adminApi } from '../../../../api/adminApi';
import { MetricItem } from '../../../../types/types';
import classes from './AdminMetricItem.module.css';
import { Button } from '@consta/uikit/Button';
import { IconCheck } from '@consta/uikit/IconCheck';
import { TextField } from '@consta/uikit/TextField';
import moment from 'moment';

type PropsType = {
  metric: MetricItem;
};
const AdminMetricItem: React.FC<PropsType> = ({ metric }) => {
  const [value, setValue] = useState<string | null>(metric.value);
  const [currentMetric, setCurrentMetric] = useState<MetricItem>(metric);
  const submit = async () => {
    if (value) {
      const res = await adminApi.updateAdminMetric({ _uuid: metric.uuid, value: value });
      setCurrentMetric(res.data);
    }
  };
  return (
    <div className={classes.metric}>
      <div className={classes.input}>
        <div className={classes.metricName}>{currentMetric.name}</div>
        <div className={classes.textInput}>
          <TextField
            className={classes.textInput}
            placeholder="Введите новое значение"
            value={value}
            onChange={({ value }) => setValue(value)}
          />
          <Button
            className={classes.submitButton}
            size="m"
            view="primary"
            form="brickDefault"
            onlyIcon
            iconRight={IconCheck}
            onClick={() => submit()}
          />
        </div>
        <div className={classes.metricDesc}>
          Текущее значение: {currentMetric.value} от {moment(currentMetric._alter_ts).format('DD.MM.YYYY')}
        </div>
      </div>
      <div className={classes.currentValues}>
        <div className={classes.currentNumber}>{currentMetric.value}</div>
        <div className={classes.currentDesc}>{currentMetric.name}</div>
      </div>
    </div>
  );
};

export default AdminMetricItem;
