import React, { useMemo, useState } from 'react';

import { ReportsDictionaryItemType } from '../../../../types/types';
import classes from './FilterCombobox.module.css';
import { CheckboxGroup } from '@consta/uikit/CheckboxGroup';
import { IconSearch } from '@consta/uikit/IconSearch';
import { TextField } from '@consta/uikit/TextField';

type PropsType = {
  items: ReportsDictionaryItemType[];
  title: string;
  currentFilter: ReportsDictionaryItemType[];
  setFilterValue: (value: ReportsDictionaryItemType[] | string | null, name: string) => void;
  code: string;
};

const FilterCombobox: React.FC<PropsType> = ({ items, title, currentFilter, setFilterValue, code }) => {
  const [searchTitleValue, setSearchTitleValue] = useState<string>('');
  const handleChangeSearchTitleValue = (value: any) => {
    setSearchTitleValue(value);
  };

  const getFilteredItems = useMemo(() => {
    if (!searchTitleValue) {
      return items;
    }
    return items.filter((el) => el.name.toLocaleLowerCase().indexOf(searchTitleValue.toLocaleLowerCase()) > -1);
  }, [searchTitleValue, items]);

  return (
    <div className={classes.filterItem}>
      <div className={classes.title}>
        {title}
        {currentFilter && !!currentFilter.length && (
          <div className={classes.clear} onClick={() => setFilterValue([], code)}>
            Сбросить
          </div>
        )}
      </div>
      <div className={classes.search}>
        <TextField
          value={searchTitleValue}
          onChange={(e) => handleChangeSearchTitleValue(e.value)}
          leftSide={IconSearch}
          className={classes.search}
          placeholder="Найти в списке"
          size={'s'}
          withClearButton={true}
        />
      </div>
      {getFilteredItems.length > 0 && (
        <div className={classes.checkboxGroup}>
          <CheckboxGroup
            value={currentFilter}
            items={getFilteredItems}
            getItemLabel={(item) => item.name}
            onChange={(value) => {
              setFilterValue(value.value, code);
            }}
            name="CheckboxGroup"
            direction="column"
            size="m"
          />
        </div>
      )}
    </div>
  );
};

export default FilterCombobox;
