import React, { useCallback, useEffect, useState } from 'react';

import './constaFix.css';

import { useAppDispatch } from '../../hooks/hooks';
import {
  getCommandBlocksThunk,
  getCommandDivisionThunk,
  getCommandGroupsByDomainThunk,
  getCommandUsersThunk,
} from '../../store/commandSlice';
import ControlSection from './ControlSection/ControlSection';
import EmbeddingSectionComponent from './EmbeddingSectionComponent/EmbeddingSectionComponent';
import TeamBanner from './TeamBanner/TeamBanner';
import TeamComponent from './TeamComponent/TeamComponent';
import classes from './TeamPage.module.css';

const items: string[] = [
  'Проектная и сервисная поддержка',
  'Внедрение в домены данных',
  'Внедрение в бизнес-единицы / ДО',
  'Внедрение в программы ЦТ',
];

const TeamPage: React.FC = () => {
  const [value, setValue] = useState<string>(items[0]);

  const dispatch = useAppDispatch();
  const getUsers = useCallback(async () => {
    await dispatch(getCommandUsersThunk(''));
    await dispatch(getCommandBlocksThunk());
    await dispatch(getCommandDivisionThunk());
    await dispatch(getCommandGroupsByDomainThunk());
  }, [dispatch]);

  useEffect(() => {
    getUsers().then();
  }, [getUsers]);

  return (
    <div className={classes.container}>
      <TeamBanner />
      <ControlSection items={items} value={value} setValue={setValue} />
      <div className={classes.infoContainer}>
        {value === items[0] && <TeamComponent />}
        {value === items[1] && <EmbeddingSectionComponent type={'domains'} />}
        {value === items[2] && <EmbeddingSectionComponent type={'units'} />}
        {value === items[3] && <EmbeddingSectionComponent type={'programs'} />}
      </div>
    </div>
  );
};

export default TeamPage;
