import React, { useState } from 'react';

import { TagServerType } from '../../../../../api/serverResponse';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/hooks';
import {
  addToSelectedTags,
  clearAllSelectedTags,
  deleteFromSelectedTags,
  selectorBlogSelectedTags,
  selectorBlogTagsDictionary,
} from '../../../../../store/blogSlice';
import classes from './TagsFilter.module.css';
import { Button } from '@consta/uikit/Button';
import { IconArrowDown } from '@consta/uikit/IconArrowDown';
import { IconArrowUp } from '@consta/uikit/IconArrowUp';
import { IconClose } from '@consta/uikit/IconClose';
import { Text } from '@consta/uikit/Text';

const TagsFilter: React.FC = () => {
  const dispatch = useAppDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const tagsDictionary = useAppSelector(selectorBlogTagsDictionary);
  const blogSelectedTags = useAppSelector(selectorBlogSelectedTags);
  const notSelectedTags = tagsDictionary.filter(
    (tag) => !blogSelectedTags.find((selectedTag) => selectedTag._uuid === tag._uuid)
  );

  const firstTenNotSelectedTags: TagServerType[] = notSelectedTags.slice(0, 10);
  const restOfFirstTenNotSelectedTags: TagServerType[] = [];
  if (notSelectedTags.length > 10) {
    restOfFirstTenNotSelectedTags.push(...notSelectedTags.slice(10, notSelectedTags.length - 1));
  }

  const onClickAddToSelectedTags = (tag: TagServerType) => {
    dispatch(addToSelectedTags(tag));
  };

  const onClickClearAllSelectedTags = () => {
    dispatch(clearAllSelectedTags());
  };

  const onClickDeleteFromSelectedTags = (tag: TagServerType) => {
    dispatch(deleteFromSelectedTags(tag));
  };

  return (
    <div className={classes.container}>
      <Text className={classes.title}>{`Отфильтровать по тегам`}</Text>
      {blogSelectedTags.length > 0 && (
        <>
          <div className={classes.tagsContainer}>
            {blogSelectedTags.map((tag, ind) => (
              <Button
                key={ind}
                label={tag.name}
                size={'xs'}
                iconRight={IconClose}
                view="ghost"
                onClick={() => onClickDeleteFromSelectedTags(tag)}
              />
            ))}
            <Button
              label={'Сбросить все'}
              size={'xs'}
              iconRight={IconClose}
              view="clear"
              onClick={onClickClearAllSelectedTags}
            />
          </div>
          <div className={classes.delimiter} />
        </>
      )}
      <div className={classes.tagsContainer}>
        {firstTenNotSelectedTags.map((tag, ind) => (
          <Button key={ind} label={tag.name} size={'xs'} view="ghost" onClick={() => onClickAddToSelectedTags(tag)} />
        ))}
        {isOpen &&
          restOfFirstTenNotSelectedTags.map((tag, ind) => (
            <Button key={ind} label={tag.name} size={'xs'} view="ghost" onClick={() => onClickAddToSelectedTags(tag)} />
          ))}
        {restOfFirstTenNotSelectedTags.length > 0 && (
          <Button
            label={isOpen ? `Свернуть` : `Развернуть`}
            view="secondary"
            className={classes.btnReadMore}
            iconRight={isOpen ? IconArrowUp : IconArrowDown}
            size={'xs'}
            onClick={() => {
              setIsOpen((prev) => !prev);
            }}
            style={{ width: '110px' }}
          />
        )}
      </div>
    </div>
  );
};

export default TagsFilter;
