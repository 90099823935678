import React from 'react';

import classes from './ModalSubscribe.module.css';
import { Button } from '@consta/uikit/Button';
import { IconClose } from '@consta/uikit/IconClose';
import { Modal } from '@consta/uikit/Modal';
import { Text } from '@consta/uikit/Text';

interface IModalSubscribeProps {
  isOpen: boolean;
  closeModal: () => void;
  format?: string;
  title?: string;
}

const ModalSubscribe: React.FC<IModalSubscribeProps> = ({ isOpen, closeModal, format, title }) => {
  return (
    <Modal isOpen={isOpen} hasOverlay onClickOutside={closeModal} onEsc={closeModal}>
      <div className={classes.container}>
        <div className={classes.topRow}>
          <Button
            className={classes.btnCornerClose}
            view="clear"
            iconLeft={IconClose}
            onlyIcon
            size={`s`}
            onClick={closeModal}
          />
        </div>
        <Text className={classes.titleModal}>{`Вы записаны на мероприятие`}</Text>
        {format ? (
          <Text className={classes.title}>{`${format} «${title}»`}</Text>
        ) : (
          <Text className={classes.title}>{title}</Text>
        )}
        <Text
          className={classes.info}
        >{`Подробная информация о мероприятии\nв ближайшее время придет вам на почту`}</Text>
        <div className={classes.delimiter} />
        <div className={classes.buttonWrapper}>
          <Button size="m" label="Отлично" onClick={closeModal} />
        </div>
      </div>
    </Modal>
  );
};

export default ModalSubscribe;
