import React from 'react';

import { PersonServerType } from '../../api/serverResponse';
import classes from './TeamMemberCard.module.css';
import { Avatar } from '@consta/uikit/Avatar';
import { Text } from '@consta/uikit/Text';

type PropsType = {
  person: PersonServerType;
};

const getPersonFIO = ({
  first_name,
  last_name,
  middle_name,
}: {
  first_name: string;
  last_name?: string | null | undefined;
  middle_name?: string | null | undefined;
}) => {
  let fio = `${first_name} `;
  if (last_name) {
    fio += last_name.slice(0, 1) + '. ';
  }
  if (middle_name) {
    fio += middle_name.slice(0, 1) + '. ';
  }
  return fio;
};

const TeamMemberCard: React.FC<PropsType> = ({ person }) => {
  const name = getPersonFIO({
    first_name: person.first_name,
    last_name: person.last_name,
    middle_name: person.middle_name,
  });

  return (
    <div className={classes.container}>
      <Avatar className={classes.teacherAvatar} url={''} size={'l'} name={name} />
      <div className={classes.teacherCardRightColumn}>
        <Text className={classes.teacherName}>{name}</Text>
        <Text className={classes.teacherPosition} view={'secondary'}>
          {person.display_role}
        </Text>
        <a href={`mailto:${person.email}`} target={'_blank'} className={classes.teacherEmail} rel="noreferrer">
          {person.email}
        </a>
      </div>
    </div>
  );
};

export default TeamMemberCard;
