import React, { useEffect } from 'react';

import classes from './ModalError.module.css';
import { Button } from '@consta/uikit/Button';
import { Modal } from '@consta/uikit/Modal';
import { Text } from '@consta/uikit/Text';

type PropsType = {
  errorMessage?: string;
};

const ModalError: React.FC<PropsType> = ({ errorMessage }) => {
  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);
  useEffect(() => {
    if (errorMessage) {
      setIsModalOpen(true);
    }
  }, [errorMessage]);

  return (
    <Modal
      isOpen={isModalOpen}
      hasOverlay
      onClickOutside={() => setIsModalOpen(false)}
      onEsc={() => setIsModalOpen(false)}
      className={classes.container}
      style={{ zIndex: 999 }}
    >
      <Text size="xl" view="link" className={classes.title}>
        Сообщение
      </Text>
      <Text size="xl" view="alert" className={classes.errorMessage}>
        {errorMessage}
      </Text>
      <div className={classes.btn}>
        <Button size="m" view="primary" label="Закрыть" onClick={() => setIsModalOpen(false)} />
      </div>
    </Modal>
  );
};

export default ModalError;
