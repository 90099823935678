import React from 'react';

import { BlogItemStatusType } from '../../../../../../types/types';
import { Badge } from '@consta/uikit/Badge';
interface IAdminBlogTableBadgeProps {
  status: BlogItemStatusType | null;
}

const AdminBlogTableBadge: React.FC<IAdminBlogTableBadgeProps> = ({ status }) => {
  if (!status) {
    return null;
  }
  const statusType =
    status.slug === 'draft'
      ? 'system'
      : status.slug === 'planned'
      ? 'normal'
      : status.slug === 'done'
      ? 'success'
      : 'error';

  return <Badge status={statusType} label={status.name} />;
};

export default AdminBlogTableBadge;
