import instanceAxios from './instanceAxios';

export const adminApi = {
  async getAdminMetric() {
    const response = await instanceAxios.get('/metrics');
    return response.data;
  },
  async getPublicMetric(slug: string) {
    const response = await instanceAxios.get(`metrics/filter-by-slug?filter[slug]=${slug}`);
    return response.data;
  },
  async updateAdminMetric(params: { _uuid: string; value: string }) {
    const response = await instanceAxios.post('/metrics', params);
    return response.data;
  },
};
