import instanceAxios from './instanceAxios';
import {
  AnnouncementServerResponseType,
  AnnouncementsServerResponseType,
  CourseServerResponseType,
  CoursesServerResponseType,
  VideosServerResponseType,
} from './serverResponse';

export const learningApi = {
  async getAnnouncements(query?: string) {
    const response = await instanceAxios.get<AnnouncementsServerResponseType>(
      `/pbs/dgp-announcements${query ? query : ''}`
    );
    return response.data;
  },

  async getAnnouncementByUuid(uuid: string) {
    const response = await instanceAxios.get<AnnouncementServerResponseType>(`/pbs/dgp-announcements/${uuid}`);
    return response.data;
  },

  async getCourses() {
    const response = await instanceAxios.get<CoursesServerResponseType>('/pbs/dgp-trainings');
    return response.data;
  },

  async getCourseByUuid(uuid: string) {
    const response = await instanceAxios.get<CourseServerResponseType>(`/pbs/dgp-trainings/${uuid}`);
    return response.data;
  },

  async getVideos() {
    const response = await instanceAxios.get<VideosServerResponseType>('/pbs/dgp-videos');
    return response.data;
  },
};
