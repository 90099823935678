import { RootState } from './store';
import { createSlice } from '@reduxjs/toolkit';

type TeamSliceType = {
  searchFIO: string | null;
};

const initialState: TeamSliceType = {
  searchFIO: '',
};

export const teamSlice = createSlice({
  name: 'teamSlice',
  initialState,
  reducers: {
    setSearchFIO: (state, action) => {
      state.searchFIO = action.payload;
    },
  },
});

export const { setSearchFIO } = teamSlice.actions;

export const selectorSearchFIOTeamSlice = (state: RootState) => state.team.searchFIO;

export default teamSlice.reducer;
