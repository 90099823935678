import { useEffect, useState } from 'react';

import { BlogNewsDetailType } from '../api/blogServerResponse';
import { serverApi } from '../api/serverApi';
import { setAppError } from '../store/appSlice';
import { useAppDispatch } from './hooks';

export const useAdminBlogNews = (id: string | undefined): [any | undefined, boolean] => {
  const dispatch = useAppDispatch();
  const [news, setNews] = useState<any | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (id) {
      setIsLoading(true);
      serverApi
        .getAdminBlogItem(id)
        .then((res) => {
          if (res) {
            setNews(res.data);
          }
        })
        .catch(() => {
          dispatch(setAppError('Ошибка получения новости в Блоге'));
        })
        .finally(() => setIsLoading(false));
    }
  }, [id, dispatch]);

  return [news, isLoading];
};
