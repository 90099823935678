import React from 'react';

import BlogSubscriptionMailBlock from '../../../../Components/BlogSubscriptionMailBlock/BlogSubscriptionMailBlock';
import BlogSuggestionsBlock from '../../../../Components/BlogSuggestionsBlock/BlogSuggestionsBlock';
import { scrollToTop } from '../../../../utils/functions';
import AnnounceCalendar from './AnnounceCalendar/AnnounceCalendar';
import DigestsBlock from './DigestsBlock/DigestsBlock';
import classes from './RightColumn.module.css';
import TagsFilter from './TagsFilter/TagsFilter';
import { Button } from '@consta/uikit/Button';
import { IconTop } from '@consta/uikit/IconTop';

const RightColumn: React.FC = () => {
  return (
    <div className={classes.container}>
      <div className={classes.top}>
        <TagsFilter />
        <BlogSubscriptionMailBlock />
        <BlogSuggestionsBlock />
        <DigestsBlock />
        <AnnounceCalendar />
      </div>
      <div className={classes.bottomBtnContainer}>
        <Button label="топ" view="ghost" iconLeft={IconTop} onlyIcon onClick={scrollToTop} />
      </div>
    </div>
  );
};

export default RightColumn;
