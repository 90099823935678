import React, { useCallback, useEffect } from 'react';

import ProductInfoCard from '../../../Components/ProductInfoCard/ProductInfoCard';
import { MetricType, ProductInfoType } from '../../../types/types';
import classes from './DataPlatformProducts.module.css';
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks';
import { getAdminMetricsThunk, selectorMetrics } from '../../../store/adminSlice';
type PropsType = {
  products: ProductInfoType[];
};

const DataPlatformProducts = React.forwardRef<HTMLDivElement, PropsType>(({ products }, ref) => {
  const dispatch = useAppDispatch();
  const metrics = useAppSelector(selectorMetrics);
  const getMetrics = useCallback(() => {
    dispatch(getAdminMetricsThunk());
  }, [dispatch]);

  useEffect(() => {
    getMetrics();
  }, [getMetrics]);

  return (
    <div ref={ref} className={classes.container}>
      <div className={classes.contentContainer}>
        <div className={classes.productCardList}>
          {metrics &&
            products.map((info, ind) => (
              <ProductInfoCard
                key={ind}
                ind={ind + 1}
                info={info}
                metric={metrics.find((el: MetricType) => el.slug === info.slug)}
                showRedirectButton={true}
              />
            ))}
        </div>
      </div>
    </div>
  );
});

export default DataPlatformProducts;
