import React from 'react';
import { useSearchParams } from 'react-router-dom';

import { MethodologySearchEnum } from '../../../../types/types';
import classes from './ControlConditionsRow.module.css';
import { Button } from '@consta/uikit/Button';
import { IconClose } from '@consta/uikit/IconClose';
import { Tag } from '@consta/uikit/Tag';

type PropsType = {
  onChangeSearchValue: (value: string) => void;
};

const ControlConditionsRow: React.FC<PropsType> = ({ onChangeSearchValue }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const onClickClearAllConditions = () => {
    onChangeSearchValue('');
    const selectedProcessUuid = searchParams.get(MethodologySearchEnum.processUuid);
    const newSearchParams = new URLSearchParams();
    if (selectedProcessUuid) {
      newSearchParams.append(MethodologySearchEnum.processUuid, selectedProcessUuid);
    }
    setSearchParams(newSearchParams);
  };

  const onClickCancelCondition = (condition: [string, string]) => {
    if (condition[0] === MethodologySearchEnum.documentTitle) {
      onChangeSearchValue('');
    }
    const params = Array.from(searchParams).filter((searchParam) => searchParam[1] !== condition[1]);
    const newSearchParams = new URLSearchParams(params);
    setSearchParams(newSearchParams);
  };

  const displaySearchParams = new URLSearchParams(searchParams);
  displaySearchParams.delete(MethodologySearchEnum.processUuid);
  displaySearchParams.delete(MethodologySearchEnum.documentUuid);

  return (
    <div className={classes.container}>
      <div className={classes.conditionsList}>
        {Array.from(displaySearchParams).map((condition, ind) => (
          <Tag
            key={ind}
            size={'s'}
            mode="cancel"
            onCancel={() => {
              onClickCancelCondition(condition);
            }}
            label={condition[1]}
            className={classes.tag}
          />
        ))}
      </div>
      <div className={classes.btnCloseContainer}>
        <Button className={classes.btnClose} view="clear" onlyIcon onClick={onClickClearAllConditions} />
        <IconClose className={classes.ico} />
      </div>
    </div>
  );
};

export default ControlConditionsRow;
