import React from 'react';

import bannerQuestions from '../../../img/learningPage/bannerQuestions.svg';
import classes from './LearningQuestions.module.css';
import { Button } from '@consta/uikit/Button';
import { Text } from '@consta/uikit/Text';

const LearningQuestions: React.FC = () => {
  const onWriteToEmail = () => {
    window.open('mailto:UPD@gazprom-neft.ru?subject=Вопрос по разделу Обучение', '_blank', 'noreferrer');
  };

  return (
    <div className={classes.container}>
      <div className={classes.leftColumn}>
        <div className={classes.textPart}>
          <Text className={classes.title}>{`Остались вопросы по разделу Обучение?`}</Text>
          <Text className={classes.description}>
            {`Изучите план развития раздела или напишите свой\nвопрос на почту UPD@gazprom-neft.ru`}
          </Text>
        </div>
        <div className={classes.buttonsRow}>
          <Button view="secondary" label="Скачать план развития" size={'s'} disabled />
          <Button view="secondary" label="Написать на почту" size={'s'} onClick={onWriteToEmail} />
        </div>
      </div>
      <div className={classes.rightColumn}>
        <img src={bannerQuestions} alt="questions banner" />
      </div>
    </div>
  );
};

export default LearningQuestions;
