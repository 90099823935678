import React, { useEffect } from 'react';

import LayoutOrdinaryPage from '../../Components/LayoutOrdinaryPage/LayoutOrdinaryPage';
import ModalError from '../../Components/ModalError/ModalError';
import { useAppDispatch, useAppSelector } from '../../hooks/hooks';
import { getCatalogsSearchCatalogsThunk, selectorCatalogsSearchError } from '../../store/catalogsSearchSlice';
import classes from './CatalogsSearchPage.module.css';
import MainPart from './MainPart/MainPart';
import { Text } from '@consta/uikit/Text';

const CatalogsSearchPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const catalogsSearchError = useAppSelector(selectorCatalogsSearchError);

  useEffect(() => {
    dispatch(getCatalogsSearchCatalogsThunk());
  }, [dispatch]);

  return (
    <LayoutOrdinaryPage fullWidthContent withoutFooter noScrollContent>
      <div className={classes.container}>
        <Text className={classes.title}>{`Поиск по каталогам`}</Text>
        <MainPart />
      </div>
      <ModalError errorMessage={catalogsSearchError} />
    </LayoutOrdinaryPage>
  );
};

export default CatalogsSearchPage;
