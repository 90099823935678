import instanceAxios from './instanceAxios';

export const dictionaryApi = {
  async getSystems() {
    const response = await instanceAxios.get('/dictionaries/systems');
    return response.data.data;
  },
  async getBlocks() {
    const response = await instanceAxios.get('/dictionaries/blocks');
    return response.data.data;
  },
  async getDataServices() {
    const response = await instanceAxios.get('/dictionaries/data-services');
    return response.data.data;
  },
};
