import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { MenuItemType } from '../../types/types';
import classes from './AppHeaderMenuItem.module.css';
import SubMenuItem from './SubMenuItem/SubMenuItem';
import { IconSelect } from '@consta/uikit/IconSelect';
import { Text } from '@consta/uikit/Text';
import classNames from 'classnames';

type PropsType = {
  item: MenuItemType;
  checked: boolean;
  onChange: React.MouseEventHandler;
  hasHiddenMenuItems?: boolean;
};

const AppHeaderMenuItem: React.FC<PropsType> = ({ item, checked, onChange, hasHiddenMenuItems }) => {
  const location = useLocation();
  const [showChildren, setShowChildren] = useState(false);
  const [hiddenMenuItemsHeight, setHiddenMenuItemsHeight] = useState<string | undefined>(undefined);
  const ref = useRef<HTMLDivElement>(null);

  const onClick = (event: React.MouseEvent) => {
    if (item.children?.length) {
      setShowChildren((prev) => !prev);
      setHiddenMenuItemsHeight(hasHiddenMenuItems && !checked ? `${item.children.length * 67}px` : undefined);
    } else {
      onChange(event);
    }
  };

  const onClickSubMenuItem = (event: React.MouseEvent) => {
    setShowChildren(false);
    setHiddenMenuItemsHeight(undefined);
    onChange(event);
  };

  const onMouseLeave = () => {
    if (showChildren) {
      setShowChildren(false);
      setHiddenMenuItemsHeight(undefined);
    }
  };

  useEffect(() => {
    setShowChildren(false);
    setHiddenMenuItemsHeight(undefined);
  }, [location.pathname]);

  return (
    <div
      ref={ref}
      className={classes.container}
      onClick={onClick}
      onMouseLeave={onMouseLeave}
      style={{
        height: `${hiddenMenuItemsHeight && showChildren ? hiddenMenuItemsHeight : '35px'}`,
      }}
    >
      <Text className={classNames(classes.tabItem, { [classes.selected]: checked, [classes.disabled]: item.disabled })}>
        {item.label}
      </Text>
      {item.children && (
        <IconSelect
          view="secondary"
          size="s"
          className={classNames(classes.ico, { [classes.rotateIco]: showChildren })}
        />
      )}
      {showChildren && item.children && (
        <div className={classes.childrenContainer} onClick={onClickSubMenuItem}>
          {item.children.map((child, ind) => (
            <SubMenuItem key={ind} item={child} />
          ))}
        </div>
      )}
    </div>
  );
};

export default AppHeaderMenuItem;
