import instanceAxios from './instanceAxios';

export const reportsApi = {
  async getReports(query?: string) {
    const response = await instanceAxios.get(`reports${query ? query : ''}`);
    return response.data.data;
  },
  async createReport(params: any) {
    const response = await instanceAxios.post(`reports`, params);
    return response.data.data;
  },
  async updateReport(params: any, id: string | undefined) {
    const response = await instanceAxios.put(`reports/${id}`, params);
    return response.data.data;
  },
  async deleteReport(id: string | undefined) {
    const response = await instanceAxios.delete(`reports/${id}`);
    return response.data.data;
  },
};
