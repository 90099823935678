import React, { useEffect, useState } from 'react';

import { serverApi } from '../api/serverApi';
import { AnnouncementServerType } from '../api/serverResponse';
import { setAppError } from '../store/appSlice';
import { daysAdd } from '../utils/blogUtils/daysAdd';
import { getDateForAnnouncementQuery } from '../utils/blogUtils/getDateForAnnouncementQuery';
import { useAppDispatch } from './hooks';

type useBlogAnnouncementsByDateType = [
  currentVisibleDate: Date,
  announcements: AnnouncementServerType[],
  isLoading: boolean,
  increment: (event: React.MouseEvent<Element, MouseEvent>) => void,
  decrement: (event: React.MouseEvent<Element, MouseEvent>) => void
];

export const useBlogAnnouncementsByDate = (): useBlogAnnouncementsByDateType => {
  const dispatch = useAppDispatch();
  const [year, setYear] = useState(new Date().getFullYear());
  const [month, setMonth] = useState(new Date().getMonth());
  const [currentVisibleDate, setCurrentVisibleDate] = useState(new Date(year, month, 1));
  const [isLoading, setIsLoading] = useState(false);

  const [announcements, setAnnouncements] = useState<AnnouncementServerType[]>([]);

  const increment = (event: React.MouseEvent<Element, MouseEvent>) => {
    event.stopPropagation();
    if (month === 11) {
      setMonth(0);
      setYear((prev) => prev + 1);
    } else {
      setMonth((prev) => prev + 1);
    }
  };

  const decrement = (event: React.MouseEvent<Element, MouseEvent>) => {
    event.stopPropagation();
    if (month === 0) {
      setMonth(11);
      setYear((prev) => prev - 1);
    } else {
      setMonth((prev) => prev - 1);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    const date = new Date(year, month, 1);
    setCurrentVisibleDate(date);
    const dateFrom = getDateForAnnouncementQuery(date);
    const dayInMonth = new Date(year, month, 0).getDate();
    const dateTo = getDateForAnnouncementQuery(daysAdd(date, dayInMonth));
    const dateQuery = `?filter[from]=${dateFrom}&filter[to]=${dateTo}`;
    serverApi
      .getAnnouncements(dateQuery)
      .then((res) => {
        const resAnnouncements = res.data;
        setAnnouncements(resAnnouncements);
      })
      .catch(() => {
        dispatch(setAppError('Ошибка получения анонса в Блоге'));
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [dispatch, year, month]);

  return [currentVisibleDate, announcements, isLoading, increment, decrement];
};
