import React from 'react';

import classes from './TableColumnHeader.module.css';
import classNames from 'classnames';

interface ICommonHeaderProps {
  title: string;
  minColumnWidth?: string;
  maxColumnWidth?: string;
  cellHeight?: string;
  children?: React.ReactNode;
}

const TableColumnHeader: React.FC<ICommonHeaderProps> = ({
  title,
  minColumnWidth,
  maxColumnWidth,
  cellHeight,
  children,
}) => {
  return (
    <div
      className={classNames(classes.container, { [classes.withChildren]: children })}
      style={{ minWidth: minColumnWidth, maxWidth: maxColumnWidth, height: cellHeight }}
    >
      <div>{title}</div>
      <div className={classes.children}>{children}</div>
    </div>
  );
};

export default TableColumnHeader;
