import React from 'react';

import EmptyDocumentsPlaceholder from '../../../../../Components/EmptyDocumentsPlaceholder/EmptyDocumentsPlaceholder';
import { useAppSelector } from '../../../../../hooks/hooks';
import { selectorMethodologyDocuments, selectorMethodologyIsLoading } from '../../../../../store/methodologySlice';
import classes from './DocumentsCardsList.module.css';
import MethodologyDocument from './MethodologyDocument/MethodologyDocument';
import { Loader } from '@consta/uikit/Loader';

const DocumentsCardsList: React.FC = () => {
  const documents = useAppSelector(selectorMethodologyDocuments);
  const isLoading = useAppSelector(selectorMethodologyIsLoading);

  return (
    <div className={classes.container}>
      {isLoading && <Loader />}
      {!isLoading && documents.map((document, ind) => <MethodologyDocument key={ind} document={document} />)}
      {!isLoading && documents.length === 0 && <EmptyDocumentsPlaceholder />}
    </div>
  );
};

export default DocumentsCardsList;
