import React from 'react';
import { useNavigate } from 'react-router-dom';

import cupIco from '../../../img/mainPage/cupIco.svg';
import classes from './WelcomeBanner.module.css';
import { Button } from '@consta/uikit/Button';
import { IconForward } from '@consta/uikit/IconForward';
import { Text } from '@consta/uikit/Text';

const WelcomeBanner: React.FC = () => {
  const navigate = useNavigate();
  return (
    <div className={classes.container}>
      <div className={classes.textContainer}>
        <Text className={classes.title}>{`Добро пожаловать в\nData Office`}</Text>
        <Text className={classes.description}>
          {`Мы обеспечиваем доступность и качество данных\nдля создания новой ценности`}
        </Text>
      </div>
      <div className={classes.bottomDesc} onClick={() => navigate('/reports')}>
        <div className={classes.imgWrap}>
          <img src={cupIco} alt="cup" />
        </div>
        <Text
          className={classes.bottomText}
        >{`«Газпромнефть» достигла стратегического уровня\n в области управления данными по методологии DCAM`}</Text>
        <Button size="s" view="clear" onlyIcon iconRight={IconForward} />
      </div>
    </div>
  );
};

export default WelcomeBanner;
