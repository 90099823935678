import { SortingItemType } from '../Pages/Admin/AdminBlogPage/AdminBlogPageHeader/AdminBlogPageActionRow/SortingSelector/SortingSelector';
import {
  getAdminBlogItemsThunk,
  selectorViewSelectorActiveSortingId,
  selectorViewSelectorBlogItems,
  selectorViewSelectorInArchive,
  selectorViewSelectorSearchValue,
} from '../store/adminBlogSlice';
import { getQueryPagination } from '../utils/adminBlog/getQueryPagination';
import { getQueryPaginationFromFirstPage } from '../utils/adminBlog/getQueryPaginationFromFirstPage';
import { getQuerySearchValue } from '../utils/adminBlog/getQuerySearchValue';
import { getQuerySortValue } from '../utils/adminBlog/getQuerySortValue';
import { combineQuery } from '../utils/combineQuery';
import { useAppDispatch, useAppSelector } from './hooks';
import { getQueryInArchive } from '../utils/adminBlog/getQueryInArchive';

interface useGetBlogItemsType {
  searchValue?: string | null | undefined;
  queryPagination?: string | undefined;
  sortingItem?: SortingItemType;
  inArchive?: boolean;
}

export const useGetBlogItems = () => {
  const dispatch = useAppDispatch();
  const searchValueStore = useAppSelector(selectorViewSelectorSearchValue);
  const inArchiveValueStore = useAppSelector(selectorViewSelectorInArchive);
  const paginationValueStore = useAppSelector(selectorViewSelectorBlogItems)?.meta;
  const activeSortingIdStore = useAppSelector(selectorViewSelectorActiveSortingId);

  return ({ searchValue, queryPagination, sortingItem, inArchive }: useGetBlogItemsType) => {
    const querySearchValue =
      searchValue === null ? '' : getQuerySearchValue(searchValue ? searchValue : searchValueStore);

    const queryInArchiveValue = getQueryInArchive(inArchive ? inArchive : inArchiveValueStore);
    // console.log(queryInArchiveValue);
    const queryPaginationValue = queryPagination ? queryPagination : getQueryPagination(paginationValueStore);
    const querySortingValue = sortingItem ? getQuerySortValue(sortingItem.id) : getQuerySortValue(activeSortingIdStore);

    const requestQuery = combineQuery(
      querySearchValue,
      querySortingValue,
      queryPaginationValue ? queryPaginationValue : getQueryPaginationFromFirstPage(paginationValueStore),
      queryInArchiveValue
    );

    dispatch(getAdminBlogItemsThunk(requestQuery));
  };
};
