import React from 'react';

import ProductPage from '../../Components/ProductPage/ProductPage';
import backgroundImage from '../../img/pageBanners/nsi.png';
import templateContent from '../../templateContent/dataPlatformProducts.json';
import { ProductType } from '../../types/types';

const product: ProductType = templateContent.products[2];

const DataInfoAndStandardsPage: React.FC = () => {
  return <ProductPage product={product} backgroundImage={backgroundImage} />;
};

export default DataInfoAndStandardsPage;
