import React from 'react';
import { useNavigate } from 'react-router-dom';

import classes from './AdminHero.module.css';
import { IconArrowRight } from '@consta/uikit/IconArrowRight';
import { Text } from '@consta/uikit/Text';

type PropsType = {
  pageName: string;
  pageDesc: string;
  backgroundImage: string;
  parentName: string;
  parentLink: string;
};

const AdminHero: React.FC<PropsType> = ({ pageName, pageDesc, backgroundImage, parentName, parentLink }) => {
  const navigate = useNavigate();
  return (
    <div className={classes.container}>
      <div
        className={classes.contentContainer}
        style={{ background: `url(${backgroundImage}) right bottom no-repeat`, backgroundSize: 'contain' }}
      >
        <div className={classes.breadcrumbs}>
          <div className={classes.breadcrumbsLink} onClick={() => navigate(parentLink)}>
            {parentName}
          </div>
          <IconArrowRight size="s" view="ghost" className={classes.breadcrumbsArrow} />
          <div className={classes.breadcrumbsCurrent}>{pageName}</div>
        </div>
        <div className={classes.productTitle}>{pageName}</div>
        <Text size="m" className={classes.subtitle}>
          {pageDesc}
        </Text>
      </div>
    </div>
  );
};

export default AdminHero;
