import React from 'react';

import { PageRoutesEnum } from '../../appMenuAndRouting/appRoutes';
import { CrumbsType } from '../../types/types';
import classes from './BreadCrumbsRow.module.css';
import { Breadcrumbs } from '@consta/uikit/Breadcrumbs';

interface IBreadCrumbsRowProps {
  label?: string;
}

const BreadCrumbsRow: React.FC<IBreadCrumbsRowProps> = ({ label }) => {
  const crumbs: CrumbsType[] = [
    /*{
      label: 'Дата Платформа',
      href: PageRoutesEnum.MainPage,
    },*/
    {
      label: 'Блог',
      href: PageRoutesEnum.Blog,
    },
  ];

  if (label) {
    crumbs.push({ label });
  }

  return (
    <div className={classes.container}>
      <Breadcrumbs items={crumbs} fitMode="scroll" size="s" />
    </div>
  );
};

export default BreadCrumbsRow;
