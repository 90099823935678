import React from 'react';

import classes from './DocumentTagsList.module.css';
import { Tag } from '@consta/uikit/Tag';
type PropsType = {
  documentTags: string[];
};

const DocumentTagsList: React.FC<PropsType> = ({ documentTags }) => {
  return (
    <div className={classes.container}>
      {documentTags.map((tag, ind) => (
        <Tag key={ind} size={'s'} mode="button" onClick={() => {}} label={tag} />
      ))}
    </div>
  );
};

export default DocumentTagsList;
