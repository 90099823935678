import { serverApi } from '../api/serverApi';
import { CatalogDataServerType, CatalogsListServerResponseType, CatalogsListServerType } from '../api/serverResponse';
import { ColumnFilterItemType } from '../shared-front/TableColumnFilter/TableColumnFilter';
import { ColumnFilterType, ColumnSortType } from '../types/types';
import { getRejectedValue } from './rejectedValueHelper';
import { RootState } from './store';
import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';

type CatalogsSearchSliceType = {
  catalogs: CatalogsListServerType[];
  catalog: CatalogDataServerType | undefined;
  activeCatalogUuid: string | undefined;
  filters: ColumnFilterType[];
  sortings: ColumnSortType[];
  isLoading: boolean;
  isError?: string;
};

const initialState: CatalogsSearchSliceType = {
  catalogs: [],
  catalog: undefined,
  activeCatalogUuid: undefined,
  filters: [],
  sortings: [],
  isLoading: false,
  isError: undefined,
};

export const getCatalogsSearchCatalogsThunk = createAsyncThunk<
  CatalogsListServerResponseType,
  undefined,
  { rejectValue: string }
>('catalogsSearch/getCatalogsThunk', async (_, { rejectWithValue }) => {
  try {
    return await serverApi.getCatalogs();
  } catch (e) {
    return rejectWithValue(getRejectedValue('Ошибка получения Каталогов для поиска', e));
  }
});

export const getCatalogDataByUuidThunk = createAsyncThunk<
  CatalogDataServerType,
  { catalogUuid: string; query: string },
  { rejectValue: string }
>('catalogsSearch/getCatalogDataByUuidThunk', async (params, { rejectWithValue }) => {
  try {
    return await serverApi.getCatalogByUuid({
      catalogUuid: params.catalogUuid,
      query: params.query,
    });
  } catch (e) {
    return rejectWithValue(getRejectedValue('Ошибка получения данных для Каталога', e));
  }
});

export const catalogsSearchSlice = createSlice({
  name: 'catalogsSearchSlice',
  initialState,
  reducers: {
    setActiveCatalogUuid: (state, action) => {
      state.activeCatalogUuid = action.payload;
    },
    updateColumnsFilters: (state, action) => {
      if (action.payload.accessorKey) {
        state.filters = state.filters.filter((filter) => filter.filterName !== action.payload.accessorKey);
        if (action.payload.filtersItems.length > 0) {
          const columnFilters: ColumnFilterType[] = action.payload.filtersItems.map(
            (filterItem: ColumnFilterItemType) => ({
              filterName: action.payload.accessorKey,
              valueUuid: filterItem.uuid,
              value: filterItem.value,
            })
          );
          state.filters = state.filters.concat(columnFilters);
        }
      }
    },
    cancelColumnFilter: (state, action) => {
      state.filters = state.filters.filter((filter) => filter.valueUuid !== action.payload.valueUuid);
    },
    updateColumnsSorting: (state, action) => {
      if (action.payload.accessorKey) {
        state.sortings = state.sortings.filter((sorting) => sorting.sortName !== action.payload.accessorKey);
        state.sortings = state.sortings.concat({
          sortName: action.payload.accessorKey,
          value: action.payload.sortDirection,
        });
      }
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getCatalogsSearchCatalogsThunk.fulfilled, (state, action) => {
        state.catalogs = action.payload.data;
      })
      .addCase(getCatalogDataByUuidThunk.fulfilled, (state, action) => {
        state.catalog = action.payload;
        state.isLoading = false;
      })
      .addMatcher(isAnyOf(getCatalogsSearchCatalogsThunk.pending, getCatalogDataByUuidThunk.pending), (state) => {
        state.isLoading = true;
        state.isError = undefined;
      })
      .addMatcher(
        isAnyOf(getCatalogsSearchCatalogsThunk.rejected, getCatalogDataByUuidThunk.rejected),
        (state, action) => {
          state.isLoading = false;
          state.isError = action.payload ? action.payload : 'Неизвестная ошибка - catalogsSearchSlice';
        }
      );
  },
});

export const { setActiveCatalogUuid, updateColumnsFilters, cancelColumnFilter, updateColumnsSorting } =
  catalogsSearchSlice.actions;

export const selectorCatalogsSearchCatalogs = (state: RootState) => state.catalogsSearch.catalogs;
export const selectorCatalogsSearchCatalogData = (state: RootState) => state.catalogsSearch.catalog;
export const selectorCatalogsSearchActiveCatalogUuid = (state: RootState) => state.catalogsSearch.activeCatalogUuid;
export const selectorCatalogsSearchFilters = (state: RootState) => state.catalogsSearch.filters;
export const selectorCatalogsSearchSortings = (state: RootState) => state.catalogsSearch.sortings;

export const selectorCatalogsSearchIsLoading = (state: RootState) => state.catalogsSearch.isLoading;
export const selectorCatalogsSearchError = (state: RootState) => state.catalogsSearch.isError;

export default catalogsSearchSlice.reducer;
