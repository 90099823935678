import React from 'react';
import { useNavigate } from 'react-router-dom';

import { PageRoutesEnum } from '../../../appMenuAndRouting/appRoutes';
import computerIco from '../../../img/mainPage/computerIco.svg';
import classes from './TellAboutBanner.module.css';
import { Button } from '@consta/uikit/Button';
import { IconForward } from '@consta/uikit/IconForward';
import { Text } from '@consta/uikit/Text';

const TellAboutBanner: React.FC = () => {
  const navigate = useNavigate();

  const onClick = () => {
    navigate(PageRoutesEnum.Instruments_DataPlatformPage);
  };

  return (
    <div className={classes.container}>
      <img src={computerIco} alt="computerIco" />
      <Text
        className={classes.title}
      >{`Рассказываем об актуальных инструментах по работе с данными и из чего они состоят\nв разделе Дата-платформа`}</Text>
      <Button label={'Перейти'} view={'clear'} iconRight={IconForward} size={'m'} onClick={onClick} />
    </div>
  );
};

export default TellAboutBanner;
