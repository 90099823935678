import React from 'react';

import IconDownload from '../../../img/iconDownload.svg';
import bannerIco_1 from '../../../img/learningPage/bannerIco_1.svg';
import bannerIco_2 from '../../../img/learningPage/bannerIco_2.svg';
import classes from './LearningPageBanner.module.css';
import { Text } from '@consta/uikit/Text';

const LearningPageBanner: React.FC = () => {
  return (
    <div className={classes.container}>
      <img src={bannerIco_1} className={classes.bannerIco_1} alt="banner" />
      <img src={bannerIco_2} className={classes.bannerIco_2} alt="banner2" />
      <div className={classes.column}>
        <Text className={classes.title}>{`Матрица компетенций`}</Text>
        <Text className={classes.subTitle}>{`Подбери необходимое тебе обучение`}</Text>
      </div>
      <div className={classes.buttonContainer}>
        <button className={classes.button}>
          <img src={IconDownload} alt="Download" className={classes.ico} />
          <div className={classes.btnTitle}>Скачать матрицу XLS</div>
        </button>
      </div>
    </div>
  );
};

export default LearningPageBanner;
