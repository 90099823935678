import React from 'react';

import { ColumnFilterItemType } from '../TableColumnFilter';
import FilterItem from './FilterItem/FilterItem';
import classes from './FilterItemsList.module.css';
import { Loader } from '@consta/uikit/Loader';

interface IFilterItemsListProps {
  filterItems: ColumnFilterItemType[];
  filters: ColumnFilterItemType[];
  onChangeFilterItem: (filterItem: ColumnFilterItemType) => void;
  isLoading: boolean;
  searchFilterValue: string | null;
}

const FilterItemsList: React.FC<IFilterItemsListProps> = ({
  filterItems,
  filters,
  onChangeFilterItem,
  isLoading,
  searchFilterValue,
}) => {
  return (
    <div className={classes.container}>
      {isLoading ? (
        <Loader size={'s'} style={{ height: '32px', marginTop: '32px' }} />
      ) : (
        filterItems.map((filterItem, ind) =>
          searchFilterValue ? (
            filterItem.value.toLowerCase().includes(searchFilterValue.toLowerCase()) ? (
              <FilterItem
                key={ind}
                filterItem={filterItem}
                isChecked={filters.map((filter) => filter.uuid).includes(filterItem.uuid)}
                onChange={onChangeFilterItem}
              />
            ) : null
          ) : (
            <FilterItem
              key={ind}
              filterItem={filterItem}
              isChecked={filters.map((filter) => filter.uuid).includes(filterItem.uuid)}
              onChange={onChangeFilterItem}
            />
          )
        )
      )}
    </div>
  );
};

export default FilterItemsList;
