import React, { MutableRefObject, useEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';

import SearchBox from '../../../../Components/SearchBox/SearchBox';
import { ComboboxItemType } from '../../../../types/types';
import { getComboboxItems } from '../../../../utils/functions';
import classes from './ControlSelectorsRow.module.css';
import { Combobox } from '@consta/uikit/Combobox';

type PropsType = {
  clearFocusRef: MutableRefObject<HTMLInputElement | null>;
  returnFocusRef: MutableRefObject<HTMLInputElement | null>;
  searchValue: string;
  onChangeSearchValue: (value: string) => void;
};

const ControlSelectorsRow: React.FC<PropsType> = ({
  clearFocusRef,
  returnFocusRef,
  searchValue,
  onChangeSearchValue,
}) => {
  const statusRef = useRef<HTMLDivElement | null>(null);
  const typeRef = useRef<HTMLDivElement | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);

  // const [searchParams, setSearchParams] = useSearchParams();
  // const documentStatuses = useAppSelector(selectorDocumentStatuses);
  const [searchParams] = useSearchParams();
  const coursesBlocks = ['Блок-1', 'Блок-2', 'Блок-3'];

  const coursesBlocksItems = getComboboxItems(coursesBlocks);
  const onChangeStatus = ({ value }: { value: ComboboxItemType | null }) => {
    // if (value?.label !== undefined) {
    //   if (!searchParams.getAll(MethodologySearchEnum.documentStatus).includes(value.label)) {
    //     searchParams.append(MethodologySearchEnum.documentStatus, value.label);
    //   }
    //   setSearchParams(searchParams);
    // }
  };

  // const documentTypes = useAppSelector(selectorDocumentTypes);
  const coursesTypes = ['Тип-1', 'Тип-2', 'Тип-3'];
  const coursesTypesItems = getComboboxItems(coursesTypes);
  //
  const onChangeType = ({ value }: { value: ComboboxItemType | null }) => {
    // if (value?.label !== undefined) {
    //   if (!searchParams.getAll(MethodologySearchEnum.documentType).includes(value.label)) {
    //     searchParams.append(MethodologySearchEnum.documentType, value.label);
    //   }
    //   setSearchParams(searchParams);
    // }
  };
  //
  // const documentTags = useAppSelector(selectorDocumentTags);
  const coursesTags = ['Тэг-1', 'Тэг-2', 'Тэг-3'];
  const coursesTagItems = getComboboxItems(coursesTags);
  const onChangeTag = ({ value }: { value: ComboboxItemType | null }) => {
    // if (value?.label !== undefined) {
    //   if (!searchParams.getAll(MethodologySearchEnum.documentTag).includes(value.label)) {
    //     searchParams.append(MethodologySearchEnum.documentTag, value.label);
    //   }
    //   setSearchParams(searchParams);
    // }
  };

  useEffect(() => {
    clearFocusRef.current?.focus();
  }, [searchParams, clearFocusRef]);

  return (
    <div className={classes.container} ref={containerRef}>
      <SearchBox returnFocusRef={returnFocusRef} searchValue={searchValue} onChangeSearchValue={onChangeSearchValue} />
      <Combobox
        ref={statusRef}
        items={coursesBlocksItems}
        value={null}
        onChange={onChangeStatus}
        size={'s'}
        placeholder={'Блок'}
        className={classes.comboBox}
      />
      <Combobox
        ref={typeRef}
        items={coursesTypesItems}
        value={null}
        onChange={onChangeType}
        size={'s'}
        placeholder={'Тип документа'}
        className={classes.comboBox}
      />
      <Combobox
        items={coursesTagItems}
        value={null}
        onChange={onChangeTag}
        size={'s'}
        placeholder={'Тэги'}
        className={classes.comboBox}
      />
    </div>
  );
};

export default ControlSelectorsRow;
