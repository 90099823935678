import { useEffect, useState } from 'react';

import { BlogAnnouncementType } from '../api/blogServerResponse';
import { serverApi } from '../api/serverApi';
import { setAppError } from '../store/appSlice';
import { useAppDispatch } from './hooks';

export const useBlogAnnounce = (id: string | undefined): [BlogAnnouncementType | undefined, boolean] => {
  const dispatch = useAppDispatch();
  const [announce, setAnnounce] = useState<BlogAnnouncementType | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (id) {
      setIsLoading(true);
      serverApi
        .getBlogAnnounceDetailById(id)
        .then((res) => {
          if (res) {
            setAnnounce(res.data);
          }
        })
        .catch(() => {
          dispatch(setAppError('Ошибка получения анонса в Блоге'));
        })
        .finally(() => setIsLoading(false));
    }
  }, [id, dispatch]);

  return [announce, isLoading];
};
