import { ReactNode } from 'react';

import { createTableColumn } from '../../../../../shared-front/Table/utils/createTableColumn';
import { ColumnGenericType } from '../../../../../types/types';

export type AdminBlogSubsriptionDataType = {
  fio?: ReactNode | null;
  email?: ReactNode | null;
  actions?: ReactNode | null;
};

export const ADMIN_BLOG_TABLE_COLUMNS: ColumnGenericType[] = [
  createTableColumn<AdminBlogSubsriptionDataType>({
    accessorKey: 'fio',
    title: 'ФИО',
    isShow: true,
    size: 768,
  }),
  createTableColumn<AdminBlogSubsriptionDataType>({
    accessorKey: 'email',
    title: 'Email',
    isShow: true,
    size: 345,
  }),
  createTableColumn<AdminBlogSubsriptionDataType>({
    accessorKey: 'actions',
    title: 'Доступные действия',
    textLinesNumber: 2,
    isShow: true,
  }),
];
