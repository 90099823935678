import React from 'react';

import schemeBg from '../../../img/data-platform-scheme.svg';
import item1 from '../../../img/dataPlatformPage/item-1.svg';
import item2 from '../../../img/dataPlatformPage/item-2.svg';
import item3 from '../../../img/dataPlatformPage/item-3.svg';
import item4 from '../../../img/dataPlatformPage/item-4.svg';
import item5 from '../../../img/dataPlatformPage/item-5.svg';
import item6 from '../../../img/dataPlatformPage/item-6.svg';
import item7 from '../../../img/dataPlatformPage/item-7.svg';
import item8 from '../../../img/dataPlatformPage/item-8.svg';
import { ProductInfoType } from '../../../types/types';
import DataPlatformProducts from '../DataPlatformProducts/DataPlatformProducts';
import classes from './DataPlatformLifeCircle.module.css';
import { useNavigate } from 'react-router-dom';

type PropsType = {
  products: ProductInfoType[];
};

const DataPlatformLifeCircle = React.forwardRef<HTMLDivElement, PropsType>(({ products }, ref) => {
  const navigate = useNavigate();
  return (
    <div className={classes.container}>
      <div ref={ref} className={classes.contentContainer}>
        <div className={classes.title}>{'Единая Дата-Платформа'}</div>
        <div className={classes.subtitle}>
          {'Обеспечиваем весь жизненный цикл работы c данными:\n от получения ' +
            'сырых данных до создания аналитических кейсов с экономическим эффектом'}
        </div>
        <div
          className={classes.scheme}
          style={{ background: `url(${schemeBg})0 20px no-repeat`, backgroundSize: 'contain' }}
        >
          <div className={classes.schemeItem}>
            <img src={item1} alt="" />
            <div className={classes.itemTitle}>Сырые данные</div>
          </div>
          <div className={classes.schemeItem} onClick={() => navigate('/data-platform/dwh')}>
            <img src={item2} alt="" />
            <div className={classes.itemTitle}>Консолидация данных</div>
            <div className={classes.itemDesc}>Хранилище и доступ к информации</div>
          </div>
          <div className={classes.schemeItem} onClick={() => navigate('/data-platform/dq')}>
            <img src={item3} alt="" />
            <div className={classes.itemTitle}>Проверка и верификация</div>
            <div className={classes.itemDesc}>Система мониторинга качества информации</div>
          </div>
          <div className={classes.schemeItem} onClick={() => navigate('/data-platform/nsi')}>
            <img src={item4} alt="" />
            <div className={classes.itemTitle}>Сопоставление</div>
            <div className={classes.itemDesc}>Система нормативно-справочной информации</div>
          </div>
          <div className={classes.schemeItem} onClick={() => navigate('/data-platform/catalog')}>
            <img src={item5} alt="" />
            <div className={classes.itemTitle}>Описание</div>
            <div className={classes.itemDesc}>Каталог данных</div>
          </div>
          <div className={classes.schemeItem} onClick={() => navigate('/data-platform/bi')}>
            <img src={item6} alt="" />
            <div className={classes.itemTitle}>Публикация отчетов</div>
            <div className={classes.itemDesc}>Бизнес-аналитика (BI)</div>
          </div>
          <div className={classes.schemeItem} onClick={() => navigate('/data-platform/laboratory')}>
            <img src={item7} alt="" />
            <div className={classes.itemTitle}>Помощь в поиске и анализ</div>
            <div className={classes.itemDesc}>Лаборатория данных (Self-Service BI)</div>
          </div>
          <div className={classes.schemeItem}>
            <img src={item8} alt="" />
            <div className={classes.itemTitle}>Экономический эффект</div>
          </div>
        </div>
        <DataPlatformProducts products={products} />
      </div>
    </div>
  );
});

export default DataPlatformLifeCircle;
