export const getDayOfWeekForDate = (dateTime?: string) => {
  if (!dateTime) {
    return '';
  }

  const eventDateTime = new Date(dateTime);

  const dateOptions: Intl.DateTimeFormatOptions = {
    weekday: 'long',
  };
  const dateFormatter = new Intl.DateTimeFormat('ru', dateOptions);
  const weekday = dateFormatter.format(eventDateTime);
  return weekday.charAt(0).toUpperCase() + weekday.slice(1);
};
