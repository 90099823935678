import React, { useCallback, useEffect, useState } from 'react';

import PersonalInfoCard from '../../../Components/PersonalInfoCard/PersonalInfoCard';
import dataPlatformAboutContent from '../../../templateContent/dataPlatformAbout.json';
import classes from './DataPlatformAbout.module.css';
import { Text } from '@consta/uikit/Text';
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks';
import { getContactsByContentBlockSlug, selectorContactsByContentBlockSlug } from '../../../store/contactsSlice';
import { selectorIsAdminContacts } from '../../../store/appSlice';
import { IconEdit } from '@consta/uikit/IconEdit';
import { Modal } from '@consta/uikit/Modal';
import ContactsForm from '../../../Components/ContactsForm/ContactsForm';

const personOneInfo = dataPlatformAboutContent.personOneInfo;
const personTwoInfo = dataPlatformAboutContent.personTwoInfo;

const DataPlatformAbout = React.forwardRef<HTMLDivElement>((_, ref) => {
  const dispatch = useAppDispatch();
  const users = useAppSelector(selectorContactsByContentBlockSlug);
  const isAdminContacts = useAppSelector(selectorIsAdminContacts);
  const [isModalShow, setIsModalShow] = useState<boolean>(false);
  const getUsers = useCallback(() => {
    dispatch(getContactsByContentBlockSlug('data-platform'));
  }, [dispatch]);

  useEffect(() => {
    getUsers();
  }, [getUsers]);
  return (
    <div ref={ref} className={classes.container}>
      <div className={classes.contentContainer}>
        <Text size="3xl" className={classes.title}>
          {'О дата-платформе'}
          {isAdminContacts && (
            <IconEdit size={'m'} view={'primary'} className={classes.iconEdit} onClick={() => setIsModalShow(true)} />
          )}
        </Text>
        <Modal
          isOpen={isModalShow}
          className={classes.contactsModal}
          hasOverlay
          onClickOutside={() => setIsModalShow(false)}
          onEsc={() => setIsModalShow(false)}
        >
          <ContactsForm
            contacts={users}
            close={() => setIsModalShow(false)}
            afterDispatch={() => dispatch(getContactsByContentBlockSlug('data-platform'))}
          />
        </Modal>
        <div className={classes.infoRow}>
          <Text size="m" className={classes.subtitle}>
            {'Дата-платформа сформирована в 2022 в качестве набора продуктов работы с данными.\n\n' +
              'В Дата-платформу включены 6 продуктов, часть из которых уже реализована на импортонезависимых технологиях, а часть находится на этапе перевода.\n\n' +
              'Основным преимуществом Дата-платформы является межкомпонентная интеграция, обеспечивающая бесперебойный конвеер поставки данных для бизнес-пользователей компании'}
          </Text>
          <div className={classes.cardsContainer}>
            {users &&
              users.contacts &&
              users.contacts.length > 0 &&
              users.contacts.map((user: any, ind) => (
                <PersonalInfoCard
                  key={ind}
                  info={{
                    avatarURL: user.person.icon,
                    name: user.person?.last_name + ' ' + user.person?.first_name + '' + user.person?.middle_name,
                    position: user.position,
                    email: user.person.email,
                  }}
                  collapsed={true}
                />
              ))}
          </div>
        </div>
      </div>
    </div>
  );
});

export default DataPlatformAbout;
