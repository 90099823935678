import React, { useCallback } from 'react';

import { useAppDispatch, useAppSelector } from '../../../../hooks/hooks';
import { setCurrentConfluence, setCurrentTeamEmployee } from '../../../../store/commandSlice';
import { UserType } from '../../../../types/types';
import TeamCard from '../TeamCard/TeamCard';
import classes from './TeamCardContainer.module.css';
import { selectorIsAdminTeam } from '../../../../store/appSlice';

type PropsType = {
  user: UserType;
  verticalColor: string;
  openSidebarClick: () => void;
  openConfluenceSidebar: () => void;
};

const TeamCardContainer: React.FC<PropsType> = ({ user, verticalColor, openSidebarClick, openConfluenceSidebar }) => {
  const dispatch = useAppDispatch();
  const isTeamAdmin = useAppSelector(selectorIsAdminTeam);
  const dbClick = useCallback(() => {
    if (!isTeamAdmin) {
      return;
    }
    if (!user.isConfluence) {
      dispatch(
        setCurrentTeamEmployee({
          position: user.userPosition,
          block: { id: user.block?._uuid, label: user.block?.name },
          division: { id: user.team?._uuid, label: user.team?.name },
          user: { id: user.userUuid, label: user.userName },
          field_uuid: user._uuid,
        })
      );
      openSidebarClick();
    } else {
      dispatch(
        setCurrentConfluence({
          name: user.userPosition,
          block: { id: user.block?._uuid, label: user.block?.name },
          division: { id: user.team?._uuid, label: user.team?.name },
          link: user.userEmail,
          field_uuid: user._uuid,
        })
      );
      openConfluenceSidebar();
    }
  }, [user, dispatch, openConfluenceSidebar, openSidebarClick]);
  return (
    <div className={classes.container} onDoubleClick={dbClick}>
      <div className={classes.centerVerticalLine} />
      <TeamCard user={user} verticalColor={verticalColor} />
      <div className={classes.centerVerticalLine} />
    </div>
  );
};

export default TeamCardContainer;
