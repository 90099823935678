import React, { useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../../../../../../hooks/hooks';
import { useGetMethodologyDocuments } from '../../../../../../../hooks/useGetMethodologyDocuments';
import { selectorMethodologySelectedProcess, setSelectedProcess } from '../../../../../../../store/methodologySlice';
import { MethodologyProcessType } from '../../../../../../../types/types';
import classes from './ProcessItem.module.css';
import { Button } from '@consta/uikit/Button';
import { IconArrowDown } from '@consta/uikit/IconArrowDown';
import { IconArrowUp } from '@consta/uikit/IconArrowUp';
import { Text } from '@consta/uikit/Text';
import classNames from 'classnames';

type PropsType = {
  process: MethodologyProcessType;
  level?: number;
};

const ProcessItem: React.FC<PropsType> = ({ process, level }) => {
  const dispatch = useAppDispatch();
  const [isOpen, setIsOpen] = useState(!level);
  const getMethodologyDocuments = useGetMethodologyDocuments();
  const selectedProcess = useAppSelector(selectorMethodologySelectedProcess);
  const isSelectedProcess = process.uuid === selectedProcess?.uuid;

  const hasChildren = process.children && process.children.length > 0;
  const curLevel = level ? level : 0;

  const onClickSelectItem = () => {
    /*if (isSelectedProcess) {
      dispatch(setSelectedProcess(undefined));
      getMethodologyDocuments({ selectedProcess: null });
    } else {*/
    console.log(process);
    dispatch(setSelectedProcess(process));
    getMethodologyDocuments({ selectedProcess: process });
    //}
  };

  const onClickOpenCloseBtn = (e: React.MouseEvent<Element, MouseEvent>) => {
    e.stopPropagation();
    if (hasChildren) {
      setIsOpen((prev) => !prev);
    }
  };

  return (
    <div className={classes.container}>
      <div
        className={classNames(classes.row, { [classes.rowSelected]: isSelectedProcess })}
        onClick={onClickSelectItem}
      >
        <div className={`${classes.verticalDelimiter} ${isSelectedProcess ? classes.verticalDelimiterSelected : ''}`} />
        <Text
          size="s"
          className={classNames(classes.processNumber, { [classes.processSelected]: isSelectedProcess })}
          style={{ marginLeft: `${curLevel * 16}px` }}
        >
          {process.code}
        </Text>
        <Text
          size="s"
          className={classNames(classes.processTitle, { [classes.processSelected]: isSelectedProcess })}
          style={{ width: `${325 - curLevel * 36}px` }}
        >
          {process.name}
        </Text>
        {hasChildren && (
          <div className={classes.btnOpenCloseContainer}>
            <Button className={classes.btnClose} view="clear" onlyIcon onClick={onClickOpenCloseBtn} />
            {isOpen ? (
              <IconArrowUp className={classNames(classes.ico, { [classes.processSelected]: isSelectedProcess })} />
            ) : (
              <IconArrowDown className={classNames(classes.ico, { [classes.processSelected]: isSelectedProcess })} />
            )}
          </div>
        )}
      </div>
      {isOpen && <div className={classes.delimiter} />}
      {isOpen &&
        process.children?.map((childrenProcess, ind) => (
          <ProcessItem key={ind} process={childrenProcess} level={curLevel + 1} />
        ))}
      {!isOpen && <div className={classes.delimiter} />}
    </div>
  );
};

export default ProcessItem;
