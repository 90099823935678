import React, { useCallback, useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import classes from './ContactsForm.module.css';
import { Button } from '@consta/uikit/Button';
import { IconAdd } from '@consta/uikit/IconAdd';
import { BlockContactsContactType, BlockContactsType } from '../../types/types';
import { IconDraggable } from '@consta/uikit/IconDraggable';
import { Avatar } from '@consta/uikit/Avatar';
import { IconEdit } from '@consta/uikit/IconEdit';
import { IconTrash } from '@consta/uikit/IconTrash';
import { TextField } from '@consta/uikit/TextField';
import UserSelect from './UserSelect/UserSelect';
import { useAppDispatch } from '../../hooks/hooks';
import { batchUpdateContacts } from '../../store/contactsSlice';

type PropType = {
  contacts: BlockContactsType | undefined;
  close: () => void;
  afterDispatch: () => void;
};
interface UsersType {
  id: string;
  label: string;
}

const ContactsForm: React.FC<PropType> = ({ contacts, close, afterDispatch }) => {
  const [users, setUsers] = useState<BlockContactsContactType[]>();
  const [editedUser, setEditedUser] = useState<BlockContactsContactType>();
  const [position, setPosition] = useState<string | null>('');
  const [newUser, setNewUser] = useState<UsersType>();
  const [editedKey, setEditedKey] = useState<number>();
  const [isCreateUser, setIsCreateUser] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (contacts?.contacts) {
      setUsers(contacts.contacts);
    }
  }, [contacts]);

  const updateUser = useCallback(() => {
    if (users && editedKey !== undefined) {
      const tmp = [...users];
      const user = tmp[editedKey];
      const tmpUser: any = {};
      tmpUser.position = position || '';
      if (user.person) {
        tmpUser.person = {};
        tmpUser.person._uuid = newUser?.id || user._uuid;
        const tmpFio = newUser?.label.split(' ');
        if (tmpFio) {
          tmpUser.person.last_name = tmpFio[0] || '';
          tmpUser.person.first_name = tmpFio[1] || '';
          tmpUser.person.middle_name = tmpFio[2] || '';
        } else {
          tmpUser.person.last_name = user.person.last_name;
          tmpUser.person.first_name = user.person.first_name;
          tmpUser.person.middle_name = user.person.middle_name;
        }
      }
      tmp[editedKey] = tmpUser;
      setEditedUser(undefined);
      setUsers(tmp);
    }
  }, [users, editedKey, position, newUser]);

  const createNewUser = useCallback(() => {
    if (users) {
      const tmp = [...users];
      const tmpUser: any = {};
      tmpUser.position = position || '';
      tmpUser.person = {};
      tmpUser._uuid = '';
      tmpUser.person._uuid = newUser?.id;
      const tmpFio = newUser?.label.split(' ');
      if (tmpFio) {
        tmpUser.person.last_name = tmpFio[0] || '';
        tmpUser.person.first_name = tmpFio[1] || '';
        tmpUser.person.middle_name = tmpFio[2] || '';
      }
      tmp.push(tmpUser);
      setEditedUser(undefined);
      setIsCreateUser(false);
      setUsers(tmp);
    }
  }, [users, position, newUser]);

  const deleteUser = useCallback(
    (key: number) => {
      if (users) {
        const tmp = [...users].filter((el, idx) => idx !== key);
        setUsers(tmp);
        setEditedUser(undefined);
      }
    },
    [users]
  );

  const addUser = useCallback(() => {
    setNewUser(undefined);
    setPosition('');
    setIsCreateUser(true);
  }, []);

  const reorder = (list: any, startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }
    const tmp = reorder(users, result.source.index, result.destination.index);
    setUsers(tmp as BlockContactsContactType[]);
  };

  const saveAllChanges = useCallback(() => {
    const contactsForUpdate: any[] = [];
    users?.map((el, idx) => {
      contactsForUpdate.push({
        position: el.position,
        ord: idx,
        uuid_person: el.person?._uuid,
      });
    });
    dispatch(batchUpdateContacts({ _uuid: contacts?._uuid, contacts: contactsForUpdate })).then(() => {
      afterDispatch();
      close();
    });
  }, [users, contacts]);

  return (
    <div className={classes.container}>
      <div className={classes.contactsHeader}>Контакты</div>
      <div className={classes.contactsBody}>
        <div className={classes.leftPart}>
          <div className={classes.headRow}>
            <div>Список сотрудников</div>
            <Button
              label={'Добавить сотрудника'}
              size={'xs'}
              view={'secondary'}
              iconRight={IconAdd}
              onClick={addUser}
            />
          </div>
          <div className={classes.contactsList}>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {users &&
                      users.length > 0 &&
                      users.map((user, key) => (
                        <Draggable key={key} draggableId={key.toString()} index={key}>
                          {(provided, snapshot) => (
                            <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                              <div
                                className={`${classes.contact}  ${
                                  user._uuid == editedUser?._uuid ? classes.active : ''
                                }`}
                                key={key}
                              >
                                <IconDraggable size={'s'} className={classes.drag} />
                                <Avatar
                                  className={classes.avatar}
                                  url={user.person?.icon ? 'data:image/png;base64,' + user.person?.icon : ''}
                                  name={
                                    user.person?.last_name +
                                    ' ' +
                                    user.person?.first_name +
                                    '' +
                                    user.person?.middle_name
                                  }
                                />
                                <div className={classes.userInfo}>
                                  <div className={classes.userName}>
                                    {user.person?.last_name +
                                      ' ' +
                                      user.person?.first_name +
                                      '  ' +
                                      user.person?.middle_name}
                                  </div>
                                  <div className={classes.userPosition}>{user.position}</div>
                                  <div className={classes.userEmail}>
                                    <a href={`mailto:${user.person?.email}`}>{user.person?.email}</a>
                                  </div>
                                </div>
                                <div className={classes.icons}>
                                  <IconEdit
                                    size={'s'}
                                    view={'ghost'}
                                    onClick={() => {
                                      setPosition(user.position);
                                      setEditedUser(user);
                                      setEditedKey(key);
                                    }}
                                  />{' '}
                                  <IconTrash size={'s'} view={'ghost'} onClick={() => deleteUser(key)} />
                                </div>
                              </div>
                            </div>
                          )}
                        </Draggable>
                      ))}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        </div>
        <div className={classes.rightPart}>
          {!editedUser && !isCreateUser && (
            <div className={classes.notSelected}>
              <div className={classes.rightTitle}>Сотрудник не выбран</div>
              <div className={classes.rightDesc}>
                Выберите сотрудника из списка слева для <br /> редактирования или добавьте нового
              </div>
            </div>
          )}
          {editedUser && (
            <div className={classes.editForm}>
              <div className={classes.formTitle}>Добавление сотрудника</div>
              <UserSelect
                initValue={
                  editedUser
                    ? {
                        id: editedUser._uuid,
                        label:
                          editedUser.person?.last_name +
                          ' ' +
                          editedUser.person?.first_name +
                          ' ' +
                          editedUser.person?.middle_name,
                      }
                    : undefined
                }
                label={'Сотрудник'}
                onChangeInit={(value) => setNewUser(value)}
              />
              <TextField
                onChange={(value) => setPosition(value.value)}
                label="Роль"
                value={position}
                type="text"
                placeholder="Введите роль"
                className={classes.fullWidth}
              />
              <Button label={'Сохранить'} view={'primary'} onClick={updateUser} className={classes.saveButton} />
            </div>
          )}
          {isCreateUser && (
            <div className={classes.editForm}>
              <div className={classes.formTitle}>Добавление сотрудника</div>
              <UserSelect initValue={undefined} label={'Сотрудник'} onChangeInit={(value) => setNewUser(value)} />
              <TextField
                onChange={(value) => setPosition(value.value)}
                label="Роль"
                value={position}
                type="text"
                placeholder="Введите роль"
                className={classes.fullWidth}
              />
              <Button label={'Добавить'} view={'primary'} onClick={createNewUser} className={classes.saveButton} />
            </div>
          )}
        </div>
      </div>
      <div className={classes.contactsFooter}>
        <Button view={'ghost'} label={'Отмена'} onClick={close} />
        <Button view={'primary'} label={'Сохранить'} onClick={saveAllChanges} />
      </div>
    </div>
  );
};

export default ContactsForm;
