import React from 'react';

import TableColumnFilter, { ColumnFilterItemType } from '../../TableColumnFilter/TableColumnFilter';
import TableColumnSort, { SortDirectionEnum } from '../../TableColumnSort/TableColumnSort';
import TableCell from '../TableCell/TableCell';
import TableColumnHeader from '../TableColumnHeader/TableColumnHeader';
import { CellContext, Column } from '@tanstack/react-table';

interface ICreateHeader {
  accessorKey: string;
  title: string;
  isShow?: boolean;
  size?: number;
  minContentWidth?: string;
  maxContentWidth?: string;
  headerCellHeight?: string;
  rowCellHeight?: string;
  isFiltering?: boolean;
  onChangeSort?: (accessorKey: string, sortDirection: SortDirectionEnum) => void;
  onChangeFilter?: (accessorKey: string, filtersItems: ColumnFilterItemType[]) => void;
  getFilterItems?: (accessorKey: string) => Promise<ColumnFilterItemType[]>;
  selectedFilters?: ColumnFilterItemType[];
  textLinesNumber?: number;
  customSortComponent?: React.FC;
  alignItemsCenter?: boolean;
}

export const createTableColumn = <T,>({
  accessorKey,
  title,
  isShow = true,
  size,
  minContentWidth,
  maxContentWidth,
  headerCellHeight,
  rowCellHeight,
  onChangeFilter,
  onChangeSort,
  getFilterItems,
  selectedFilters,
  textLinesNumber,
  customSortComponent,
  alignItemsCenter,
}: ICreateHeader) => {
  return {
    accessorKey,
    title,
    isShow,
    header: ({ column }: { column: Column<T> }) => (
      <TableColumnHeader
        title={title}
        minColumnWidth={minContentWidth}
        maxColumnWidth={maxContentWidth}
        cellHeight={headerCellHeight}
      >
        {customSortComponent && customSortComponent({ column })}
        {onChangeSort && <TableColumnSort accessorKey={accessorKey} onChangeSortDirection={onChangeSort} />}
        {onChangeFilter && (
          <TableColumnFilter
            accessorKey={accessorKey}
            selectedFilters={selectedFilters}
            onChangeFilter={onChangeFilter}
            getFilterItems={getFilterItems!}
          />
        )}
      </TableColumnHeader>
    ),
    cell: (row: CellContext<T, unknown>) => (
      <TableCell
        value={row.getValue() as React.ReactNode}
        maxContentWidth={maxContentWidth}
        cellHeight={rowCellHeight}
        textLinesNumber={textLinesNumber}
        alignItemsCenter={alignItemsCenter}
      />
    ),
    size,
  };
};
