import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import classes from './AppFooter.module.css';
import footerLogoDefault from './assets/footerLogo.svg';
import { scrollToTop } from './utils/utils';
import { Button } from '@consta/uikit/Button';
import { IconMail } from '@consta/uikit/IconMail';
import { Text } from '@consta/uikit/Text';

type PropsType = {
  questionEmail?: string;
  portalName?: string;
  onLogoClickRoute?: string;
  onLogoClick?: () => void;
  withoutFooterRoutes?: string[];
  footerLogo?: React.ReactNode;
  version?: string;
};

const AppFooter: React.FC<PropsType> = ({
  portalName,
  questionEmail,
  onLogoClickRoute,
  onLogoClick,
  withoutFooterRoutes,
  footerLogo,
  version,
}) => {
  const location = useLocation();
  const navigate = useNavigate();

  const onClickLogo = () => {
    onLogoClick && onLogoClick();
    if (onLogoClickRoute) {
      scrollToTop();
      navigate(onLogoClickRoute);
    }
  };

  if (withoutFooterRoutes) {
    for (const withoutFooterRoute of withoutFooterRoutes) {
      if (location.pathname.includes(withoutFooterRoute)) {
        return null;
      }
    }
  }

  const onClickSendEmail = () => {
    window.open(
      `mailto:${questionEmail ? questionEmail : 'data-platform@gazprom-neft.ru'}?subject=Вопрос по порталу ${
        portalName ? portalName : ''
      }`
    );
  };

  return (
    <div className={classes.container}>
      <div className={classes.leftColumn}>
        <div className={classes.leftPart}>
          <div className={classes.logoContainer} onClick={onClickLogo}>
            {footerLogo ? (
              footerLogo
            ) : (
              <img src={footerLogoDefault} className={classes.logo} alt="footer default logo" />
            )}
          </div>
          <Text className={classes.copyright}>{`© Дата-Платформа ${new Date().getFullYear()}`}</Text>
        </div>
        {version && <div className={classes.versionContainer}>v{version}</div>}
      </div>

      <div className={classes.rightColumn}>
        <div className={classes.questionsContainer}>
          <Text className={classes.questions}>{`Остались вопросы?\nНапишите нам на почту:`}</Text>
          <Button
            size={'xs'}
            view={'ghost'}
            form={'round'}
            label={'data-platform@gazprom-neft.ru'}
            className={classes.button}
            iconLeft={IconMail}
            onClick={onClickSendEmail}
          />
        </div>
      </div>
    </div>
  );
};

export default AppFooter;
