import React from 'react';

import { ReportsDictionaryItemType } from '../../../../types/types';
import classes from './FilterRadio.module.css';
import { RadioGroup } from '@consta/uikit/RadioGroup';

type PropsType = {
  items: ReportsDictionaryItemType[];
  title: string;
  currentFilter: ReportsDictionaryItemType[];
  setFilterValue: (value: ReportsDictionaryItemType[] | string | null, name: string) => void;
  code: string;
};

const FilterRadio: React.FC<PropsType> = ({ items, title, currentFilter, setFilterValue, code }) => {
  const radioValues: ReportsDictionaryItemType[] = [
    {
      name: 'Есть доступ',
      _uuid: '1',
    },
    {
      name: 'Нет доступа',
      _uuid: '0',
    },
  ];
  return (
    <div className={classes.filterItem}>
      <div className={classes.title}>
        {title}
        {currentFilter[0] && (
          <div className={classes.clear} onClick={() => setFilterValue([], code)}>
            Сбросить
          </div>
        )}
      </div>

      <div className={classes.checkboxGroup}>
        <RadioGroup
          value={currentFilter[0]}
          items={radioValues}
          getItemLabel={(item: ReportsDictionaryItemType) => item.name}
          onChange={(value) => {
            setFilterValue([value.value], code);
          }}
          name="CheckboxGroup"
          direction="column"
          size="m"
        />
      </div>
    </div>
  );
};

export default FilterRadio;
