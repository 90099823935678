import React from 'react';

import { BlogPersonType } from '../../../../../../api/blogServerResponse';
import classes from './CaseAuthors.module.css';
import { Avatar } from '@consta/uikit/Avatar';
import { Text } from '@consta/uikit/Text';

interface IAnnounceSpeakersProps {
  authors?: BlogPersonType[];
}

const CaseAuthors: React.FC<IAnnounceSpeakersProps> = ({ authors }) => {
  return (
    <div className={classes.container}>
      <Text className={classes.title}>{`Авторы кейса`}</Text>
      <div className={classes.speakersContainer}>
        {authors?.map((author, ind) => {
          return (
            <div key={ind} className={classes.row}>
              <Avatar
                className={classes.avatar}
                url={undefined}
                name={`${author.first_name} ${author.last_name}`}
                size={'l'}
              />
              <div className={classes.textContainer}>
                <Text className={classes.name}>{`${author.first_name} ${author.last_name}`}</Text>
                <Text className={classes.position}>{author.position}</Text>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CaseAuthors;
