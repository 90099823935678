import React, { useEffect, useState } from 'react';

import { useAppSelector } from '../../../../hooks/hooks';
import { selectorSearchFIOTeamSlice } from '../../../../store/teamSlice';
import { UserType } from '../../../../types/types';
import classes from './Section.module.css';
import SectionContent from './SectionContent/SectionContent';
import { IconArrowDown } from '@consta/uikit/IconArrowDown';
import { IconArrowUp } from '@consta/uikit/IconArrowUp';
import { Text } from '@consta/uikit/Text';
import classNames from 'classnames';

type PropsType = {
  title: string;
  users: UserType[][];
  openSidebarClick: () => void;
  openConfluenceSidebar: () => void;
  domain: { label: string; id: string }[];
  division: { label: string; id: string };
};

const Section: React.FC<PropsType> = ({ title, users, openSidebarClick, openConfluenceSidebar, domain, division }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isShowOutline, setIsShowOutline] = useState(false);
  const searchFIO = useAppSelector(selectorSearchFIOTeamSlice);

  useEffect(() => {
    if (searchFIO) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  }, [searchFIO]);

  const onMouseEnter = () => {
    if (!isOpen) {
      setIsShowOutline(true);
    }
  };

  const onMouseLeave = () => {
    setIsShowOutline(false);
  };

  const onClick = () => {
    setIsOpen((prev) => !prev);
  };

  return (
    <div className={classes.container}>
      <div
        className={classNames(classes.sectionHeader, {
          [classes.openBorders]: isOpen,
          [classes.showOutline]: isShowOutline,
        })}
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <div className={classes.titleRow}>
          <Text className={classes.title}>{title}</Text>
          {<Text className={classes.icoTitle}>{isOpen ? 'Свернуть' : 'Раскрыть'}</Text>}
          <div className={classes.icoContainer}>
            {isOpen ? <IconArrowUp size={'xs'} /> : <IconArrowDown size={'xs'} />}
          </div>
        </div>
      </div>
      {isOpen && (
        <div className={classes.openPart}>
          <SectionContent
            users={users}
            domain={domain}
            division={division}
            openSidebarClick={openSidebarClick}
            openConfluenceSidebar={openConfluenceSidebar}
          />
        </div>
      )}
    </div>
  );
};

export default Section;
