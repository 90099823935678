import React from 'react';

import { CatalogsListServerType } from '../../../../../../api/serverResponse';
import { useAppDispatch } from '../../../../../../hooks/hooks';
import { setActiveCatalogUuid } from '../../../../../../store/catalogsSearchSlice';
import classes from './CatalogItem.module.css';
import classNames from 'classnames';

type PropsType = {
  catalog: CatalogsListServerType;
  isActive?: boolean;
  isLast?: boolean;
};

const CatalogItem: React.FC<PropsType> = ({ catalog, isActive, isLast }) => {
  const dispatch = useAppDispatch();

  const onClick = () => {
    if (!catalog.is_empty) {
      dispatch(setActiveCatalogUuid(catalog.uuid));
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.row}>
        <div className={classNames(classes.marker, { [classes.markerActive]: isActive })} />
        <div
          className={classNames(classes.title, {
            [classes.titleActive]: isActive,
            [classes.noContentTitle]: catalog.is_empty,
          })}
          onClick={onClick}
        >
          {catalog.name}
        </div>
      </div>
      {!isLast && <div className={classes.delimiter} />}
    </div>
  );
};

export default CatalogItem;
