import React from 'react';

import { useAppDispatch } from '../../../../hooks/hooks';
import { setIsOpenBlogModalSuggestion } from '../../../../store/blogSlice';
import classes from './SuccessSentFrom.module.css';
import { Button } from '@consta/uikit/Button';
import { IconClose } from '@consta/uikit/IconClose';
import { Text } from '@consta/uikit/Text';

interface ISuccessSentFromProps {}

const SuccessSentFrom: React.FC<ISuccessSentFromProps> = () => {
  const dispatch = useAppDispatch();

  const closeModal = () => {
    dispatch(setIsOpenBlogModalSuggestion(false));
  };

  return (
    <div className={classes.container}>
      <div className={classes.topRow}>
        <Button
          className={classes.btnCornerClose}
          view="clear"
          iconLeft={IconClose}
          onlyIcon
          size={`s`}
          onClick={closeModal}
        />
      </div>
      <Text className={classes.title}>{`Ваше предложение принято`}</Text>
      <Text className={classes.description}>{'Администратор свяжется с вами в ближайшее время'}</Text>
      <div className={classes.delimiter} />
      <div className={classes.buttonContainer}>
        <Button size="m" label="Отлично" onClick={closeModal} />
      </div>
    </div>
  );
};

export default SuccessSentFrom;
