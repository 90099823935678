import React from 'react';

import { BlogAnnouncementType } from '../../../../../api/blogServerResponse';
import BlogShareBlock from '../../../../../Components/BlogShareBlock/BlogShareBlock';
import BlogSubscribeBlock from '../../../../../Components/BlogSubscribeBlock/BlogSubscribeBlock';
import classes from './RightColumn.module.css';

interface IRightColumnProps {
  announce: BlogAnnouncementType | undefined;
}

const RightColumn: React.FC<IRightColumnProps> = ({ announce }) => {
  return (
    <div className={classes.container}>
      <BlogSubscribeBlock announce={announce} />
      <BlogShareBlock title={`Знаете, кому будет интересен этот\nанонс? Отправьте ссылку на почту в\nодин клик`} />
      {/*<div className={classes.growWrapper}>
        <div style={{ transform: 'translateY(40px)' }}>
          <BlogSubscribeBlock announce={announce} />
        </div>
      </div>*/}
    </div>
  );
};

export default RightColumn;
