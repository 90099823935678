import React from 'react';

import ProductPage from '../../Components/ProductPage/ProductPage';
import backgroundImage from '../../img/pageBanners/data-catalog.png';
import templateContent from '../../templateContent/dataPlatformProducts.json';
import { ProductType } from '../../types/types';

const product: ProductType = templateContent.products[3];

const DataCatalogPage: React.FC = () => {
  return <ProductPage product={product} backgroundImage={backgroundImage} />;
};

export default DataCatalogPage;
