import React from 'react';
import { Children } from 'react';

import classes from './LayoutOrdinaryPage.module.css';
import classNames from 'classnames';

type PropsType = {
  fullWidthContent?: boolean;
  noScrollContent?: boolean;
  withoutFooter?: boolean;
  children: React.ReactElement | React.ReactElement[];
};

const LayoutOrdinaryPage: React.FC<PropsType> = ({ fullWidthContent, noScrollContent, withoutFooter, children }) => {
  return (
    <div
      className={classNames(classes.container, {
        [classes.containerNoScroll]: noScrollContent,
        [classes.withoutFooter]: withoutFooter,
      })}
    >
      <div className={classNames(classes.contentContainer, { [classes.fullScreenContent]: fullWidthContent })}>
        {Children.map(children, (child) => child)}
      </div>
    </div>
  );
};

export default LayoutOrdinaryPage;
