import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import BreadCrumbsRow from '../../../Components/BreadCrumbsRow/BreadCrumbsRow';
import LayoutOrdinaryPage from '../../../Components/LayoutOrdinaryPage/LayoutOrdinaryPage';
import { useBlogNews } from '../../../hooks/useBlogNews';
import classes from './BlogContentNews.module.css';
import BlogContentNewsMain from './BlogContentNewsMain/BlogContentNewsMain';
import { Loader } from '@consta/uikit/Loader';
import { useAppDispatch } from '../../../hooks/hooks';
import { BlogNewsDetailType } from '../../../api/blogServerResponse';
import { serverApi } from '../../../api/serverApi';
import { setAppError } from '../../../store/appSlice';

const BlogContentNews: React.FC = () => {
  let { id } = useParams();
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [news, setNews] = useState<BlogNewsDetailType | undefined>(undefined);

  const getNews = useCallback(() => {
    if (id) {
      setIsLoading(true);
      serverApi
        .getBlogNewsDetailById(id)
        .then((res) => {
          if (res) {
            setNews(res.data);
          }
        })
        .catch(() => {
          dispatch(setAppError('Ошибка получения новости в Блоге'));
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [id, setNews, setIsLoading, isLoading]);

  useEffect(() => {
    getNews();
  }, []);

  return (
    <div className={classes.container}>
      <LayoutOrdinaryPage>
        {isLoading ? (
          <Loader style={{ marginTop: '80px' }} />
        ) : (
          <>
            <BreadCrumbsRow label={news?.name} />
            <BlogContentNewsMain updateNews={() => getNews()} news={news} />
          </>
        )}
      </LayoutOrdinaryPage>
    </div>
  );
};

export default BlogContentNews;
