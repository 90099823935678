import React, { useEffect, useState } from 'react';

import { serverApi } from '../../../../../api/serverApi';
import { MethodologyProcessServerType } from '../../../../../api/serverResponse';
import { useAppSelector } from '../../../../../hooks/hooks';
import { selectorMethodologyProcesses } from '../../../../../store/methodologySlice';
import { PersonRolesType } from '../../../../../types/types';
import { getParentProcessName, getPersonRoles } from '../../../../../utils/methodology';
import classes from './AboutProcessView.module.css';
import { Loader } from '@consta/uikit/Loader';
import { Text } from '@consta/uikit/Text';

type PropsType = {
  processUuid?: string;
};

const AboutProcessView: React.FC<PropsType> = ({ processUuid }) => {
  const processes = useAppSelector(selectorMethodologyProcesses);
  const [process, setProcess] = useState<MethodologyProcessServerType>();
  const [isLoading, setIsLoading] = useState(false);

  const personRoles = getPersonRoles(process);
  const processOwner = personRoles.find((person) => person.role === 'Владелец процесса');
  const competenceCenter = personRoles.find((person) => person.role?.includes('Центр компетенции'));
  const parentProcessName = getParentProcessName(process?.uuid_process_parent, processes);

  useEffect(() => {
    if (processUuid) {
      setIsLoading(true);
      serverApi.getProcessByUuid(processUuid).then((result) => {
        if (result.state === 'success') {
          setProcess(result.data);
          setIsLoading(false);
        }
      });
    }
  }, [processUuid]);

  if (isLoading) {
    return <Loader />;
  }

  const PersonRole = ({ personRole }: { personRole: PersonRolesType }) => {
    return (
      <div className={classes.splitRowPart}>
        <Text size={'s'} weight={'bold'} className={classes.sectionTitle}>
          {personRole.role}
        </Text>
        <Text size={'s'} className={classes.titleName}>
          {personRole.name}
        </Text>
        <Text size={'xs'} className={classes.titlePosition}>
          {personRole.position}
        </Text>
        <a
          href={`mailto:${personRole.email}?subject=Вопрос по процессу: ${process?.name}`}
          target={'_blank'}
          className={classes.linkToEmail}
          rel="noreferrer"
        >
          {personRole.email}
        </a>
      </div>
    );
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.container}>
        <div className={classes.splitRow}>
          {processOwner ? (
            <PersonRole personRole={processOwner} />
          ) : (
            <div className={classes.splitRowPart}>
              <Text size={'s'} weight={'bold'} className={classes.sectionTitle}>
                Владелец процесса
              </Text>
              <Text size={'s'} className={classes.titleName}>
                -
              </Text>
            </div>
          )}
          {competenceCenter ? (
            <PersonRole personRole={competenceCenter} />
          ) : (
            <div className={classes.splitRowPart}>
              <Text size={'s'} weight={'bold'} className={classes.sectionTitle}>
                Центр компетенции
              </Text>
              <Text size={'s'} className={classes.titleName}>
                -
              </Text>
            </div>
          )}
        </div>
        <div className={classes.splitRow}>
          <div className={classes.splitRowPart}>
            <Text size={'s'} weight={'bold'} className={classes.sectionTitle}>
              {'Основные этапы процесса'}
            </Text>
            <Text size={'s'} className={classes.description}>
              {process?.major_stages ? process.major_stages : '-'}
            </Text>
          </div>
          <div className={classes.splitRowPart}>
            <Text size={'s'} weight={'bold'} className={classes.sectionTitle}>
              {'Метрики процесса'}
            </Text>
            <Text size={'s'} className={classes.description}>
              {process?.metrics ? process.metrics : '-'}
            </Text>
          </div>
        </div>
        <div className={classes.row}>
          <Text size={'s'} weight={'bold'} className={classes.sectionTitle}>
            {'Вышестоящие процессы'}
          </Text>
          <Text size={'s'} className={classes.description}>
            {parentProcessName ? parentProcessName : '-'}
          </Text>
        </div>
        <div className={classes.row}>
          <Text size={'s'} weight={'bold'} className={classes.sectionTitle}>
            {'Краткое описание БП'}
          </Text>
          <Text size={'s'} className={classes.description}>
            {process?.description}
          </Text>
        </div>
        {process?.picture && <img src={process.picture} className={classes.img} alt="process" />}
      </div>
    </div>
  );
};

export default AboutProcessView;
