import React from 'react';

import { UserType } from '../../../../types/types';
import TeamHeadCard from './TeamHeadCard/TeamHeadCard';
import classes from './TeamHeadersSection.module.css';

type PropsType = {
  verticalHeads: {
    title: string;
    color: string;
    _uuid: string;
    head: UserType;
  }[];
  openSidebarClick: () => void;
};

const TeamHeadersSection: React.FC<PropsType> = ({ verticalHeads, openSidebarClick }) => {
  return (
    <div className={classes.container}>
      {verticalHeads.map((verticalHead, ind) => {
        return (
          <TeamHeadCard
            key={ind}
            verticalColor={verticalHead.color}
            verticalTitle={verticalHead.title}
            blockUuid={verticalHead._uuid}
            userName={verticalHead?.head?.userName}
            userEmail={verticalHead?.head?.userEmail}
            userPosition={verticalHead?.head?.userPosition || ''}
            userAvatarFile={verticalHead?.head?.icon || ''}
            userUuid={verticalHead?.head?._uuid}
            openSidebarClick={openSidebarClick}
          />
        );
      })}
    </div>
  );
};

export default TeamHeadersSection;
