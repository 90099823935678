import React from 'react';
import { useNavigate } from 'react-router-dom';

import { CourseServerType } from '../../../../../api/serverResponse';
import { PageRoutesEnum } from '../../../../../appMenuAndRouting/appRoutes';
import classes from './CourseCard.module.css';
import { Button } from '@consta/uikit/Button';
import { Card } from '@consta/uikit/Card';
import { Text } from '@consta/uikit/Text';

type PropsType = {
  course: CourseServerType;
};

const CourseCard: React.FC<PropsType> = ({ course }) => {
  const navigate = useNavigate();

  const onViewDetails = () => {
    navigate(`${PageRoutesEnum.Learning_Courses}/${course._uuid}`);
  };

  return (
    <Card verticalSpace="l" horizontalSpace="l" shadow={false} border={true} className={classes.container}>
      <Text className={classes.title}>{course.name}</Text>
      <Text className={classes.description} view={'secondary'}>
        {course.description}
      </Text>
      <Text className={classes.type} view={'secondary'}>
        {course.type}
      </Text>
      <div className={classes.buttonRow}>
        <div>
          <Button view="secondary" label="Открыть" size={'s'} onClick={onViewDetails} />
        </div>
        <div>
          <Button view="secondary" label="Начать обучение" size={'s'} />
        </div>
      </div>
    </Card>
  );
};

export default CourseCard;
