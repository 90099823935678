import React from 'react';

import { BlogCaseType } from '../../../../../api/blogServerResponse';
import BlogContentImage from '../../../../../Components/BlogContentImage/BlogContentImage';
import BlogContentInfoRow from '../../../../../Components/BlogContentInfoRow/BlogContentInfoRow';
import BlogContentText from '../../../../../Components/BlogContentText/BlogContentText';
import CaseAuthors from './CaseAuthors/CaseAuthors';
import classes from './LeftColumn.module.css';

interface ILeftColumnProps {
  blogCase: BlogCaseType | undefined;
}

const LeftColumn: React.FC<ILeftColumnProps> = ({ blogCase }) => {
  return (
    <div className={classes.container}>
      <BlogContentInfoRow
        title={blogCase?.name}
        date={blogCase?.publication_date!}
        type={'Кейс'}
        tags={blogCase?.tags}
      />
      <BlogContentImage imageUrl={blogCase?.picture?.path} />
      <BlogContentText text={blogCase?.description} />
      <CaseAuthors authors={blogCase?.authors} />
    </div>
  );
};

export default LeftColumn;
