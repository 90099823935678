import React from 'react';

import classes from './TableCell.module.css';

interface ITableCellProps {
  value: React.ReactNode;
  maxContentWidth?: string;
  cellHeight?: string;
  textLinesNumber?: number;
  alignItemsCenter?: boolean;
}

const TableCell: React.FC<ITableCellProps> = ({
  value,
  maxContentWidth,
  cellHeight,
  textLinesNumber,
  alignItemsCenter,
}) => {
  return (
    <div
      className={`${textLinesNumber ? classes.container_multiLineTruncate : classes.container} ${
        alignItemsCenter ? classes.alignItemsCenter : ''
      }`}
      style={{ maxWidth: maxContentWidth, height: cellHeight }}
    >
      <div
        className={textLinesNumber ? classes.value_multiLineTruncate : classes.value}
        style={textLinesNumber ? { WebkitLineClamp: textLinesNumber } : undefined}
      >
        {value}{' '}
      </div>
    </div>
  );
};

export default TableCell;
