import React, { useCallback, useEffect, useState } from 'react';

import { dictionaryApi } from '../../api/dictionaryApi';
import { reportsApi } from '../../api/reportsApi';
import { ReportsDictionaryType, ReportsItemType } from '../../types/types';
import classes from './Reports.module.css';
import ReportsFilter from './ReportsFilter/ReportsFilter';
import ReportsList from './ReportsList/ReportsList';
import { Loader } from '@consta/uikit/Loader';

const Reports: React.FC = () => {
  const [queryString, setQueryString] = useState<string>('');
  const [reports, setReports] = useState<ReportsItemType[] | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [dictionaries, setDictionaries] = useState<ReportsDictionaryType | any>({
    blocks: [],
    systems: [],
    dataServices: [],
  });

  const fetchData = useCallback(async (queryString: string) => {
    setIsLoading(true);
    const res = await reportsApi.getReports(queryString);
    setReports(res);
    setIsLoading(false);
  }, []);

  const fetchDictionaries = useCallback(async () => {
    const blocks = await dictionaryApi.getBlocks();
    const systems = await dictionaryApi.getSystems();
    const dataServices = await dictionaryApi.getDataServices();
    setDictionaries({
      blocks: blocks,
      systems: systems,
      dataServices: dataServices,
    });
  }, []);

  useEffect(() => {
    fetchData(queryString).catch(console.error);
  }, [fetchData, queryString]);

  useEffect(() => {
    fetchDictionaries().catch(console.error);
  }, [fetchDictionaries]);

  return (
    <div className={classes.container}>
      <div className={classes.contentContainer}>
        <div className={classes.title}>Отчеты</div>
        <ReportsFilter
          setQueryString={setQueryString}
          refetch={() => fetchData(queryString)}
          dictionaries={dictionaries}
        />
        {!isLoading && (
          <ReportsList
            reports={reports}
            queryString={queryString}
            dictionaries={dictionaries}
            refetch={() => fetchData(queryString)}
          />
        )}
        {isLoading && <Loader />}
      </div>
    </div>
  );
};

export default Reports;
