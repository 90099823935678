import React from 'react';

import classes from './ModalSubscriptionMail.module.css';
import { Button } from '@consta/uikit/Button';
import { IconClose } from '@consta/uikit/IconClose';
import { Modal } from '@consta/uikit/Modal';
import { Text } from '@consta/uikit/Text';

interface IModalSubscribeProps {
  isOpen: boolean;
  closeModal: () => void;
  subscribe: boolean;
}

const ModalSubscriptionMail: React.FC<IModalSubscribeProps> = ({ isOpen, closeModal, subscribe }) => {
  return (
    <Modal isOpen={isOpen} hasOverlay onClickOutside={closeModal} onEsc={closeModal}>
      <div className={classes.container}>
        <div className={classes.topRow}>
          <Button
            className={classes.btnCornerClose}
            view="clear"
            iconLeft={IconClose}
            onlyIcon
            size={`s`}
            onClick={closeModal}
          />
        </div>
        <Text className={classes.titleModal}>
          {!subscribe ? 'Вы отписаны от рассылки' : `Вы подписаны на рассылку`}
        </Text>
        <Text className={classes.info}>
          {!subscribe
            ? 'Вы больше не узнаете о новых статьях, кейсах\nи анонсах'
            : `Вы первым узнаете о новых статьях, кейсах\nи анонсах`}
        </Text>
        <div className={classes.delimiter} />
        <div className={classes.buttonWrapper}>
          <Button size="m" label="Отлично" onClick={closeModal} />
        </div>
      </div>
    </Modal>
  );
};

export default ModalSubscriptionMail;
