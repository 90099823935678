import React from 'react';

import { BlogAnnouncementType } from '../../../../../../api/blogServerResponse';
import clockIco from '../../../../../../img/blogPage/clockIco.svg';
import houseIco from '../../../../../../img/blogPage/houseIco.svg';
import linkIco from '../../../../../../img/blogPage/linkIco.svg';
import { getDateTimeForBlogEvent } from '../../../../../../utils/blogUtils/getDateTimeForBlogEvent';
import { openURLinNewTab } from '../../../../../../utils/openURLinNewTab';
import classes from './AnnounceInfo.module.css';
import { Text } from '@consta/uikit/Text';

interface IAnnounceInfoProps {
  announce?: BlogAnnouncementType;
}

const AnnounceInfo: React.FC<IAnnounceInfoProps> = ({ announce }) => {
  const onClickOpenLink = () => {
    announce?.link && openURLinNewTab(announce.link);
  };

  return (
    <div className={classes.container}>
      <Text className={classes.title}>{`Время и место проведения`}</Text>
      <div className={classes.row}>
        <img src={clockIco} className={classes.ico} alt=" clock" />
        <div className={classes.textContainer}>
          <Text className={classes.topText}>{getDateTimeForBlogEvent(announce?.start_date)}</Text>
          <div className={classes.separator}>{'-'}</div>
          <Text className={classes.topText}>{getDateTimeForBlogEvent(announce?.finish_date)}</Text>
        </div>
      </div>
      <div className={classes.row}>
        <img src={houseIco} className={classes.ico} alt=" clock" />
        <div className={classes.rowTextContainer}>
          <Text className={classes.topText}>{announce?.place_name}</Text>
        </div>
      </div>
      {announce?.link && (
        <div className={classes.row}>
          <img src={linkIco} className={classes.ico} alt=" clock" />
          <div className={classes.rowTextContainer}>
            <Text className={classes.linkText} onClick={onClickOpenLink}>
              {announce?.link}
            </Text>
          </div>
        </div>
      )}
    </div>
  );
};

export default AnnounceInfo;
