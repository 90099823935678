import React from 'react';

import { useAppDispatch, useAppSelector } from '../../../../../hooks/hooks';
import { cancelColumnFilter, selectorCatalogsSearchFilters } from '../../../../../store/catalogsSearchSlice';
import { ColumnFilterType } from '../../../../../types/types';
import classes from './TagsRow.module.css';
import { Tag } from '@consta/uikit/Tag';

const TagsRow: React.FC = () => {
  const dispatch = useAppDispatch();
  const selectedFilters = useAppSelector(selectorCatalogsSearchFilters);

  const cancelFilter = (filter: ColumnFilterType) => {
    dispatch(cancelColumnFilter(filter));
  };

  return (
    <div className={classes.container}>
      {selectedFilters.length > 0 ? (
        selectedFilters.map((filter, ind) => (
          <Tag
            key={ind}
            size={'s'}
            mode="cancel"
            onCancel={() => cancelFilter(filter)}
            label={filter.value}
            className={classes.tag}
          />
        ))
      ) : (
        <Tag mode="button" label="Фильтры не выбраны" size="xs" onClick={() => {}} />
      )}
    </div>
  );
};

export default TagsRow;
