import React from 'react';

import Delimiter from '../../../../shared-front/Delimiter/Delimiter';
import AdminBlogPageActionRow from './AdminBlogPageActionRow/AdminBlogPageActionRow';
import classes from './AdminBlogPageHeader.module.css';
import AdminBlogPageViewSelector from './AdminBlogPageViewSelector/AdminBlogPageViewSelector';
import { Text } from '@consta/uikit/Text';
interface IAdminBlogPageHeaderProps {}

const AdminBlogPageHeader: React.FC<IAdminBlogPageHeaderProps> = () => {
  return (
    <div className={classes.container}>
      <Text className={classes.title}>{'Администрирование блога'}</Text>
      <AdminBlogPageViewSelector />
      <Delimiter />
      <AdminBlogPageActionRow />
    </div>
  );
};

export default AdminBlogPageHeader;
