import React, { useCallback, useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../../../hooks/hooks';
import {
  deleteTeamConfluenceThunk,
  getCommandTeamThunk,
  postTeamConfluenceThunk,
  pushSnackBarItemTeam,
  selectorBlocks,
  selectorCurrentTeamConfluence,
  selectorDivisions,
} from '../../../../store/commandSlice';
import classes from './TeamConfluenceFormComponent.module.css';
import { Button } from '@consta/uikit/Button';
import { Combobox } from '@consta/uikit/Combobox';
import { IconTrash } from '@consta/uikit/IconTrash';
import { TextField } from '@consta/uikit/TextField';

type Item = {
  label: string;
  id: number;
};
type PropsType = {
  closeSideBar: () => void;
};

const TeamConfluenceFormComponent: React.FC<PropsType> = ({ closeSideBar }) => {
  const dispatch = useAppDispatch();
  const confluence = useAppSelector(selectorCurrentTeamConfluence);
  const divisions: Item[] = useAppSelector(selectorDivisions);
  const blocks: Item[] = useAppSelector(selectorBlocks);
  const [blockValue, setBlockValue] = useState<Item | null>(confluence?.block);
  const [divisionValue, setDivisionValue] = useState<Item | null>(confluence?.division);
  const [positionValue, setPositionValue] = useState<string | null>(confluence?.name);
  const [linkValue, setLinkValue] = useState<string | null>(confluence?.link);

  const handlePositionChange = ({ value }: { value: string | null }) => setPositionValue(value);
  const handleLinkChange = ({ value }: { value: string | null }) => setLinkValue(value);

  const postConfluence = useCallback(() => {
    dispatch(
      postTeamConfluenceThunk({
        uuid_block: blockValue?.id,
        uuid_team_division: divisionValue?.id,
        name: positionValue,
        link: linkValue,
      })
    ).finally(() => {
      dispatch(
        pushSnackBarItemTeam({
          key: Math.random(),
          message: `Ссылка на Confluence добавлена`,
          status: 'success',
          autoClose: 5,
        })
      );
      closeSideBar();
      dispatch(getCommandTeamThunk()).then();
    });
  }, [linkValue, positionValue, blockValue, divisionValue, closeSideBar, dispatch]);

  const deleteConfluence = useCallback(() => {
    dispatch(deleteTeamConfluenceThunk(confluence?.field_uuid)).finally(() => {
      dispatch(
        pushSnackBarItemTeam({
          key: Math.random(),
          message: `Ссылка на Confluence удалена`,
          status: 'success',
          autoClose: 5,
        })
      );
      closeSideBar();
      dispatch(getCommandTeamThunk()).then();
    });
  }, [confluence, closeSideBar, dispatch]);

  return (
    <div className={classes.sidebarFilter}>
      <div className={classes.sidebarTitle}>
        <div>Информация о Confluence</div>
      </div>
      <div className={classes.sidebarItem}>
        <div className={classes.itemTitle}>Блок</div>
        <div className={classes.itemText}>{blockValue?.label}</div>
      </div>
      <div className={classes.sidebarItem}>
        <div className={classes.itemTitle}>Подразделение</div>
        <div className={classes.itemText}>{divisionValue?.label}</div>
      </div>
      {/*<div className={classes.sidebarItem}>*/}
      {/*  {blocks && blocks.length > 0 && (*/}
      {/*    <Combobox*/}
      {/*      style={{ zIndex: 999 }}*/}
      {/*      label="Блок"*/}
      {/*      items={blocks}*/}
      {/*      value={blockValue}*/}
      {/*      onChange={(valueItem) => setBlockValue(valueItem.value)}*/}
      {/*      aria-placeholder="Выберите блок"*/}
      {/*    />*/}
      {/*  )}*/}
      {/*</div>*/}
      {/*<div className={classes.sidebarItem}>*/}
      {/*  {divisions && divisions.length > 0 && (*/}
      {/*    <Combobox*/}
      {/*      style={{ zIndex: 999 }}*/}
      {/*      label="Подразделение"*/}
      {/*      items={divisions}*/}
      {/*      value={divisionValue}*/}
      {/*      onChange={(valueItem) => setDivisionValue(valueItem.value)}*/}
      {/*      aria-placeholder="Выберите подразделение"*/}
      {/*    />*/}
      {/*  )}*/}
      {/*</div>*/}
      <div className={classes.sidebarItem}>
        <TextField
          onChange={handlePositionChange}
          label="Название практики"
          value={positionValue}
          type="textarea"
          cols={200}
          rows={3}
          placeholder="Введите название практики"
        />
      </div>
      <div className={classes.sidebarItem}>
        <TextField
          onChange={handleLinkChange}
          label="Ссылка на Confluence"
          value={linkValue}
          type="textarea"
          cols={200}
          rows={3}
          placeholder="Введите ссылку на Confluence"
        />
      </div>
      <div className={classes.bottomButtons}>
        <div>
          {confluence?.field_uuid && (
            <Button size={'m'} view={'ghost'} onlyIcon iconRight={IconTrash} onClick={deleteConfluence} />
          )}
        </div>
        <div>
          <Button size="m" view="ghost" form={'default'} label={'Отмена'} onClick={closeSideBar} />
          <Button
            size="m"
            view="primary"
            form={'default'}
            label={'Сохранить'}
            onClick={postConfluence}
            disabled={!(linkValue && positionValue && blockValue && divisionValue)}
          />
        </div>
      </div>
    </div>
  );
};

export default TeamConfluenceFormComponent;
