import React from 'react';

import { BlogPictureType } from '../../../../../../api/blogServerResponse';
import classes from './AnnounceImage.module.css';

interface IAnnounceImageProps {
  picture: BlogPictureType;
}

const AnnounceImage: React.FC<IAnnounceImageProps> = ({ picture }) => {
  return <img src={picture.path} className={classes.img} alt="content thumbnail" />;
};

export default AnnounceImage;
