import React from 'react';

import { TableColumnType } from '../../../Table/Table';
import classes from './ColumnsTuneListItem.module.css';
import { Checkbox } from '@consta/uikit/Checkbox';

interface IColumnsTuneListItemProps {
  column: object & TableColumnType;
  onChangeShowColumn: (val: string) => void;
}

const ColumnsTuneListItem: React.FC<IColumnsTuneListItemProps> = ({ column, onChangeShowColumn }) => {
  const onChange = () => {
    onChangeShowColumn(column.accessorKey);
  };

  return (
    <div className={classes.container}>
      <Checkbox checked={column.isShow} label={column.title} onChange={onChange} />
    </div>
  );
};

export default ColumnsTuneListItem;
