import React from 'react';
import { useDispatch } from 'react-redux';

import { useAppSelector } from '../../../hooks/hooks';
import { selectorSearchFIOTeamSlice, setSearchFIO } from '../../../store/teamSlice';
import classes from './ControlSection.module.css';
import { Button } from '@consta/uikit/Button';
import { ChoiceGroup } from '@consta/uikit/ChoiceGroup';
import { FieldGroup } from '@consta/uikit/FieldGroup';
import { IconSearch } from '@consta/uikit/IconSearch';
import { TextField } from '@consta/uikit/TextField';

interface IControlSectionProps {
  items: string[];
  value: string;
  setValue: (value: string) => void;
}

const ControlSection: React.FC<IControlSectionProps> = ({ items, value, setValue }) => {
  const dispatch = useDispatch();
  const searchFIO = useAppSelector(selectorSearchFIOTeamSlice);

  const onChange = ({ value }: { value: string | null }) => {
    dispatch(setSearchFIO(value));
  };

  return (
    <div className={classes.contentContainer}>
      <div className={classes.choiceGroupWrapper}>
        <ChoiceGroup
          value={value}
          onChange={({ value }) => setValue(value)}
          items={items}
          getItemLabel={(item) => item}
          multiple={false}
          name="ChoiceGroupCommand"
          size={'s'}
        />
        <div className={classes.searchWrapper}>
          <FieldGroup size="s" style={{ width: '100%' }}>
            <TextField
              placeholder={'Поиск по ФИО...'}
              style={{ width: '100%' }}
              value={searchFIO}
              onChange={onChange}
            />
            <Button label="Button" onlyIcon={true} iconRight={IconSearch} />
          </FieldGroup>
        </div>
      </div>
    </div>
  );
};

export default ControlSection;
