import React, { forwardRef, useCallback, useImperativeHandle, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';

import { useClickOutsideComponentWithDomException } from '../hooks/useClickOutsideComponentWithDomException';
import IconArrowNext from './IconArrowNext.svg';
import classes from './LeftSideBar.module.css';
import { Button } from '@consta/uikit/Button';

type PropsType = {
  children: React.ReactNode;
  refButton?: React.RefObject<HTMLButtonElement>;
  isUseClickOutside?: boolean;
  closeSidebar?: () => void;
  isSecondaryBackground?: boolean;
};

export type LeftSideBarHandleType = {
  openSidebar: () => void;
  closeSidebar: () => void;
  getIsSidebarOpen: () => boolean;
};

const LeftSideBar = forwardRef<LeftSideBarHandleType, PropsType>(
  ({ refButton, children, isUseClickOutside, closeSidebar, isSecondaryBackground }, ref) => {
    const refSidebar = useRef<HTMLDivElement>(null);
    const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);

    const openSidebarHandler = useCallback(() => {
      if (!isSidebarOpen) {
        setIsSidebarOpen(true);
      }
    }, [isSidebarOpen]);

    const closeSidebarHandler = useCallback(() => {
      if (isSidebarOpen) {
        setIsSidebarOpen(false);
        closeSidebar && closeSidebar();
      }
    }, [isSidebarOpen, closeSidebar]);

    useClickOutsideComponentWithDomException(
      refSidebar,
      isUseClickOutside ? closeSidebarHandler : undefined,
      refButton,
      '.DatePickerDropdown'
    );

    useImperativeHandle(
      ref,
      () => {
        return {
          openSidebar() {
            openSidebarHandler();
          },
          closeSidebar() {
            closeSidebarHandler();
          },
          getIsSidebarOpen() {
            return isSidebarOpen;
          },
        };
      },
      [openSidebarHandler, closeSidebarHandler, isSidebarOpen]
    );

    const refChildren = useRef<HTMLDivElement>(null);

    return (
      <div ref={refSidebar}>
        <CSSTransition in={isSidebarOpen} nodeRef={refChildren} timeout={500} classNames={{ ...classes }} unmountOnExit>
          <div
            ref={refChildren}
            className={`${classes.sidebar} ${isSecondaryBackground ? classes.secondaryBackground : ''}`}
          >
            <div className={classes.children}>{children}</div>
            {isSidebarOpen && (
              <Button
                size={'xs'}
                label="Close"
                view="ghost"
                iconLeft={() => <img src={IconArrowNext} alt="IconArrowNext" style={{ marginLeft: '2px' }} />}
                onlyIcon
                className={classes.closeButton}
                onClick={closeSidebarHandler}
              />
            )}
          </div>
        </CSSTransition>
      </div>
    );
  }
);

export default LeftSideBar;
