import React from 'react';

import classes from './403.module.css';
import { Responses403 } from '@consta/uikit/Responses403';

const Page403: React.FC = () => {
  return (
    <div className={classes.container}>
      <Responses403 />
    </div>
  );
};

export default Page403;
