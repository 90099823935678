import React from 'react';
import { useNavigate } from 'react-router-dom';

import { AnnouncementServerType } from '../../../../../api/serverResponse';
import { PageRoutesEnum } from '../../../../../appMenuAndRouting/appRoutes';
import { formatDateToDDMMHHMM } from '../../../../../utils/formatDateTimeUtils';
import classes from './AnnouncementCard.module.css';
import { Badge } from '@consta/uikit/Badge';
import { Button } from '@consta/uikit/Button';
import { Card } from '@consta/uikit/Card';
import { Text } from '@consta/uikit/Text';

type PropsType = {
  announcement: AnnouncementServerType;
};

const AnnouncementCard: React.FC<PropsType> = ({ announcement }) => {
  const navigate = useNavigate();

  const onDetails = () => {
    navigate(`${PageRoutesEnum.Learning_Announcements}/${announcement._uuid}`);
  };

  return (
    <Card verticalSpace="l" horizontalSpace="l" shadow={false} border={true} className={classes.container}>
      <div className={classes.badgesContainer}>
        {announcement.format && <Badge status="normal" view="stroked" size="s" label={announcement.format} />}
        {announcement.place_name && <Badge status="success" view="stroked" size="s" label={announcement.place_name} />}
      </div>
      <Text className={classes.title}>{announcement.name}</Text>
      <Text className={classes.date} view={'secondary'}>
        {announcement.start_date ? formatDateToDDMMHHMM(announcement.start_date) : 'Дата еще не назначена'}
        {announcement.finish_date ? (
          <>
            &nbsp;-&nbsp;
            {formatDateToDDMMHHMM(announcement.finish_date)}
          </>
        ) : (
          ''
        )}
      </Text>
      <Text className={classes.tags} view={'secondary'}>
        {announcement.tags?.map((tag) => `${tag.name}; `)}
      </Text>
      <div className={classes.buttonRow}>
        <div>
          <Button view="secondary" label="Подробнее" size={'s'} onClick={onDetails} />
        </div>
        <div>
          <Button view="secondary" label="Записаться" size={'s'} onClick={onDetails} />
        </div>
      </div>
    </Card>
  );
};

export default AnnouncementCard;
