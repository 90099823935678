import React from 'react';

import { MetricItem } from '../../../types/types';
import classes from './PerformanceItem.module.css';
import { Text } from '@consta/uikit/Text';

type PropsType = {
  productPerformance: MetricItem;
};

const PerformanceItem: React.FC<PropsType> = ({ productPerformance }) => {
  return (
    <div className={classes.container}>
      <Text className={classes.specification} size="2xl" view="brand">
        {productPerformance.value}
      </Text>
      <Text size="m" className={classes.description} view="secondary">
        {productPerformance.name}
      </Text>
    </div>
  );
};

export default PerformanceItem;
