import React from 'react';

import { useAppSelector } from '../../../../hooks/hooks';
import { useGetBlogItems } from '../../../../hooks/useGetBlogItems';
import Pagination from '../../../../shared-front/Pagination/Pagination';
import { selectorViewSelectorBlogItems } from '../../../../store/adminBlogSlice';
import classes from './AdminBlogPagePaginationRow.module.css';

interface IAdminBlogPagePaginationRowProps {}

const AdminBlogPagePaginationRow: React.FC<IAdminBlogPagePaginationRowProps> = () => {
  const getBlogItems = useGetBlogItems();
  const pagination = useAppSelector(selectorViewSelectorBlogItems)?.meta;

  const onChangePagination = (queryPagination: string) => {
    getBlogItems({ queryPagination });
  };

  return (
    <div className={classes.container}>
      <Pagination
        currentPageSize={pagination?.per_page}
        currentPage={pagination?.current_page}
        total={pagination?.total}
        className={classes.pagination}
        onChangePagination={onChangePagination}
        lastPage={pagination?.last_page}
      />
    </div>
  );
};

export default AdminBlogPagePaginationRow;
