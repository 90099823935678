import React from 'react';

import classes from './ReportsHero.module.css';
import { Button } from '@consta/uikit/Button';
import { IconForward } from '@consta/uikit/IconForward';

const ReportsHero: React.FC = () => {
  return (
    <div className={classes.container}>
      <div className={classes.contentContainer}>
        <div className={classes.infoContainer}>
          <div className={classes.title}>Отчеты</div>
          <div className={classes.subtitle}>{'Показываем статистику по ключевым показателями функции'}</div>
          <div className={classes.button}>
            <Button
              size="m"
              form="round"
              view="primary"
              label="Перейти к отчетам"
              iconRight={IconForward}
              onClick={() =>
                window.scrollTo({
                  top: 1050,
                  behavior: 'smooth',
                })
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportsHero;
