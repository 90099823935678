import React from 'react';
import { useNavigate } from 'react-router-dom';

import { BlogEventEnum } from '../../../../../../api/blogServerResponse';
import { AnnouncementServerType } from '../../../../../../api/serverResponse';
import { PageRoutesEnum } from '../../../../../../appMenuAndRouting/appRoutes';
import { getTimeDateForBlogEvent } from '../../../../../../utils/blogUtils/getTimeDateForBlogEvent';
import classes from './AnnounceCalendarItem.module.css';
import { Button } from '@consta/uikit/Button';
import { Text } from '@consta/uikit/Text';

interface IAnnounceCalendarItemProps {
  announcement: AnnouncementServerType;
}

const AnnounceCalendarItem: React.FC<IAnnounceCalendarItemProps> = ({ announcement }) => {
  const navigate = useNavigate();

  const onClick = () => {
    navigate(`${PageRoutesEnum.Blog}/${BlogEventEnum.announcement}/${announcement._uuid}`);
  };

  return (
    <div className={classes.container}>
      {announcement.start_date && (
        <Text className={classes.date}>{getTimeDateForBlogEvent(announcement.start_date)}</Text>
      )}
      {announcement.name && <Text className={classes.title}>{announcement.name}</Text>}
      <div className={classes.rowBottom}>
        <Button label={`Перейти к анонсу`} view="clear" className={classes.btnReadMore} size={'s'} onClick={onClick} />
      </div>
    </div>
  );
};

export default AnnounceCalendarItem;
