import { serverApi } from '../api/serverApi';
import { getRejectedValue } from './rejectedValueHelper';
import { RootState } from './store';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { StoreSnackBarItemType } from '../types/types';

type AppSliceType = {
  userInfo: any | undefined;
  isLoadingUserInfo: boolean;
  isErrorUserInfo: string | undefined;
  error: string | undefined;
  isAdminMetric: boolean;
  isAdminReport: boolean;
  isAdminBlog: boolean;
  isAdminTeam: boolean;
  isAdminContacts: boolean;
  snackBarItems: StoreSnackBarItemType[];
};

const initialState: AppSliceType = {
  userInfo: undefined,
  isAdminMetric: false,
  isAdminContacts: false,
  isAdminReport: false,
  isAdminBlog: false,
  isAdminTeam: false,
  isLoadingUserInfo: false,
  isErrorUserInfo: undefined,
  snackBarItems: [],
  error: undefined,
};

export const getUserInfoThunk = createAsyncThunk<any, undefined, { rejectValue: string }>(
  'app/getUserInfoThunk',
  async (_, { rejectWithValue }) => {
    try {
      return await serverApi.getUserInfo();
    } catch (e) {
      return rejectWithValue(getRejectedValue('Ошибка получения данных пользователя', e));
    }
  }
);

export const userToggleSubscribe = createAsyncThunk<any, undefined, { rejectValue: string }>(
  'app/userToggleSubscribe',
  async (_, { rejectWithValue }) => {
    try {
      return await serverApi.toggleNewsSubscribe();
    } catch (e) {
      return rejectWithValue(getRejectedValue('Ошибка подписки пользователя', e));
    }
  }
);

export const appSlice = createSlice({
  name: 'appSlice',
  initialState,
  reducers: {
    setAppError: (state, action) => {
      state.error = action.payload;
    },
    resetAppError: (state) => {
      state.error = undefined;
    },
    clearAppError: (state) => {
      state.isErrorUserInfo = undefined;
    },
    clearSnackBarItemsReports: (state) => {
      state.snackBarItems = [];
    },
    deleteSnackBarItemReports: (state, action) => {
      state.snackBarItems = state.snackBarItems.filter((item) => item.key !== action.payload.key);
    },
    pushSnackBarItemReports: (state, action) => {
      state.snackBarItems.push({
        key: action.payload.key ? action.payload.key : action.payload.message + action.payload.status,
        message: action.payload.message,
        status: action.payload.status,
        autoClose: action.payload.autoClose ? 5 : undefined,
      });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserInfoThunk.fulfilled, (state, action) => {
        state.isLoadingUserInfo = false;
        state.userInfo = action.payload.data;
        const groups = state.userInfo.groups;
        if (state.userInfo.groups.length) {
          const isPortalAdmin = !!groups.find((el: any) => el.slug === 'portal_admin');
          const isAdminBlog = !!groups.find((el: any) => el.slug === 'blog_admin');
          const isAdminMetric = !!groups.find((el: any) => el.slug === 'portal_admin');
          const isAdminReport = !!groups.find((el: any) => el.slug === 'adminReport');
          const isAdminTeam = !!groups.find((el: any) => el.slug === 'team_admin');
          if (isPortalAdmin) {
            state.isAdminBlog = true;
            state.isAdminMetric = true;
            state.isAdminBlog = true;
            state.isAdminReport = true;
            state.isAdminTeam = true;
            state.isAdminContacts = true;
          }
          if (isAdminBlog) {
            state.isAdminBlog = true;
          }
          if (isAdminMetric) {
            state.isAdminMetric = true;
          }
          if (isAdminReport) {
            state.isAdminReport = true;
          }
          if (isAdminTeam) {
            state.isAdminTeam = true;
          }
        }
      })
      .addCase(getUserInfoThunk.pending, (state) => {
        state.userInfo = undefined;
        state.isErrorUserInfo = undefined;
        state.isLoadingUserInfo = true;
      })
      .addCase(getUserInfoThunk.rejected, (state, action) => {
        state.userInfo = undefined;
        state.isLoadingUserInfo = false;
        state.isErrorUserInfo = action.payload ? action.payload : 'Неизвестная ошибка - appSlice';
      });
  },
});

export const {
  setAppError,
  resetAppError,
  clearSnackBarItemsReports,
  deleteSnackBarItemReports,
  pushSnackBarItemReports,
} = appSlice.actions;

export const selectorAppError = (state: RootState) => state.app.error;

export const { clearAppError } = appSlice.actions;

export const selectorUserInfo = (state: RootState) => state.app.userInfo;
export const selectorIsAdminReports = (state: RootState) => state.app.isAdminReport;
export const selectorIsAdminBlog = (state: RootState) => state.app.isAdminBlog;
export const selectorIsAdminTeam = (state: RootState) => state.app.isAdminTeam;
export const selectorIsAdminContacts = (state: RootState) => state.app.isAdminContacts;
export const selectorSnackBarItemsReports = (state: RootState) => state.app.snackBarItems;
export const selectorIsAdminMetric = (state: RootState) => state.app.isAdminMetric;
export const selectorIsLoadingUserInfo = (state: RootState) => state.app.isLoadingUserInfo;
export const selectorIsErrorUserInfo = (state: RootState) => state.app.isErrorUserInfo;

export default appSlice.reducer;
