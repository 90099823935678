import React, { useState } from 'react';

import { BlogItemType } from '../../../../../../types/types';
import classes from './AdminBlogSubscriptionActions.module.css';
import { Button } from '@consta/uikit/Button';
import { IconEdit } from '@consta/uikit/IconEdit';
import { IconStorage } from '@consta/uikit/IconStorage';
import { IconTrash } from '@consta/uikit/IconTrash';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useAppDispatch } from '../../../../../../hooks/hooks';
import {
  archiveBlogNewsThunk,
  deleteBlogNewsThunk,
  deleteBlogSubscriptionThunk,
  getAdminBlogItemsThunk,
  getAdminBlogSubscriptionThunk,
} from '../../../../../../store/adminBlogSlice';
import ReportsModalDelete from '../../../../../../Components/Reports/ReportsModalDelete/ReportsModalDelete';
import BlogModalDelete from '../../../BlogModalDelete/BlogModalDelete';
import { useGetBlogItems } from '../../../../../../hooks/useGetBlogItems';
import { pushSnackBarItemReports } from '../../../../../../store/appSlice';

interface IAdminBlogTableActionsProps {
  item: BlogItemType;
}

const AdminBlogSubscriptionTableActions: React.FC<IAdminBlogTableActionsProps> = ({ item }) => {
  const dispatch = useAppDispatch();
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);

  const onDelete = (uuid: string) => {
    dispatch(deleteBlogSubscriptionThunk(item._uuid)).then((data) => {
      setIsOpenDeleteModal(false);
      dispatch(getAdminBlogSubscriptionThunk()).then();
      dispatch(
        pushSnackBarItemReports({
          key: Math.random(),
          message: `Подписка удалена!`,
          status: 'success',
          autoClose: 5,
        })
      );
    });
  };

  return (
    <div className={classes.container}>
      <Button size="xs" view="ghost" iconLeft={IconTrash} onlyIcon onClick={() => setIsOpenDeleteModal(true)} />
      <BlogModalDelete
        isModalOpen={isOpenDeleteModal}
        setIsModalOpen={setIsOpenDeleteModal}
        modalDeleteTitle={'Вы действительно хотите удалить подписчика?'}
        onConfirmDelete={() => onDelete(item._uuid)}
      />
    </div>
  );
};

export default AdminBlogSubscriptionTableActions;
