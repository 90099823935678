import React from 'react';

import LayoutBanneredPage from '../../Components/LayoutBanneredPage/LayoutBanneredPage';
import LearningAllVideosControl from './LearningAllVideosControl/LearningAllVideosControl';
import LearningAllVideosHeader from './LearningAllVideosHeader.tsx/LearningAllVideosHeader';
import LearningAllVideosList from './LearningAllVideosList/LearningAllVideosList';

const LearningAllVideosPage: React.FC = () => {
  return (
    <LayoutBanneredPage pageHeader={<LearningAllVideosHeader />}>
      <LearningAllVideosControl />
      <LearningAllVideosList />
    </LayoutBanneredPage>
  );
};

export default LearningAllVideosPage;
