import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';

import 'normalize.css';
import './index.css';

import App from './Components/App/App';
import { store } from './store/store';

const container = document.getElementById('root')!;
const root = createRoot(container);

export const CLIENT_VERSION = '1.1.1';

console.log(`client version =${CLIENT_VERSION}`);

root.render(
  <Provider store={store}>
    <App />
  </Provider>
);
