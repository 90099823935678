import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import './fixTab.css';

import { MenuItemType } from '../types/types';
import UserModal from '../UserModal/UserModal';
import classes from './AppHeader.module.css';
import AppHeaderMenuItem from './AppHeaderMenuItem/AppHeaderMenuItem';
import Logo from './Logo/Logo';
import { PortalsEnum, sharedSideBarMenuItems } from './sharedSideBarMenuItems';
import { getMenuItem, scrollToTop } from './utils/utils';
import { Button } from '@consta/uikit/Button';
// import { IconKebab } from '@consta/uikit/IconKebab';
import { ContextMenu } from '@consta/uikit/ContextMenu';
import { IconComponent } from '@consta/uikit/Icon';
import { IconOperators } from '@consta/uikit/IconOperators';
import { IconQuestion } from '@consta/uikit/IconQuestion';
import { RenderItemProps, Tabs } from '@consta/uikit/Tabs';
import { useFlag } from '@consta/uikit/useFlag';
import { Tooltip } from '@consta/uikit/Tooltip';
import { Text } from '@consta/uikit/Text';

type ManualMenuItem = {
  label: string;
  icon: IconComponent;
  status?: string;
  value: string;
};

type PropsType = {
  portal: PortalsEnum;
  appHeaderMenuItems: MenuItemType[];
  appSideBarMenuItems: MenuItemType;
  onLogoClickRoute?: string;
  onLogoClick?: () => void;
  authMode?: boolean;
  dqBarComponent?: React.FC;
  appLogo?: React.ReactNode;
  manuals?: ManualMenuItem[];
  manualsTooltip?: string;
  customLogo?: string;
  resetTabSelection?: boolean;
};

const AppHeader: React.FC<PropsType> = ({
  portal,
  appHeaderMenuItems,
  appSideBarMenuItems,
  onLogoClickRoute,
  onLogoClick,
  authMode = false,
  dqBarComponent,
  manuals,
  manualsTooltip,
  resetTabSelection,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isOpenUser, setIsOpenUser] = useState<boolean>(false);
  const [isOpenContextMenu, setIsOpenContextMenu] = useState(false);
  const [isTooltipVisible, setIsTooltipVisible] = useFlag(false);
  const activeMenuItem = appHeaderMenuItems.findIndex(
    (menuItem) => menuItem.href?.split('?')[0].split('/')[1] === location.pathname.split('/')[1]
  );
  const [value, setValue] = useState(appHeaderMenuItems[activeMenuItem > -1 ? activeMenuItem : 0]);
  const manualRef = useRef<HTMLButtonElement>(null);
  const renderLeftSideItem = (item: any) => {
    const Icon = item.icon;
    return <Icon size="s" view={item.status} />;
  };
  const onChange = (value: MenuItemType) => {
    if (value.href && value.href !== location.pathname && !value.disabled) {
      const matchedMenuitem = getMenuItem(value.href, appHeaderMenuItems);
      if (matchedMenuitem && !matchedMenuitem?.children) {
        setValue(value);
        scrollToTop();
        navigate(value.href);
      }
    } else if (value.extUrl && !value.disabled) {
      window.open(value.extUrl, '_blank', 'noreferrer');
    }
  };

  useEffect(() => {
    if (location.pathname !== value?.href) {
      const activeMenuItem = appHeaderMenuItems.findIndex((menuItem) => {
        return menuItem.href?.split('/')[1] === location.pathname.split('/')[1];
      });
      if (activeMenuItem > -1) {
        setValue(appHeaderMenuItems[activeMenuItem]);
        scrollToTop();
      }
    }
  }, [location.pathname, value?.href, appHeaderMenuItems]);

  const sideBarMenuItemsNew = sharedSideBarMenuItems.map((sharedSideBarMenuItem) => {
    if (sharedSideBarMenuItem.href === portal) {
      return appSideBarMenuItems;
    } else {
      return sharedSideBarMenuItem;
    }
  });

  const tabValue = resetTabSelection ? null : value;

  const [hasHiddenMenuItems, setHasHiddenMenuItems] = useState(false);

  const renderItem = useCallback(
    ({ item, checked, onChange }: RenderItemProps<MenuItemType>) => (
      <AppHeaderMenuItem item={item} checked={checked} onChange={onChange} hasHiddenMenuItems={hasHiddenMenuItems} />
    ),
    [hasHiddenMenuItems]
  );
  const onItemClick = (item: ManualMenuItem) => {
    window.open(item.value, '_blank');
    return () => setIsOpenContextMenu(false);
  };

  const ref = useCallback((node: HTMLDivElement | null) => {
    if (node !== null) {
      const element = node.getElementsByClassName('TabsFitModeDropdownWrapper-MoreItems')[0];
      const hiddenItemsButton = node.getElementsByClassName('TabsMoreItems-Button')[0];
      setTimeout(() => {
        if (hiddenItemsButton?.getAttribute('style')) {
          hiddenItemsButton.classList.add(classes.hiddenItemsButton);
        }
      }, 0);
      setTimeout(() => {
        if (element?.getAttribute('style')) {
          setHasHiddenMenuItems(true);
        } else {
          setHasHiddenMenuItems(false);
        }
      }, 250);
    }
  }, []);

  return (
    <div className={classes.layout}>
      <Logo
        portal={portal}
        sideBarMenuItems={sideBarMenuItemsNew}
        onLogoClickRoute={onLogoClickRoute}
        onLogoClick={onLogoClick}
      />
      <div className={classes.tabsWrapper} ref={ref}>
        <Tabs
          value={tabValue}
          onChange={({ value }) => onChange(value)}
          items={appHeaderMenuItems}
          view={'clear'}
          size={'m'}
          renderItem={renderItem}
        />
      </div>
      {dqBarComponent && dqBarComponent({})}
      {authMode && (
        <div className={classes.btnWrapper}>
          <Button size="s" view="ghost" iconLeft={IconOperators} onClick={() => setIsOpenUser(true)} />
          <UserModal isOpen={isOpenUser} setIsOpen={setIsOpenUser} />
        </div>
      )}
      {manuals && (
        <>
          <Button
            size="s"
            form="round"
            view="ghost"
            onlyIcon
            iconRight={IconQuestion}
            className={classes.manual}
            ref={manualRef}
            onMouseEnter={setIsTooltipVisible.toggle}
            onMouseLeave={setIsTooltipVisible.toggle}
            onClick={() => setIsOpenContextMenu((prev) => !prev)}
          />
          <ContextMenu
            items={manuals}
            isOpen={isOpenContextMenu}
            getItemLeftSide={renderLeftSideItem}
            anchorRef={manualRef}
            className={classes.manuals}
            getItemOnClick={(item) => onItemClick(item)}
            direction="downStartRight"
            onClickOutside={() => setIsOpenContextMenu(false)}
          />

          {manualsTooltip && isTooltipVisible && (
            <Tooltip
              direction="upCenter"
              placeholder=""
              spareDirection="downStartLeft"
              size={'s'}
              isInteractive={false}
              anchorRef={manualRef}
              className={classes.tooltip}
            >
              <Text view="primary" lineHeight="m" size="xs">
                {manualsTooltip}
              </Text>
            </Tooltip>
          )}
        </>
      )}
    </div>
  );
};

export default AppHeader;
