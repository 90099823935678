import React from 'react';

import { ProductComponentType } from '../../types/types';
import ProductComponentCard from '../ProductComponentCard/ProductComponentCard';
import classes from './SectionProductComponents.module.css';

type PropsType = {
  components: ProductComponentType[];
};

const SectionProductComponents: React.FC<PropsType> = ({ components }) => {
  return (
    <div className={classes.container}>
      <div className={classes.contentContainer}>
        <div className={classes.title}>{'Компоненты продукта'}</div>
        <div className={classes.thead}>
          <div className={classes.thProduct}></div>
          <div className={classes.thCurrent}>{'Текущие'}</div>
          <div className={classes.thFuture}>{'Целевые'}</div>
        </div>
        <div className={classes.componentsList}>
          {components?.map((component, ind) => (
            <ProductComponentCard key={ind} component={component} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default SectionProductComponents;
