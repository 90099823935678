import React, { useEffect, useState } from 'react';

import classes from './SearchContainer.module.css';
import { Button } from '@consta/uikit/Button';
import { FieldGroup } from '@consta/uikit/FieldGroup';
import { IconSearch } from '@consta/uikit/IconSearch';
import { TextField } from '@consta/uikit/TextField';

interface ISearchContainerProps {
  value: string;
  onSearch: (val: string) => void;
  placeHolder?: string;
  size?: string;
}

const SearchContainer: React.FC<ISearchContainerProps> = ({ value, placeHolder, onSearch, size = 's' }) => {
  const [searchValue, setSearchValue] = useState(value);

  const onChangeHandler = ({ value }: { value: string | null }) => {
    if (value) {
      setSearchValue(value);
    } else {
      setSearchValue('');
    }
  };

  const onSearchHandler = () => {
    onSearch(searchValue);
  };

  const handleKeypress = (e: React.KeyboardEvent) => {
    if (e.code === 'Enter') {
      onSearchHandler();
    }
  };

  useEffect(() => {
    setSearchValue(value);
  }, [value]);

  return (
    <div className={classes.container}>
      <FieldGroup size={size === 'm' ? 'm' : 's'} style={{ width: '400px' }}>
        <TextField
          value={searchValue}
          onChange={onChangeHandler}
          type="text"
          placeholder={placeHolder}
          style={{ width: '100%' }}
          leftSide={IconSearch}
          onKeyPress={handleKeypress}
          withClearButton={true}
        />
        <Button label="Найти" view={'secondary'} onClick={onSearchHandler} />
      </FieldGroup>
    </div>
  );
};

export default SearchContainer;
