import React, { useEffect } from 'react';

import LayoutBanneredPage from '../../Components/LayoutBanneredPage/LayoutBanneredPage';
import ModalError from '../../Components/ModalError/ModalError';
import { useAppDispatch, useAppSelector } from '../../hooks/hooks';
import {
  getAnnouncementsThunk,
  getCoursesThunk,
  getVideosThunk,
  selectorLearningError,
} from '../../store/learningSlice';
import LearningCourses from './LearningCourses/LearningCourses';
import LearningPageBanner from './LearningPageBanner/LearningPageBanner';
import LearningPageHeader from './LearningPageHeader/LearningPageHeader';
import LearningQuestions from './LearningQuestions/LearningQuestions';
import LearningTeam from './LearningTeam/LearningTeam';
import LearningVideos from './LearningVideos/LearningVideos';

const LearningPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const learningError = useAppSelector(selectorLearningError);

  useEffect(() => {
    dispatch(getAnnouncementsThunk());
    dispatch(getCoursesThunk());
    dispatch(getVideosThunk());
  }, [dispatch]);

  return (
    <LayoutBanneredPage pageHeader={<LearningPageHeader />}>
      <LearningCourses />
      <LearningPageBanner />
      <LearningVideos />
      <LearningTeam />
      <LearningQuestions />
      <ModalError errorMessage={learningError} />
    </LayoutBanneredPage>
  );
};

export default LearningPage;
