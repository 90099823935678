import React from 'react';
import { Link } from 'react-router-dom';

import { PageRoutesEnum } from '../../../appMenuAndRouting/appRoutes';
import blogBanner from '../../../img/mainPage/blogBanner.png';
import classes from './VisitBlogBanner.module.css';
import { Button } from '@consta/uikit/Button';
import { Text } from '@consta/uikit/Text';

const VisitBlogBanner = () => {
  return (
    <div className={classes.container}>
      <img src={blogBanner} alt="blogBanner" />
      <div className={classes.leftColumn}>
        <div className={classes.topPart}>
          <Text className={classes.title}>{`Посетите наш блог`}</Text>
          <Text
            className={classes.description}
          >{`Делимся новостями, анонсами и успешными кейсами\nфункции управления данными`}</Text>
        </div>
        <div className={classes.bottomPart}>
          <Link to={PageRoutesEnum.Blog}>
            <Button label={'Перейти'} form="round" size={'m'} />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default VisitBlogBanner;
