import React from 'react';

import digest_test from './digest_test.png';
import classes from './DigestsBlock.module.css';
import { Button } from '@consta/uikit/Button';
import { Text } from '@consta/uikit/Text';

const DigestsBlock: React.FC = () => {
  return (
    <div className={classes.container}>
      <Text className={classes.title}>{`Дайджесты функции УпД`}</Text>
      <div className={classes.imgContainer}>
        <img src={digest_test} alt="digest" />
      </div>
      <Text className={classes.subTitle}>{`Выпуск №1`}</Text>
      <div className={classes.bottom}>
        <div className={classes.btnSelector}>
          <button className={classes.button}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path
                d="M20 11H7.83L13.42 5.41L12 4L4 12L12 20L13.41 18.59L7.83 13H20V11Z"
                fill="#00395C"
                fillOpacity="0.8"
              />
            </svg>
          </button>
          <Text className={classes.info}>{`1 из 1`}</Text>
          <button className={classes.button}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path
                d="M12 4L10.59 5.41L16.17 11H4V13H16.17L10.59 18.59L12 20L20 12L12 4Z"
                fill="#00395C"
                fillOpacity="0.8"
              />
            </svg>
          </button>
        </div>

        {/*<Button label={`Читать дальше`} view="clear" className={classes.btnReadMore} size={'s'} disabled />*/}
      </div>
    </div>
  );
};

export default DigestsBlock;
