import React, { useEffect } from 'react';

export const useClickOutsideComponentWithDomException = (
  ref: React.RefObject<HTMLElement>,
  callback?: () => void,
  refException?: React.RefObject<HTMLElement>,
  querySelectorException?: string
) => {
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (querySelectorException && !document.querySelector(querySelectorException)) {
        if (ref.current && !ref.current.contains(event.target as Node)) {
          if (refException?.current) {
            if (!refException.current.contains(event.target as Node)) {
              callback && callback();
            }
          } else {
            callback && callback();
          }
        }
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, refException, callback, querySelectorException]);
};
