import React from 'react';

import { BlogAnnouncementType } from '../../../../../api/blogServerResponse';
import BlogContentInfoRow from '../../../../../Components/BlogContentInfoRow/BlogContentInfoRow';
import BlogContentText from '../../../../../Components/BlogContentText/BlogContentText';
import AnnounceImage from './AnnounceImage/AnnounceImage';
import AnnounceInfo from './AnnounceInfo/AnnounceInfo';
import AnnounceSpeakers from './AnnounceSpeakers/AnnounceSpeakers';
import classes from './LeftColumn.module.css';

interface ILeftColumnProps {
  announce: BlogAnnouncementType | undefined;
}

const LeftColumn: React.FC<ILeftColumnProps> = ({ announce }) => {
  return (
    <div className={classes.container}>
      <BlogContentInfoRow
        format={announce?.format}
        title={announce?.name}
        date={announce?.publication_date!}
        type={'Анонс'}
        tags={announce?.tags}
      />
      {announce?.picture && <AnnounceImage picture={announce.picture} />}
      <BlogContentText text={announce?.description} />
      <AnnounceInfo announce={announce} />
      <AnnounceSpeakers speakers={announce?.instructors} />
    </div>
  );
};

export default LeftColumn;
