import React from 'react';
import { useSearchParams } from 'react-router-dom';

import './constaFix.css';

import { MethodologyDocumentServerType, MethodologyProcessServerType } from '../../../../../api/serverResponse';
import { useAppSelector } from '../../../../../hooks/hooks';
import { selectorMethodologyProcesses } from '../../../../../store/methodologySlice';
import { formatDateToDDMMYYYY } from '../../../../../utils/formatDateTimeUtils';
import {
  getBadgeStatus,
  getDocumentAuthorNameAndPosition,
  getDocumentTags,
  getParentProcessName,
  getPersonRoles,
  getSelectedProcess,
} from '../../../../../utils/methodology';
import DocumentTagsList from '../../RightColum/DocumentsCardsList/MethodologyDocument/DocumentTagsList/DocumentTagsList';
import classes from './ProcessCardsModal.module.css';
import { Badge } from '@consta/uikit/Badge';
import { Breadcrumbs } from '@consta/uikit/Breadcrumbs';
import { IconConnection } from '@consta/uikit/IconConnection';
import { IconDocBlank } from '@consta/uikit/IconDocBlank';
import { IconThumbUp } from '@consta/uikit/IconThumbUp';
import { Text } from '@consta/uikit/Text';
import classNames from 'classnames';
import { PersonRolesType } from '../../../../../types/types';

const getParentProcess = (
  uuid_process_parent: string | null | undefined,
  processes: MethodologyProcessServerType[]
) => {
  return processes.find((process) => process._uuid === uuid_process_parent);
};

const getToolTipTile = (document: MethodologyDocumentServerType, processes: MethodologyProcessServerType[]) => {
  const levels: string[] = [];
  let uuid_process_parent = document.v_dct_processes?.uuid_process_parent;
  while (uuid_process_parent) {
    const parentProcess = getParentProcess(uuid_process_parent, processes);
    if (parentProcess?.name) {
      levels.push(`${parentProcess.code} ${parentProcess.name}`);
    }
    uuid_process_parent = parentProcess?.uuid_process_parent;
  }
  const items: { label: string }[] = [];
  let title = '';
  levels.forEach((level) => {
    title += `/ ${level}\n`;
    items.push({ label: level });
  });
  title += `/ ${document.v_dct_processes?.code} ${document.v_dct_processes?.name}\n`;
  items.push({ label: `${document.v_dct_processes?.code} ${document.v_dct_processes?.name}` });
  return items;
};

type PropsType = {
  process: MethodologyProcessServerType;
};

const ProcessCardsModal: React.FC<PropsType> = ({ process }) => {
  const [searchParams] = useSearchParams();
  const processes = useAppSelector(selectorMethodologyProcesses);
  const personRoles = getPersonRoles(process);
  const processOwner = personRoles.find((person) => person.role === 'Владелец процесса');
  const competenceCenter = personRoles.find((person) => person.role?.includes('Центр компетенции'));
  const parentProcessName = getParentProcessName(process?.uuid_process_parent, processes);

  const PersonRole = ({ personRole }: { personRole: PersonRolesType }) => {
    return (
      <div className={classes.splitRowPart}>
        <Text size={'s'} weight={'bold'} className={classes.sectionTitle}>
          {personRole.role}
        </Text>
        <Text size={'s'} className={classes.titleName}>
          {personRole.name}
        </Text>
        <Text size={'xs'} className={classes.titlePosition}>
          {personRole.position}
        </Text>
        <a
          href={`mailto:${personRole.email}?subject=Вопрос по процессу: ${process?.name}`}
          target={'_blank'}
          className={classes.linkToEmail}
          rel="noreferrer"
        >
          {personRole.email}
        </a>
      </div>
    );
  };

  return (
    <div className={classes.container}>
      <Text size="xl" className={classes.documentTitle} weight={'bold'}>
        {process.name}
      </Text>
      <div className={classes.wrapper}>
        <div className={classes.container}>
          <div className={classes.splitRow}>
            {processOwner ? (
              <PersonRole personRole={processOwner} />
            ) : (
              <div className={classes.splitRowPart}>
                <Text size={'s'} weight={'bold'} className={classes.sectionTitle}>
                  Владелец процесса
                </Text>
                <Text size={'s'} className={classes.titleName}>
                  -
                </Text>
              </div>
            )}
            {competenceCenter ? (
              <PersonRole personRole={competenceCenter} />
            ) : (
              <div className={classes.splitRowPart}>
                <Text size={'s'} weight={'bold'} className={classes.sectionTitle}>
                  Центр компетенции
                </Text>
                <Text size={'s'} className={classes.titleName}>
                  -
                </Text>
              </div>
            )}
          </div>
          <div className={classes.splitRow}>
            <div className={classes.splitRowPart}>
              <Text size={'s'} weight={'bold'} className={classes.sectionTitle}>
                {'Основные этапы процесса'}
              </Text>
              <Text size={'s'} className={classes.description}>
                {process?.major_stages ? process.major_stages : '-'}
              </Text>
            </div>
            <div className={classes.splitRowPart}>
              <Text size={'s'} weight={'bold'} className={classes.sectionTitle}>
                {'Метрики процесса'}
              </Text>
              <Text size={'s'} className={classes.description}>
                {process?.metrics ? process.metrics : '-'}
              </Text>
            </div>
          </div>
          <div className={classes.row}>
            <Text size={'s'} weight={'bold'} className={classes.sectionTitle}>
              {'Вышестоящие процессы'}
            </Text>
            <Text size={'s'} className={classes.description}>
              {parentProcessName ? parentProcessName : '-'}
            </Text>
          </div>
          <div className={classes.row}>
            <Text size={'s'} weight={'bold'} className={classes.sectionTitle}>
              {'Краткое описание БП'}
            </Text>
            <Text size={'s'} className={classes.description}>
              {process?.description}
            </Text>
          </div>
          {process?.picture && <img src={process.picture} className={classes.img} alt="process" />}
        </div>
      </div>
      <div className={classes.delimiter} />
    </div>
  );
};

export default ProcessCardsModal;
