import React from 'react';

import { InfoLinkType } from '../../types/types';
import { openURLinNewTab } from '../../utils/openURLinNewTab';
import classes from './InfoLinkCard.module.css';

type PropsType = {
  infoLink: InfoLinkType;
};

const InfoLinkCard: React.FC<PropsType> = ({ infoLink }) => {
  const onClick = () => {
    openURLinNewTab(infoLink.url);
  };

  return (
    <div className={classes.container}>
      <div className={classes.title} onClick={onClick}>
        {infoLink.title} →
      </div>
      {infoLink.description && <div className={classes.description}>{infoLink.description}</div>}
    </div>
  );
};

export default InfoLinkCard;
