import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { MenuItemType } from '../../../../../types/types';
import { hasActiveChildren } from '../../../../utils/utils';
import classes from './SidebarSubMenuItem.module.css';
import { IconArrowDown } from '@consta/uikit/IconArrowDown';
import { Text } from '@consta/uikit/Text';
import classNames from 'classnames';

type PropsType = {
  item: MenuItemType;
  level: number;
  closeSidebar: () => void;
};

const SidebarSubMenuItem: React.FC<PropsType> = ({ item, level, closeSidebar }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [showChildren, setShowChildren] = useState(false);

  useEffect(() => {
    setShowChildren(hasActiveChildren(location.pathname, item));
  }, [location.pathname, item]);

  const onClick = () => {
    if (item.children) {
      setShowChildren((prev) => !prev);
    } else {
      if (item.href && !item.disabled) {
        navigate(item.href);
        closeSidebar();
      } else if (item.extUrl && !item.disabled) {
        window.open(item.extUrl, '_blank', 'noreferrer');
        closeSidebar();
      }
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.row} onClick={onClick}>
        <div style={{ width: `${(level - 1) * 20}px` }} />
        <Text
          className={classNames(classes.title, {
            [classes.titleWithChildren]: item.children,
            [classes.titleWithoutChildren]: !item.children,
            [classes.disabled]: item.disabled,
          })}
          truncate
        >
          {item.label}
        </Text>
        {item.children && (
          <IconArrowDown
            view="primary"
            size="s"
            className={classNames(classes.ico, { [classes.rotateIco]: showChildren })}
          />
        )}
      </div>
      {showChildren &&
        item.children &&
        item.children.map((item, ind) => (
          <SidebarSubMenuItem key={ind} item={item} level={level + 1} closeSidebar={closeSidebar} />
        ))}
    </div>
  );
};

export default SidebarSubMenuItem;
