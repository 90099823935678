import React, { useCallback, useEffect, useState } from 'react';

import { reportsApi } from '../../../api/reportsApi';
import { ReportsDictionaryItemType, ReportsDictionaryType, ReportsItemType } from '../../../types/types';
import { prepareReportAction } from '../../../utils/prepareReportAction';
import { CustomEditor } from '../../CustomEditor/CustomEditor';
import classes from './ReportsForm.module.css';
import { Button } from '@consta/uikit/Button';
import { Combobox } from '@consta/uikit/Combobox';
import { IconAdd } from '@consta/uikit/IconAdd';
import { IconTrash } from '@consta/uikit/IconTrash';
import { TextField } from '@consta/uikit/TextField';
import { useAppDispatch } from '../../../hooks/hooks';
import { pushSnackBarItemReports } from '../../../store/appSlice';
import { IconClose } from '@consta/uikit/IconClose';

type PropType = {
  onCancel: () => void;
  dictionaries: ReportsDictionaryType;
  refetch: () => void;
  item?: ReportsItemType | null;
};
const ReportsForm: React.FC<PropType> = ({ onCancel, dictionaries, refetch, item }) => {
  const dispatch = useAppDispatch();
  const [blocks, setBlocks] = useState<ReportsDictionaryItemType[] | null>();
  const [systems, setSystems] = useState<ReportsDictionaryItemType | null>();
  const [dataServices, setDataServices] = useState<ReportsDictionaryItemType[] | null>();
  const [link, setLink] = useState<string | null>();
  const [name, setName] = useState<string | null>();
  const [ad, setAd] = useState<string[] | [] | null>([]);
  const [manual, setManual] = useState<string | null>('');
  useEffect(() => {
    if (item?.blocks) {
      setBlocks(dictionaries.blocks?.filter((el) => item.blocks?.find((block) => el?._uuid === block._uuid)));
    }
    if (item?.system) {
      setSystems(dictionaries.systems?.find((el) => item.system?._uuid === el._uuid));
    }
    if (item?.data_services) {
      setDataServices(
        dictionaries.dataServices?.filter((el) =>
          item.data_services?.find((dataService) => el?._uuid === dataService._uuid)
        )
      );

      if (item?.link) {
        setLink(item.link);
      }
      if (item?.name) {
        setName(item.name);
      }
      if (item?.ad_groups) {
        setAd(item?.ad_groups);
      }
      if (item?.manual) {
        setManual(item.manual);
      }
    }
  }, [item, dictionaries]);
  const create = useCallback(async () => {
    const params = prepareReportAction(blocks, systems, dataServices, link, name, ad, manual);
    if (item) {
      await reportsApi.updateReport(params, item?.uuid);
      dispatch(
        pushSnackBarItemReports({
          key: Math.random(),
          message: `Отчет обновлен`,
          status: 'success',
          autoClose: 5,
        })
      );
    } else {
      await reportsApi.createReport(params);
      dispatch(
        pushSnackBarItemReports({
          key: Math.random(),
          message: `Отчет создан`,
          status: 'success',
          autoClose: 5,
        })
      );
    }
    refetch();
    onCancel();
  }, [blocks, systems, dataServices, link, name, ad, manual, item, onCancel, refetch]);

  const setAdGroup = useCallback(
    (value: string | null, key: number) => {
      if (value) {
        const tmp = [...(ad as string[])];
        tmp[key] = value;
        setAd(tmp);
      }
    },
    [ad]
  );
  const addGroup = useCallback(() => {
    const tmp = [...(ad as string[])];
    if (tmp) {
      tmp.push('');
      setAd(tmp);
    }
  }, [ad]);

  const deleteGroup = useCallback(
    (key: number) => {
      const tmp = [...(ad as string[])].filter((el, idx) => key !== idx);
      if (tmp) {
        setAd(tmp);
      }
    },
    [ad]
  );

  return (
    <div className={classes.reportsModal}>
      <div className={classes.closeButton}>
        <Button size={'m'} onlyIcon view={'clear'} iconRight={IconClose} onClick={() => onCancel()} />
      </div>
      <div className={classes.header}>{item ? 'Редактирование отчета' : 'Создание отчета'}</div>
      <div className={classes.body}>
        <div className={classes.field}>
          <TextField
            placeholder="Введите название"
            label="Название отчета"
            labelPosition="top"
            required
            onChange={(value) => setName(value.value)}
            value={name}
            className={classes.TextField}
          />
        </div>
        {dictionaries.blocks && (
          <div className={classes.field}>
            <Combobox
              placeholder="Выберите блок"
              items={dictionaries.blocks}
              value={blocks}
              getItemLabel={(el) => el.name}
              getItemKey={(el) => el._uuid}
              onChange={(value) => setBlocks(value.value)}
              label={'Блок'}
              required
              multiple
            />
          </div>
        )}
        {dictionaries.systems && (
          <div className={classes.field}>
            <Combobox
              placeholder="Выберите систему"
              items={dictionaries.systems}
              value={systems}
              getItemLabel={(el) => el.name}
              getItemKey={(el) => el._uuid}
              onChange={(value) => setSystems(value.value)}
              label={'Система'}
              required
            />
          </div>
        )}
        {dictionaries.dataServices && (
          <div className={classes.field}>
            <Combobox
              placeholder="Выберите дата-сервисы"
              items={dictionaries.dataServices}
              value={dataServices}
              getItemLabel={(el) => el.name}
              getItemKey={(el) => el._uuid}
              onChange={(value) => setDataServices(value.value)}
              label={'Дата-сервисы'}
              required
              multiple
            />
          </div>
        )}
        <div className={classes.field}>
          <div className={classes.label}>Инструкция по получению доступа</div>
          <CustomEditor
            placeholder={'Инструкция по получению доступа'}
            value={manual}
            onChange={(...event: any[]) => setManual(event[0])}
          />
        </div>
        <div className={classes.field}>
          <TextField
            placeholder="https://"
            label="Ссылка на отчет"
            labelPosition="top"
            onChange={(value) => setLink(value.value)}
            value={link}
            required
            className={classes.TextField}
          />
        </div>
        <div className={classes.adGroups}>AD-группы</div>
        {ad &&
          ad?.length > 0 &&
          ad.map((el, key) => (
            <div className={classes.field} key={key}>
              <div className={classes.label}>
                AD-группа <span className="FieldLabel-Star">*</span>
                {key !== 0 && (
                  <Button
                    size={'xs'}
                    view={'clear'}
                    form={'default'}
                    iconRight={IconTrash}
                    onlyIcon
                    onClick={() => deleteGroup(key)}
                  />
                )}
              </div>
              <TextField
                placeholder="Введите AD-группу"
                // label="AD-группа"
                labelPosition="top"
                onChange={(value) => setAdGroup(value.value, key)}
                value={ad[key]}
                required
                className={classes.TextField}
              />
            </div>
          ))}
        {!ad ||
          (ad?.length === 0 && (
            <div className={classes.field}>
              <div className={classes.label}>
                AD-группа <span className="FieldLabel-Star">*</span>
              </div>
              <TextField
                placeholder="Введите AD-группу"
                // label="AD-группа"
                labelPosition="top"
                onChange={(value) => setAdGroup(value.value, 0)}
                value={ad[0]}
                required
                className={classes.TextField}
              />
            </div>
          ))}
        <Button
          size={'s'}
          view={'ghost'}
          form={'default'}
          label={'Добавить еще'}
          iconLeft={IconAdd}
          onClick={addGroup}
        />
      </div>
      <div className={classes.footer}>
        <Button size={'m'} view={'ghost'} label={'Отменить'} onClick={() => onCancel()} />
        <Button size={'m'} view={'primary'} label={item ? 'Сохранить' : 'Создать'} onClick={() => create()} />
      </div>
    </div>
  );
};

export default ReportsForm;
