import React, { useCallback, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

import { useAppDispatch, useAppSelector } from '../../../../../hooks/hooks';
import {
  deleteTeamProgramThunk,
  deleteTeamUnitThunk,
  getCommandDomainThunk,
  getCommandUnitsThunk,
  getCommandUsersThunk,
  postTeamUnitThunk,
  pushSnackBarItemTeam,
  selectorCommandUsers,
  selectorCurrentUnit,
} from '../../../../../store/commandSlice';
import classes from './TeamUnitsFormComponent.module.css';
import { Button } from '@consta/uikit/Button';
import { Combobox } from '@consta/uikit/ComboboxCanary';
import { IconDraggable } from '@consta/uikit/IconDraggable';
import { IconTrash } from '@consta/uikit/IconTrash';
import { RadioGroup } from '@consta/uikit/RadioGroup';
import { TextField } from '@consta/uikit/TextField';
import { IconAdd } from '@consta/uikit/IconAdd';
import { pushSnackBarItemReports } from '../../../../../store/appSlice';

type Item = {
  label: string;
  id: string | number;
  ord: number;
} | null;
type PropsType = {
  closeSideBar: () => void;
};
type IsHeadItem = {
  name: string;
  disabled?: boolean;
};

const isHeadItems: IsHeadItem[] = [
  {
    name: 'Да',
  },
  {
    name: 'Нет',
  },
];
const TeamUnitsFormComponent: React.FC<PropsType> = ({ closeSideBar }) => {
  const dispatch = useAppDispatch();
  const unitData = useAppSelector(selectorCurrentUnit);

  const users: Item[] = useAppSelector(selectorCommandUsers);
  const [currentUsers, setCurrentUsers] = useState<Item[] | null>(unitData.users);
  const [isHeadValue, setIsHeadValue] = useState<IsHeadItem>(unitData.isHeadValue);
  const [unitValue, setUnitValue] = useState<string | null>(unitData.unitName);
  const [confluenceValue, setConfluenceValue] = useState<string | null>(unitData.confluence);
  const [searchValue, setSearchValue] = useState<string>('');

  const setSearch = useCallback((searchString: string) => {
    setSearchValue(searchString);
    console.log(searchString);
    dispatch(getCommandUsersThunk(searchString)).then(() => {});
  }, []);

  const reorder = (list: any, startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }
    const tmp = reorder(currentUsers, result.source.index, result.destination.index);
    setCurrentUsers(tmp as Item[]);
  };

  const onChangeUserEvent = useCallback(
    (value: Item | null, key: number) => {
      // if (value) {
      const tmp = [...(currentUsers as Item[])];
      if (value) {
        tmp[key] = {
          ord: key,
          id: value.id,
          label: value.label,
        };
      } else {
        tmp[key] = null;
      }
      setCurrentUsers(tmp);
      //}
    },
    [currentUsers]
  );

  const addGroup = useCallback(() => {
    const tmp = [...(currentUsers as Item[])];
    if (tmp) {
      tmp.push(null);
      setCurrentUsers(tmp);
    }
  }, [currentUsers]);

  const deleteGroup = useCallback(
    (key: number) => {
      const tmp = [...(currentUsers as Item[])].filter((el, idx) => key !== idx);
      if (tmp) {
        setCurrentUsers(tmp);
      }
    },
    [currentUsers]
  );

  const postUnit = useCallback(() => {
    const tmp: any = {
      uuid_block: unitData.domain_uuid,
      name: unitValue,
      is_main: isHeadValue.name === 'Да',
      confluence_link: confluenceValue,
      persons: currentUsers
        ?.filter((el) => el?.id)
        .map((el) => {
          return {
            _uuid: el?.id,
          };
        }),
    };
    if (unitData.unitUuid) {
      tmp.uuid_organization = unitData.unitUuid;
    }
    dispatch(postTeamUnitThunk(tmp)).finally(() => {
      closeSideBar();
      dispatch(getCommandUnitsThunk()).then();
    });
  }, [dispatch, currentUsers, confluenceValue, unitValue, isHeadValue, unitData, closeSideBar]);

  const deleteUnit = useCallback(() => {
    dispatch(deleteTeamUnitThunk(unitData?.unitUuid)).finally(() => {
      dispatch(
        pushSnackBarItemTeam({
          key: Math.random(),
          message: `Подразделение удалено `,
          status: 'success',
          autoClose: 5,
        })
      );
      closeSideBar();
      dispatch(getCommandUnitsThunk()).then();
    });
  }, [unitData, closeSideBar, dispatch]);

  return (
    <div className={classes.sidebarFilter}>
      <div className={classes.itemBlock}>
        <div className={classes.sidebarTitle}>
          <div>Информация об отделе</div>
        </div>
        <div className={classes.sidebarItem}>
          <div className={classes.itemTitle}>Блок</div>
          <div className={classes.itemText}>{unitData.domain_name}</div>
        </div>
        <div className={classes.sidebarItem}>
          <TextField
            value={unitValue}
            type="textarea"
            cols={200}
            rows={2}
            placeholder="Введите название отдела"
            onChange={(value) => setUnitValue(value.value)}
            label={'Название бизнес-единицы / ДО'}
          />
        </div>
        <div className={classes.sidebarItem}>
          <TextField
            value={confluenceValue}
            type="text"
            placeholder="Введите ссылку"
            label={'Ссылка на Confluence ЦДД'}
            onChange={(value) => setConfluenceValue(value.value)}
            className={classes.fullWidth}
          />
        </div>
        <div className={classes.sidebarItem}>
          <div className={classes.itemTitle}>Является главным отделом</div>
          <RadioGroup
            value={isHeadValue}
            items={isHeadItems}
            getItemLabel={(item: IsHeadItem) => item.name}
            getItemDisabled={(item: IsHeadItem) => item.disabled}
            onChange={(value) => setIsHeadValue(value.value)}
            direction="row"
          />
        </div>
      </div>
      <div className={classes.itemBlock}>
        <div className={classes.sidebarTitle}>
          <div>Сотрудники</div>
        </div>

        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {currentUsers &&
                  currentUsers.length > 0 &&
                  currentUsers.map((user, key) => (
                    <Draggable key={key} draggableId={key.toString()} index={key}>
                      {(provided) => (
                        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                          <div className={classes.sidebarItem} key={key}>
                            <div className={classes.itemTitle}>
                              <IconDraggable size={'s'} /> Сотрудник №{key + 1}{' '}
                              <IconTrash size={'s'} className={classes.IconTrash} onClick={() => deleteGroup(key)} />
                            </div>
                            {users && (
                              <Combobox
                                style={{ zIndex: 999 }}
                                label="Cотрудник"
                                items={users}
                                value={user?.id ? user : null}
                                getItemLabel={(el: Item) => el?.label || ''}
                                getItemKey={(el: Item) => el?.id || ''}
                                onSearchValueChange={setSearch}
                                searchValue={searchValue}
                                onChange={(valueItem) => onChangeUserEvent(valueItem, key)}
                                placeholder="Выберите сотрудника из списка"
                              />
                            )}
                          </div>
                        </div>
                      )}
                    </Draggable>
                  ))}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        <div className={classes.sidebarItem}>
          <Button
            size={'s'}
            view={'ghost'}
            label={'Добавить еще'}
            onClick={addGroup}
            iconLeft={IconAdd}
            className={classes.moreButton}
          />
        </div>
      </div>
      <div className={classes.bottomButtons}>
        <div>
          {unitData?.unitUuid && (
            <Button size={'m'} view={'ghost'} onlyIcon iconRight={IconTrash} onClick={deleteUnit} />
          )}
        </div>
        <div>
          <Button size="m" view="ghost" form={'default'} label={'Отмена'} onClick={closeSideBar} />
          <Button size="m" view="primary" form={'default'} label={'Сохранить'} onClick={postUnit} />
        </div>
      </div>
    </div>
  );
};

export default TeamUnitsFormComponent;
