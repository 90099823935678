import React from 'react';

import infoIcon from '../../img/info.svg';
import { InfoLinkType } from '../../types/types';
import InfoLinkCard from '../InfoLinkCard/InfoLinkCard';
import classes from './SectionProductInfoLinks.module.css';
import { Text } from '@consta/uikit/Text';

type PropsType = {
  infoLinks: InfoLinkType[] | undefined;
};

const SectionProductInfoLinks: React.FC<PropsType> = ({ infoLinks }) => {
  return (
    <div className={classes.container}>
      <div className={classes.contentContainer}>
        <Text size="3xl" weight="bold" className={classes.title}>
          <img src={infoIcon} alt="info" className={classes.infoImg} /> Информационные ссылки
        </Text>
        <div className={classes.list}>
          {infoLinks?.map((infoLink, ind) => (
            <InfoLinkCard key={ind} infoLink={infoLink} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default SectionProductInfoLinks;
