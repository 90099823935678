import React, { useState } from 'react';

import classes from './ExpandGroup.module.css';
import { IconArrowDown } from '@consta/uikit/IconArrowDown';
import { IconArrowUp } from '@consta/uikit/IconArrowUp';
import { Text } from '@consta/uikit/Text';
import classNames from 'classnames';

type PropsType = {
  groupTitle: string;
  groupColor: string;
  children: JSX.Element;
};

const ExpandGroup: React.FC<PropsType> = ({ groupTitle, groupColor, children }) => {
  const [isOpen, setIsOpen] = useState(true);
  const [isShowOutline, setIsShowOutline] = useState(false);

  const onMouseEnter = () => {
    if (!isOpen) {
      setIsShowOutline(true);
    }
  };

  const onMouseLeave = () => {
    setIsShowOutline(false);
  };

  const onClick = () => {
    setIsOpen((prev) => !prev);
  };

  return (
    <div className={classes.container}>
      <div
        className={classNames(classes.sectionHeader, {
          [classes.openBorders]: isOpen,
          [classes.showOutline]: isShowOutline,
        })}
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <div className={classes.colorSide} style={{ backgroundColor: `${groupColor}` }} />
        <div className={classes.titleRow}>
          <Text className={classes.title}>{groupTitle}</Text>
          {
            <Text size="s" className={classes.icoTitle}>
              {isOpen ? 'Свернуть' : 'Раскрыть'}
            </Text>
          }
          <div className={classes.icoContainer}>
            {isOpen ? <IconArrowUp size={'xs'} /> : <IconArrowDown size={'xs'} />}
          </div>
        </div>
      </div>
      {isOpen && <div className={classes.openPart}>{children}</div>}
    </div>
  );
};

export default ExpandGroup;
