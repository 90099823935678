import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

import { RouteType } from './types/types';
import { Location } from 'history';

type PropsType = {
  appRoutes: RouteType[];
  appRedirectRoute: string;
  onChangeLocation?: (location: Location) => void;
};

const AppRouter: React.FC<PropsType> = ({ appRoutes, appRedirectRoute, onChangeLocation }) => {
  const search = useLocation().search;
  const pathname = useLocation().pathname;

  React.useEffect(() => {
    onChangeLocation && onChangeLocation({ pathname, search, hash: '', key: '', state: '' });
  }, [pathname, search, onChangeLocation]);

  return (
    <Routes>
      {appRoutes.map((route, ind) => (
        <Route key={ind} path={route.path} element={route.element} />
      ))}
      <Route key={`redirectRoute`} path={'*'} element={<Navigate to={appRedirectRoute} replace />} />
    </Routes>
  );
};

export default AppRouter;
