import { serverApi } from '../api/serverApi';
import { getRejectedValue } from './rejectedValueHelper';
import { RootState } from './store';
import { createAsyncThunk, createSlice, current, isAnyOf } from '@reduxjs/toolkit';
import { StoreSnackBarItemType } from '../types/types';
import { pushSnackBarItemReports } from './appSlice';

type TeamTeamType = {
  team: any[];
  domains: [];
  units: [];
  programs: [];
  users: [];
  blocks: [];
  divisions: [];
  currentGroups: [];
  currentLeader: any;
  currentTeamEmployee: any;
  currentConfluence: any;
  group?: any;
  currentUnit: any;
  isLoading: boolean;
  isError?: string;
  snackBarItems: StoreSnackBarItemType[];
  groupsByDomain: any;
};

const initialState: TeamTeamType = {
  team: [],
  domains: [],
  units: [],
  programs: [],
  currentLeader: {},
  currentTeamEmployee: {},
  currentConfluence: {},
  currentUnit: {},
  users: [],
  blocks: [],
  currentGroups: [],
  divisions: [],
  isLoading: false,
  isError: undefined,
  snackBarItems: [],
  group: null,
  groupsByDomain: [],
};

export const getCommandTeamThunk = createAsyncThunk<any, undefined, { rejectValue: string }>(
  'command/getTeamThunk',
  async (_, { rejectWithValue }) => {
    try {
      return await serverApi.getTeamTeam();
    } catch (e) {
      return rejectWithValue(getRejectedValue('Ошибка получения команды', e));
    }
  }
);
export const getCommandDomainThunk = createAsyncThunk<any, undefined, { rejectValue: string }>(
  'command/getDomainThunk',
  async (_, { rejectWithValue }) => {
    try {
      return await serverApi.getTeamDomains();
    } catch (e) {
      return rejectWithValue(getRejectedValue('Ошибка получения доменов', e));
    }
  }
);

export const getCommandUnitsThunk = createAsyncThunk<any, undefined, { rejectValue: string }>(
  'command/getUnitsThunk',
  async (_, { rejectWithValue }) => {
    try {
      return await serverApi.getTeamUnits();
    } catch (e) {
      return rejectWithValue(getRejectedValue('Ошибка получения ДО', e));
    }
  }
);

export const getCommandProgramsThunk = createAsyncThunk<any, undefined, { rejectValue: string }>(
  'command/getProgramsThunk',
  async (_, { rejectWithValue }) => {
    try {
      return await serverApi.getTeamPrograms();
    } catch (e) {
      return rejectWithValue(getRejectedValue('Ошибка получения ДО', e));
    }
  }
);
export const getCommandUsersThunk = createAsyncThunk<any, any, { rejectValue: string }>(
  'command/getUsersThunk',
  async (search: string = '', { rejectWithValue }) => {
    try {
      return await serverApi.getTeamUsers(search);
    } catch (e) {
      return rejectWithValue(getRejectedValue('Ошибка получения пользователей', e));
    }
  }
);

export const getCommandGroupsByDomainThunk = createAsyncThunk<any, undefined, { rejectValue: string }>(
  'command/getCommandGroupsByDomainThunk',
  async (_, { rejectWithValue }) => {
    try {
      return await serverApi.getGroupsByDomain();
    } catch (e) {
      return rejectWithValue(getRejectedValue('Ошибка получения групп', e));
    }
  }
);

export const getCommandBlocksThunk = createAsyncThunk<any, undefined, { rejectValue: string }>(
  'command/getBlocksThunk',
  async (_, { rejectWithValue }) => {
    try {
      return await serverApi.getTeamBlocks();
    } catch (e) {
      return rejectWithValue(getRejectedValue('Ошибка получения блоков', e));
    }
  }
);

export const getCommandDivisionThunk = createAsyncThunk<any, undefined, { rejectValue: string }>(
  'command/getDivisionThunk',
  async (_, { rejectWithValue }) => {
    try {
      return await serverApi.getTeamDivisions();
    } catch (e) {
      return rejectWithValue(getRejectedValue('Ошибка получения подразделений', e));
    }
  }
);

export const postCommandLeaderThunk = createAsyncThunk<any, any, { rejectValue: string }>(
  'command/postCommandLeaderThunk',
  async (leaderData: any, { rejectWithValue }) => {
    try {
      return await serverApi.postLeader(leaderData.uuid_block, leaderData.uuid_person, leaderData.position);
    } catch (e) {
      return rejectWithValue(getRejectedValue('Ошибка получения пользователей', e));
    }
  }
);

export const postCommandGroupsThunk = createAsyncThunk<any, any, { rejectValue: string }>(
  'command/postCommandGroupsThunk',
  async (divisions: any, { rejectWithValue }) => {
    try {
      return await serverApi.postTeamDivisions(divisions);
    } catch (e) {
      return rejectWithValue(getRejectedValue('Ошибка  редактирования групп', e));
    }
  }
);

export const postCommandDomainGroupsThunk = createAsyncThunk<any, any, { rejectValue: string }>(
  'command/postCommandDomainGroupsThunk',
  async (data: any, { rejectWithValue }) => {
    try {
      return await serverApi.postCommandDomainGroups(data.groups, data.uuid_block);
    } catch (e) {
      return rejectWithValue(getRejectedValue('Ошибка  редактирования групп', e));
    }
  }
);
export const postTeamEmployeeThunk = createAsyncThunk<any, any, { rejectValue: string }>(
  'command/postTeamEmployeeThunk',
  async (emplpoyeeData: any, thunkAPI) => {
    try {
      return await serverApi.postEmployee(
        emplpoyeeData.uuid_block,
        emplpoyeeData.uuid_person,
        emplpoyeeData.position,
        emplpoyeeData.uuid_team_division
      );
    } catch (e: any) {
      const messages = Object.keys(e.response.data);
      const firstError: any = Object.keys(e.response.data[messages[0]])[0];
      const errorMessage = e.response.data[messages[0]][firstError].toString();
      thunkAPI.dispatch(
        pushSnackBarItemReports({
          key: Math.random(),
          message: errorMessage,
          status: 'alert',
          autoClose: 5,
        })
      );
      return thunkAPI.rejectWithValue(getRejectedValue('Ошибка удаления подразделения', e));
    }
  }
);

export const postTeamUnitThunk = createAsyncThunk<any, any, { rejectValue: string }>(
  'command/postTeamUnit',
  async (unitData: any, thunkAPI) => {
    try {
      return await serverApi.postUnit(unitData);
    } catch (e: any) {
      const messages = Object.keys(e.response.data);
      const firstError: any = Object.keys(e.response.data[messages[0]])[0];
      const errorMessage = e.response.data[messages[0]][firstError].toString();
      thunkAPI.dispatch(
        pushSnackBarItemReports({
          key: Math.random(),
          message: errorMessage,
          status: 'alert',
          autoClose: 5,
        })
      );
      return thunkAPI.rejectWithValue(getRejectedValue('Ошибка удаления подразделения', e));
    }
  }
);

export const postTeamDomainThunk = createAsyncThunk<any, any, { rejectValue: string }>(
  'command/postTeamDomainThunk',
  async (unitData: any, thunkAPI) => {
    try {
      return await serverApi.postTeamDomain(unitData);
    } catch (e: any) {
      const messages = Object.keys(e.response.data);
      const firstError: any = Object.keys(e.response.data[messages[0]])[0];
      const errorMessage = e.response.data[messages[0]][firstError].toString();
      thunkAPI.dispatch(
        pushSnackBarItemReports({
          key: Math.random(),
          message: errorMessage,
          status: 'alert',
          autoClose: 5,
        })
      );
      return thunkAPI.rejectWithValue(getRejectedValue('Ошибка удаления подразделения', e));
    }
  }
);

export const postTeamProgramThunk = createAsyncThunk<any, any, { rejectValue: string }>(
  'command/postTeamProgram',
  async (unitData: any, thunkAPI) => {
    try {
      return await serverApi.postProgram(unitData);
    } catch (e: any) {
      const messages = Object.keys(e.response.data);
      const firstError: any = Object.keys(e.response.data[messages[0]])[0];
      const errorMessage = e.response.data[messages[0]][firstError].toString();
      thunkAPI.dispatch(
        pushSnackBarItemReports({
          key: Math.random(),
          message: errorMessage,
          status: 'alert',
          autoClose: 5,
        })
      );
      return thunkAPI.rejectWithValue(getRejectedValue('Ошибка удаления подразделения', e));
    }
  }
);

export const deleteTeamEmployeeThunk = createAsyncThunk<any, any, { rejectValue: string }>(
  'command/deleteTeamEmployeeThunk',
  async (uuid: string, { rejectWithValue }) => {
    try {
      return await serverApi.deleteEmployee(uuid);
    } catch (e) {
      return rejectWithValue(getRejectedValue('Ошибка получения удаления сотрудника', e));
    }
  }
);

export const postTeamConfluenceThunk = createAsyncThunk<any, any, { rejectValue: string }>(
  'command/postTeamConfluenceThunk',
  async (confluenceData: any, { rejectWithValue }) => {
    try {
      return await serverApi.postConfluence(
        confluenceData.uuid_block,
        confluenceData.uuid_team_division,
        confluenceData.name,
        confluenceData.link
      );
    } catch (e) {
      return rejectWithValue(getRejectedValue('Ошибка создания конфлюенса', e));
    }
  }
);

export const deleteTeamConfluenceThunk = createAsyncThunk<any, any, { rejectValue: string }>(
  'command/deleteTeamConfluenceThunk',
  async (uuid: string, { rejectWithValue }) => {
    try {
      return await serverApi.deleteConfluence(uuid);
    } catch (e) {
      return rejectWithValue(getRejectedValue('Ошибка удаления конфлюенса', e));
    }
  }
);

export const deleteTeamDomainThunk = createAsyncThunk<any, any, { rejectValue: string }>(
  'command/deleteTeamDomainThunk',
  async (uuid: string, { rejectWithValue }) => {
    try {
      return await serverApi.deleteDomain(uuid);
    } catch (e) {
      return rejectWithValue(getRejectedValue('Ошибка удаления домена', e));
    }
  }
);
export const deleteTeamUnitThunk = createAsyncThunk<any, any, { rejectValue: string }>(
  'command/deleteTeamUnitThunk',
  async (uuid: string, { rejectWithValue }) => {
    try {
      return await serverApi.deleteUnit(uuid);
    } catch (e) {
      return rejectWithValue(getRejectedValue('Ошибка удаления подразделения', e));
    }
  }
);
export const deleteTeamProgramThunk = createAsyncThunk<any, any, { rejectValue: string }>(
  'command/deleteTeamProgramThunk',
  async (uuid: string, { rejectWithValue }) => {
    try {
      return await serverApi.deleteProgram(uuid);
    } catch (e) {
      return rejectWithValue(getRejectedValue('Ошибка удаления программы', e));
    }
  }
);

export const commandSlice = createSlice({
  name: 'commandSlice',
  initialState,
  reducers: {
    setIsError: (state, action) => {
      state.isError = action.payload;
    },
    setCurrentLeader: (state, action) => {
      state.currentLeader = action.payload;
    },
    setCurrentTeamEmployee: (state, action) => {
      state.currentTeamEmployee = action.payload;
    },
    setCurrentConfluence: (state, action) => {
      state.currentConfluence = action.payload;
    },
    setCurrentUnit: (state, action) => {
      state.currentUnit = action.payload;
    },
    clearSnackBarItemsTeam: (state) => {
      state.snackBarItems = [];
    },
    deleteSnackBarItemTeam: (state, action) => {
      state.snackBarItems = state.snackBarItems.filter((item) => item.key !== action.payload.key);
    },
    pushSnackBarItemTeam: (state, action) => {
      state.snackBarItems.push({
        key: action.payload.key ? action.payload.key : action.payload.message + action.payload.status,
        message: action.payload.message,
        status: action.payload.status,
        autoClose: action.payload.autoClose ? 5 : undefined,
      });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCommandTeamThunk.fulfilled, (state, action) => {
        state.team = action.payload.data;
      })
      .addCase(getCommandDomainThunk.fulfilled, (state, action) => {
        state.domains = action.payload.data;
      })
      .addCase(getCommandUnitsThunk.fulfilled, (state, action) => {
        state.units = action.payload.data;
      })
      .addCase(getCommandProgramsThunk.fulfilled, (state, action) => {
        state.programs = action.payload.data;
      })
      .addCase(getCommandUsersThunk.fulfilled, (state, action) => {
        state.users = action.payload;
      })
      .addCase(getCommandGroupsByDomainThunk.fulfilled, (state, action) => {
        state.groupsByDomain = action.payload;
      })
      .addCase(getCommandDivisionThunk.fulfilled, (state, action) => {
        state.divisions = action.payload;
      })
      .addCase(getCommandBlocksThunk.fulfilled, (state, action) => {
        state.blocks = action.payload;
      })
      .addCase(postTeamUnitThunk.fulfilled, (state, action) => {
        state.snackBarItems.push({
          key: Math.random(),
          message: `Информация об отделе обновлена `,
          status: 'success',
          autoClose: 5,
        });
      })
      .addCase(postTeamProgramThunk.fulfilled, (state, action) => {
        state.snackBarItems.push({
          key: Math.random(),
          message: `Информация о программе  обновлена`,
          status: 'success',
          autoClose: 5,
        });
      })
      .addCase(postTeamDomainThunk.fulfilled, (state, action) => {
        state.snackBarItems.push({
          key: Math.random(),
          message: `Информация о домене обновлена `,
          status: 'success',
          autoClose: 5,
        });
      })
      .addMatcher(
        isAnyOf(
          getCommandTeamThunk.pending,
          getCommandDomainThunk.pending,
          getCommandUnitsThunk.pending,
          getCommandProgramsThunk.pending,
          postCommandLeaderThunk.pending,
          postTeamUnitThunk.pending,
          postTeamDomainThunk.pending,
          getCommandBlocksThunk.pending
        ),
        (state) => {
          state.isLoading = true;
        }
      )
      .addMatcher(
        isAnyOf(
          getCommandTeamThunk.rejected,
          getCommandDomainThunk.rejected,
          getCommandUnitsThunk.rejected,
          getCommandProgramsThunk.rejected,
          postCommandLeaderThunk.rejected,
          postTeamUnitThunk.rejected,
          postTeamDomainThunk.rejected,
          deleteTeamEmployeeThunk.rejected
        ),
        (state, action) => {
          state.isLoading = false;
          state.isError = action.payload ? action.payload : 'Неизвестная ошибка - commandSlice';
        }
      );
  },
});

export const {
  setIsError,
  setCurrentLeader,
  setCurrentTeamEmployee,
  setCurrentConfluence,
  setCurrentUnit,
  clearSnackBarItemsTeam,
  deleteSnackBarItemTeam,
  pushSnackBarItemTeam,
} = commandSlice.actions;

// export const selectorLearningIsLoading = (state: RootState) => state.learning.isLoading;
export const selectorCommandError = (state: RootState) => state.command.isError;
export const selectorCommandTeam = (state: RootState) => state.command.team;
export const selectorCommandDomains = (state: RootState) => state.command.domains;
export const selectorCommandUnits = (state: RootState) => state.command.units;
export const selectorCommandPrograms = (state: RootState) => state.command.programs;
export const selectorCommandUsers = (state: RootState) => state.command.users;
export const selectorCurrentLeader = (state: RootState) => state.command.currentLeader;
export const selectorCurrentTeamEmployee = (state: RootState) => state.command.currentTeamEmployee;
export const selectorCurrentTeamConfluence = (state: RootState) => state.command.currentConfluence;
export const selectorCurrentUnit = (state: RootState) => state.command.currentUnit;
export const selectorCurrentGroups = (state: RootState) => state.command.groupsByDomain;
export const selectorBlocks = (state: RootState) => state.command.blocks;
export const selectorDivisions = (state: RootState) => state.command.divisions;
export const selectorSnackBarItemsTeam = (state: RootState) => state.command.snackBarItems;
export default commandSlice.reducer;
