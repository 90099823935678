import React, { useEffect } from 'react';

import ModalError from '../../Components/ModalError/ModalError';
import { useAppDispatch, useAppSelector } from '../../hooks/hooks';
import {
  getDocumentFormatsThunk,
  getDocumentStatusesThunk,
  getDocumentsThunk,
  getDocumentTagsThunk,
  getDocumentTypesThunk,
  getProcessesThunk,
  selectorMethodologyError,
} from '../../store/methodologySlice';
import MainPart from './MainPart/MainPart';
import ModalDocumentCard from './MainPart/ModalDocumentCard/ModalDocumentCard';
import classes from './MethodologyPage.module.css';
import PageHeader from './PageHeader/PageHeader';
import ModalProcessCard from './MainPart/ModalProcessCard/ModalProcessCard';

const MethodologyPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const methodologyError = useAppSelector(selectorMethodologyError);

  useEffect(() => {
    dispatch(getDocumentTagsThunk());
    dispatch(getDocumentTypesThunk());
    dispatch(getDocumentFormatsThunk());
    dispatch(getDocumentStatusesThunk());
    dispatch(getProcessesThunk());
    dispatch(getDocumentsThunk({ queryString: '' }));
  }, [dispatch]);

  return (
    <div className={classes.container}>
      <PageHeader />
      <MainPart />
      <ModalError errorMessage={methodologyError} />
      <ModalDocumentCard />
      <ModalProcessCard />
    </div>
  );
};

export default MethodologyPage;
