import { MetaServerType, TagServerType } from './serverResponse';

export enum BlogEventEnum {
  news = 'news',
  announcement = 'announcement',
  case = 'best_practice',
}

export type BlogPictureType = {
  _uuid: string;
  path: string;
};

export type BlogEventType = {
  _uuid: string;
  type: BlogEventEnum;
  name: string;
  description: string;
  picture: BlogPictureType;
  link: string;
  tags: TagServerType[];
  publication_date: string;
};

export type BlogEventsServerResponseType = {
  data: BlogEventType[];
  meta: MetaServerType;
};

export type BlogPersonType = {
  _uuid: string;
  first_name: string;
  last_name?: string;
  middle_name?: string;
  email?: string;
  display_role?: string;
  role?: string;
  position?: string;
  is_news_subscriber: boolean;
};

export type BlogCommentType = {
  _uuid: string;
  value: string;
  person: BlogPersonType;
  _create_ts: string;
};

export type BlogNewsDetailType = {
  _uuid: string;
  name: string;
  description: string;
  picture: BlogPictureType;
  comments: BlogCommentType[];
  tags: TagServerType[];
  publication_date: string | null;
};

export type BlogNewsDetailServerResponseType = {
  data: BlogNewsDetailType;
};

export type BlogAnnouncementType = {
  _uuid: string;
  name: string;
  description: string;
  format: string;
  start_date: string;
  finish_date: string;
  place_name: string;
  provider: string;
  total_place: number;
  free_place: number;
  uuid_training: string | null;
  instructors: BlogPersonType[];
  tags: TagServerType[];
  picture?: BlogPictureType;
  publication_date: string | null;
  link: string | null;
};

export type BlogAnnouncementServerResponseType = {
  data: BlogAnnouncementType;
};

export type BlogTagsDictionaryResponseType = {
  data: TagServerType[];
};

export type BlogCaseType = {
  _uuid: string;
  name: string;
  description: string;
  publication_date: string;
  type: string;
  source: string | null;
  document: string | null;
  authors: BlogPersonType[];
  tags: TagServerType[];
  picture?: BlogPictureType;
};

export type BlogCasesDetailsResponseType = {
  data: BlogCaseType;
};

export type SuggestionContentType = 'announcement' | 'news';

export type BlogPostSuggestionParamsType = {
  value: string;
  type: SuggestionContentType;
  is_speaker: boolean;
  uuid_person: string;
};

export type BlogPostSuggestionServerType = {
  _uuid: string;
  value: string;
  type: SuggestionContentType;
  is_speaker: boolean;
  person: BlogPersonType;
};

export type BlogPostSuggestionServerResponseType = {
  data: BlogPostSuggestionServerType;
};

export type BlogGetUsersServerResponseType = {
  data: BlogPersonType[];
};
