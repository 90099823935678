import React, { useEffect } from 'react';

export const useClickOutsideComponent = (
  ref: React.RefObject<HTMLElement>,
  callback: () => void,
  refException?: React.RefObject<HTMLElement>
) => {
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        if (refException?.current) {
          if (!refException.current.contains(event.target as Node)) {
            callback();
          }
        } else {
          callback();
        }
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, refException, callback]);
};
