import React from 'react';

import { useAppSelector } from '../../../../hooks/hooks';
import Delimiter from '../../../../shared-front/Delimiter/Delimiter';
import {
  selectorViewSelectorActiveItem,
  selectorViewSelectorIsLoadingBlogItems,
} from '../../../../store/adminBlogSlice';
import classes from './AdminBlogPageContent.module.css';
import AdminBlogTable from './AdminBlogTable/AdminBlogTable';
import { Loader } from '@consta/uikit/Loader';
import AdminSubscriptionTable from './AdminSubscriptionTable/AdminSubscriptionTable';

interface IAdminBlogPageContentProps {}

const AdminBlogPageContent: React.FC<IAdminBlogPageContentProps> = () => {
  const isLoadingBlogItems = useAppSelector(selectorViewSelectorIsLoadingBlogItems);
  const view = useAppSelector(selectorViewSelectorActiveItem);

  return (
    <div className={classes.container}>
      <Delimiter />
      {view?.name == 'Опубликованные материалы' && (
        <div className={classes.content}>{isLoadingBlogItems ? <Loader /> : <AdminBlogTable />}</div>
      )}
      {view?.name !== 'Опубликованные материалы' && (
        <div className={classes.content}>{isLoadingBlogItems ? <Loader /> : <AdminSubscriptionTable />}</div>
      )}
      <Delimiter />
    </div>
  );
};

export default AdminBlogPageContent;
