import React from 'react';

import { useAppDispatch } from '../../hooks/hooks';
import { setIsOpenBlogModalSuggestion } from '../../store/blogSlice';
import BlogModalSuggestion from './BlogModalSuggestion/BlogModalSuggestion';
import classes from './BlogSuggestionsBlock.module.css';
import { Button } from '@consta/uikit/Button';
import { IconForward } from '@consta/uikit/IconForward';
import { Text } from '@consta/uikit/Text';

const BlogSuggestionsBlock = () => {
  const dispatch = useAppDispatch();

  const onClick = () => {
    dispatch(setIsOpenBlogModalSuggestion(true));
  };

  return (
    <div className={classes.container}>
      <Text className={classes.title}>{`Предложите новость\nили тему события`}</Text>
      <Text
        className={classes.subtitle}
      >{`Расскажите, какой новостью полезно поделиться с читателями или какое событие мы бы могли провести.`}</Text>
      <div>
        <Button
          label={`Предложить`}
          className={classes.btnReadMore}
          iconRight={IconForward}
          view={'ghost'}
          onClick={onClick}
        />
      </div>
      <BlogModalSuggestion />
    </div>
  );
};

export default BlogSuggestionsBlock;
