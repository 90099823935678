import React, { useState } from 'react';

import { MetricType } from '../../../types/types';
import classes from './AdminMetricContent.module.css';
import AdminMetricItem from './AdminMetricItem/AdminMetricItem';
import classNames from 'classnames';

type PropsType = {
  metrics: MetricType[];
};
const AdminMetricContent: React.FC<PropsType> = ({ metrics }) => {
  const refArr: React.RefObject<HTMLDivElement>[] = [];
  const [currentItem, setCurrentItem] = useState(0);
  metrics.forEach(() => {
    const tmpRef: React.RefObject<HTMLDivElement> = React.createRef();
    refArr.push(tmpRef);
  });

  const scrollToRef = (ref: React.RefObject<HTMLDivElement>) => {
    if (ref) {
      ref?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  const getIcon = (iconName: string) => {
    return require(`../../../img/admin/productIcons/${iconName}.svg`);
  };
  return (
    <>
      <div className={classes.container}>
        <div className={classes.contentContainer}>
          <div className={classes.menu}>
            {metrics.map((el, i) => (
              <div
                className={classNames(classes.menuItem, { [classes.menuItemActive]: i === currentItem })}
                onClick={() => {
                  scrollToRef(refArr[i]);
                  setCurrentItem(i);
                }}
              >
                {el.name}
              </div>
            ))}
          </div>
          <div className={classes.content}>
            {metrics.map((el, i) => (
              <div className={classes.metricsContainer} ref={refArr[i]}>
                <div className={classes.metricTitle}>
                  <img src={getIcon(el.slug)} alt={el.slug} className={classes.productImg} />
                  {el.name}
                  <div className={classes.productOwner}>{el.owner}</div>
                </div>
                {el.metrics.map((item) => (
                  <AdminMetricItem metric={item} />
                ))}
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminMetricContent;
