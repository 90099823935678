import React, { useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import { useClickOutsideComponent } from '../../hooks/useClickOutsideComponent';
import { MenuItemType } from '../../types/types';
import logo_data_office from '../assets/logo_data_office.svg';
import logo_laba from '../assets/logo_laba.svg';
import logo_market from '../assets/logo_market.svg';
import logo_quality from '../assets/logo_quality.svg';
import logo_school from '../assets/logo_school.svg';
import logo_tech_meta from '../assets/logo_tech_meta.svg';
import { PortalsEnum } from '../sharedSideBarMenuItems';
import { scrollToTop } from '../utils/utils';
import classes from './Logo.module.css';
import SidebarMenu from './SidebarMenu/SidebarMenu';
import { Button } from '@consta/uikit/Button';
import { IconClose } from '@consta/uikit/IconClose';
import { IconHamburger } from '@consta/uikit/IconHamburger';
import classNames from 'classnames';

type PropsType = {
  portal: PortalsEnum;
  sideBarMenuItems: MenuItemType[];
  appLogo?: React.ReactNode;
  onLogoClickRoute?: string;
  onLogoClick?: () => void;
  customLogo?: string;
};

const Logo: React.FC<PropsType> = ({ portal, sideBarMenuItems, onLogoClickRoute, onLogoClick }) => {
  const navigate = useNavigate();
  const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);

  const onLogoClickHandler = () => {
    onLogoClick && onLogoClick();
    if (onLogoClickRoute) {
      scrollToTop();
      navigate(onLogoClickRoute);
    }
  };

  const onToggleSidebar = () => {
    setIsSidebarOpen((prev) => !prev);
  };

  const closeSidebar = () => {
    if (isSidebarOpen) {
      setIsSidebarOpen(false);
    }
  };

  const refSidebar = useRef<HTMLDivElement>(null);
  const refButton = useRef<HTMLDivElement>(null);
  useClickOutsideComponent(refSidebar, closeSidebar, refButton);

  const logoImgSrc =
    portal === PortalsEnum.DataOffice
      ? logo_data_office
      : portal === PortalsEnum.Laboratory
      ? logo_laba
      : portal === PortalsEnum.Quality
      ? logo_quality
      : portal === PortalsEnum.Meta
      ? logo_tech_meta
      : portal === PortalsEnum.School
      ? logo_school
      : portal === PortalsEnum.Market
      ? logo_market
      : logo_data_office;

  return (
    <div className={classes.container}>
      <div ref={refButton} className={classes.btnContainer}>
        <Button
          className={classes.logoBtn}
          iconRight={isSidebarOpen ? IconClose : IconHamburger}
          onClick={onToggleSidebar}
          view={'clear'}
          onlyIcon
        />
      </div>
      <div className={classes.logoWrapper}>
        <img src={logoImgSrc} className={classes.logo} alt="logo" onClick={onLogoClickHandler} />
      </div>
      <div ref={refSidebar} className={classNames(classes.sideBar, { [classes.sidebarOpen]: isSidebarOpen })}>
        <SidebarMenu isSidebarOpen={isSidebarOpen} sideBarMenuItems={sideBarMenuItems} closeSidebar={closeSidebar} />
      </div>
    </div>
  );
};

export default Logo;
