import React from 'react';

import { openURLinNewTab } from '../../../utils/openURLinNewTab';
import classes from './DataProviders.module.css';
import { Text } from '@consta/uikit/Text';
import { Select } from '@consta/uikit/Select';

type Item = {
  label: string;
  id: number;
  url: string;
};

const items: Item[] = [
  {
    id: 1,
    label: 'БЛПС',
    url: 'https://blps.pixbi',
  },
  {
    id: 2,
    label: 'БРД',
    url: 'https://brd.pixbi',
  },
  {
    id: 3,
    label: 'ДРП',
    url: 'https://drp.pixbi',
  },
  {
    id: 4,
    label: 'КФ',
    url: 'https://cc.pixbi',
  },
];

const itemsLux: Item[] = [
  {
    id: 1,
    label: 'БРД',
    url: 'https://brd.luxmsbi.gazprom-neft.local/',
  },
  {
    id: 2,
    label: 'КФ',
    url: 'https://cc.luxmsbi.gazprom-neft.local/',
  },
];

const DataProviders: React.FC = () => {
  const cardLabClick = () => {
    openURLinNewTab('https://datalab.gazprom-neft.local');
  };
  const onChange = ({ value }: { value: Item | null }) => {
    openURLinNewTab(value?.url);
  };
  return (
    <div className={classes.container}>
      <Text className={classes.title}>{`Предоставляем качественные данные`}</Text>
      <Text
        className={classes.description}
      >{`Даем возможность гибкого поиска данных,\nвизуализации и анализа для бизнес-пользователей`}</Text>
      <div className={classes.cardsContainer}>
        <div className={classes.cardSearch}>
          <Text className={classes.cardTitle}>{`Поиск по техническим\nметаданным`}</Text>
          <Text
            className={classes.cardDescription}
          >{`Гибкий поиск метаданных\nпо техническому наименованию и описанию`}</Text>
        </div>
        <div className={classes.cardLab} onClick={cardLabClick}>
          {/*<div className={classes.cardLab}>*/}
          <Text className={classes.cardTitle}>{`Лаборатория данных`}</Text>
          <Text className={classes.cardDescription}>{`Self-service разработка дашбордов\nдля анализа данных`}</Text>
        </div>
        <div
          className={classes.cardMeta}
          onClick={() => openURLinNewTab('http://spb99pkl-alxc01.gazprom-neft.local/search?query=&comeFrom=suggest')}
        >
          <Text className={classes.cardTitle}>{`Поиск по бизнес-метаданным`}</Text>
          <Text
            className={classes.cardDescription}
          >{`Гибкий поиск данных по описанию,\nсформулированному в бизнес-терминах`}</Text>
        </div>
        <div className={classes.cardBi}>
          <Text className={classes.cardTitle}>{`Бизнес-аналитика (BI)`}</Text>
          <Text className={classes.cardDescription}>{`Преднастроенные дашборды для\nанализа данных`}</Text>
          <div className={classes.selects}>
            <Select
              placeholder="PIX BI"
              form="round"
              items={items}
              onChange={onChange}
              size={'s'}
              className={classes.mySelect}
            />
            <Select
              placeholder="LuxMS"
              form="round"
              items={itemsLux}
              onChange={onChange}
              size={'s'}
              className={classes.mySelect}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DataProviders;
