import React from 'react';
import { Children } from 'react';

import classes from './LayoutBanneredPage.module.css';

type PropsType = {
  pageHeader: React.ReactElement;
  children: React.ReactElement | React.ReactElement[];
};

const LayoutBanneredPage: React.FC<PropsType> = ({ pageHeader, children }) => {
  return (
    <div className={classes.container}>
      {pageHeader}
      <div className={classes.contentContainer}>{Children.map(children, (child) => child)}</div>
    </div>
  );
};

export default LayoutBanneredPage;
