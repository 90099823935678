import { ReportsDictionaryItemType } from '../types/types';

export const prepareReportAction = (
  blocks: ReportsDictionaryItemType[] | any,
  systems: ReportsDictionaryItemType | any,
  dataServices: ReportsDictionaryItemType[] | any,
  link: string | any,
  name: string | any,
  ad: string[] | any,
  manual: string | any
) => {
  return {
    name: name,
    link: link,
    manual: manual,
    uuid_system: systems._uuid,
    uuids_block: blocks?.map((el: any) => el._uuid),
    uuids_data_service: dataServices.map((el: any) => el._uuid),
    ad_groups: ad
      .filter((el: string) => el)
      .map((el: string) => {
        return { name: el };
      }),
  };
};
