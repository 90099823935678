import React, { useCallback, useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../../../../hooks/hooks';
import {
  ADMIN_BLOG_PAGE_VIEW_SELECTOR_ITEMS,
  selectorViewSelectorActiveItem,
  setAdminBlogInArchive,
  setViewSelectorActiveItem,
} from '../../../../../store/adminBlogSlice';
import { ViewSelectorItemType } from '../../../../../types/types';
import classes from './AdminBlogPageViewSelector.module.css';
import { ChoiceGroup } from '@consta/uikit/ChoiceGroup';
import { Switch } from '@consta/uikit/Switch';
import { Text } from '@consta/uikit/Text';
import { useGetBlogItems } from '../../../../../hooks/useGetBlogItems';
interface IAdminBlogPageViewSelectorProps {}

const AdminBlogPageViewSelector: React.FC<IAdminBlogPageViewSelectorProps> = () => {
  const dispatch = useAppDispatch();
  const viewSelectorActiveItem = useAppSelector(selectorViewSelectorActiveItem);
  const [inArchive, setInArchive] = useState<boolean>(false);

  const onChangeViewSelector = ({ value }: { value: ViewSelectorItemType }) => {
    dispatch(setViewSelectorActiveItem(value));
  };

  const getBlogItems = useGetBlogItems();

  const handleChange = useCallback((checked: boolean) => {
    setInArchive(checked);
    dispatch(setAdminBlogInArchive(checked));
    getBlogItems({ inArchive: checked });
  }, []);
  return (
    <div className={classes.container}>
      <ChoiceGroup
        size={'s'}
        value={viewSelectorActiveItem}
        onChange={onChangeViewSelector}
        items={ADMIN_BLOG_PAGE_VIEW_SELECTOR_ITEMS}
        getItemLabel={(item: ViewSelectorItemType) => item.name}
        multiple={false}
        name={'adminBlogPageViewSelector'}
      />
      <div className={classes.switch}>
        <Text className={classes.title}>{'В архиве'}</Text>
        <Switch view="ghost" checked={inArchive} onChange={({ checked }) => handleChange(checked)} />
      </div>
    </div>
  );
};

export default AdminBlogPageViewSelector;
