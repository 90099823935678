import axios from 'axios';

const instanceAxios = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL_API,
  withCredentials: false,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

instanceAxios.interceptors.request.use((config) => {
  const user = localStorage.getItem('user');
  config.headers['X-Auth-User'] = user ? user : 'aberzhanin';
  return config;
});

export default instanceAxios;
