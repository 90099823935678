import React from 'react';

import classes from '../../../TableColumnsTune/ColumnsTuneList/ColumnsTuneListItem/ColumnsTuneListItem.module.css';
import { ColumnFilterItemType } from '../../TableColumnFilter';
import { Checkbox } from '@consta/uikit/Checkbox';

interface IFilterItemProps {
  filterItem: ColumnFilterItemType;
  onChange: (filterItem: ColumnFilterItemType) => void;
  isChecked: boolean;
}

const FilterItem: React.FC<IFilterItemProps> = ({ filterItem, onChange, isChecked }) => {
  const onChangeHandler = () => onChange(filterItem);
  return (
    <div className={classes.container}>
      <Checkbox checked={isChecked} align={'center'} label={filterItem.value} onChange={onChangeHandler} />
    </div>
  );
};

export default FilterItem;
