import { ColumnSortType } from '../types/types';

export const getColumnSortingsQuery = (selectedSortings: ColumnSortType[]) => {
  let query = '';
  if (selectedSortings.length > 0) {
    selectedSortings.forEach((sort) => {
      query = `${query}&sort[${sort.sortName}]=${sort.value}`;
    });
  }
  return query;
};
