import React, { useEffect, useState } from 'react';

import { useAppSelector } from '../../../../../hooks/hooks';
import { selectorMethodologyIsLoading, setIsOpenLeftColumn } from '../../../../../store/methodologySlice';
import DocumentsTable from './DocumentsTable/DocumentsTable';
import classes from './DocumentsTableContainer.module.css';
import { Loader } from '@consta/uikit/Loader';

const DocumentsTableContainer: React.FC = () => {
  const isLoading = useAppSelector(selectorMethodologyIsLoading);

  const isOpenLeftColumn = useAppSelector(setIsOpenLeftColumn);
  const [showTable, setShowTable] = useState(true);

  useEffect(() => {
    setShowTable(false);
    setTimeout(() => {
      setShowTable(true);
    }, 500);
  }, [isOpenLeftColumn]);

  return (
    <div className={classes.container}>
      {isLoading || !showTable ? (
        <div className={classes.loaderContainer}>
          <Loader />
        </div>
      ) : (
        <DocumentsTable />
      )}
    </div>
  );
};

export default DocumentsTableContainer;
