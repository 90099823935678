import React from 'react';

import ContentList from './ContentList/ContentList';
import classes from './LeftColumn.module.css';

const LeftColumn: React.FC = () => {
  return (
    <div className={classes.container}>
      <ContentList />
    </div>
  );
};

export default LeftColumn;
