import React from 'react';
import { useNavigate } from 'react-router-dom';

import { PageRoutesEnum } from '../../../appMenuAndRouting/appRoutes';
import classes from './LearningVideos.module.css';
import VideosCarousel from './VideosCarousel/VideosCarousel';
import { Button } from '@consta/uikit/Button';
import { Tag } from '@consta/uikit/Tag';
import { Text } from '@consta/uikit/Text';

const LearningVideos: React.FC = () => {
  const navigate = useNavigate();

  const onViewAllVideos = () => {
    navigate(`${PageRoutesEnum.Learning_Videos}`);
  };

  return (
    <div className={classes.container}>
      <Text className={classes.title}>{`Видео материалы`}</Text>
      <div className={classes.filtersRow}>
        <Tag mode="button" onClick={() => {}} label="Все" className={classes.allTag} size={'s'} />
        <div className={classes.tagGroup}>
          <Tag mode="check" onChange={() => {}} label="Програмирование" size={'s'} checked={false} />
          <Tag mode="check" onChange={() => {}} label="Аналитика" size={'s'} checked={false} />
          <Tag mode="check" onChange={() => {}} label="DevOps" size={'s'} checked={false} />
          <Tag mode="check" onChange={() => {}} label="Product" size={'s'} checked={false} />
          <Tag mode="check" onChange={() => {}} label="Front" size={'s'} checked={false} />
          <Tag mode="check" onChange={() => {}} label="Копирайтинг" size={'s'} checked={false} />
          <Tag mode="check" onChange={() => {}} label="UX редактура" size={'s'} checked={false} />
        </div>
      </div>
      <VideosCarousel />
      <div className={classes.btnSeeAll}>
        <Button view="secondary" label="Смотреть все видео" size={'s'} onClick={onViewAllVideos} />
      </div>
    </div>
  );
};

export default LearningVideos;
