import { useEffect, useState } from 'react';

import { BlogCaseType } from '../api/blogServerResponse';
import { serverApi } from '../api/serverApi';
import { setAppError } from '../store/appSlice';
import { useAppDispatch } from './hooks';

export const useBlogCase = (id: string | undefined): [BlogCaseType | undefined, boolean] => {
  const dispatch = useAppDispatch();
  const [blogCase, setBlogCase] = useState<BlogCaseType | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (id) {
      setIsLoading(true);
      serverApi
        .getBlogCaseDetailsById(id)
        .then((res) => {
          if (res) {
            setBlogCase(res.data);
          }
        })
        .catch(() => {
          dispatch(setAppError('Ошибка получения кейса в Блоге'));
        })
        .finally(() => setIsLoading(false));
    }
  }, [id, dispatch]);

  return [blogCase, isLoading];
};
