export const getDateForAnnouncementQuery = (dateTime: Date) => {
  const yearOptions: Intl.DateTimeFormatOptions = {
    year: 'numeric',
  };
  const yearFormatter = new Intl.DateTimeFormat('ru', yearOptions);
  const year = yearFormatter.format(dateTime);

  const monthOptions: Intl.DateTimeFormatOptions = {
    month: '2-digit',
  };
  const monthFormatter = new Intl.DateTimeFormat('ru', monthOptions);
  const month = monthFormatter.format(dateTime);

  const dayOptions: Intl.DateTimeFormatOptions = {
    day: '2-digit',
  };
  const dayFormatter = new Intl.DateTimeFormat('ru', dayOptions);
  const day = dayFormatter.format(dateTime);

  return `${year}-${month}-${day}`;
};
