import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../../../../hooks/hooks';
import { useGetBlogItems } from '../../../../../hooks/useGetBlogItems';
import TextSearchArea from '../../../../../shared-front/TextSearchArea/TextSearchArea';
import {
  addBlogSubscriptionThunk,
  getAdminBlogSubscriptionThunk,
  selectorViewSelectorActiveItem,
  selectorViewSelectorSearchValue,
  setAdminBlogSearchValue,
} from '../../../../../store/adminBlogSlice';
import classes from './AdminBlogPageActionRow.module.css';
import SortingSelector from './SortingSelector/SortingSelector';
import { Button } from '@consta/uikit/Button';
import { IconAdd } from '@consta/uikit/IconAdd';
import { IconDownload } from '@consta/uikit/IconDownload';
import { IconFunnel } from '@consta/uikit/IconFunnel';
import { Modal } from '@consta/uikit/Modal';
import { Combobox } from '@consta/uikit/ComboboxCanary';
import { getCommandUsersThunk, selectorCommandUsers } from '../../../../../store/commandSlice';
import { pushSnackBarItemReports } from '../../../../../store/appSlice';

interface IAdminBlogPageActionRowProps {}
interface UsersType {
  id: string;
  label: string;
}
const AdminBlogPageActionRow: React.FC<IAdminBlogPageActionRowProps> = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const getBlogItems = useGetBlogItems();
  const searchValue = useAppSelector(selectorViewSelectorSearchValue);
  const view = useAppSelector(selectorViewSelectorActiveItem);
  const [subscriptionModalIsOpen, setSubscriptionModalIsOpen] = useState<boolean>(false);
  const users = useAppSelector(selectorCommandUsers);
  const [user, setUser] = useState<UsersType | null>(null);
  const [userSearchValue, setUserSearchValue] = useState<string>('');

  const setSearchValueHandler = (value: string) => {
    dispatch(setAdminBlogSearchValue(value));
  };

  const setUserSearch = useCallback((searchString: string) => {
    setUserSearchValue(searchString);
    dispatch(getCommandUsersThunk(searchString)).then(() => {});
  }, []);
  const onSearch = (value: { searchValue?: string }) => {
    if (value.searchValue) {
      getBlogItems({ searchValue: value.searchValue });
    } else {
      getBlogItems({ searchValue: null });
    }
  };

  const addToSubscription = useCallback(() => {
    if (user) {
      dispatch(addBlogSubscriptionThunk(user?.id)).then((data) => {
        setSubscriptionModalIsOpen(false);
        dispatch(
          pushSnackBarItemReports({
            key: Math.random(),
            message: `${user.label} добавлен в рассылку!`,
            status: 'success',
            autoClose: 5,
          })
        );
        dispatch(getAdminBlogSubscriptionThunk());
      });
    }
  }, [user, dispatch, setSubscriptionModalIsOpen]);

  return (
    <div className={classes.container}>
      <div className={classes.leftSide}>
        {view?.slug === 'news' && (
          <Button label={'Добавить'} size={'s'} iconRight={IconAdd} onClick={() => navigate('/admin/blog/news/add')} />
        )}
        {view?.slug === 'subscription' && (
          <>
            <Button
              label={'Добавить'}
              size={'s'}
              iconRight={IconAdd}
              onClick={() => setSubscriptionModalIsOpen(true)}
            />
            <Modal
              isOpen={subscriptionModalIsOpen}
              className={classes.reportsModal}
              hasOverlay
              onClickOutside={() => setSubscriptionModalIsOpen(false)}
              onEsc={() => setSubscriptionModalIsOpen(false)}
            >
              <div className={classes.addSubscribers}>
                <div className={classes.formTitle}>Добавление в рассылку</div>
                <div className={classes.formContent}>
                  <Combobox
                    style={{ zIndex: 99999 }}
                    label="Сотрудник"
                    items={users}
                    value={user?.id ? user : null}
                    onChange={(value: any) => {
                      setUser(value);
                    }}
                    getItemLabel={(el: UsersType) => el.label}
                    getItemKey={(el: UsersType) => el.id}
                    onSearchValueChange={setUserSearch}
                    searchValue={userSearchValue}
                    className={classes.fullWidth}
                  />
                </div>
                <div className={classes.formFooter}>
                  <Button view={'primary'} size={'m'} label={'Добавить'} onClick={addToSubscription} />
                </div>
              </div>
            </Modal>
          </>
        )}
      </div>
      <div className={classes.rightSide}>
        <TextSearchArea
          searchValue={searchValue ? searchValue : ''}
          setSearchValue={setSearchValueHandler}
          onSearch={onSearch}
          customStyleWidth={'140px'}
          placeholder={'Поиск'}
        />
        <div className={classes.divider} />
        <Button label={'PDF'} size={'s'} view={'ghost'} disabled iconLeft={IconDownload} />
        <div className={classes.divider} />
        <SortingSelector />
        <Button onlyIcon size={'s'} view={'ghost'} disabled iconLeft={IconFunnel} style={{ marginLeft: '12px' }} />
      </div>
    </div>
  );
};

export default AdminBlogPageActionRow;
